import React from 'react';

// banner img
import StuBanner1 from 'assets/img/banner/student-dashboard/stu_dash_banner_01.png';
import StuBanner3 from 'assets/img/banner/student-dashboard/stu_dash_banner_03.png';
import StuBanner4 from 'assets/img/banner/student-dashboard/stu_dash_banner_04.jpg';
import StuBanner5 from 'assets/img/banner/student-dashboard/stu_dash_banner_05.jpg';
import StuBanner6 from 'assets/img/banner/student-dashboard/stu_dash_banner_06.jpg';
import StuBanner7 from 'assets/img/banner/student-dashboard/stu_dash_banner_07.jpg';
import StuBanner8 from 'assets/img/banner/student-dashboard/stu_dash_banner_08.jpg';

import BoardPreView from 'components/board/_components/_boardPreView';
import SwiperContainer from 'components/_common/lib/swiper/SwiperContainer';

/** 학생 대시보드 페이지의 "DSAT info" 영역 컴포넌트 */
export default function DsatInfoArea() {
  return (
    <>
      <BoardPreView type='dsat' user='student' />
      <SwiperContainer
        containerClass={'banner'}
        itemClass={''}
        bannerContent={[
          // { link: 'https://forms.gle/GUN1FVtRqUGXn7dF9', image: StuBanner6, visibleTo: 'B2B' },
          { link: 'https://pf.kakao.com/_prxbCG/106579635', image: StuBanner7, visibleTo: 'B2B' },
          { link: 'http://pf.kakao.com/_prxbCG/106579515', image: StuBanner8, visibleTo: 'B2B' },
          {
            link: 'https://product.kyobobook.co.kr/detail/S000213501792',
            image: StuBanner1,
            visibleTo: 'ALL',
          },
          { link: 'https://www.youtube.com/watch?v=YhTTOtyFlAU', image: StuBanner3, visibleTo: 'ALL' },
          { link: 'https://pf.kakao.com/_prxbCG', image: StuBanner4, visibleTo: 'ALL' },
          { link: 'https://www.gateplus.org', image: StuBanner5, visibleTo: 'ALL' },
        ]}
      />
    </>
  );
}
