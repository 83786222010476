import Lottie from 'lottie-react';
import examLoadingAnimation from 'assets/lottie/exam_loading_animation.json';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

/** 시험 로딩 모달 컴포넌트 */
function ExamLoadingModal({ baseTime = 0.2 }) {
  const [isToLong, setIsToLong] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsToLong(true);
    }, baseTime * 1000);
  }, [baseTime]);

  return isToLong ? (
    <SimpleModalBase>
      <div className='modal_img_wrapper' style={{ height: '13.5rem', overflow: 'hidden' }}>
        <Lottie animationData={examLoadingAnimation} style={{ width: '26.5rem', height: '26.5rem' }} />
      </div>

      <div className='modal_text_container'>
        <p style={{ fontSize: '1.25rem', color: '#7F8998' }}>
          Your internet connection is too slow.
          <br />
          Please wait or close the window and try again.
        </p>
      </div>
    </SimpleModalBase>
  ) : (
    <S.ShortLoading></S.ShortLoading>
  );
}

export default ExamLoadingModal;

const S = {};

/** 시험 로딩중 더블클릭을 막기 위한 투명한 오버레이  */
S.ShortLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.01);
  filter: blur(0.6);
`;
