import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Gnb from 'layout/Gnb';
import Footer from 'layout/Footer'; //Footer
import Login from 'components/user/sign-in/Page';
import DSATInfoListPage from 'components/board/DSAT-info/list/Page';
import DSATInfoViewPage from 'components/board/DSAT-info/view/Page';

const DSATInfoRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        { path: '/', element: <Navigate to='list' /> },
        // { path: "/feedback" , element: <DSATInfoList/> }, // component 수정해야 함
        { path: '/list', element: <DSATInfoListPage /> },
        { path: '/view', element: <DSATInfoViewPage /> },

        { path: '*', element: <Navigate to='news/list' /> },
        // { path: "/" , element: <DSATInfoView/> }, // component 수정해야 함
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  console.log(pathname, authBool);

  return (
    <>
      <Gnb PgTit='SAT INFO' />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default DSATInfoRoute;
