import React from 'react';

/** 학생 대시보드 페이지의 "Advanced Report" Section 의 "Correct answer rate by Categories(영어)" 영역 컴포넌트 */
export default function CorrectAnswerRateByCategoriesRw({ barChartData, handleClickGraphItemField, activeSatBox, testResult }) {
  const barChartDataAvg = barChartData[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / barChartData[0].data.length;

  return (
    <article className={`box_graph per50 ${activeSatBox === 'rw' ? 'active' : 'disable'}`} onClick={() => handleClickGraphItemField('rw')}>
      {/*활성화 시 addClass active*/}
      <div className='top_area'>
        <p className='tit_field'>Correct answer rate by Categories</p>
        <div className='type_field'>
          <p className='name'>RW</p>
          <p className='data'>{testResult.subject !== 'M' ? `${barChartDataAvg.toFixed(2)}%` : '-'}</p>
        </div>
      </div>
      {/* <BarChart barChartData={barChartData}/>    */}
      <div className='com_answer_grah'>
        {/*Craft and Structure*/}
        <div className='item type01'>
          <p className='tit_field'>
            <i className='color'>&nbsp;</i>CAS
          </p>
          <div className='grah_field'>
            <div className='graph_wrap' style={{ width: `${barChartData[0].data[0]}%` }}>
              <div
                className={`rate my ${barChartData[0].data[0] < barChartData[0].gatePlusData[0] ? 'low' : ''} ${barChartData[0].data[0] === 0 ? 'zero' : ''}`}>
                <div className='bar'>&nbsp;</div>
                <p className='num'>{barChartData[0].data[0]}%</p>
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>Craft and Structure :&nbsp;
                <b>{barChartData[0].data[0]}%</b>
              </div>
            </div>
            <div
              className='graph_wrap'
              style={{
                width: `${
                  barChartData[0].data[0] === barChartData[0].gatePlusData[0]
                    ? `calc(${barChartData[0].data[0]} + 10px)%`
                    : `${barChartData[0].gatePlusData[0]}%`
                }`,
              }}>
              <div
                className={`rate average ${barChartData[0].data[0] > barChartData[0].gatePlusData[0] ? 'low' : ''} ${
                  barChartData[0].gatePlusData[0] === 0 ? 'zero' : ''
                }`}>
                {/*수치가 더 낮은 rate에 addClass low*/}
                <div className='bar'>&nbsp;</div>
                {/* <p className="num">28.57%</p> */}
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                <b>{barChartData[0].gatePlusData[0]}%</b>
              </div>
            </div>
            <div className='bar'>&nbsp;</div>
          </div>
        </div>
        {/*Information and Ideas*/}
        <div className='item type02'>
          <p className='tit_field'>
            <i className='color'>&nbsp;</i>IAI
          </p>
          <div className='grah_field'>
            <div className='graph_wrap' style={{ width: `${barChartData[0].data[1]}%` }}>
              <div
                className={`rate my ${barChartData[0].data[1] < barChartData[0].gatePlusData[1] ? 'low' : ''} ${barChartData[0].data[1] === 0 ? 'zero' : ''}`}>
                {/*수치가 더 낮은 rate에 addClass low*/}
                <div className='bar'>&nbsp;</div>
                <p className='num'>{barChartData[0].data[1]}%</p>
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>Information and Ideas :&nbsp;
                <b>{barChartData[0].data[1]}%</b>
              </div>
            </div>
            <div
              className='graph_wrap'
              style={{
                width: `${
                  barChartData[0].data[1] === barChartData[0].gatePlusData[1]
                    ? `calc(${barChartData[0].data[1]} + 10px)%`
                    : `${barChartData[0].gatePlusData[1]}%`
                }`,
              }}>
              <div
                className={`rate average ${barChartData[0].data[1] > barChartData[0].gatePlusData[1] ? 'low' : ''} ${
                  barChartData[0].gatePlusData[1] === 0 ? 'zero' : ''
                }`}>
                <div className='bar'>&nbsp;</div>
                {/* <p className="num">54%</p> */}
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                <b>{barChartData[0].gatePlusData[1]}%</b>
              </div>
            </div>
            <div className='bar'>&nbsp;</div>
          </div>
        </div>
        {/*Standard English Conventions*/}
        <div className='item type03'>
          <p className='tit_field'>
            <i className='color'>&nbsp;</i>SEC
          </p>
          <div className='grah_field'>
            <div className='graph_wrap' style={{ width: `${barChartData[0].data[2]}%` }}>
              <div
                className={`rate my ${barChartData[0].data[2] < barChartData[0].gatePlusData[2] ? 'low' : ''} ${barChartData[0].data[2] === 0 ? 'zero' : ''}`}>
                <div className='bar'>&nbsp;</div>
                <p className='num'>{barChartData[0].data[2]}%</p>
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>Standard English Conventions :&nbsp;<b>{barChartData[0].data[2]}%</b>
              </div>
            </div>
            <div
              className='graph_wrap'
              style={{
                width: `${
                  barChartData[0].data[2] === barChartData[0].gatePlusData[2]
                    ? `calc(${barChartData[0].data[2]} + 10px)%`
                    : `${barChartData[0].gatePlusData[2]}%`
                }`,
              }}>
              {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
              <div
                className={`rate average ${barChartData[0].data[2] > barChartData[0].gatePlusData[2] ? 'low' : ''} ${
                  barChartData[0].gatePlusData[2] === 0 ? 'zero' : ''
                }`}>
                <div className='bar'>&nbsp;</div>
                {/* <p className="num">70%</p> */}
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                <b>{barChartData[0].gatePlusData[2]}%</b>
              </div>
            </div>
            <div className='bar'>&nbsp;</div>
          </div>
        </div>
        {/*Expression of Ideas*/}
        <div className='item type04'>
          <p className='tit_field'>
            <i className='color'>&nbsp;</i>EOI
          </p>
          <div className='grah_field'>
            <div className='graph_wrap' style={{ width: `${barChartData[0].data[3]}%` }}>
              <div
                className={`rate my ${barChartData[0].data[3] < barChartData[0].gatePlusData[3] ? 'low' : ''} ${barChartData[0].data[3] === 0 ? 'zero' : ''}`}>
                <div className='bar'>&nbsp;</div>
                <p className='num'>{barChartData[0].data[3]}%</p>
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>Expression of Ideas :&nbsp;
                <b>{barChartData[0].data[3]}%</b>
              </div>
            </div>
            <div
              className='graph_wrap'
              style={{
                width: `${
                  barChartData[0].data[3] === barChartData[0].gatePlusData[3]
                    ? `calc(${barChartData[0].data[3]} + 10px)%`
                    : `${barChartData[0].gatePlusData[3]}%`
                }`,
              }}>
              {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
              <div
                className={`rate average ${barChartData[0].data[3] > barChartData[0].gatePlusData[3] ? 'low' : ''} ${
                  barChartData[0].gatePlusData[3] === 0 ? 'zero' : ''
                }`}>
                <div className='bar'>&nbsp;</div>
                {/* <p className="num">25%</p> */}
              </div>
              <div className='layer'>
                <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                <b>{barChartData[0].gatePlusData[3]}%</b>
              </div>
            </div>
            <div className='bar'>&nbsp;</div>
          </div>
        </div>
      </div>
    </article>
  );
}
