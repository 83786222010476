const { useSelector } = require('react-redux');

/** 시험 모듈 버튼 컴포넌트 */
export default function BtnTest({
  isB2c,
  tit,
  seq,
  rw,
  math,
  rwTakenNum,
  maTakenNum,
  rwTakenUser,
  maTakenUser,
  viewAverageSubject,
  selectModules,
  setSelectModules,
}) {
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  const toggleActive = (item) => {
    if (!selectModules.includes(item)) {
      const updateSelectList = [...selectModules, item];
      setSelectModules(updateSelectList);
    } else {
      const updateSelectList = selectModules.filter((key) => key !== item);
      setSelectModules(updateSelectList);
    }
  };

  return (
    <button className={`btn_test ${selectModules.includes(seq) ? 'active' : ''}`} onClick={() => toggleActive(seq)}>
      <div className='txt_box__wrap'>
        {viewAverageSubject !== 'M' && rwTakenNum > 0 && (
          <div className='txt_box'>
            {isB2c ? `You have taken/scheduled RW` : `${rwTakenNum} / ${stateSetTest.selectedStudents.length} students have taken MA`}
            {/* {tit}*/}
            <div className='layer'>
              <p className='layer_tit'>RW</p>
              {!isB2c && <p className='txt'>Go back to previous step to change the selected student list</p>}
              <div className='student_list scroll'>
                {rwTakenUser &&
                  rwTakenUser.map((i, idx) => (
                    <div className='item' key={`inner_${idx}`}>
                      <p className='name'>{i.userName}</p>
                      <div className='btn_area'>
                        {i.isTaken > 0 && <span className='com_btn xxs lightgray taken'>Taken</span>}
                        {i.isScheduled > 0 && <span className='com_btn xxs lightgray scheduled'>Scheduled</span>}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {viewAverageSubject !== 'RW' && maTakenNum > 0 && (
          <div className='txt_box'>
            {isB2c ? `You have taken/scheduled Math` : `${maTakenNum} / ${stateSetTest.selectedStudents.length} students have taken Math`}
            {/* {tit}*/}
            <div className='layer'>
              <p className='layer_tit'>Math</p>
              {!isB2c && <p className='txt'>Go back to previous step to change the selected student list</p>}
              <div className='student_list scroll'>
                {maTakenUser &&
                  maTakenUser.map((i, idx) => (
                    <div className='item' key={`inner_${idx}`}>
                      <p className='name'>{i.userName}</p>
                      <div className='btn_area'>
                        {i.isTaken > 0 && <span className='com_btn xxs lightgray taken'>Taken</span>}
                        {i.isScheduled > 0 && <span className='com_btn xxs lightgray scheduled'>Scheduled</span>}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <span className='tit_field'>{tit}</span>
      {viewAverageSubject === 'F' ? (
        <span className='txt_field'>
          {rw}
          <br />
          {math}
        </span>
      ) : viewAverageSubject === 'RW' ? (
        <span className='txt_field'>
          {rw}
          <br />
        </span>
      ) : (
        <span className='txt_field'>
          {math}
          <br />
        </span>
      )}
    </button>
  );
}
