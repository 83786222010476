import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { nvl } from 'utils/Common.utils';

//////// components
import styled from 'styled-components';
import { styledCssEllipsis } from 'styles/styledComponents/globalStyles';
import useUserLevels from 'hooks/useUserLevels';
import request from 'utils/Request.utils';
import Layout from 'components/com/tests/_components/_common/Layout';
import { clearReduxStateSetTests, updateReduxStateSelectedConfig } from 'reducers/setTests/action';

/** Set Tests : "Configuration 선택" 페이지 */
export default function TestsSetConfigPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent || useLevels.isParent;
  const dispatch = useDispatch();
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** "과목" 선택 중 "Full" 버튼 클릭 핸들러 */
  const handleClickOnTheFullButtonInSubject = () => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testConfiguration: 'F',
      })
    );
  };
  /** "과목" 선택 중 "RW" 버튼 클릭 핸들러 */
  const handleClickOnTheRwButtonInSubject = () => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testConfiguration: 'RW',
      })
    );
  };
  /** "과목" 선택 중 "Math" 버튼 클릭 핸들러 */
  const handleClickOnTheMathButtonInSubject = () => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testConfiguration: 'M',
      })
    );
  };

  /** "adaptive" 버튼 클릭 핸들러 */
  const adaptiveHandler = () => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testType: true,
        testRwDiff: false,
        testMathDiff: false,
      })
    );
  };

  /** "fixed" 버튼 클릭 핸들러 */
  const fixedHandler = () => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testType: false,
        testRwDiff: 'E',
        testMathDiff: 'E',
      })
    );
  };

  /** 영어 활성화 클릭 핸들러 */
  const rwActiveClick = (diff) => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testRwDiff: diff,
      })
    );
  };

  /** 수학 활성화 클릭 핸들러 */
  const mathActiveClick = (diff) => {
    dispatch(
      updateReduxStateSelectedConfig({
        ...stateSetTest.selectedConfig,
        testMathDiff: diff,
      })
    );
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  useEffect(() => {
    if (isB2c) {
      dispatch(clearReduxStateSetTests());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isB2c]);

  return (
    <Layout>
      <S.Wrap>
        <article className='item'>
          <p className='tit'>
            <span className='num'>01</span>Configuration
          </p>
          <div className='btn_test__wrap'>
            <button
              className={`btn_test ${stateSetTest.selectedConfig.testConfiguration === 'F' ? 'active' : ''}`}
              onClick={handleClickOnTheFullButtonInSubject}>
              <span className='tit_field'>Full Set</span>
              <span className='txt_field'>Includes both Math and RW tests</span>
            </button>
            {!isB2c && !useLevelsIsLoading && (
              <>
                <button
                  className={`btn_test ${stateSetTest.selectedConfig.testConfiguration === 'RW' ? 'active' : ''}`}
                  onClick={handleClickOnTheRwButtonInSubject}>
                  <span className='tit_field'>Reading and Writing</span>
                  <span className='txt_field'>
                    Includes Expression of Ideas, <br />
                    Standard English Conventions, <br />
                    Information and Ideas, and Craft and Structure
                  </span>
                </button>
                <button
                  className={`btn_test ${stateSetTest.selectedConfig.testConfiguration === 'M' ? 'active' : ''}`}
                  onClick={handleClickOnTheMathButtonInSubject}>
                  <span className='tit_field'>Math</span>
                  <span className='txt_field'>
                    Includes Algebra, Problem Solving and <br />
                    Data Analysis, Geometry and <br />
                    Trigonometry, and Advanced Math
                  </span>
                </button>
              </>
            )}
          </div>
        </article>

        <article className='item'>
          <p className='tit'>
            <span className='num'>02</span>Type
          </p>
          <div className='btn_test__wrap'>
            <button className={`btn_test ${stateSetTest.selectedConfig.testType ? 'active' : ''}`} onClick={() => adaptiveHandler()}>
              <span className='tit_field'>Adaptive</span>
              <span className='txt_field'>Test with an algorithm that automatically adjusts Module 2 difficulty</span>
            </button>
            <button className={`btn_test ${!stateSetTest.selectedConfig.testType ? 'active' : ''}`} onClick={() => fixedHandler()} disabled={isB2c}>
              <span className='tit_field'>Fixed</span>
              <span className='txt_field'>Test with a fixed Module 2 difficulty</span>
            </button>
          </div>
        </article>

        {!stateSetTest.selectedConfig.testType && ( //01.Configuration = Full Set / 02.Type=Fixed
          <>
            {stateSetTest.selectedConfig.testConfiguration !== 'M' && (
              <article className='item'>
                <p className='tit'>
                  <span className='num'>03</span>Reading and Writing Difficulty
                </p>
                <div className='btn_test__wrap'>
                  <button className={`btn_test ${stateSetTest.selectedConfig.testRwDiff === 'E' ? 'active' : ''}`} onClick={() => rwActiveClick('E')}>
                    <span className='tit_field'>Easy</span>
                    <span className='txt_field'>Subject section score range: 200~600</span>
                  </button>
                  <button className={`btn_test ${stateSetTest.selectedConfig.testRwDiff === 'H' ? 'active' : ''}`} onClick={() => rwActiveClick('H')}>
                    <span className='tit_field'>Hard</span>
                    <span className='txt_field'>Subject Section score range: 200~800</span>
                  </button>
                </div>
              </article>
            )}
            {stateSetTest.selectedConfig.testConfiguration !== 'RW' && (
              <article className='item'>
                <p className='tit'>
                  <span className='num'>{stateSetTest.selectedConfig.testConfiguration !== 'RW' ? '03' : '04'}</span>
                  Math Difficulty
                </p>
                <div className='btn_test__wrap'>
                  <button className={`btn_test ${stateSetTest.selectedConfig.testMathDiff === 'E' ? 'active' : ''}`} onClick={() => mathActiveClick('E')}>
                    <span className='tit_field'>Easy</span>
                    <span className='txt_field'>Subject section score range: 200~600</span>
                  </button>
                  <button className={`btn_test ${stateSetTest.selectedConfig.testMathDiff === 'H' ? 'active' : ''}`} onClick={() => mathActiveClick('H')}>
                    <span className='tit_field'>Hard</span>
                    <span className='txt_field'>Subject Section score range: 200~800</span>
                  </button>
                </div>
              </article>
            )}
          </>
        )}
      </S.Wrap>
    </Layout>
  );
}

const S = {};

S.Wrap = styled.div`
  padding: 15px 0 15px 15px;
  .btn_test {
    border: 1px solid #d2dbe2;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 30px;
    border-radius: 10px;
    align-self: stretch;
    .tit_field {
      font-size: 16px;
      font-weight: 500;
    }
    .txt_field {
      font-size: 10px;
      line-height: 16px;
      color: $emphasis;
      margin-top: 10px;
      & + .com_chk_wrap {
        margin-top: 10px;
      }
    }
    .txt_box {
      border: 1px solid #ebebeb;
      background-color: #fafafa;
      color: var(--SubText-color);
      font-size: 10px;
      font-weight: 500;
      height: 24px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      .layer {
        position: absolute;
        left: auto;
        top: 24px;
        box-shadow: 0px 2px 6px -3px #00000026;
        background-color: #fff;
        text-align: left;
        z-index: 3;
        border-radius: 4px;
        display: none;
        padding: 15px;
        width: 100%;
        &_tit {
          color: #000;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .txt {
          color: var(--SubText-color);
          font-size: 10px;
          line-height: 11.72px;
          margin-bottom: 15px;
        }
        .student_list {
          background-color: #fafafa;
          border-radius: 8px;
          max-height: 246px;
          .item {
            justify-content: space-between;
            gap: 4px;
            .name {
              padding: 10px;
              font-size: 12px;
              color: #4b5563;
              flex: 1;
              ${styledCssEllipsis}
            }
            .btn_area {
              align-items: center;
              margin-left: auto;
              gap: 4px;
              .com_btn {
                border: 1px solid var(--c-border);
                &.taken {
                  color: var(--c-red);
                }
                &.scheduled {
                  color: var(--point-color);
                }
              }
            }
          }
        }
      }
      &:hover {
        border-color: #888888 !important;
        color: #4b5563 !important;
        .layer {
          display: block;
        }
      }
    }
    &.active {
      border-color: #477bff;
      background-color: #f7f8ff;
      & :is(.tit_field, .txt_field) {
        color: #0068bd;
      }
      .txt_box {
        color: #2c85cf;
        background-color: #fff;
        border-color: #d1deff;
        &:hover {
          border-color: var(--point-color);
          color: var(--point-color);
        }
      }
    }
  }
  .btn_test__wrap {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .item {
    .tit {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 1.25rem;
      .num {
        margin-right: 1.5rem;
        color: #a5aab1;
      }
    }
    & + .item {
      margin-top: 3.75rem;
    }
  }
`;
