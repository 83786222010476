import React from 'react';
import { Link } from 'react-router-dom';

/** 각 메인별 이동 버튼 */
export default function MainLink(props) {
  return (
    <section className='com_main_link'>
      <Link to='/com/students' className={`link students ${props.type === 'students' ? 'active' : ''}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>STUDENTS</p>
        <p className='txt'>Manage student settings</p>
      </Link>
      <Link to='/com/tests/scheduled' className={`link test ${props.type === 'test' ? 'active' : ''}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>TEST</p>
        <p className='txt'>Manage test settings</p>
      </Link>
      <Link to='/com/score' className={`link score ${props.type === 'score' ? 'active' : ''}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>SCORE</p>
        <p className='txt'>See test reports</p>
      </Link>
    </section>
  );
}
