import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import request from 'utils/Request.utils';
import useUserLevels from 'hooks/useUserLevels';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


/** 
 * swiper(캐러셀 라이브러리) 사용을 위한 컴포넌트 
 * 🔍 검색 키워드 - #광고 #배너 #캐러셀
 * */
function SwiperContainer({ containerClass, itemClass, bannerContent }) {
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);
  /** b2b 유저인지 확인  */
  const isB2b = useLevels.isAcademy || useLevels.isTutor || useLevels.isTeacher;
  /** 유저 권한별 배너 데이터 필터링  */
  const filteredBanner = bannerContent.filter((item) => {
    // visibleTo = 'ALL' || 'B2B' || 'B2C'
    if (isB2b) {
      return item.visibleTo === 'ALL' || item.visibleTo === 'B2B';
    } else {
      return item.visibleTo === 'ALL' || item.visibleTo === 'B2C';
    }
  });

  // link 없을시 클릭 이벤트 없음
  const noLinkStyle = {
    pointerEvents: 'none',
  };

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      className={containerClass}
      spaceBetween={5}
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      {filteredBanner.map((content, idx) => {
        return (
          <SwiperSlide className={itemClass} key={idx}>
            <a href={content.link} target='_blank' rel='noopener noreferrer' style={content.link === '' ? noLinkStyle : {}}>
              <img src={content.image} alt='배너 이미지' />
            </a>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default SwiperContainer;
