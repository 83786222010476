import React, { useState } from 'react';
import { getRBCEventColor } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import moment from 'moment';

export default function Toolbar({ date, onView, onNavigate, classInfo, setClassInfo, events, setEvents }) {
  const navigate = (action) => {
    onNavigate(action);
  };

  const customView = (type) => {
    onView(type);
  };
  //color 선택 layer
  const [selectColor, setSelectColor] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectedClass, setSelectedClass] = useState(classInfo[0]);

  const toggleSelectColor = () => {
    setSelectColor(!selectColor);
    //setIsActive(!setIsActive);
    setIsOpen((current) => !current);
    setIsActive(false);
  };
  const toggleInputActive = () => {
    setIsActive((current) => !current);
  };
  const handleRadioInput = (event) => {
    const selectedColor = event.target.value;
    const classSeq = event.target.dataset.classSeq;

    const updatedClassInfo = classInfo.map((item) => {
      if (item.classTitle === selectedClass.classTitle) return { ...item, classColor: selectedColor };
      return item;
    });
    const updatedEvents = events.map((item) => {
      if (item.classSeq === selectedClass.classSeq) return { ...item, classColor: selectedColor };
      return item;
    });

    setClassInfo(updatedClassInfo);
    setEvents(updatedEvents);

    let params = { classColor: selectedColor };
    const successHandler = (response) => {
      // if (response.code === 200) console.log(response)
    };
    request.put(`/api/dsat/calendar/classes/${classSeq}`, params, successHandler);
  };

  return (
    <div className='rbc-toolbar'>
      <div className='rbc-btn-group left'>
        <button type='button' onClick={navigate.bind(null, 'TODAY')} className='today'>
          Today
        </button>
      </div>
      <div className='rbc-btn-group date'>
        <button type='button' onClick={navigate.bind(null, 'PREV')} className='com_btn'>
          <i className='svg_icon icon_prev'>&nbsp;</i>
        </button>
        <div className='rbc-toolbar-label'>
          <span className='month'>{`${moment(date).format('MMMM')}`}</span>
          {/*To Do : 달이름이 영문으로 나오도록..*/} &nbsp;
          <span className='day'>{`${date.getFullYear()}`}</span>
        </div>
        <button type='button' onClick={navigate.bind(null, 'NEXT')} className='com_btn'>
          <i className='svg_icon icon_next'>&nbsp;</i>
        </button>
      </div>
      <div className='rbc-btn-group right'>
        <button className={`com_btn s line blue ${isOpen ? 'open' : ''}`} onClick={toggleSelectColor}>
          <i className='svg_icon icon_color before'>&nbsp;</i>Edit Color
          <i className='svg_icon icon_select blue after'>&nbsp;</i>
        </button>
        {/*컬러 선택 레이어*/}
        <div className={`com_set_layer type_color ${selectColor ? 'active' : ''}`}>
          <div className='tit'>Edit Color</div>
          <ul className='com_input_sec'>
            <li className='item'>
              <p className='tit_field'>Class</p>
              <div className='input_field' onClick={toggleInputActive}>
                <div className={`com_select_layer ${isActive ? 'active' : ''}`}>
                  <input type='text' className='selected' placeholder={selectedClass?.classTitle} />
                  <div className='layer' onClick={(e) => setSelectedClass(classInfo.find((info) => info.classTitle === e.target.textContent))}>
                    {classInfo.map((classInfo, idx) => (
                      <p className='option' key={idx}>
                        {classInfo.classTitle}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            <li className='item'>
              <p className='tit_field'>Color</p>

              <div className='input_field color_list'>
                {getRBCEventColor().map((Info, idx) => {
                  return (
                    <div key={idx}>
                      <input
                        type='radio'
                        name='selectColor'
                        className={`chk_color ${Info.name}`}
                        id={`color${idx}`}
                        value={Info.name}
                        onChange={handleRadioInput}
                        checked={selectedClass?.classColor === Info.name}
                        data-class-seq={selectedClass?.classSeq}
                      />
                      <label htmlFor={`color${idx}`}>&nbsp;</label>
                    </div>
                  );
                })}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
