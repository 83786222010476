import React, { useEffect, useState } from 'react';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import SessionStorage from 'utils/SessionStorage.utils';
import dayjsTZ, { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
//img
import IconOk from 'assets/img/icon_ok.svg';
//components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import CustomAlert from 'components/_common/alerts/CustomAlert';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import DeleteModal from 'components/_common/modals/DeleteModal';
import SvgIconTextBtn from 'components/_common/button/SvgIconTextBtn';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import ClassesInfoRow from './_components/table/_ClassesInfoRow.jsx';
import PopClassManage from './_components/popup/_PopClassManage.jsx';
import MainLink from 'components/_common/button/_MainLinks.jsx';

// import PdfDownloadButton from '../_common/PdfDownloadButton.jsx'

/**
 * classes 페이지
 * @description
 * 🔍 검색 키워드 - #classes #page #com/students/classes
 */
export default function ClassesMainPage() {
  const [userInfo, _] = useState(request.tokenDecoder());

  const [lineLength, setLineLength] = useState(10);
  const limitSelect = (value) => {
    setLineLength(value);
    setPagingInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  /** 오늘 날짜를 문자열로 return 하는 함수 */
  const getTodayDate = (value) => {
    const now = dayjsTZ();
    const formats = {
      time: 'YYYY. MM. DD. HH : mm',
      hyphen: 'YYYY-MM-DD',
      default: 'YYYY년 MM월 DD일',
    };
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    const formatted = now.format(formats[value] || formats.default);
    return value === 'default' ? `${formatted} (${week[now.day()]})` : formatted;
  };

  const [openClassManage, setOpenClassManage] = useState(false);
  const handleClassManageOpen = (boolean) => {
    setEditClassInfo(null);
    setOpenClassManage(boolean);
  };

  const [actionType, setActionType] = useState('');

  // Edit 버튼 코드
  const [editClassInfo, setEditClassInfo] = useState(null);
  const handleShowEdit = (classId = null) => {
    if (classId === null) {
      if (checkedClassIds.length > 1 || checkedClassIds.length === 0) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You can only edit one class at a time',
          };
        });
        return;
      }
    }

    classId = classId || checkedClassIds[0];

    let selectedEditData = searchData.find((data) => data.classId === classId);
    selectedEditData = {
      ...selectedEditData,
      startDate: selectedEditData.startDate,
      endDate: selectedEditData.endDate,
    };
    setEditClassInfo(selectedEditData);
    setOpenClassManage(true);
  };

  // Delete 버튼 코드
  const [deleteClass, setDeleteClass] = useState(false); //Class 삭제 팝업
  const [deleteClassAlert, setDeleteClassAlert] = useState(false); //Class 삭제 confirm 팝업
  const handleDeleteBtn = () => {
    if (checkedClassIds.length > 0) {
      setDeleteClass(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };
  const deleteClassAlertOpen = () => {
    setDeleteClass(false);
    setDeleteClassAlert(false);

    const successHandler = (response) => {
      if (response.code === 200) {
        // console.log(response)
        searchClasses();
        setAlert({
          active: true,
          message: 'Successfully deleted selected class.',
        });
      }
    };

    const classList = checkedClassIds.join('|');
    request.del(`/api/academy/classes?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, null, successHandler);
  };
  const [alert, setAlert] = useState({ active: false, message: '' });

  const [searchInfo, setSearchInfo] = useState({
    isDateRangeSelected: false,
    searchStartDate: dayjsTZ().toDate(),
    searchEndDate: dayjsTZ().toDate(),
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [allSearchData, setAllSearchData] = useState([]);

  // Download 버튼 코드
  const [btnDownload, setBtnDownload] = useState(false);
  const toggleBtnDownload = () => {
    setBtnDownload((prevState) => !prevState);
    setBtnPrint(false);
  };
  const excelDownloadSelect = () => {
    if (checkedClassIds.length === 0) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please select classes you want to download or print',
        };
      });
    } else {
      let filename = 'Selected classes' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명
      const classList = checkedClassIds.join('|');
      request.downloadFile(`/api/academy/classes/excel?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, filename);
    }
    setBtnDownload(false);
  };
  const excelDownloadAll = () => {
    setActionType('download');
    if (userInfo.ecSeq > 0) getAllData();

    setBtnDownload(false);
  };

  // Print 버튼 코드
  const [btnPrint, setBtnPrint] = useState(false);
  const toggleBtnPrint = () => {
    setBtnPrint((prevState) => !prevState);
    setBtnDownload(false);
  };

  const printAll = () => {
    setActionType('print');
    if (userInfo.ecSeq > 0) getAllData();

    setBtnPrint(false);
  };
  const printSelect = () => {
    if (checkedClassIds.length === 0) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please select classes you want to download or print',
        };
      });
    } else {
      SessionStorage.clearItem('printCard');
      const selectClassesList = searchData.filter((item) => checkedClassIds.includes(item.classId));
      SessionStorage.setItem('printCard', 'classes', selectClassesList);

      window.open('/com/classes/print', '_blank');
    }
    setBtnPrint(false);
  };
  useEffect(() => {
    if (allSearchData.length > 0) {
      if (actionType === 'print') {
        const printData = allSearchData.map(({ testScheduled, testTaken, ...selectedData }) => selectedData);

        SessionStorage.clearItem('printCard');
        SessionStorage.setItem('printCard', 'classes', printData);

        window.open('/com/classes/print', '_blank');
      }

      if (actionType === 'download') {
        let filename = 'Selected classes' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명
        const classList = allSearchData.map((data) => data.classId).join('|');
        request.downloadFile(`/api/academy/classes/excel?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, filename);
      }
    }

    setActionType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSearchData]);

  // check 버튼 코드
  const [checkedClassIds, setCheckedClassIds] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const isSelectedOneClassId = checkedClassIds.length === 1;
  const isSelectedClassId = checkedClassIds.length > 0;

  const handleCheckboxChange = (classId, isChecked) => {
    if (isChecked) setCheckedClassIds((prev) => [...prev, classId]);
    else setCheckedClassIds((prev) => prev.filter((id) => id !== classId));

    setIsAllChecked(searchData.length === checkedClassIds.length + (isChecked ? 1 : -1));
  };
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);
    if (isChecked) setCheckedClassIds(searchData.map((data) => data.classId));
    else setCheckedClassIds([]);
  };

  // search 코드
  const searchClasses = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { totalCount } = response.result;
        setPagingInfo((prev) => {
          return { ...prev, totalCount, pagePerBlock: 10 };
        });

        const searchData = response.result.classesList.map((data) => {
          return {
            classId: data.classSeq,
            classTitle: data.className,
            startDate: isoTimeToDayjs(data.classStartDate),
            endDate: isoTimeToDayjs(data.classEndDate),
            studentList: data.studentList ? JSON.parse(data.studentList) : [],
            testScheduled: data.testHistoryScheduled ? JSON.parse(data.testHistoryScheduled) : [],
            testTaken: data.testHistoryTaken ? JSON.parse(data.testHistoryTaken) : [],
          };
        });
        setSearchData(searchData);
      }
    };

    let orderBy = 'reg_date DESC';

    if (sortStartDate === 1) orderBy = 'class_start_date DESC, reg_date DESC';
    if (sortStartDate === 2) orderBy = 'class_start_date ASC, reg_date DESC';
    if (sortEndDate === 1) orderBy = 'class_end_date DESC, reg_date DESC';
    if (sortEndDate === 2) orderBy = 'class_end_date ASC, reg_date DESC';

    const searchStartDate = encodeURIComponent(dayjsTZ(searchInfo.searchStartDate).startOf('day').format());
    const searchEndDate = encodeURIComponent(dayjsTZ(searchInfo.searchEndDate).endOf('day').format());

    // 전체검색, 날짜검색, 날짜검색&키워드검색
    // 검색 날짜를 선택 안하고, searchKeyword가 빈문자열(검색어 지정 안함)이면 - 전체 날짜, 모든 키워드 검색
    if (!searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜가 지정되고, searchKeyword가 빈문자열(검색어 지정 안함)이면 - 지정 날짜, 모든 키워드 검색
    } else if (searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜를 선택 안하고, searchKeyword에 검색어가 지정되면 - 전체 날짜, 지정 키워드 검색
    } else if (!searchInfo.isDateRangeSelected && searchKeyword !== '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchKeyword=${encodeURIComponent(searchKeyword)}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜가 지정되고, searchKeyword에 검색어가 지정되면 - 지정 날짜, 지정 키워드 검색
    } else {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchKeyword=${encodeURIComponent(searchKeyword)}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
    }
  };

  // 전체 데이터 호출하기
  const getAllData = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const allSearchData = response.result.classesList.map((data) => {
          return {
            classId: data.classSeq,
            classTitle: data.className,
            startDate: data.classStartDate.split(' ')[0],
            endDate: data.classEndDate.split(' ')[0],
            studentList: data.studentList ? JSON.parse(data.studentList) : [],
            testScheduled: data.testHistoryScheduled ? JSON.parse(data.testHistoryScheduled) : [],
            testTaken: data.testHistoryTaken ? JSON.parse(data.testHistoryTaken) : [],
          };
        });
        setAllSearchData((prev) => (prev = allSearchData));
      }
    };

    const searchStartDate = encodeURIComponent(dayjsTZ(searchInfo.searchStartDate).startOf('day').format());
    const searchEndDate = encodeURIComponent(dayjsTZ(searchInfo.searchEndDate).endOf('day').format());

    if (!searchInfo.isDateRangeSelected && !searchKeyword) {
      request.get(`/api/academy/classes?ecSeq=${userInfo.ecSeq}`, null, successHandler);
    } else if (searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(`/api/academy/classes?ecSeq=${userInfo.ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`, null, successHandler);
    } else {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&searchKeyword=${searchKeyword}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`,
        null,
        successHandler
      );
    }
  };

  // 정렬 코드
  const [sortStartDate, setSortStartDate] = useState(0);
  const [sortEndDate, setSortEndDate] = useState(0);

  const handleClickStartDate = () => {
    setSortStartDate((sortStartDate + 1) % 3);

    setSortEndDate(0);
  };
  const handleClickEndDate = () => {
    setSortEndDate((sortEndDate + 1) % 3);

    setSortStartDate(0);
  };

  // CustomAlert 코드
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // closeCustomAlert()
          }
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  // Paging 코드
  const [pagingInfo, setPagingInfo] = useState({
    totalCount: 10,
    currentPage: 1,
    numPerPage: lineLength,
    pagePerBlock: 10,
  });
  const nextPageMove = (value) => {
    setPagingInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  useEffect(() => {
    setIsAllChecked(false);
    setCheckedClassIds([]);
  }, [lineLength, searchData]);

  useEffect(() => {
    if (userInfo.ecSeq > 0) searchClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingInfo.currentPage, pagingInfo.numPerPage, searchInfo, sortStartDate, sortEndDate]);

  return (
    <>
      <div className='p_classes'>
        <MainLink />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              <section className='top'>
                <article className='btn_area'>
                  <SvgIconTextBtn
                    text='Create Class'
                    svgClassName='svg_icon icon_person_add blue'
                    bgColor='#DFF1FF'
                    color='#4692D1'
                    borderColor='none'
                    onClick={() => handleClassManageOpen(true)}
                  />

                  <MiniSvgIconBtn text='Edit' svgClassName='svg_icon icon_write' onClick={() => handleShowEdit()} radius='medium' dataHover />
                  <MiniSvgIconBtn text='Delete' svgClassName='svg_icon icon_trash' onClick={handleDeleteBtn} radius='medium' dataHover />
                  <div className='com_btn_drop'>
                    <MiniSvgIconBtn text='Download' svgClassName='svg_icon icon_download' onClick={toggleBtnDownload} radius='medium' dataHover />
                    <div className={`layer ${btnDownload ? 'active' : ''}`}>
                      <button className='btn' onClick={excelDownloadSelect}>
                        <i className='svg_icon icon_person_select'>&nbsp;</i>
                        Selected Classes
                      </button>
                      <button className='btn' onClick={excelDownloadAll}>
                        <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
                      </button>
                    </div>
                  </div>

                  <div className='com_btn_drop'>
                    <MiniSvgIconBtn text='Print' svgClassName='svg_icon icon_print' onClick={toggleBtnPrint} radius='medium' dataHover />
                    <div className={`layer ${btnPrint ? 'active' : ''}`}>
                      <button className='btn' onClick={printSelect}>
                        <i className='svg_icon icon_person_select'>&nbsp;</i>
                        Selected Classes
                      </button>
                      <button className='btn' onClick={printAll}>
                        <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
                      </button>
                    </div>
                  </div>
                </article>
                <article className='search_area'>
                  <PopupCalendarPeriod tit={dayjsTZ().format('MM / DD / YYYY')} setSearchInfo={setSearchInfo} />
                  <div className='search'>
                    <input
                      type='text'
                      placeholder='Enter class name'
                      className='input search_area_input'
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      onKeyDown={(e) => e.key === 'Enter' && searchClasses()}
                    />
                    <button className='com_btn m blue btn_search' onClick={searchClasses}>
                      <i className='svg_icon icon_search blue before'>&nbsp;</i>
                      SEARCH
                    </button>
                  </div>
                  <CustomSelect
                    options={[
                      { label: '10 line', value: 10 },
                      { label: '25 line', value: 25 },
                      { label: '50 line', value: 50 },
                      { label: '100 line', value: 100 },
                    ]}
                    onSelect={limitSelect}
                  />
                </article>
              </section>
              <table className='list'>
                <colgroup>
                  <col width='4%' />
                  <col width='4.6%' />
                  <col width='24%' />
                  <col width='12%' />
                  <col width='12%' />
                  <col width='12%' />
                  <col width='14%' />
                  <col width='14%' />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <input type='checkbox' className='com_chk' id='chk01' checked={isAllChecked} onChange={handleAllCheckboxChange} />
                      <label htmlFor='chk01'>&nbsp;</label>
                    </th>
                    <th>No.</th>
                    <th>Class Name</th>

                    <th>
                      <button
                        className={`btn_sort ${sortStartDate === 0 ? 'default' : sortStartDate === 1 ? '' : 'active'}`}
                        onClick={() => handleClickStartDate()}>
                        Start Date
                      </button>
                    </th>
                    {/*클릭 시 toggleClass active, 원위치 addClass default*/}
                    <th>
                      <button className={`btn_sort ${sortEndDate === 0 ? 'default' : sortEndDate === 1 ? '' : 'active'}`} onClick={() => handleClickEndDate()}>
                        End Date
                      </button>
                    </th>
                    {/*클릭 시 toggleClass active, 원위치 addClass default*/}

                    <th>No. of Students</th>
                    <th>Test Scheduled</th>
                    <th>Test Taken</th>
                  </tr>
                </thead>
                <tbody>
                  {searchData.length === 0 ? (
                    <tr>
                      <td colSpan={8}>
                        <h2>You do not have any classes… yet!</h2>
                      </td>
                    </tr>
                  ) : (
                    searchData.map((data, index) => {
                      return (
                        <ClassesInfoRow
                          key={index}
                          index={pagingInfo.totalCount - pagingInfo.numPerPage * (pagingInfo.currentPage - 1) - index}
                          classId={data.classId}
                          classTitle={data.classTitle}
                          startDate={data.startDate.format('MM/DD/YYYY')}
                          endDate={data.endDate.format('MM/DD/YYYY')}
                          studentCount={data.studentList.length}
                          testScheduled={data.testScheduled}
                          testTaken={data.testTaken}
                          checked={checkedClassIds.includes(data.classId)}
                          onCheckboxChange={handleCheckboxChange}
                          handleShowEdit={handleShowEdit}
                        />
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <Paging
              totalCount={pagingInfo.totalCount}
              currentPage={pagingInfo.currentPage}
              numPerPage={pagingInfo.numPerPage}
              pagePerBlock={pagingInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
            {alertLayerPopup.visible && (
              <CustomAlert
                onClose={closeCustomAlert}
                alertType={alertLayerPopup.alertType}
                alertMessage={alertLayerPopup.alertMessage}
                returnValue={alertLayerPopup.returnValue}
              />
            )}
          </div>
        </div>
      </div>
      {/*반관리 팝업*/}
      {openClassManage && (
        <PopClassManage handleClassManageOpen={handleClassManageOpen} editClassInfo={editClassInfo} searchClasses={searchClasses} setAlert={setAlert} />
      )}
      {/* 학생 삭제 팝업 */}
      {deleteClass && (
        <DeleteModal
          hideModal={() => setDeleteClass(false)}
          callback={deleteClassAlertOpen}
          text={'The data for this class will still be available \n but will not be linked together.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      <div className={`com_popup type_alert ${deleteClassAlert ? 'active' : ''}`}>
        <div className='pop_container'>
          <div className='pop_body ok_contents'>
            <p className='alert_title'>Successfully deleted</p>
            <div className='icon'>
              <img src={IconOk} alt='icon' />
            </div>
          </div>
          <div className='pop_footer com_btn_wrap'>
            <button className='com_btn point xl full' onClick={() => setDeleteClassAlert(false)}>
              Confirm
            </button>
          </div>
        </div>
      </div>

      {alert.active && <MiniAlert text={alert.message} active={alert.active} inactive={() => setAlert({ ...alert, active: false })} timeOut={2000} />}
    </>
  );
}
