import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ engScores, mathScores, xaxis }) => {
  const seriesData = [
    {
      name: 'RW',
      data: engScores,
    },
    {
      name: 'Math',
      data: mathScores,
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        // 그래프 애니메이션
        enabled: false,
      },
      fontFamily: 'Roboto, Arial, sans-serif',
    },
    markers: {
      size: 5,
      hover: {
        sizeOffset: 1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: xaxis,
    },
    yaxis: {
      min: 200,
      max: 800,
      tickAmount: 6,
    },
    colors: ['#008CFF', '#1FDF9F'],
    legend: {
      position: 'top',
      markers: {
        width: 40,
        height: 8,
        radius: 8,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={chartOptions} series={seriesData} type='line' height={350} />
    </div>
  );
};

export default LineChart;
