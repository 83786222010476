import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import getCurrentIndex from '../../_hooks/getCurrentIndex';

/** set tests의 단계 진행 상태 표시 영역 Component */
const SetTestsStep = ({ isB2c = false, useLevelsIsLoading = true }) => {
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  const currentIndex = getCurrentIndex();

  /** 단계 데이터 (B to B) */
  const STEPS_DATA_IN_B_TO_B = [
    {
      title: 'Select Setting type',
      currentRouter: [0],
    },
    {
      title: `Select ${stateSetTest.selectedUserType}`, // "set tests" 1단계에서 선택한 종류
      currentRouter: [1, 2],
    },
    {
      title: 'Select Test',
      currentRouter: [3, 4],
    },
    {
      title: 'Select Date',
      currentRouter: [5],
    },
    {
      title: 'Review',
      currentRouter: [6],
    },
  ];
  /** 단계 데이터 (B to C) */
  const STEPS_DATA_IN_B_TO_C = [
    {
      title: 'Select Setting type',
      currentRouter: [3],
    },
    {
      title: 'Select TEST',
      currentRouter: [4],
    },
    {
      title: 'Review',
      currentRouter: [6],
    },
  ];

  const stepData = !useLevelsIsLoading & isB2c ? STEPS_DATA_IN_B_TO_C : STEPS_DATA_IN_B_TO_B;

  /** 현재 Step UI에 표시 될 단계 (실제 라우트 단계와 1 대 1로 대응하지는 않는다.) */
  const currentStep = stepData.findIndex((item) => {
    const isMatch = item.currentRouter.find((item) => item === currentIndex);
    return isMatch !== undefined ?? isMatch;
  });

  return (
    <S.ComSetTestsStepWrap className='com_set_tests_step'>
      {!isB2c && currentStep === 0 ? (
        <div className='step step1'>
          <span className='num_field'>1</span>
          <p className='tit_field'>Select Setting type</p>
        </div>
      ) : (
        stepData.map((item, index) => {
          const showNumber = index + 1;
          return (
            <div key={index} className={`step ${currentStep > index ? 'selected' : ''} ${currentStep === index ? 'active' : ''}`}>
              <span className='num_field'>{showNumber}</span>
              <p className='tit_field'>{item.title}</p>
            </div>
          );
        })
      )}
    </S.ComSetTestsStepWrap>
  );
};

export default SetTestsStep;

const S = {};

S.ComSetTestsStepWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2.75rem 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .step {
    flex: 1;
    display: flex;
    align-items: center;
    .num_field {
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      background-color: #b3d1ed;
      border: 4px solid #d4ebff;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.375rem;
      font-weight: 700;
      z-index: 2;
    }
    .tit_field {
      background-color: #b3d1ed;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: left;
      padding-left: 1.875rem;
      z-index: 1;
      margin-inline: -1.25rem;
      height: 1.75rem;
      min-width: 200px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 1.25rem;
      text-transform: uppercase;
    }
    &.active {
      .num_field {
        background-color: var(--point-color);
        border-color: #81c0f3;
      }
      .tit_field {
        background-color: var(--point-color);
      }
    }
    &.selected {
      .num_field {
        background-color: #5ba2db;
        border-color: #b3d1ed;
        font-size: 0;
        background-size: 2.75rem auto;
        background-position: center;
      }
      .tit_field {
        background-color: #fff;
        color: var(--point-color);
      }
    }
    &.step1 {
      justify-content: center;
      margin-left: -1.25rem;
      .num_field {
        background-color: var(--point-color);
        border-color: #81c0f3;
        background-size: 2.75rem auto;
        background-position: center;
      }
      .tit_field {
        background-color: #fff;
        color: var(--point-color);
        width: auto;
        padding-right: 0.625rem;
      }
    }
    &:last-child {
      .tit_field {
        margin-right: 0;
      }
    }
  }
`;
