import React from 'react';
//img
import ImgVisual01 from 'assets/img/student_bg.png';
import ImgVisual02 from 'assets/img/student_bg2.png';

/** 학생 대시보드 페이지의 "상단 비주얼 영역" 컴포넌트 */
export default function VisualArea() {
  return <section className='com_visual_area'>
  <div className='com_center_wrap'>
    <div className='right'>
      <img src={ImgVisual01} alt='illustration' />
    </div>
    <div className='left'>
      <img src={ImgVisual02} alt='object' />
    </div>
  </div>
</section>;
}
