import React from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom';

//img
import ImgLogo from '../../assets/img/gateplus_logo_shadow.png';

const Header = (props) => {
  const logoBtn = () => {
    const currentURL = window.location.href;
    if (currentURL.includes('signin')) {
      window.location.href = !currentURL.includes('/find') ? 'https://www.gateplus.org' : '/signin';
    } else window.location.href = '/signin';
  };
  return (
    <header className='gate_member_header'>
      <button onClick={logoBtn} className='logo_link'>
        <img src={ImgLogo} alt='Logo' className='logo' />
      </button>
      {props.children}
    </header>
  );
};

export default Header;
