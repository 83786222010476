import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import request from 'utils/Request.utils';
import { getRBCEventColor } from 'utils/Common.utils';
///////// components
//order details
import Layout from 'components/com/tests/_components/_common/Layout';
import SetTestCalendar from './_components/_SetTestCalender';
import dayjsTZ, { getDateOnlyDayjsTZ } from 'utils/functions/time/dayjs-config';
import dayjs from 'dayjs';

/**
 * Set Tests : "날짜 선택(달력)" 페이지
 * @description
 * 🔍 검색 키워드 - #setTest #com/tests/set/selection/date
 */
export default function TestsSetDatePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  const rbcEventColors = getRBCEventColor();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////
  const [timeLayer, setTimeLayer] = useState();

  const [testOrderInfo, setTestOrderInfo] = useState(
    stateSetTest.selectedModules.map((item) => {
      return {
        testName: item.testName,
        startDate: '-',
        customSetSeq: item.customSetSeq,
        userSeq: item.userSeq,
        classSeq: item.classSeq,
        testTimeInfo: {
          start: {
            date: '',
            time: '',
          },
          end: {
            date: '',
            time: '',
          },
        },
        point: 0, // item.point
      };
    })
  );

  const [selectedTest, setSelectedTest] = useState(
    stateSetTest.selectedModules && stateSetTest.selectedModules.length > 0 ? stateSetTest.selectedModules[0].customSetSeq : null
  );

  const [selectedDate, setSelectedDate] = useState(dayjsTZ().toDate());
  const [selectedTime, setSelectedTime] = useState('00:00');

  const [events, setEvents] = useState([
    // {
    //   classSeq: 0,
    //   title: '',
    //   start: null,
    //   end: null,
    //   classColor: '',
    //   allDay: true,
    // },
  ]);
  const [classInfo, setClassInfo] = useState([{ classSeq: 0, classTitle: '-', classColor: '-' }]);
  const [currentDate, setCurrentDate] = useState(dayjsTZ().toDate());

  const [customSetInfo, setCustomSetInfo] = useState([{ customSetSeq: 0, classTitle: '', classColor: 'random' }]);
  /////////////////// React useState 선언 영역 끝 ///////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const handleTestConfirm = (type) => {
    if (selectedTest === null) return;
    const selectTime = type === 'skip' ? '00:00' : selectedTime;

    const filteredChangeTestInfo = testOrderInfo.find((item) => selectedTest === item.customSetSeq);

    const updatedTestOrderInfo = testOrderInfo.map((item) => {
      if (filteredChangeTestInfo === item) {
        return {
          ...item,
          startDate: dayjs(`${dayjs(selectedDate).format('YYYY-MM-DD')} ${selectTime}`).format('MM / DD / YYYY hh : mm A'),
          testTimeInfo: {
            start: {
              date: dayjs(selectedDate).format('YYYY-MM-DD'),
              time: selectTime,
            },
            end: {
              date: dayjs(selectedDate).add(7, 'day').format('YYYY-MM-DD'),
              time: selectTime,
            },
          },
        };
      }
      return item;
    });
    setTestOrderInfo(updatedTestOrderInfo);

    setEvents((prev) => {
      const updatedData = {
        classSeq: filteredChangeTestInfo.classSeq,
        title: filteredChangeTestInfo.testName,
        start: selectedDate,
        end: selectedDate,
        classColor: 'red',
        allDay: true,
      };

      const filteredEvents = prev.filter((event) => updatedData.title !== event.title);

      return [...filteredEvents, updatedData];
    });

    setSelectedTest((prev) => {
      if (updatedTestOrderInfo.filter((item) => item.startDate === '-').length === 0) return null;
      return updatedTestOrderInfo.filter((item) => item.startDate === '-')[0].customSetSeq;
    });

    setTimeLayer(false);
    setSelectedDate(null);
  };

  const getCalendarData = (ecSeq, userSeq = userInfo.userSeq) => {
    if (ecSeq === 0) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result?.scheduleInfo;
        // scheduleInfo가 없을때 return으로 에러 발생 예외 처리 ( set Test 가 이루어지는 공간이므로 큰 문제는 없음 )
        if (!scheduleInfo) return;

        const filteredEvents = scheduleInfo
          .filter((item) => {
            const endMoment = dayjs(item.testEndDate.split(' ')[0]);
            return endMoment.isSameOrAfter(dayjsTZ(), 'day');
          })
          .map((item) => {
            return {
              classSeq: item.classSeq,
              title: item.testName,
              start: dayjs(item.testStartDate.split(' ')[0]).toDate(),
              end: dayjs(item.testStartDate.split(' ')[0]).toDate(),
              classColor: item.classColor,
              allDay: true,
            };
          });

        setEvents(filteredEvents);
      }
    };
    const searchStartDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).add(2, 'month').date('1').format());

    const apiUrl = `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
    request.get(apiUrl, null, successHandler);
  };

  const getClassColor = (ecSeq) => {
    if (ecSeq === 0) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        const classInfo = response.result.classInfo;
        setClassInfo(
          classInfo.map((data) => {
            return {
              classSeq: data.classSeq,
              classTitle: data.className,
              classColor: data.classColor === null ? '' : data.classColor,
            };
          })
        );
      }
    };
    const searchStartDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).add(2, 'month').date('1').format());

    const apiUrl = `/api/dsat/calendar/classes?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
    request.get(apiUrl, null, successHandler);
  };

  const submitRandomClassColor = (classSeq, color) => {
    let params = { classColor: color };
    const successHandler = (response) => {
      //
    };
    request.put(`/api/dsat/calendar/classes/${classSeq}`, params, successHandler);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getCalendarData(userInfo.ecSeq);
    getClassColor(userInfo.ecSeq);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const colorMapping = {};
    classInfo.forEach((info) => {
      if (info.classColor === '') {
        info.classColor = rbcEventColors[Math.floor(Math.random() * rbcEventColors.length)].name;
        submitRandomClassColor(info.classSeq, info.classColor);
      }
      colorMapping[info.classSeq] = info.classColor;
    });

    const updatedEvents = events.map((event) => {
      const classColor = colorMapping[event.classSeq];
      return classColor ? { ...event, classColor } : event;
    });

    setEvents(updatedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout testOrderInfo={testOrderInfo} selectedTest={selectedTest} setSelectedTest={setSelectedTest}>
      <SetTestCalendar
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        events={events}
        setEvents={setEvents}
        customSetInfo={customSetInfo}
        setCustomSetInfo={setCustomSetInfo} // setTest에서 미사용
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleTestConfirm={handleTestConfirm}
        setTimeLayer={setTimeLayer}
        timeLayer={timeLayer}
        selectedTest={selectedTest}
      />
    </Layout>
  );
}
