import React, { useState, useEffect, useRef } from 'react';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

//components

import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import CustomAlert from 'components/_common/alerts/CustomAlert';
import EditTestScheduleModal from './_components/EditTestScheduleModal'; //test 수정 팝업
import Tab from './_components/Tab';
import ByIndividualTest from './_components/tapContents/ByIndividualTest';
import ByTestSet from './_components/tapContents/ByTestSet';
import MainLink from 'components/_common/button/_MainLinks';

/** com/tests/schedule 경로의 Page */
export default function TestSchedulePage() {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 /////////////
  /** 커스텀 알럿 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  const [activeTest, setActiveTest] = useState({
    customSetSeq: 0,
    classMemberList: [],
    classMemberListAll: [],
    totalCnt: 0,
    testName: '',
  });

  /** tab */
  const [tabState, setTabState] = useState(0);
  /** mini alert */
  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  const [editTest, setEditTest] = useState({
    active: false,
    item: 0,
    type: '',
  });
  /** 인피니티 스크롤 */
  const [isLoading, setIsLoading] = useState(true);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 중복 제거 1 */
  const removeDuplicatesOne = (arr, prop) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };

  /** 중복 제거 2 */
  const removeDuplicatesTwo = (arr, prop1, prop2) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]);
    });
  };

  /** 반 별 예정 시험 조회 API 호출 */
  const getTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let scheduleList = response.result.scheduleListc;

        let deduplicationClass = removeDuplicatesOne(scheduleList, 'classSeq');
        let sameClassList = [];
        for (let i = 0; i < deduplicationClass.length; i++) {
          const classSeq = deduplicationClass[i].classSeq;
          const filteredSchedule = scheduleList.filter((i) => i.classSeq === classSeq);
          sameClassList.push(filteredSchedule);
        }

        let courseList = [];
        let deduplicationTestDate = [];
        for (let i = 0; i < sameClassList.length; i++) {
          deduplicationTestDate = removeDuplicatesTwo(sameClassList[i], 'testStartDate', 'testEndDate');

          for (let j = 0; j < deduplicationTestDate.length; j++) {
            const startDate = deduplicationTestDate[j].testStartDate;
            const endDate = deduplicationTestDate[j].testEndDate;

            const filteredTestSchedule = sameClassList[i].filter((i) => i.testStartDate === startDate && i.testEndDate === endDate);
            courseList.push(filteredTestSchedule);
          }
        }
        const sortList = courseList.sort((a, b) => new Date(a[0].testStartDate) - new Date(b[0].testStartDate));
        setActiveTest((prev) => {
          return { ...prev, classMemberList: sortList };
        });
      }
    };

    request
      .get(`/api/test/schedules/pre/exams/${activeTest.customSetSeq}?ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "반 별 예정 시험 조회" API 응답 에러', error));
  };

  /** 시험 일정 얻어오기 API 호출 */
  const getSchedules = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq,
      searchField: searchInfo.setSearchField === 'Name' ? 'userName' : 'testName',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: 10,
      offset: (scheduleInfo.currentPage - 1) * scheduleInfo.numPerPage,
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let scheduleList = response.result.scheduleList;
        let totalCnt = response.result.totalCnt;

        setScheduleInfo((prev) => {
          return { ...prev, uthList: scheduleList, totalCount: totalCnt };
        });

        if (totalCnt > 0 && !activeTest.customSetSeq)
          setActiveTest((prev) => {
            return {
              ...prev,
              customSetSeq: scheduleList[0].customSetSeq,
              testName: scheduleList[0].testName,
            };
          });
      }
    };

    if (userInfo?.ecSeq > 0)
      request
        .get(
          `/api/test/schedules/pre?ecSeq=${pms.ecSeq}&limit=${pms.limit}&offset=${pms.offset}&searchField=${pms.searchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&searchStartDate=${pms.searchStartDate}&searchEndDate=${pms.searchEndDate}`,
          null,
          successHandler
        )
        .catch((error) => console.error('tests/scheduled 페이지의 "/api/test/schedules/pre" API 응답 에러', error));
  };

  /** 반 별 전체 시험 목록 조회 재시도 */
  const schedulesClassListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getAllTestClassMember();
  };

  const classEditClick = (item) => {
    setEditTest((prev) => {
      return { ...prev, active: true, item: item, type: 'class' };
    });
  };

  /** 반 별 예정 시험 전체 조회 API 요청 */
  const getAllTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let scheduleListA = response.result.scheduleListA;
        let totalCnt = response.result.totalCnt;

        const parsedData = scheduleListA.map((entry) => {
          const classList = JSON.parse(entry.classList);
          return classList.map((classItem) => {
            const studentList = typeof classItem.studentList === 'string' ? JSON.parse(classItem.studentList) : classItem.studentList;
            return studentList.map((student) => ({
              ...student,
              seqStr: entry.seqStr,
            }));
          });
        });

        if (scheduleInfo.currentPage === 1) {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: parsedData,
            };
          });
        } else {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: prev.classMemberListAll.concat(parsedData),
            };
          });
        }

        setIsLoading(false);
      }
    };

    if (userInfo?.ecSeq > 0)
      request
        .get(`/api/test/schedules/pre/exams?ecSeq=${userInfo.ecSeq}&currentPage=${scheduleInfo.currentPage}`, null, successHandler)
        .catch((error) => console.error('tests/scheduled 페이지의 "반 별 예정 시험 전체 조회" API 응답 에러', error));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            console.log('요기에서 작업');

            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  useEffect(() => {
    if (activeTest.customSetSeq > 0) getTestClassMember();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.customSetSeq]);

  useEffect(() => {
    if (tabState) {
      getAllTestClassMember();
    } else {
      getSchedules();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.currentPage]);

  useEffect(() => {
    // 반 별 전체 예정된 시험 조회
    if (tabState && scheduleInfo.currentPage === 1) {
      schedulesClassListReload();
      setIsLoading(false);
    } else {
      setActiveTest((prev) => {
        return { ...prev, classMemberListAll: [] };
      });
      setIsLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <Tab tabState={tabState} setTabState={setTabState} />
          <div className='container'>
            {tabState === 0 ? (
              <ByIndividualTest
                tabState={tabState}
                setAlertLayerPopup={setAlertLayerPopup}
                setSearchInfo={setSearchInfo}
                searchInfo={searchInfo}
                scheduleInfo={scheduleInfo}
                setActiveTest={setActiveTest}
                setEditTest={setEditTest}
                setScheduleInfo={setScheduleInfo}
                activeTest={activeTest}
                classEditClick={classEditClick}
                getSchedules={getSchedules}
                getTestClassMember={getTestClassMember}
                schedulesClassListReload={schedulesClassListReload}
              />
            ) : tabState === 1 ? (
              <ByTestSet
                activeTest={activeTest}
                classEditClick={classEditClick}
                getSchedules={getSchedules}
                schedulesClassListReload={schedulesClassListReload}
                setAlertLayerPopup={setAlertLayerPopup}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setScheduleInfo={setScheduleInfo}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}
      {/*//To Do : 테스트 셋팅 완료 후 Test schedule 페이지로 이동 시 뜨도록*/}
      {editTest.active && (
        <EditTestScheduleModal
          editTest={editTest}
          activeTest={activeTest}
          setEditTest={setEditTest}
          setAlertLayerPopup={setAlertLayerPopup}
          setIsLoading={setIsLoading}
          getSchedules={getSchedules}
          getTestClassMember={getTestClassMember}
          getAllTestClassMember={schedulesClassListReload}
          setMiniAlert={setMiniAlert}
        />
      )}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}
