import { useState, useRef, useEffect } from 'react';

export default function EditDropbox(props) {
  const { optionName, inputValue, options, handleChanges } = props;

  const [isActive, setIsActive] = useState(false);

  // 드롭박스 닫히게
  const dropboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropboxRef.current && !dropboxRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li className='item' key={optionName}>
      <div className='tit_field'>{optionName}</div>
      <div className='input_field' ref={dropboxRef}>
        <div className={`com_select_layer ${isActive ? 'active' : ''}`} onClick={() => setIsActive((prev) => !prev)}>
          <input type='text' className='selected' value={inputValue} readOnly />
          <div className='layer' onClick={handleChanges(optionName)}>
            {options.map((item, idx) => {
              return (
                <p className='option' key={`${item.name}-${idx}`} data-value={item.name}>
                  {item.name}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
}
