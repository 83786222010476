import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ barChartData }) => {
  const seriesData = barChartData;

  const chartOptions = {
    chart: {
      type: 'bar',
      height: barChartData[0].height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: barChartData[0].borderRadius,
        horizontal: barChartData[0].horizontal,
        columnWidth: barChartData[0].columnWidth,
        barHeight: '60%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:
        barChartData[0]?.xaxisCategories.length > 3
          ? barChartData[0]?.xaxisCategories.map((category) => (category.length > 3 ? category.slice(0, 3) + '...' : category))
          : barChartData[0]?.xaxisCategories,
      labels: {
        show: barChartData[0]?.labels,
      },
    },
    yaxis: {
      show: barChartData[0].show,
      title: {
        rotate: 0,
        offsetX: -5,
        offsetY: -170,
      },
      max: 1600,
      tickAmount: 4,
    },
    colors: barChartData[0].colors,
    legend: {
      show: barChartData[0].legend,
    },
    tooltip: {
      x: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          let originalLabel = barChartData[0]?.xaxisCategories[dataPointIndex];

          if (barChartData[0] && barChartData[0].series[seriesIndex]) {
            return originalLabel.replace('...', barChartData[0].series[seriesIndex].tooltipReplacement || '');
          }

          return value;
        },
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={chartOptions} series={seriesData} type='bar' height={chartOptions.chart.height} />
    </div>
  );
};

export default BarChart;
