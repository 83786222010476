import { useEffect, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';

import GateKeyHeader from './_components/GateKeyHeader';
import GateKeyHistoryList from './_components/GateKeyHistoryList';

import moment from 'moment';

import LocalStorage from 'utils/LocalStorage.utils';

function GateKeyPopup({ isB2c }) {
  const [keyHistoryData, setKeyHistoryData] = useState({
    gateKeyAmount: 0,
    gateKeyInfoList: [],
  });

  /** 유저의 GateKey 정보를 불러오는 함수 */
  const getKeyData = () => {
    const api = isB2c ? '/api/gatekey/info/b2c' : '/api/gatekey/info/academy';

    const successHandler = (response) => {
      if (response.code === 200) {
        const remainingKey = isB2c ? response.result.b2cGateKeyAmount : response.result.gateKeyAmount;
        const KeyInfoList = isB2c ? response.result.b2cGateKeyInfoList : response.result.gateKeyInfoList;

        setKeyHistoryData({
          gateKeyAmount: remainingKey === 0 ? 0 : remainingKey,
          gateKeyInfoList: KeyInfoList,
        });
      }
    };

    request.get(api, null, successHandler).catch((error) => console.error(error));
  };

  // 랜더링할 데이터 날짜로 정렬
  const getSortedGroupByDate = (HistoryData) => {
    if (HistoryData.length === 0) return [];

    const sortedGroupByDate = HistoryData.reduce((acc, cur) => {
      const date = moment(cur.regDate).format('LL');
      acc[date] = acc[date] ? [...acc[date], cur] : [cur];

      return acc;
    }, {});

    Object.keys(sortedGroupByDate).forEach((key) => {
      sortedGroupByDate[key] = sortedGroupByDate[key].sort((a, b) => moment(b.date, 'LL').toDate() - moment(a.date, 'LL').toDate());
    });

    return sortedGroupByDate;
  };

  useEffect(() => {
    getKeyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSession = LocalStorage.getItemJsonParse('userSession');

  const responseLink = `${process.env.REACT_APP_MAINGATE_ROOT_URL}/sign/signin/?lastRoute=/en-US/payment-history&accessToken=${userSession.accessToken}&refreshToken=${userSession.refreshToken}`;

  return (
    <S.GateKeyPopup>
      <GateKeyHeader remainingKey={keyHistoryData.gateKeyAmount} /> {/* GateKey Popup의 header 영역 */}
      <S.GateKeyHistory>
        <a href={responseLink} target='_blank' rel='noopener noreferrer' className='key-history-title'>
          <h4>Gate Key History</h4>
          <div role='button'>
            <i className='svg_icon icon_chevron_down'>&nbsp;</i>
          </div>
        </a>
        <GateKeyHistoryList sortedGroupByDate={getSortedGroupByDate(keyHistoryData.gateKeyInfoList)} /> {/* Gate Key History 리스트 영역 */}
      </S.GateKeyHistory>
    </S.GateKeyPopup>
  );
}

export default GateKeyPopup;

// --- --- ---

const S = {}; // S-dot 문법용 객체

S.GateKeyPopup = styled.div`
  position: absolute;
  box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.15);
  left: -1.5rem;
  border-radius: 0.5rem;
  background-color: white;
  padding: 1.75rem;
  width: 420px;
  top: 75px;
`;

S.GateKeyHistory = styled.div`
  display: flex;
  flex-direction: column;

  .key-history-title {
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    h4 {
      font-weight: 500;
    }

    div[role='button'] {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        transform: rotate(270deg);
      }
    }
  }
`;
