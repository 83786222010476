import useOutsideClick from 'hooks/useOutsideClick.js';
import { useRef, useState } from 'react';

import gateKeyColorIcon from 'assets/img/svg/icon_gate_key_color.svg';

import GateKeyPopup from './GateKeyPopup';

import styled from 'styled-components';

function GateKeyBtn({ remainingKey, isB2c }) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  // GateKeyPopup 외부 클릭 시 팝업 닫기
  const popupRef = useRef();
  useOutsideClick(popupRef, () => setIsOpenPopup(false), isOpenPopup);

  return (
    <S.GateKeyBtn ref={popupRef}>
      <button className='menu' onClick={() => setIsOpenPopup((prev) => !prev)}>
        <img src={gateKeyColorIcon} alt='gate key' className='icon' />
        <p className='tit'>{remainingKey === 0 ? '-' : remainingKey}</p>
      </button>

      {isOpenPopup && <GateKeyPopup isB2c={isB2c} />}
    </S.GateKeyBtn>
  );
}

export default GateKeyBtn;

// --- --- ---

const S = {}; // S-dot 문법용 객체

S.GateKeyBtn = styled.div`
  position: relative;
`;
