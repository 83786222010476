import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** 시험 시간 5분 남았을 때, 알림 컴포넌트 */
export default function FiveMinutesAlert() {
  const dispatch = useDispatch();
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  return (
    <section className={`exam_alert ${stateExamComponents.isFiveMinutesRemaining ? 'active' : ''}`}>
      <button
        className='svg_icon btn_pop_close white'
        onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } })}></button>
      <div className='tit'>5 Minutes Left in This Module</div>
      <div>If you're still working when tine runs out, your work will be automatically saved.</div>
    </section>
  );
}
