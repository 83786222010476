import moment from 'moment';
import styled from 'styled-components';
import { useState } from 'react';
import request from 'utils/Request.utils';
import useUserLevels from 'hooks/useUserLevels';

const keyStatusText = {
  ADD: 'GATE+ Pass Purchase', // +, key 구매 // (key 구매, 돈-)
  REFUND: 'GATE+ Pass Refund', // +, 시험에 세팅한 key 환불 // (key 판매, 돈+)
  // --------------------------------------------
  USE: 'Used for Test Setting', // -, key 사용 // (시험 구매, 키- )
  CANCEL: 'Cancelled Test', // +, 시험 구매 취소 // (시험 판매, 키+)
  SUB: 'Administrator retrieves key', // -, TMS에서 key 회수
};

/** key history 단일 item 컴포넌트  */
function HistoryCard({ history }) {
  // showQuantity는 우식대리 요청대로 작업함
  const showQuantity = history.status === 'ADD' || history.status === 'REFUND' || history.status === 'SUB';

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** 렌더링 할 데이터 */
  const renderingData = {
    historyStatus: keyStatusText[history.status],
    historyDate: `${moment(history.regDate).format('HH')}:${moment(history.regDate).format('mm')}`,
    historyTestName: history.testName ?? '',
    historyPassName: history.paymentItemName ?? '',
    changedKey: history.amount,
    isIncrease: history.amount > 0,
    // quantity: showQuantity ? Math.abs(history.amount) : history.setPeople,
    quantity: showQuantity ? '' : history.setPeople,
  };

  return (
    <S.HistoryCard>
      <div className='history-info'>
        <p>{renderingData.historyStatus}</p>
        <div>
          <p>{renderingData.historyDate}</p>
          {renderingData.historyPassName !== '' ? <p>{renderingData.historyPassName}</p> : <p>{renderingData.historyTestName}</p>}
        </div>
      </div>

      <div className='key-history-info'>
        <div className='left-section'>
          <p className={`${renderingData.isIncrease ? 'increase' : 'decrease'}`}>
            {renderingData.isIncrease ? `+${renderingData.changedKey}` : renderingData.changedKey}
          </p>
          <p className='item-type'>
            {showQuantity ? (
              // 'Quantity'
              ''
            ) : (
              // 유저가 BtoC 일때만 아이콘 표시
              <span>{!(useLevels.isStudent || useLevels.isParent) && <i className='svg_icon icon_person black'>&nbsp;</i>}</span>
            )}
          </p>
        </div>
        <div className='right-section'>
          <p>Key</p>
          <p className='item-count'>{renderingData.quantity}</p>
        </div>
      </div>
    </S.HistoryCard>
  );
}

export default HistoryCard;

const S = {}; // S-dot 문법용 객체

S.HistoryCard = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 1.25rem 0;
  border-top: 1px solid #eaeaea;
  white-space: pre-wrap;

  .history-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > p {
      font-size: 0.875rem;
      font-weight: 500;
    }

    > div {
      display: flex;
      gap: 0.25rem;
      p {
        font-size: 0.875rem;
        font-weight: 300;
      }
    }
  }
  .key-history-info {
    display: flex;
    gap: 1rem;

    .left-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 0.7rem;
      width: 3rem;
      font-size: 0.875rem;
      font-weight: 500;
      .increase {
        color: #0068bd;
      }
      .decrease {
        color: #f24b4b;
      }
    }
    .right-section {
      display: flex;
      justify-content: flex-start;
      gap: 0.7rem;
      align-items: end;
      flex-direction: column;
      width: 2.5rem;
      > p {
        font-weight: 500;
      }
      .item-count {
        font-weight: 300;
      }
    }
  }
`;
