import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
const PasswdResetComplete = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);
  return (
    <div className='container'>
      <Header>
        <p className='desc'>The door to educational aspiration</p>
      </Header>
      <section className='forgot_email'>
        <h1 className='forgot_title'>Your password has been reset</h1>
        <p className='txt'>Please sign in again with your new password.</p>
      </section>
      <section className='btns'>
        <Link to='/signin' className='btn btn_fill btn_big'>
          Go back to Sign in
        </Link>
      </section>
    </div>
  );
};

export default PasswdResetComplete;
