import {
  UPDATE_SELECTED_USER_TYPE,
  UPDATE_SELECTED_CLASS,
  UPDATE_SELECTED_STUDENT_LIST,
  UPDATE_SELECTED_CONFIG,
  UPDATE_SELECTED_MODULES,
  UPDATE_SELECTED_DATE,
  CLEAR_SET_TESTS_STATE,
  UPDATE_TOTAL_GATE_KEY_STATE,
} from './actionTypes';

////// "Set Tests" 전역 상태 리덕스 액션 ///////

/** 리듀서 전역 상태 액션 : "Set Tests" - 1단계 "사용자" 선택  */
export const updateReduxStateSelectedUserType = (userType) => ({
  type: UPDATE_SELECTED_USER_TYPE,
  payload: userType,
});

/** 리듀서 전역 상태 액션 : "Set Tests" - 2단계 "반" 선택  */
export const updateReduxStateSelectedClass = (selectedClass) => ({
  type: UPDATE_SELECTED_CLASS,
  payload: selectedClass,
});

/** 리듀서 전역 상태 액션 : "Set Tests" - 3단계 "학생" 선택  */
export const updateReduxStateSelectedUserList = (selectedStudents) => ({
  type: UPDATE_SELECTED_STUDENT_LIST,
  payload: selectedStudents,
});

/** 리듀서 전역 상태 액션 : "Set Tests" - 4단계 "과목, 난이도" 선택  */
export const updateReduxStateSelectedConfig = (selectedConfig) => ({
  type: UPDATE_SELECTED_CONFIG,
  payload: selectedConfig,
});

/** 리듀서 전역 상태 액션 : "Set Tests" - 5단계 "시험 모듈" 선택  */
export const updateReduxStateSelectedModules = (selectedTestList) => ({
  type: UPDATE_SELECTED_MODULES,
  payload: selectedTestList,
});

/** 리듀서 전역 상태 액션 : "Set Tests" - 6단계 시험 모듈 별 "날짜/시간" 선택  */
export const updateReduxStateSelectedDate = (selectedDateTimeArray) => ({
  type: UPDATE_SELECTED_DATE,
  payload: selectedDateTimeArray,
});

/** 리듀서 전역 상태 액션 : "Set Tests" 에서 선택한 상품의 총 가격(Key) 전역 상태 수정  */
export const updateReduxStateTotalGateKey = (totalAmount) => ({
  type: UPDATE_TOTAL_GATE_KEY_STATE,
  payload: totalAmount,
});

/** 리듀서 전역 상태 액션 : "Set Tests" 전역 상태 초기화  */
export const clearReduxStateSetTests = () => {
  console.log('set test 초기화');
  return {
    type: CLEAR_SET_TESTS_STATE,
  };
};
