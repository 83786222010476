import React, { useRef, useEffect, memo } from 'react';

import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';

const Tooltip = React.forwardRef((props, ref) => (
  <div ref={ref} style={toolTipStyle}>
    Tooltip
  </div>
));

const toolTipStyle = {
  display: 'none',
  visibility: 'hidden',
  position: 'absolute',
  left: '0',
  bottom: '0',
  backgroundColor: 'white',
  color: 'black',
  padding: '8px',
  border: '2px solid black',
  borderRadius: '5px',
  zIndex: 100,
};

const PassageAreaJYT = ({
  setAnnotateMemo,
  setIsClickAnnotate,
  annotationId,
  setAnnotationId,
  annotateMemo,
  annotateName,
  setAnnotateName,
  examComponents,
  setExamComponents,
  setIsDragged,
  passage,
  ...rest
}) => {
  const passageRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    rangy.init();

    const createAnnotation = (selection) => {
      const uniqueId = `${1}-${Math.floor(Math.random() * 9000) + 1000}`; // userSeq
      const annotation = {
        id: uniqueId,
        text: selection.toString().length > 30 ? `${selection.toString().slice(0, 10)}...${selection.toString().slice(-10)}` : selection.toString(),
      };
      return annotation;
    };

    const applyAnnotation = (annotation) => {
      const classApplier = rangy.createClassApplier('rangy-highlight', {
        elementTagName: 'span',
        elementProperties: {
          className: 'rangy-highlight-selected',
          style: {
            // backgroundColor: '#FAD021',
          },
        },
        elementAttributes: {
          'data-annotation-id': annotation.id,
          'data-text': annotation.text,
          'data-memo': '',
        },

        onElementCreate: (element) => {
          element.addEventListener('mouseover', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);
            const firstSelectedAnnotation = selectedAnnotations[0];

            selectedAnnotations.forEach((el) => {
              el.classList.add('rangy-highlight-hover');
            });

            const position = {
              x: firstSelectedAnnotation.getBoundingClientRect().left + firstSelectedAnnotation.getBoundingClientRect().width / 2,
              y:
                firstSelectedAnnotation.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect().bottom -
                firstSelectedAnnotation.getBoundingClientRect().bottom +
                firstSelectedAnnotation.getBoundingClientRect().height -
                1,
            };
            showTooltip(event, element.getAttribute('data-memo'), position);
          });

          element.addEventListener('mouseout', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

            selectedAnnotations.forEach((el) => {
              el.classList.remove('rangy-highlight-hover');
            });

            hideTooltip();
          });

          element.addEventListener('click', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const annotationMemo = event.target.getAttribute('data-memo');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

            selectedAnnotations.forEach((el) => {
              el.classList.add('rangy-highlight-selected');
            });

            setAnnotationId(annotationId);
            setAnnotateName(memoTitle);
            setAnnotateMemo(annotationMemo);

            setIsClickAnnotate(true);
          });
        },
      });
      classApplier.applyToSelection();
    };

    const showTooltip = (event, memo, position) => {
      const tooltip = tooltipRef.current;
      tooltip.innerText = memo;

      tooltip.style.bottom = `${position.y}px`;
      tooltip.style.left = `${position.x}px`;
      tooltip.style.display = 'inline-block';
      tooltip.style.color = 'black';
      tooltip.style.transform = 'translate(-50%, 0)';
      tooltip.style.visibility = 'visible';
    };

    const hideTooltip = () => {
      const tooltip = tooltipRef.current;

      tooltip.style.display = 'none';
      tooltip.style.visibility = 'hidden';
    };

    const applyHighlightIfAllowed = () => {
      if (examComponents.annotationDisplay) {
        const selection = rangy.getSelection();
        if (!selection.isCollapsed) {
          const annotation = createAnnotation(selection);

          setAnnotateName(annotation.text);
          setAnnotationId(annotation.id);

          applyAnnotation(annotation);
          selection.removeAllRanges();
        }
      }
    };

    const handleMouseUp = () => {
      const selection = rangy.getSelection();
      if (!selection.isCollapsed) setIsDragged(true);
    };
    const editor = passageRef.current;
    editor.addEventListener('mouseup', handleMouseUp);

    applyHighlightIfAllowed();

    return () => {
      editor.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examComponents.annotationDisplay]);

  const passageAreaStyle = {
    WebkitUserSelect: 'text',
    MozUserSelect: 'text',
    MsUserSelect: 'text',
    userSelect: 'text',
  };

  return (
    <>
      <div style={passageAreaStyle} ref={passageRef} className='question' dangerouslySetInnerHTML={{ __html: passage }} {...rest} />
      <Tooltip ref={tooltipRef} />
    </>
  );
};

export default PassageAreaJYT;
