import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import { MathJax } from 'better-react-mathjax';

//img
import examples from 'assets/img/exam/examples.png';

import 'styles/css/exam.css';

import { USER_LEVELS } from 'utils/constants.js';

const ExamMaReview = ({ examComponents, setExamComponents, currentQuestion, setCurrentQuestion, userLevel }) => {
  const dispatch = useDispatch();

  const userInfo = request.tokenDecoder();

  const [isUndoActive, setUndoActive] = useState(false);
  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });
  const [selectionRate, setSelectRate] = useState({
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    short: 0,
  });

  const examDivRef = useRef();
  const examArticleRef = useRef();

  const wrapButtonHandler = (arrow) => {
    if (arrow === 'left') {
      if (wrap.leftArrow)
        setWrap((prev) => {
          return { ...prev, leftArrow: false };
        });
      else {
        if (!wrap.leftArrow && wrap.rightArrow)
          setWrap((prev) => {
            return { ...prev, rightArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, leftArrow: !wrap.leftArrow };
          });
      }
    } else {
      if (wrap.rightArrow)
        setWrap((prev) => {
          return { ...prev, rightArrow: false };
        });
      else {
        if (!wrap.rightArrow && wrap.leftArrow)
          setWrap((prev) => {
            return { ...prev, leftArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, rightArrow: !wrap.rightArrow };
          });
      }
    }
  };

  const handleCloseButtonClick = () => {
    dispatch({ type: 'initExamInfo' });
    window.close();
  };

  useEffect(() => {
    if (currentQuestion.tryAnswerCntList != null) {
      let aCnt = 0;
      let bCnt = 0;
      let cCnt = 0;
      let dCnt = 0;
      let omitCnt = 0;

      if (currentQuestion?.questionFormat === 'M') {
        currentQuestion?.tryAnswerCntList.map((item, index) => {
          // Z 는 omit 값

          if (nvl(item.tryAnswer) === 'A') aCnt += item.tryAnswerCnt;
          else if (nvl(item.tryAnswer) === 'B') bCnt += item.tryAnswerCnt;
          else if (nvl(item.tryAnswer) === 'C') cCnt += item.tryAnswerCnt;
          else if (nvl(item.tryAnswer) === 'D') dCnt += item.tryAnswerCnt;
          else if (nvl(item.tryAnswer) === 'Z') omitCnt += item.tryAnswerCnt;
          else console.log('주관식?');

          return null;
        });

        let allCnt = aCnt + bCnt + cCnt + dCnt + omitCnt;

        setSelectRate((prevState) => ({
          ...prevState,
          A: (aCnt / allCnt) * 100,
          B: (bCnt / allCnt) * 100,
          C: (cCnt / allCnt) * 100,
          D: (dCnt / allCnt) * 100,
        }));
      } else {
        setSelectRate((prev) => ({
          ...prev,
          short: currentQuestion?.tryAnswerCntList,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 선생님 북마크 */
  const classOptionBookmarkHandler = () => {
    setCurrentQuestion((prev) => {
      return {
        ...prev,
        classBookmarkYn: nvl(currentQuestion.classBookmarkYn) === 'Y' ? 'N' : 'Y',
      };
    });

    updateClassBookmark();
  };

  const updateClassBookmark = () => {
    let params = {
      bookmarkYn: currentQuestion?.classBookmarkYn === 'Y' ? 'N' : 'Y',
      questionSeq: currentQuestion?.questionSeq,
      userSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        try {
          window.opener.getParentQuestion();
        } catch (error) {
          console.log('부모창과 연결이 끊겼어요');
        }
      }
    };

    request.put(`/api/dsat/class/option/bookmark/${currentQuestion.classSeq}/${currentQuestion?.questionSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('수학 시험 - Review - class 북마크 API 응답 실패');
    });
  };

  /** 북마크 수정 핸들러 */
  const bookmarkChangeHandler = () => {
    setCurrentQuestion((prev) => {
      return {
        ...prev,
        reviewBookmark: currentQuestion.reviewBookmark === 'Y' ? 'N' : 'Y',
      };
    });
    updateBookmark();
  };

  const updateBookmark = () => {
    let params = {
      reviewBookmarkYn: currentQuestion?.reviewBookmark === 'Y' ? 'N' : 'Y',
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        try {
          window.opener.getParentModule(currentQuestion?.uthSeq);
        } catch (error) {
          console.log('부모창과 연결이 끊겼어요');
        }
      }
    };

    request.put(`/api/exam/resultHistory/${currentQuestion?.uthSeq}/${currentQuestion?.questionSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('수학 시험 - Review - 북마크 API 응답 실패');
    });
  };

  /** 정답, 시도한 답을 비교해서 boolean return */
  const answerCheck = (correctAnswer, tryAnswer) => {
    const correctAnswers = correctAnswer.split(',').map((item) => item.trim());
    const normalizedTryAnswer = tryAnswer;

    return correctAnswers.includes(normalizedTryAnswer);
  };

  return (
    <div
      className={`com_popup pop_exam type_correct_answer active ${examComponents.directionDisplay ? 'direction_on' : ''} ${examComponents.cpDisplay && currentQuestion?.questionFormat !== 'S' ? 'calculator_on' : ''}`}>
      {/*addClass 'active' 시 노출*/} {/*addClass 'direction_on' 시 Directions 레이어 노출*/} {/*계산기 노출 시 addClass 'calculator_on'*/}
      <div className='pop_container'>
        <section ref={examDivRef} className='pop_header'>
          <div className='tit_area'>
            <div className='tit'>review</div>

            <button
              className='btn'
              onClick={() =>
                setExamComponents((prev) => {
                  return {
                    ...prev,
                    directionDisplay: !examComponents.directionDisplay,
                  };
                })
              }>
              Directions<i className='svg_icon icon_arrow black'>&nbsp;</i>
            </button>
            {examComponents.directionDisplay && <DirectionsPopup setExamComponents={setExamComponents} examDivRef={examDivRef} />}
          </div>
        </section>
        <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
          {' '}
          {/*btn_wrap 클릭 시 addClass " change_wrap_width"*/}
          {currentQuestion?.questionFormat !== 'M' && <MathQuestion wrap={wrap} setWrap={setWrap} wrapButtonHandler={wrapButtonHandler} />}
          <article ref={examArticleRef} className={`wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''} `}>
            {/*btn_undo 클릭 시 addClass 'undo_active'*/}
            <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
              &nbsp;
            </button>

            <MathJax>
              <div className='scroll_wrap'>
                <div className='inner'>
                  <div className='top_area'>
                    <div className='num'>{currentQuestion?.questionOrder}</div>
                    <div className='bookmark'>
                      <input type='checkbox' id='bookmark1' className='btn_bookmark' checked={currentQuestion?.bookMark === 'Y'} disabled />
                      <label style={{ cursor: 'auto' }} htmlFor='bookmark1'>
                        Mark for Review
                      </label>
                    </div>

                    {userInfo.userLevel === USER_LEVELS.STUDENT ? (
                      <div className='add_review_note'>
                        <input
                          type='checkbox'
                          id='addToReviewNote'
                          className='btn_add_review_note'
                          onChange={() => (userInfo.userLevel === USER_LEVELS.STUDENT ? bookmarkChangeHandler() : null)}
                          checked={currentQuestion?.reviewBookmark === 'Y'}
                        />
                        <label htmlFor='addToReviewNote'>{currentQuestion?.reviewBookmark !== 'Y' ? 'Add to Review Note' : 'Added to Review Note'}</label>
                      </div>
                    ) : (
                      <div className='review_bookmark'>
                        <input
                          type='checkbox'
                          id='reviewBookmark1'
                          className='btn_bookmark'
                          onChange={(e) => {
                            classOptionBookmarkHandler(e);
                          }}
                          checked={nvl(currentQuestion.classBookmarkYn) === 'Y'}
                        />
                        <label htmlFor='reviewBookmark1'>{currentQuestion.classBookmarkYn !== 'Y' ? 'Bookmark' : 'Bookmarked'}</label>
                      </div>
                    )}

                    <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={() => setUndoActive(!isUndoActive)}>
                      <i className='svg_icon icon_undo'>&nbsp;</i>
                      <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
                    </button>
                  </div>
                  <div id='questionArea' name='questionArea' className='text'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentQuestion.question.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                      }}
                    />
                    {/* <MathJaxViewer convertString={ nvl(currentQuestion?.question) } /> */}
                  </div>
                  <div id='answerArea' name='answerArea' className='answer_area'>
                    {/*객관식*/}
                    {currentQuestion?.questionFormat !== 'S' && (
                      <>
                        {/* <div className="item"> */}
                        {/* <div className={ nvl(currentQuestion?.underLine1) !== 'Y' ? 'item type_top' : 'item  type_top undo_selected' }> */}{' '}
                        {/*1130 item 높이 100넘을때 만 addClass type_top*/}
                        <div className={nvl(currentQuestion?.underLine1) !== 'Y' ? 'item' : 'item undo_selected'}>
                          <input
                            type='radio'
                            name='answer1'
                            id='answer1_1'
                            className='radio_answer'
                            value='A'
                            checked={currentQuestion?.tryAnswer === 'A'}
                            disabled
                          />
                          <label
                            className={`answer ${currentQuestion?.correctAnswer === 'A' ? 'right' : currentQuestion?.tryAnswer === 'A' ? 'wrong' : ''}`}
                            htmlFor='answer1_1'>
                            {/*1213 정답 addClass right*/}
                            <span className='num'>A</span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: currentQuestion?.multipleChoiceItems1.replace(
                                  '/common/commonImageView.do',
                                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                ),
                              }}
                            />
                          </label>
                          <button className='num' value='A'>
                            A
                          </button>
                        </div>
                        {/*1213 정답 일경우*/}
                        <div className={`answer_per ${currentQuestion?.correctAnswer === 'A' ? '' : 'wrong'}`}>
                          {currentQuestion?.correctAnswer === 'A' && <>Correct Answer</>}
                          {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'A' && <>Your Choice</>}
                          {userLevel && <div className='per'>{Math.round(selectionRate.A)}%</div>}
                        </div>
                        <div className={nvl(currentQuestion?.underLine2) !== 'Y' ? 'item' : 'item undo_selected'}>
                          <input
                            type='radio'
                            name='answer1'
                            id='answer1_2'
                            className='radio_answer'
                            value='B'
                            checked={currentQuestion?.tryAnswer === 'B'}
                            disabled
                          />
                          <label
                            className={`answer ${currentQuestion?.correctAnswer === 'B' ? 'right' : currentQuestion?.tryAnswer === 'B' ? 'wrong' : ''}`}
                            htmlFor='answer1_2'>
                            {/*1213 내 답이 오답일 경우 addClass wrong*/}
                            <span className='num'>B</span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: currentQuestion?.multipleChoiceItems2.replace(
                                  '/common/commonImageView.do',
                                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                ),
                              }}
                            />
                            {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems2) } /> */}
                          </label>
                          <button className='num' value='B'>
                            B
                          </button>
                        </div>
                        {/*1213 오답률*/}
                        <div className={`answer_per ${currentQuestion?.correctAnswer === 'B' ? '' : 'wrong'}`}>
                          {currentQuestion?.correctAnswer === 'B' && <>Correct Answer</>}
                          {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'B' && <>Your Choice</>}
                          {/*1213 내 답이 오답일 경우에만 노출*/}
                          {userLevel && <div className='per'>{Math.round(selectionRate.B)}%</div>}
                        </div>
                        <div className={nvl(currentQuestion?.underLine3) !== 'Y' ? 'item' : 'item undo_selected'}>
                          <input
                            type='radio'
                            name='answer1'
                            id='answer1_3'
                            className='radio_answer'
                            value='C'
                            checked={currentQuestion?.tryAnswer === 'C'}
                            disabled
                          />
                          <label
                            className={`answer ${currentQuestion?.correctAnswer === 'C' ? 'right' : currentQuestion?.tryAnswer === 'C' ? 'wrong' : ''}`}
                            htmlFor='answer1_3'>
                            <span className='num'>C</span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: currentQuestion?.multipleChoiceItems3.replace(
                                  '/common/commonImageView.do',
                                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                ),
                              }}
                            />
                            {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems3) } /> */}
                          </label>
                          <button className='num' value='C'>
                            C
                          </button>
                        </div>
                        {/*1213 오답률*/}
                        <div className={`answer_per ${currentQuestion?.correctAnswer === 'C' ? '' : 'wrong'}`}>
                          {currentQuestion?.correctAnswer === 'C' && <>Correct Answer</>}
                          {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'C' && <>Your Choice</>}
                          {userLevel && <div className='per'>{Math.round(selectionRate.C)}%</div>}
                        </div>
                        <div className={nvl(currentQuestion?.underLine4) !== 'Y' ? 'item' : 'item undo_selected'}>
                          <input
                            type='radio'
                            name='answer1'
                            id='answer1_4'
                            className='radio_answer'
                            value='D'
                            checked={currentQuestion?.tryAnswer === 'D'}
                            disabled
                          />
                          <label
                            className={`answer ${currentQuestion?.correctAnswer === 'D' ? 'right' : currentQuestion?.tryAnswer === 'D' ? 'wrong' : ''}`}
                            htmlFor='answer1_4'>
                            <span className='num'>D</span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: currentQuestion?.multipleChoiceItems4.replace(
                                  '/common/commonImageView.do',
                                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                ),
                              }}
                            />
                            {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems4) } /> */}
                          </label>
                          <button className='num' value='D'>
                            D
                          </button>
                        </div>
                        {/*1213 오답률*/}
                        <div className={`answer_per ${currentQuestion?.correctAnswer === 'D' ? '' : 'wrong'}`}>
                          {currentQuestion?.correctAnswer === 'D' && <>Correct Answer</>}
                          {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'D' && <>Your Choice</>}
                          {userLevel && <div className='per'>{Math.round(selectionRate.D)}%</div>}
                        </div>
                      </>
                    )}

                    {/*주관식*/}
                    {currentQuestion?.questionFormat !== 'M' && (
                      <div className='answer_input'>
                        <div className={`answer_input ${answerCheck(currentQuestion?.correctAnswer, currentQuestion?.tryAnswer) ? '' : 'wrong'}`}>
                          {/* ← To Do 1228 : 오답일 경우, 정답을 기입안했을때 addClass wrong*/}
                          {userLevel && (
                            <div className='input_box '>
                              <input type='text' className='input' defaultValue={currentQuestion?.tryAnswer} readOnly />
                            </div>
                          )}
                          {/*1228 객관식 정답을 기입했을때 만 노출*/}
                          {userLevel &&
                            (nvl(currentQuestion?.tryAnswer) !== '' || currentQuestion?.tryAnswer != null ? (
                              <span className='txt'>
                                Your choice{' '}
                                {/* To Do 1220 : 오답일 경우에만 노출 → {`${currentQuestion?.correctAnswer} !== ${currentQuestion?.tryAnswer} ? 'wrong' : ''}` && '66%'} */}
                              </span>
                            ) : (
                              <span className='txt'>You have omitted this question.</span>
                            ))}
                        </div>
                        <div className='input_box correct_answer'>
                          <input type='text' className='input' defaultValue={currentQuestion?.correctAnswer} readOnly />
                        </div>
                        {userLevel && (
                          <span className='txt'>
                            Correct Answer &nbsp;&nbsp;&nbsp;
                            {selectionRate?.short}%
                          </span>
                        )}
                      </div>
                    )}
                    {/*1228 객관식 정답을 선택 안했을때 만 노출*/}
                    {userLevel &&
                      (nvl(currentQuestion?.tryAnswer) === '' || nvl(currentQuestion?.tryAnswer) == null) &&
                      currentQuestion?.questionFormat !== 'S' && <div className='omit'>You have omitted this question.</div>}
                  </div>
                </div>
              </div>
            </MathJax>
          </article>
        </section>

        <section className='pop_footer'>
          <div className='name'>{nvl(userInfo?.userName)}</div>
          <article className='btn_area'>
            <button className='btn btn_close' onClick={handleCloseButtonClick}>
              Close
            </button>
            {/*1213 클릭 시 팝업 닫힘*/}
          </article>
        </section>
      </div>
    </div>
  );
};

// Directions
const DirectionsPopup = ({ setExamComponents, examDivRef }) => {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, directionDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents, examDivRef]);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of important math skills.
        <br />
        Use of a calculator is permitted for all questions. A reference sheet, calculator, and these directions can be accessed throughout the test.
        <br />
        Unless otherwise indicated:
        <ul className='list'>
          <li>All variables and expressions represent real numbers.</li>
          <li>Figures provided are drawn to scale.</li>
          <li>All figures lie in a plane.</li>
          <li>
            The domain of a given function <i className='math'>f</i> is the set of all real numbers x for which <i className='math'>f(x)</i>is a real number:
          </li>
        </ul>
        For <b>multiple-choice questions,</b> solve each problem and choose the correct answer from the choices provided.
        <br />
        Each multiple choice question has a single correct answer
        <br />
        For <b>student-produced response questions,</b> solve each problem and enter your answer as described below.
        <ul className='list'>
          <li>
            If you find <b>more than one correct answer,</b> enter only one answer.
          </li>
          <li>
            You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
          </li>
          <li>
            If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
          </li>
          <li>
            If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
          </li>
          <li>
            If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
          </li>
          <li>
            Don't enter <b>symbols</b> such as a percent sign. comma or dollar sign.
          </li>
          <li>
            Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
            count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
          </li>
        </ul>
        <img src={examples} alt='examples' />
      </div>
      <div className='btn_field'>
        <button
          className='btn close'
          onClick={() =>
            setExamComponents((prev) => {
              return { ...prev, directionDisplay: false };
            })
          }>
          Close
        </button>
      </div>
    </article>
  );
};

const MathQuestion = ({ wrap, setWrap, wrapButtonHandler }) => {
  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      {' '}
      {/*icon_wrap 클릭 시 addClass "big"*/}
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div className='inner question'>
          <h2>
            <b>Student-produced response directions</b>
          </h2>
          <ul className='list'>
            <li>
              If you find <b>more than one correct answer,</b> enter only one answer.
            </li>
            <li>
              You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
            </li>
            <li>
              If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
            </li>
            <li>
              If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
            </li>
            <li>
              If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
            </li>
            <li>
              Don't enter <b>symbols</b> such as a percent sign. comma or dollar sign.
            </li>
            <li>
              Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
              count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
            </li>
          </ul>
          <img src={examples} alt='examples' />
        </div>
      </div>
    </article>
  );
};

export default ExamMaReview;
