import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import SplineChart from 'components/_common/chart/SplineChart';

/** 학생 대시보드 페이지의 "Score Trends" section 컴포넌트 */
export default function ScoreTrendsSection({ testResult, childSeqState, dailyAveragePoint, weeklyAveragePoint, monthlyAveragePoint, scoreTrends }) {
  const userInfo = request.tokenDecoder();

  const [trendsButtons, setTrendsButtons] = useState([
    { text: 'Day', value: 0, active: true },
    { text: 'Week', value: 1, active: false },
    { text: 'Month', value: 2, active: false },
  ]);

  /** 테스트 결과의 날짜 얻기(월, 주, 일) */
  const getDateArray = (type) => {
    switch (type) {
      case 'day':
        dailyAveragePoint(testResult.firstTestDate, userInfo.userLevel === USER_LEVELS.STUDENT ? userInfo.userSeq : childSeqState);

        break;

      case 'week':
        weeklyAveragePoint(testResult.firstTestDate);
        break;

      case 'month':
        monthlyAveragePoint(testResult.firstTestDate);
        break;

      default:
        break;
    }
  };

  /** 날짜 변경 텝 핸들러 */
  const changeData = (value) => {
    setTrendsButtons((prevButtons) =>
      prevButtons.map((button) => ({
        ...button,
        active: button.value === value,
      }))
    );

    if (value === 0) getDateArray('day');
    else if (value === 1) getDateArray('week');
    else if (value === 2) getDateArray('month');
  };

  return (
    <>
      <div className='com_tab'>
        {trendsButtons?.map((item) => (
          <button key={'button_' + uuidv4()} className={item.active ? 'menu active' : 'menu'} onClick={() => changeData(item.value)}>
            {item.text}
          </button>
        ))}
      </div>
      <div className='graph_area'>
        <SplineChart dateArray={scoreTrends.dateArray} mathAvg={scoreTrends.mathAvg} engAvg={scoreTrends.engAvg} />
      </div>
    </>
  );
}
