import React, { useRef } from 'react';

//img
import imgKakao from 'assets/img/sns_kakao.svg';
import imgInsta from 'assets/img/sns_insta.svg';
// banner img
import Infonews1 from 'assets/img/banner/sat-infonews/infonews_banner_01.png';
import Infonews3 from 'assets/img/banner/sat-infonews/infonews_banner_03.png';
import Infonews4 from 'assets/img/banner/sat-infonews/infonews_banner_04.jpg';
import Infonews5 from 'assets/img/banner/sat-infonews/infonews_banner_05.jpg';
import Infonews6 from 'assets/img/banner/sat-infonews/infonews_banner_06.jpg';
import Infonews7 from 'assets/img/banner/sat-infonews/infonews_banner_07.jpg';
import Infonews8 from 'assets/img/banner/sat-infonews/infonews_banner_08.jpg';
// component
import BoardPreView from './_boardPreView';
import SwiperContainer from 'components/_common/lib/swiper/SwiperContainer';

const PostSide = ({ boardType, boardInfo, setSearchKeyword }) => {
  return (
    <section className='com_container right'>
      <BoardSearch setSearchKeyword={setSearchKeyword} />
      <FollowUs />
      <SwiperContainer
        containerClass={''}
        itemClass={'box banner_sec'}
        bannerContent={[
          // { link: 'https://forms.gle/GUN1FVtRqUGXn7dF9', image: Infonews6, visibleTo: 'B2B' },
          { link: 'https://pf.kakao.com/_prxbCG/106579635', image: Infonews7, visibleTo: 'B2B' },
          { link: 'http://pf.kakao.com/_prxbCG/106579515', image: Infonews8, visibleTo: 'B2B' },
          {
            link: 'https://product.kyobobook.co.kr/detail/S000213501792',
            image: Infonews1,
            visibleTo: 'ALL',
          },
          { link: 'https://www.youtube.com/watch?v=YhTTOtyFlAU', image: Infonews3, visibleTo: 'ALL' },
          { link: 'https://pf.kakao.com/_prxbCG', image: Infonews4, visibleTo: 'ALL' },
          { link: 'https://www.gateplus.org', image: Infonews5, visibleTo: 'ALL' },
        ]}
      />
      {boardType === 'news' ? <BoardPreView type='gate' /> : <BoardPreView type='dsat' />}
    </section>
  );
};

//게시글 검색
const BoardSearch = ({ setSearchKeyword }) => {
  const searchKeywordRef = useRef();
  const handleSearch = (e) => {
    if (e.key === 'Enter') setSearchKeyword(searchKeywordRef.current.value);
  };
  return (
    <article className='box search_sec'>
      <div className='input_box'>
        <i className='svg_icon icon_search'>&nbsp;</i>
        <input type='text' placeholder='Search' ref={searchKeywordRef} onKeyUp={(e) => handleSearch(e)} />
      </div>
    </article>
  );
};
//sns
const FollowUs = () => {
  return (
    <article className='box sns_sec'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_follow bullet'>&nbsp;</i>
          Follow Us
        </h2>
      </div>
      <div className='sns'>
        {/* <button><img src={imgBlog} alt="blog" onClick={() => window.open("https://blog.naver.com/gate_plus")}/></button> */}
        <button>
          <img src={imgKakao} alt='kakao' onClick={() => window.open('http://pf.kakao.com/_prxbCG')} />
        </button>
        <button>
          <img src={imgInsta} alt='insta' onClick={() => window.open('https://www.instagram.com/gateplus_official/')} />
        </button>
        {/* <button><img src={imgFacebook} alt="facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=61552643307566")}/></button> */}
      </div>
    </article>
  );
};

export default PostSide;
