import React from 'react';
import logo from 'assets/img/logo.png';

/** PDF Viewer 페이지의 첫 번째 영역 (시험 이름, 모듈 이름 등) */
export default function FirstSection({ testList }) {
  return (
    <section className='first_sec'>
      <p className='title'>Digital SAT</p>
      {(() => {
        switch (testList[0].set_subject) {
          case 'F':
            return (
              <>
                <p className='name'>
                  Practice
                  <br />
                  Test Set
                </p>
                <p className='test_name'>{testList[0]?.test_name}</p>
              </>
            );
          case 'E':
            return (
              <>
                <p className='name'>
                  Practice
                  <br />
                  Reading and Writing
                </p>
                <p className='test_name'>{testList[0]?.test_name}</p>
              </>
            );
          default:
            return (
              <>
                <p className='name'>
                  Practice
                  <br />
                  Math
                </p>
                <p className='test_name'>{testList[0]?.test_name}</p>
              </>
            );
        }
      })()}
      <div className='txt'>
        <p>
          SAT® is a registered trademark of the College Board, which is not affiliated with GATE+ and was not involved in the production of, and does not
          endorse, this product.
        </p>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
      </div>
    </section>
  );
}
