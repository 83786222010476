const timeLineDateText = {
  1: 'Registration Deadline',
  2: 'Deadline for Changes, Regular Cancellation,\nand Late Registration',
  3: 'SAT Test Date',
};

const timeLineDate = [
  { y: 2024, m: 'Aug', d: 9, textIndex: 1 },
  { y: 2024, m: 'Aug', d: 13, textIndex: 2 },
  { y: 2024, m: 'Aug', d: 24, textIndex: 3 },

  { y: 2024, m: 'Sept', d: 20, textIndex: 1 },
  { y: 2024, m: 'Sept', d: 24, textIndex: 2 },
  { y: 2024, m: 'Oct', d: 5, textIndex: 3 },

  { y: 2024, m: 'Oct', d: 18, textIndex: 1 },
  { y: 2024, m: 'Oct', d: 22, textIndex: 2 },
  { y: 2024, m: 'Nov', d: 2, textIndex: 3 },

  { y: 2024, m: 'Nov', d: 22, textIndex: 1 },
  { y: 2024, m: 'Nov', d: 26, textIndex: 2 },
  { y: 2024, m: 'Dec', d: 7, textIndex: 3 },
];

/** 학생 대시보드 페이지의 "SAT Timeline" Section 컴포넌트 */
export default function TimeLineSection() {
  return (
    <article className='time_line scroll'>
      {timeLineDate.map((date, i) => (
        <TimeLineItem key={i} {...date} txt={timeLineDateText[date.textIndex]} />
      ))}
    </article>
  );
}

// --- --- ---

function TimeLineItem({ y, m, d, txt }) {
  return (
    <div className='item'>
      <div className='date'>
        <b className='point'>{m}</b>.<b className='point'>{d}</b>.{y}
      </div>
      <div className='txt'>{txt}</div>
    </div>
  );
}
