// Packages
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Assets
import ImgCompletion from 'assets/img/exam/icon_completion.png';
// Functions
import request from 'utils/Request.utils';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';

/** 시험 완료 페이지 컴포넌트 */
export default function ExamCompletionPage() {
  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [completionBool, setCompletionBool] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** Next 버튼 동작 핸들러 함수 */
  const handleButtonNext = (e) => {
    window.self.close();
    // window.opener.navigate('/student/dashboard')
    window.opener.location.reload();
  };
  /** 시험 일정 수정 API 요청 */
  const endTest = () => {
    let params = {
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        setCompletionBool(true);
      }
    };

    request.put(`/api/exam/userTestHistory/${stateExamInfo.uthSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('시험 완료 - 시험 일정 수정 API 응답 실패');
    });
  };
  /** 과목별 점수 계산 API 요청 */
  const scoreInsert = () => {
    let params = {
      uthSeq: stateExamInfo.uthSeq,
    };

    if (stateExamInfo.setSubject !== 'E') params.mm2d = stateExamInfo.currentTest.mm2d;
    else params.em2d = stateExamInfo.currentTest.em2d;

    const successHandler = (response) => {
      if (response.code === 200) {
        endTest();
      }
    };

    request.post('/api/exam/insertPoint', params, successHandler).catch((error) => {
      console.error(error);
      alert('쉬는 시간 - 과목별 점수 계산 API 응답 실패');
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (stateExamInfo.uthSeq > 0) {
      if (stateExamInfo['currentTest'].em2 === 'D') scoreInsert();
      else if (stateExamInfo['currentTest'].mm2 === 'D') scoreInsert();
    }

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (completionBool) {
      dispatch({ type: 'clearExamInfo' });
      dispatch({ type: 'clearExamComponents' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completionBool]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <div className='com_popup type_normal pop_completion active'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='tit'>You're All Finished!</div>
          <div className='box'>
            <img src={ImgCompletion} className='icon' alt='Completion' />
            <p className='txt'>
              Return to <b>Student Dashboard</b> to view your score.
            </p>
            <p className='txt'>When you see this page on test day, you'll know your scores have been submitted.</p>
            <article className='btn_area'>
              {(completionBool || stateExamInfo.uthSeq === 0) && (
                <button className='btn' onClick={(e) => handleButtonNext(e)}>
                  Return to Dashboard
                </button>
              )}
            </article>
          </div>
        </section>
      </div>
    </div>
  );
}
