/** 시험 정보 전역 상태 수정 */
export const SET_EXAM_INFO = 'setExamInfo';
/** 시험 정보 전역 상태 중 문제 목록 갱신(덮어쓰기) */
export const SET_EXAM_QUESTION_LIST = 'setExamQuestionList';
/** 시험 정보 전역 상태 중 문제 목록 추가 */
// export const ADD_EXAM_QUESTION_LIST = 'addExamQuestionList';
/** 시험 정보 전역 상태 중 문제 목록 수정 */
export const EDIT_EXAM_QUESTION_LIST = 'editExamQuestionList';
/** 시험 정보 전역 상태 중 문제 목록 초기화 */
export const CLEAR_EXAM_QUESTION_LIST = 'clearExamQuestionList';
/** 시험 정보 전역 상태 초기화 (전체 시험 세트) */
export const CLEAR_EXAM_INFO = 'clearExamInfo';
/** 시험 정보 전역 상태 초기화 (과목 별) */
export const INIT_EXAM_INFO = 'initExamInfo';

/** 타이머 수정 */
export const SET_TIMER = 'setTimer';
/** 타이머 초기화 */
export const CLEAR_TIMER = 'clearTimer';
