import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import useResponsiveFontSize from 'hooks/useResponsiveFontSize';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

/** 학생 대시보드 페이지의 "상단 비주얼 (글자) 영역" 컴포넌트 */
export default function VisualTextArea() {
  const stateMenuManager = useSelector((state) => state.MenuManager);
  const userInfo = request.tokenDecoder();
  /** useResponsiveFontSize 사용 코드(학생 이름 크기 조절) */
  const textRef = useRef(null);

  const fontSize = useResponsiveFontSize(textRef, 48);

  return (
    <section className='com_visual_text'>
      <div className='tit'>
        Welcome,
        <span className='dashboard-text-section'>
          <b
            className='point name'
            ref={textRef}
            style={{
              fontSize: `${fontSize}px`,
              visibility: fontSize ? 'visible' : 'hidden',
            }}>
            &nbsp;
            {nvl(stateMenuManager?.userName) !== '' ? stateMenuManager?.userName : userInfo?.userName}!
          </b>
        </span>
      </div>

      <div className='txt'>Take a practice test and get ready for test day.</div>
    </section>
  );
}
