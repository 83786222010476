import { useEffect, useRef } from 'react';
import examples from 'assets/img/exam/examples.png';

/** (수학 시험)문제 입력 방법 가이드 툴팁 컴포넌트 */
export default function DirectionsPopup({ dispatch, stateExamComponents, examDivRef }) {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: false } });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.directionDisplay, examDivRef]);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of important math skills.
        <br />
        Use of a calculator is permitted for all questions. A reference sheet, calculator, and these directions can be accessed throughout the test.
        <br />
        Unless otherwise indicated:
        <ul className='list'>
          <li>All variables and expressions represent real numbers.</li>
          <li>Figures provided are drawn to scale.</li>
          <li>All figures lie in a plane.</li>
          <li>
            The domain of a given function <i className='math'>f</i> is the set of all real numbers x for which <i className='math'>f(x)</i>is a real number:
          </li>
        </ul>
        For <b>multiple-choice questions,</b> solve each problem and choose the correct answer from the choices provided.
        <br />
        Each multiple choice question has a single correct answer
        <br />
        For <b>student-produced response questions,</b> solve each problem and enter your answer as described below.
        <ul className='list'>
          <li>
            If you find <b>more than one correct answer,</b> enter only one answer.
          </li>
          <li>
            You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
          </li>
          <li>
            If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
          </li>
          <li>
            If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
          </li>
          <li>
            If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
          </li>
          <li>
            Don't enter <b>symbols</b> such as a percent sign. comma or dollar sign.
          </li>
          <li>
            Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0”
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; will not count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not
            count as
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; a correct answer.
          </li>
        </ul>
        <img src={examples} alt='examples' />
      </div>
      <div className='btn_field'>
        <button className='btn close' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: false } })}>
          Close
        </button>
      </div>
    </article>
  );
}
