import { TOTAL_QUESTION_NUMBER_MATH } from 'components/exam/dsat/_utils/constants/totalQuestionNumberSetting';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nvl } from 'utils/Common.utils';

/** 문제 선택 (미니 창) 컴포넌트 (수학)  */
export default function MiniReviewPage({ miniReviewToggleRef }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const miniReviewRef = useRef();

  const gotoBigReview = () => {
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'miniReviewDisplay', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: true } });
  };

  const gotoChoiceQuestion = (page) => {
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'miniReviewDisplay', data: false } });

    navigate('/exam/dsat/math', {
      state: { currentNum: page },
      replace: true,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (miniReviewRef.current && !miniReviewRef.current.contains(event.target) && !miniReviewToggleRef.current.contains(event.target)) {
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'miniReviewDisplay', data: false } });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents]);

  return (
    <article ref={miniReviewRef} className='question_layer layer'>
      <button className='btn_layer_close' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'miniReviewDisplay', data: false } })}>
        <i className='svg_icon btn_pop_close black'>&nbsp;</i>
      </button>
      <div className='tit_field'>
        Section 1, Module {stateExamInfo.questionList[stateExamInfo.currentNum]?.moduleNum}: Reading and Writing <br /> Question
      </div>
      <button className='btn_close'>
        <i className='svg_icon icon_delete'>&nbsp;</i>
      </button>
      <div className='info_field'>
        <div className='item'>
          <i className='svg_icon icon_current'>&nbsp;</i>Current
        </div>
        <div className='item'>
          <i className='svg_icon icon_unanswered'>&nbsp;</i>Unanswered
        </div>
        <div className='item'>
          <i className='svg_icon icon_bookmark'>&nbsp;</i>For Review
        </div>
      </div>
      <div className='question_field'>
        {Array.from({ length: TOTAL_QUESTION_NUMBER_MATH }, (_, index) => (
          <button
            key={`mini_${index + 1}`}
            className={` item ${stateExamInfo.currentNum === index + 1 ? 'current' : ''} 
                            ${nvl(stateExamInfo.questionList[index]?.tryAnswer) !== '' ? 'answered' : ''}
                            ${stateExamInfo.questionList[index]?.bookMark === 'Y' ? 'review' : ''} `}
            onClick={() => gotoChoiceQuestion(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
      <div className='btn_field'>
        <button className='btn' onClick={gotoBigReview}>
          Go to Review Page
        </button>
      </div>
    </article>
  );
}
