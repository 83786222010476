import React from 'react';
import styled from 'styled-components';

/** 주요 버튼 컴포넌트 */
function PrimaryBtn({ clickHandler, ...rest }) {
  return <S.Wrap onClick={clickHandler} {...rest}></S.Wrap>;
}

export default PrimaryBtn;

const S = {};
S.Wrap = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1.25rem;
  border-radius: 0.375rem;
  background-color: #0068bd;
  color: var(--white-100, #fff);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.32px;
`;
