import styled from 'styled-components';
import imgCheckboxDefault from 'assets/gate_member/image/select-box_default.svg';
import imgCheckboxCheck from 'assets/gate_member/image/select-box_pressed.svg';
import imgCheckIcon from 'assets/gate_member/image/check_icon.svg';
import imgCheckIconSuccess from 'assets/gate_member/image/success_icon.svg';
import { useEffect, useState } from 'react';
import SelectBox from 'components/_common/inputs/SelectBox';
import { useForm } from 'react-hook-form';
import request from 'utils/Request.utils';
import SchoolLocationSelectBox from 'components/_common/inputs/SchoolLocationSelectBox';
import usePressESC from 'hooks/usePressESC';
// hook

/** 학생 추가 form modal */
function AddStudentAccount({ setBack, setShowAddStudentModal, callback }) {
  usePressESC(() => handleButtonBack());

  const [schoolTypeList, setSchoolTypeList] = useState(); // 학교 종류 목록 상태
  const [locationList, setLocationList] = useState([]); // 학교 위치 목록 상태
  const [schoolType, setSchoolType] = useState(''); // 학교 종류 상태
  const [schoolLocation, setSchoolLocation] = useState(''); // 학교 위치 상태

  /** react-hook-form */
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({ mode: 'onChange' });

  /** 비밀번호 실시간 유효성 검증 메소드 묶음 */
  const vaildationPassword = {
    islength: (text) => text.length >= 8,
    isPatteurn: (text) => {
      const patternSpecial = /[\W_]/;
      const patternNum = /[0-9]/;
      return patternSpecial.test(text) && patternNum.test(text);
    },
    isMatch: (text1, text2) => text1 === text2,
  };
  /** 전화 번호 입력 시, 포메팅 */
  const phoneNumberCheck = (event, filedName, maxLength = 13) => {
    let formattedValue = event.target.value.replace(/[^0-9-]/g, '');

    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.slice(0, maxLength);
    }

    setValue(filedName, formattedValue);
  };

  /** Form 제출 로직 */
  const onValid = (data, e) => {
    const userInfo = request.tokenDecoder();
    if (!data) {
      console.log('입력 받은 데이터에 오류 발생');
      return;
    } else if (!userInfo) {
      console.log('유저 정보를 받아 올 수 없습니다.');
      return;
    }

    // 비밀번호 매칭 확인
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { message: '비밀번호가 일치하지 않습니다.' });
      return;
    }

    // schoolType 코드 확인
    if (!schoolType) {
      setError('schoolType', { message: 'schoolType 코드가 존재하지 않습니다.' });
      return;
    }
    // schoolLocation 코드 확인
    if (!schoolLocation) {
      setError('schoolLocation', { message: 'schoolLocation 코드가 존재하지 않습니다.' });
      return;
    }

    /** Post 요청으로 보낼 data */
    const params = {
      ecSeq: String(userInfo.ecSeq).trim(),
      userEmail: String(data.email).trim(),
      userPasswd: String(data.password).trim(),
      userName: String(data.userName).trim(),
      studentSchool: String(data.schoolName).trim(), // 학교 이름
      schoolType: String(schoolType).trim(), // 학교 유형 (공통코드)
      studentGrade: String(data.grade).trim(),
      schoolLocation: String(schoolLocation).trim(), // 공통코드
      parentPhone: String(data.parentPhoneNumber).trim(),
      userMobile: String(data.mobilePhoneNumber).trim(),
      regUserSeq: String(userInfo.userSeq).trim(), // 등록자 id(시퀀스)
      acceptReceivingMail: data.agree03 ? 'Y' : 'N', // 메일 수신 동의
      acceptMarketingMail: data.agree02 ? 'Y' : 'N', // 마케팅 수신 동의
    };

    /** 응답 이후 동작 */
    const successHandler = (response) => {
      if (response.code === 407) {
        setError('email', { message: 'The email is already in use. Please use another email.' });
        return;
      }
      if (response.code === 408) {
        setError('mobilePhoneNumber', { message: 'The phone number is already in use. Please use another phone number.' });
        return;
      }
      if (response.code === 200) {
        clearErrors();
        reset();
        // 응답 성공 후 form 비우고, 모달 닫기
        setShowAddStudentModal(false);
        callback();
      }
    };

    request.post(`/api/academy/student`, params, successHandler);
  };
  /** Form 제출 실패 로직 */
  const onInValid = (errors, e) => {
    console.log('실패 : ', errors, e);
    return null;
  };

  /** "Back" 버튼 핸들러 */
  const handleButtonBack = () => {
    setBack(true);
  };

  /** Grade 목록 설정 */
  const gradeList = Array.from({ length: 12 }, (_, index) => ({
    value: String(index + 1),
    name: String(index + 1),
  }));
  /** 학교 분류 목록, 학교 위치 목록 API 얻어오기 */
  const getSchoolTypeList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let commonCodeList = response.result.commonCodeList;
        let locationList = response.result.locationList;

        setSchoolTypeList(
          commonCodeList &&
            commonCodeList
              .filter((i) => i.parentCode === '012000000000000')
              .map((item) => ({
                value: item.commonCode,
                name: item.codeName,
              }))
        );

        setLocationList(locationList);
      }
    };

    request.get(`/api/common/common-code?multiCode=${encodeURI('012000000000000')}&schoolLocationYn=Y`, null, successHandler);
  };

  /** 셀렉트 박스(SchoolType) 선택 동작 핸들러 */
  const handleSelectChangeSchoolType = (name = '', item) => {
    setSchoolType(item.value);
    setValue(name, item.name);
  };
  /** 셀렉트 박스(Grade) 선택 동작 핸들러 */
  const handleSelectChangeGrade = (name = '', item) => {
    setValue(name, item.name);
  };
  /** 셀렉트 박스(SchoolLocation) 선택 동작 핸들러 */
  const handleSelectChangeSchoolLocation = (name = '', item) => {
    setSchoolLocation(item.commonCode);
    setValue(name, item.schoolLocation);
  };

  useEffect(() => {
    getSchoolTypeList();
  }, []);

  return (
    <ModalBackgroundWrap>
      <ModalBox>
        <div className='inner_scroll_area scroll'>
          <h2 className='title_main'>Add student account</h2>
          <p className='title_sub'>Manually enter account information to create new Student account</p>
          <form onSubmit={handleSubmit(onValid, onInValid)}>
            <div className='input_area'>
              <fieldset className='w-50'>
                <legend>Account information</legend>
                <ul>
                  <li>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      id='email'
                      className={errors.email && 'error_input'}
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      required
                    />
                    {errors.email && <p className='error_message'>{errors.email.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='password'>Password</label>
                    <input
                      type='password'
                      id='password'
                      className={errors.password && 'error_input'}
                      {...register('password', {
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        // validate: {
                        //   isValidPassword: (value) => {
                        //     if (value === '12345678') {
                        //       console.log('중복체크')
                        //       return '중복입니다.'
                        //     }
                        //   },
                        // },
                      })}
                      required
                      minLength={8}
                    />
                    {errors.password && <p className='error_message'>{errors.password.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='confirmPassword'>Confirm Password</label>
                    <input
                      type='password'
                      id='confirmPassword'
                      className={errors.confirmPassword && 'error_input'}
                      {...register('confirmPassword', {
                        required: 'Confirm Password is required',
                        minLength: {
                          value: 8,
                          message: 'Confirm Password must be at least 8 characters',
                        },
                        validate: {
                          isMatch: (value) => {
                            return value === watch('password') || 'Passwords must match';
                          },
                        },
                      })}
                      required
                      minLength={8}
                    />
                    {errors.confirmPassword && <p className='error_message'>{errors.confirmPassword.message}</p>}
                  </li>
                </ul>
                <div className='com_password_message'>
                  <p className={`check ${watch().password && watch().password.length >= 8 ? 'valid' : ''}`}>Must be over 8 characters</p>
                  <p className={`check ${watch('password') && vaildationPassword.isPatteurn(watch('password')) ? 'valid' : ''}`} id='characterMessage'>
                    Must have a number and a special character (!, @ , ?)
                  </p>
                  <p
                    className={`check ${watch('password') && watch().password.length >= 8 && watch().password === watch().confirmPassword ? 'valid' : ''}`}
                    id='matchMessage'>
                    Passwords must match
                  </p>
                </div>
              </fieldset>
              <fieldset className='w-50'>
                <legend>student information</legend>
                <ul>
                  <li>
                    <label htmlFor='userName'>Name</label>
                    <input
                      type='text'
                      id='userName'
                      className={errors.userName && 'error_input'}
                      {...register('userName', {
                        required: 'Name is required',
                      })}
                      required
                    />
                    {errors.userName && <p className='error_message'>{errors.userName.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='schoolName'>School Name</label>
                    <input
                      type='text'
                      id='schoolName'
                      className={errors.schoolName && 'error_input'}
                      {...register('schoolName', {
                        required: 'School Name is required',
                      })}
                      required
                    />
                    {errors.schoolName && <p className='error_message'>{errors.schoolName.message}</p>}
                  </li>
                  <li className='horizontal_area'>
                    <div className='required'>
                      <div className='label'>School Type</div>
                      <SelectBox
                        register={register('schoolType', {
                          required: 'School Type is required',
                        })}
                        handleChange={handleSelectChangeSchoolType}
                        placeholder='School Type'
                        optionList={schoolTypeList}
                      />
                    </div>
                    <div className='required'>
                      <div className='label'>Grade</div>
                      <SelectBox
                        register={register('grade', {
                          required: 'Grade is required',
                        })}
                        handleChange={handleSelectChangeGrade}
                        placeholder='Grade'
                        optionList={gradeList}
                      />
                    </div>
                    {errors.schoolType && <p className='error_message'>{errors.schoolType.message}</p>}
                    {errors.grade && <p className='error_message'>{errors.grade.message}</p>}
                  </li>
                  <li>
                    <div className='required'>
                      <div className='label'>School Location</div>
                      <SchoolLocationSelectBox
                        register={register('schoolLocation', {
                          required: 'School Location is required',
                        })}
                        handleChange={handleSelectChangeSchoolLocation}
                        placeholder='Enter school location'
                        optionList={locationList}
                      />
                    </div>
                    {errors.schoolLocation && <p className='error_message'>{errors.schoolLocation.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='parentPhoneNumber'>Parent Phone NO.</label>
                    <input
                      type='text'
                      id='parentPhoneNumber'
                      className={errors.parentPhoneNumber && 'error_input'}
                      {...register('parentPhoneNumber', {
                        //  required: 'Parent Phone NO. is required',
                        pattern: {
                          value: /^\d{3}-\d{3,4}-\d{4}$/,
                          message: 'Invalid phone number format (e.g. 010-1234-5678 or 010-123-1234)',
                        },
                      })}
                      onChange={(e) => phoneNumberCheck(e, 'parentPhoneNumber')}
                      //required
                    />
                    {errors.parentPhoneNumber && <p className='error_message'>{errors.parentPhoneNumber.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='mobilePhoneNumber'>Mobile Phone NO.</label>
                    <input
                      type='tel'
                      id='mobilePhoneNumber'
                      className={errors.mobilePhoneNumber && 'error_input'}
                      {...register('mobilePhoneNumber', {
                        required: 'Mobile Phone NO. is required',
                        pattern: {
                          value: /^\d{3}-\d{3,4}-\d{4}$/,
                          message: 'Invalid phone number format (e.g. 010-1234-5678 or 010-123-1234)',
                        },
                      })}
                      onChange={(e) => phoneNumberCheck(e, 'mobilePhoneNumber')}
                      required
                    />
                    {errors.mobilePhoneNumber && <p className='error_message'>{errors.mobilePhoneNumber.message}</p>}
                  </li>
                </ul>
              </fieldset>
            </div>
            <fieldset className='agree_check_area'>
              <legend>Agree</legend>
              <ul>
                <li>
                  <input type='checkbox' name='' id='agree01' {...register('agree01', { required: 'This check box is required' })} />
                  <label htmlFor='agree01'>
                    I acknowledge that I am creating an account on behalf of the Student, and that the Student have read and agreed to GATE+{' '}
                    <a href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3' target='_blank' rel='noopener noreferrer'>
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13' target='_blank' rel='noopener noreferrer'>
                      Terms of Service
                    </a>{' '}
                    (Mandatory){' '}
                  </label>
                  {errors.agree01 && <p className='error_message'>{errors.agree01.message}</p>}
                </li>
                <li>
                  <input type='checkbox' name='' id='agree02' />
                  <label htmlFor='agree02'>
                    I agree to receive{' '}
                    <a href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369' target='_blank' rel='noopener noreferrer'>
                      marketing communications
                    </a>{' '}
                    from GATE+ (Optional)
                  </label>
                </li>
                <li>
                  <input type='checkbox' name='' id='agree03' />
                  <label htmlFor='agree03'>I want to receive Gate+ Newsletter (Optional) </label>
                </li>
              </ul>
            </fieldset>
            <div className='button_area'>
              <button type='button' className='btn_stroke' onClick={handleButtonBack}>
                Back
              </button>
              <button type='submit' className='btn_fill'>
                Next
              </button>
            </div>
          </form>
        </div>
      </ModalBox>
    </ModalBackgroundWrap>
  );
}

export default AddStudentAccount;

const ModalBackgroundWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBox = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  width: 50rem;
  box-sizing: border-box;
  padding: 1rem;
  .inner_scroll_area {
    overflow-y: scroll;
    max-height: 80vh;
    padding: 0.875rem;
  }
  .title_main {
    color: var(--sub-text, #7f8998);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .title_sub {
    color: var(--sub-text, #7f8998);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.5rem;
  }
  fieldset {
    & > ul {
      & > li {
        &.horizontal_area {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 0.5rem;
          & > *:nth-child(1) {
            flex: 2.5;
          }
          & > *:nth-child(2) {
            flex: 1;
          }
        }
        &:has(input[required]) label::before,
        &:has(.required) .label::before {
          content: '*';
          color: var(--error-color, #f24b4b);
          font-size: 1rem;
          font-weight: 700;
          line-height: 0.75rem; /* 75% */
          text-transform: uppercase;
          margin-right: 0.375rem;
        }
        .error_message {
          display: block;
          flex: 100%;
          width: 100%;
          color: #f24b4b;
          margin-top: 0.5rem;
        }
      }
      & > li + li {
        margin-top: 1.875rem;
      }
    }
    &.agree_check_area {
      margin-top: 1.875rem;
      legend {
        display: none;
      }
      & > ul {
        label {
          text-transform: none;
        }
        & > li {
          position: relative;
          &:has(input[required]) label::before,
          &:has(.required) .label::before {
            content: '';
          }
        }
        & > li + li {
          margin-top: 0;
        }
      }
    }
  }
  .input_area {
    display: flex;
    gap: 2.813rem;
    margin-top: 2.813rem;
  }
  .w-50 {
    width: 50%;
  }
  legend {
    color: var(--main-blue, #0068bd);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem; /* 111.111% */
    text-transform: uppercase;
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eff1f3;
    margin-bottom: 1rem;
  }
  label,
  .label {
    color: var(--sub-text, #7f8998);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5rem;
    a {
      text-decoration: underline;
      color: #0068bd;
    }
  }
  input.error_input {
    border-color: #f24b4b;
  }
  input[type='checkbox'] {
    font-size: inherit;
    border: none;
    border-radius: 0;
    height: 0;
    width: 0;
    padding-inline: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    & + label {
      color: #434d5b;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.75rem;
      cursor: pointer;
      display: block;
      position: relative;
      box-sizing: border-box;
      padding-left: 2rem;
      margin-top: 0.625rem;
      &::before {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: calc(50% - 0.775rem);
        left: 0;
        display: inline-block;
        background: url(${imgCheckboxDefault}) no-repeat center center;
        background-size: cover;
      }
    }
    &:checked + label {
      &::before {
        background: url(${imgCheckboxCheck}) no-repeat center center;
      }
    }
  }
  .button_area {
    margin-top: 2.813rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    button {
      min-width: 11.25rem;
      height: 3.5rem;
      padding: 1.25rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 100%;
      border-radius: 6px;
      transition: 0.3s;
      cursor: pointer;
      &.btn_stroke {
        background-color: #fff;
        border: 1px solid #0068bd;
        color: #0068bd;
      }
      &.btn_fill {
        background-color: #0068bd;
        color: #fff;
      }
    }
  }

  .com_password_message {
    margin-block: 0.5rem 1.25rem !important;
    .check {
      color: rgb(34, 34, 34, 0.5);
      font-weight: 400;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-image: ${imgCheckIcon};
        background-position: center;
        background-repeat: no-repeat;
      }
      &.valid {
        color: rgb(34, 34, 34);
        &::before {
          background-image: url(${imgCheckIconSuccess});
        }
      }
    }
  }
`;
