import React, { lazy, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Lnb from 'layout/Lnb'; //Lnb
import Gnb from 'layout/Gnb';
import Footer from 'layout/Footer'; //Footer

// added by psk 20240119 - 각종 auth check
import AuthCheck from 'layout/AuthCheck'; //Gnb

import request from 'utils/Request.utils';

// upgrade
import DsatIntro from 'components/exam/dsat/intro/Page';
import DsatTutorial from 'components/exam/dsat/Tutorial/Page';
import DsatBreakTime from 'components/exam/dsat/BreakTime/Page';
import ExamEnglish from 'components/exam/dsat/ExamEn/Page';
import ExamMathematics from 'components/exam/dsat/ExamMa/Page';
import InProgress from 'components/exam/dsat/inprogress/Page';
import Completion from 'components/exam/dsat/Completion/Page';
import ExamMaSubjectiveTest from 'components/exam/dsat/examMaSubjectiveTest/Page';

// const ExamIntro = lazy(() => import('components/exam/Intro'))

const TestRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/exam/tms') >= 0)
    authBool = true; // 무조건 통과
  else if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        // { path: "/intro", element: <ExamIntro /> },
        // { path: "/moduleOver", element: <ModuleOver /> },
        // { path: "/progress", element: <ExamProgress /> },
        // { path: "/main", element: <ExamMain /> },

        // 고도화 작업
        { path: '/dsat/intro', element: <DsatIntro /> },
        { path: '/dsat/tutorial', element: <DsatTutorial /> },
        { path: '/dsat/rw', element: <ExamEnglish /> },
        { path: '/dsat/breaktime', element: <DsatBreakTime /> },
        { path: '/dsat/math', element: <ExamMathematics /> },
        { path: '/dsat/inprogress', element: <InProgress /> },
        { path: '/dsat/completion', element: <Completion /> },
        { path: '/dsat/mathSubjective', element: <ExamMaSubjectiveTest /> },

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  return (
    <>
      {/* {
        pathname.toLowerCase().indexOf('/intro') < 0 && pathname.toLowerCase().indexOf('/moduleover') < 0 && pathname.toLowerCase().indexOf('/progress') < 0 ? (
          <>
            <Lnb/>
            <Gnb />
          </>
        )
        :  
          null
      } */}
      <AuthCheck />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {/* { pathname.toLowerCase().indexOf('/intro') < 0 && pathname.toLowerCase().indexOf('/moduleover') < 0 && pathname.toLowerCase().indexOf('/progress') < 0 ? <Footer/> : null } */}
    </>
  );
};

export default TestRoute;
