import React from 'react';
import styled from 'styled-components';
import selectBoxDefault from 'assets/gate_member/image/select-box_default.svg';
import selectBoxChecked from 'assets/gate_member/image/select-box_pressed.svg';
import { FieldValues, UseFormRegisterReturn } from 'react-hook-form';

interface IProps {
  register: UseFormRegisterReturn<any>;
  inputId: string;
  children: React.ReactNode;
}

/** react-hook-form 용 check box 컴포넌트 */
function CheckBox({ register, inputId = '', children }: IProps) {
  return (
    <S.Wrap>
      <input {...register} type='checkbox' id={inputId} />
      <label htmlFor={inputId}>{children}</label>
    </S.Wrap>
  );
}

export default CheckBox;

const S: any = {};
S.Wrap = styled.div`
  input[type='checkbox'] {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    & + label {
      cursor: pointer;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: left;
      text-align: left;
      a {
        color: #0068bd;
        text-decoration: underline;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(${selectBoxDefault});
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 0.5rem;
      }
    }
    &:checked {
      & + label {
        &::before {
          background-image: url(${selectBoxChecked});
        }
      }
    }
  }
`;
