import { useState, useEffect } from 'react';

import { USER_LEVELS } from '../../../../utils/constants';

export default function PersonalInformation(props) {
  const { ID, UserDetails, setOpenEditModal, setOpenDeleteAccountModal, dbUserInfo, setModifiedData, setNoPassword, reloadData } = props;
  const [renderingData, setRenderingData] = useState([]);

  useEffect(() => {
    setNoPassword(false);

    const renderInfoItems = () => {
      if (!dbUserInfo) return [];

      const infoItemsBase = [
        { name: 'userName', value: dbUserInfo.userName },
        { name: 'Registration Date', value: dbUserInfo.regDate },
        { name: 'Email', value: dbUserInfo.email },
        {
          name: 'Mobile Number',
          value: dbUserInfo.mobile.replace(/-/g, ' - '),
        },
        { name: 'Password', value: '******' },
      ];

      if (dbUserInfo.userLevel === USER_LEVELS.STUDENT) {
        // 학생
        return [
          ...infoItemsBase.slice(0, 4),
          {
            name: 'Parent Mobile Number',
            value: dbUserInfo?.familyMobile?.replace(/-/g, ' - '),
          },
          { name: 'School Name', value: dbUserInfo.studentSchool },
          {
            name: 'School Location',
            value: dbUserInfo.schoolLocationName.toUpperCase(),
          },
          { name: 'School Type', value: dbUserInfo.schoolTypeName },
          { name: 'Grade', value: dbUserInfo.grade.toString() },
          { name: 'Password', value: '******' },
        ];
      } else {
        return infoItemsBase;
      }
    };

    setRenderingData(renderInfoItems().slice(1));
    setModifiedData(renderInfoItems().slice(0, renderInfoItems().length - 1));
    // eslint-disable-next-line
  }, [dbUserInfo, reloadData]);

  const accountInfo = {
    infoMode: 'personal',
    userSeq: dbUserInfo.userSeq,
    userName: dbUserInfo.userName,
    userLevel: dbUserInfo.userLevel,
    upFileSeq: dbUserInfo.upFileSeq,
    companyName: '',
  };

  return (
    <>
      <h2 className='tit'>personal information</h2>
      <p className='sub_tit'>Your Personal Details</p>
      <div className='btn_area'>
        <button className='com_btn m point' onClick={() => setOpenEditModal(true)}>
          EDIT
        </button>
      </div>
      <article className='information_sec'>
        <ID accountInfo={accountInfo} size='big' personalProfile={true} />
        <UserDetails renderingData={renderingData} />
        <div className='com_btn_wrap bottom'>
          <button className='com_btn xs txt red' onClick={() => setOpenDeleteAccountModal(true)}>
            DELETE ACCOUNT
          </button>
        </div>
      </article>
    </>
  );
}
