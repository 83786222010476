import styled from 'styled-components';

/** history 날자를 랜더링 해주는 컴포넌트 */
function KeyHistoryDate({ date }) {
  const dataArr = date.split(/[,\s]+/);

  return (
    <S.HistoryDate>
      <span>{dataArr[0]}</span>
      <span>{dataArr[1]}th</span>
      <span>,</span>
      <span>{dataArr[2]}</span>
    </S.HistoryDate>
  );
}

export default KeyHistoryDate;

const S = {}; // S-dot 문법용 객체

S.HistoryDate = styled.p`
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 1.88rem;
  gap: 0.35rem;
  padding-bottom: 0.35rem;
`;
