import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BasicColumnChart = ({ timePerQuestionData }) => {
  const defaultSeries = [
    {
      name: 'You',
      data: [],
      color: '#0068BD',
    },
    {
      name: 'GATE⁺',
      data: [],
      color: '#E3E7ED',
    },
  ];
  return (
    <div id='chart'>
      {timePerQuestionData.series[0].data.length > 0 ? (
        <ReactApexChart options={timePerQuestionData.options} series={timePerQuestionData.series} type='bar' height={350} />
      ) : (
        <ReactApexChart options={timePerQuestionData.options} series={defaultSeries} type='bar' height={350} />
      )}
    </div>
  );
};

export default BasicColumnChart;
