import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import styled from 'styled-components';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';

function ScoreHistoryModal({ setShowModal, userSeq, userName }) {
  const [scoreHistory, setScoreHistory] = useState([]); // [ { class: '6월 시험 대비반', testDate: '12 / 29 / 2024', testName: 'level test', rw: 500, math: 700, totalScore: 1200 }

  // 화면 열고 나서 바로 실행되는 함수
  useEffect(() => {
    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        let studentList = response.result.scoreHistory;

        setScoreHistory(studentList);
      }
    };
    request.get(`/api/member/student/score/history?userSeq=${userSeq}`, null, successHandler);
  }, [userSeq]);

  // 리포트 카드 열기
  const [reportCardActive, setReportCardActive] = useState(false);
  const reportHandler = (uthSeq, userSeq) => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', userSeq);

    setReportCardActive(true);
  };

  useEffect(() => {
    if (reportCardActive) {
      window.open(`/com/reports`, '_blank');
      setReportCardActive(false);
    }
  }, [reportCardActive]);

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} p='1rem' w='60rem' gap='0'>
      <div className='modal_title_container'>
        <S.ModalTitle>{userName}</S.ModalTitle>
        <button onClick={() => setShowModal(false)}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </div>

      <S.TableWrapper>
        <S.TableScrollWrapper className='scroll'>
          <S.Table>
            <colgroup>
              <col width='8.5%' />
              <col />
              <col width='18%' />
              <col width='10%' />
              <col width='6%' />
              <col width='7.5%' />
              <col width='12.5%' />
              <col width='6.25%' />
              <col width='6.25%' />
            </colgroup>

            <thead>
              <S.HeaderTableRow>
                <th>No.</th>
                <th>CLASS</th>
                <th>TEST DATE</th>
                <th>TEST NAME</th>
                <th>RW</th>
                <th>MATH</th>
                <th>TOTAL SCORE</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </S.HeaderTableRow>
            </thead>

            <tbody>
              {Array.isArray(scoreHistory) && scoreHistory.length > 0 ? (
                scoreHistory.map((item, index) => (
                  <Fragment key={`score_${index}`}>
                    <S.MainTableRow>
                      <td>{scoreHistory.length - index}</td>
                      <td className='student-history-class-name'>{item.className}</td>
                      <td>{isoTimeToDayjs(item.modiDate).format('YYYY-MM-DD')}</td>
                      <td>{item.testName}</td>
                      <td>{item.rwScore === 0 ? '-' : item.rwScore}</td>
                      <td>{item.mathScore === 0 ? '-' : item.mathScore}</td>
                      <td>{item.rwScore + item.mathScore}</td>
                      <td className='btn-item'>
                        <div>
                          <button onClick={() => reportHandler(item.uthSeq, item.userSeq)}>
                            <i className='svg_icon icon_score_report'>&nbsp;</i>
                          </button>
                        </div>
                      </td>
                      <td className='btn-item'>
                        <div>
                          <Link
                            target='_new'
                            to={`/test/test/view?viewTest=${encodeURIComponent(item.uthSeqStr)}&type=student`}
                            className='svg_icon icon_report_pdf'></Link>
                        </div>
                      </td>
                    </S.MainTableRow>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan='9' style={{ padding: '3rem' }}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>

            {
              <S.TableFooter>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className='score-history-average'>Average</td>
                  <td>
                    {Array.isArray(scoreHistory) && scoreHistory.length > 0
                      ? Math.ceil(
                          scoreHistory.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.rwScore;
                          }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                        )
                      : 0}
                  </td>
                  <td>
                    {Array.isArray(scoreHistory) && scoreHistory.length > 0
                      ? Math.ceil(
                          scoreHistory.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.mathScore;
                          }, 0) / scoreHistory.filter((S) => S.mathScore > 0).length
                        )
                      : 0}
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </S.TableFooter>
            }
          </S.Table>
        </S.TableScrollWrapper>
      </S.TableWrapper>
    </SimpleModalBase>
  );
}

export default ScoreHistoryModal;

// --- --- ---

const S = {}; // S-dot 문법용 객체

S.ModalTitle = styled.h2`
  color: #7f8998;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1.2rem 1rem;
`;

S.TableWrapper = styled.div`
  padding: 1rem;
  background-color: #f4f8ff;
  border-radius: 0.5rem;
`;
S.TableScrollWrapper = styled.div`
  max-height: 23.5rem;
  background-color: white;
`;
S.Table = styled.table`
  width: 100%;
  text-align: center;
`;

S.HeaderTableRow = styled.tr`
  position: sticky;
  z-index: 2;
  margin-bottom: 1px;
  th {
    background-color: black;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    height: 2.5rem;
  }
`;
S.MainTableRow = styled.tr`
  border-top: 1px solid #eff1f3;

  .student-history-class-name {
    font-weight: 500;
  }
  td {
    font-size: 0.875rem;
    height: 3rem;
    color: #4b5563;
  }
  .btn-item {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

S.TableFooter = styled.tfoot`
  height: 3rem;

  td {
    border-top: 1px solid black;

    &:first-child,
    &:nth-child(8),
    &:nth-child(9) {
      border: none;
    }
  }

  .score-history-average {
    font-weight: 700;
  }
`;
