import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import TestList from 'components/com/tests/_components/_TestListClassLayer';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import Layout from 'components/com/tests/_components/_common/Layout';

/** Set Tests : 학원 선택 페이지 */
export default function TestsSetSelectClassPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 /////
  const userInfo = request.tokenDecoder();
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////

  /////////////////// React useState 선언 영역 시작 ////////////////////
  const [classesInfo, setClassesInfo] = useState({
    classesList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10, // line 수
    pagePerBlock: 10,
    selectedClassSeq: nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0,
    selectedClassName: nvl(stateSetTest.selectedClass.className) !== '' ? stateSetTest.selectedClass.className : '',
  });

  const [searchInfo, setSearchInfo] = useState({
    searchStartDate: '',
    searchEndDate: '',
    searchField: '',
    searchKeyword: '',
  });
  /////////////////// React useState 선언 영역 끝 ////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const onChangeLineHandler = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  const getClassesList = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq,
      chargeSeq: userInfo?.userSeq,
      searchKeyword: searchInfo.searchKeyword,
      limit: classesInfo.numPerPage,
      offset: (classesInfo.currentPage - 1) * classesInfo.numPerPage,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let classesList = response.result.classesList;
        let totalCount = response.result.totalCount;

        setClassesInfo((prev) => {
          return { ...prev, classesList: classesList, totalCount: totalCount };
        });
      }
    };

    request.get(
      `/api/academy/classes?ecSeq=${pms.ecSeq}&chargeSeq=${pms.chargeSeq}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&limit=${pms.limit}&offset=${pms.offset}&actionMode=setTest`,
      null,
      successHandler
    );
  };

  const nextPageMove = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const onCheckHandler = (event) => {
    let parentNode = event.target.parentElement.parentElement; // tr

    parentNodeHandler(parentNode);

    setClassesInfo((prev) => {
      return {
        ...prev,
        selectedClassSeq: event.target.value.split('|')[0],
        selectedClassName: event.target.value.split('|')[1],
      };
    });
  };

  const classSelect = (event) => {
    let parentNode = event.target.parentElement; // tr

    if (parentNode) {
      parentNodeHandler(parentNode);

      setClassesInfo((prev) => {
        return {
          ...prev,
          selectedClassSeq: parentNode?.children[0]?.children[0]?.value.split('|')[0],
          selectedClassName: parentNode?.children[0]?.children[0]?.value.split('|')[1],
        };
      });
    }
  };

  const parentNodeHandler = (parentNode) => {
    for (let i = 0; i < parentNode.parentElement.children.length; i++) {
      parentNode?.parentElement?.children[i]?.classList.remove('active');

      if (parentNode?.parentElement?.children[i]?.children[0]?.children[0]) {
        parentNode.parentElement.children[i].children[0].children[0].checked = false;
      }
    }

    parentNode?.classList.add('active');

    if (parentNode?.children[0]?.children[0]) parentNode.children[0].children[0].checked = true;

    setClassesInfo((prev) => {
      return {
        ...prev,
        selectedClassSeq: parentNode?.children[0]?.children[0]?.value.split('|')[0],
        selectedClassName: parentNode?.children[0]?.children[0]?.value.split('|')[1],
      };
    });

    if (parentNode?.classList.contains('active')) {
      parentNode.parentElement.children[0].checked = true;

      setClassesInfo((prev) => {
        return {
          ...prev,
          selectedClassSeq: parentNode?.children[0]?.children[0]?.value.split('|')[0],
          selectedClassName: parentNode?.children[0]?.children[0]?.value.split('|')[1],
        };
      });
    } else {
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => {
        return { ...prev, searchKeyword: e.target.value };
      });
    }
  };

  const searchClassList = (e) => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: searchKeywordRef.current.value };
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getClassesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesInfo.currentPage, classesInfo.numPerPage, searchInfo.searchKeyword]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout classSeq={classesInfo.selectedClassSeq} selectedClassName={classesInfo.selectedClassName}>
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_ol black'>&nbsp;</i>Class List
        </h2>
      </article>
      <article className='com_list'>
        <section className='top'>
          <article className='search_area full'>
            {/* <PopupCalendarPeriod setSearchInfo={ setSearchInfo } tit="Select Date" /> */}
            {/* <div style={{ width: 400 }}>&nbsp;</div> */}
            <div className='search'>
              <input ref={searchKeywordRef} type='text' placeholder='Enter keywords' className='input search_area_input' onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={searchClassList}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={onChangeLineHandler}
            />
          </article>
        </section>
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='53px' />
            <col />
            <col width='128px' />
            <col width='123' />
            <col width='120' />
            <col width='120' />
          </colgroup>
          <thead>
            <tr>
              {/* <th><input type="checkbox"  className="com_chk" id="chkAll" /><label htmlFor="chkAll">&nbsp;</label></th> */}
              <th> </th>
              <th>No.</th>
              <th>Classes Name</th>
              <th>Start Date </th>
              <th>No. of Students</th>
              <th>Test Scheduled</th>
              <th>Test Taken</th>
            </tr>
          </thead>
          <tbody>
            {classesInfo.classesList == null || classesInfo.classesList.length < 1 ? (
              <tr>
                <td colSpan='7'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            ) : (
              classesInfo.classesList.map((item, index) => {
                return (
                  <tr key={`class_${index}`}>
                    <td>
                      <input
                        type='checkbox'
                        className='com_chk'
                        id={`class_${index}`}
                        value={`${item.classSeq}|${item.className}`}
                        onChange={onCheckHandler}
                        checked={Number(item.classSeq) === Number(classesInfo.selectedClassSeq)}
                      />
                      <label htmlFor={`class_${index}`}>&nbsp;</label>
                    </td>
                    <td onClick={classSelect}>{classesInfo.totalCount - index}</td>
                    <td onClick={classSelect} className='tit_field'>
                      <b className='ellipsis'>{item.className}</b>
                    </td>
                    <td onClick={classSelect}>{moment(item.classStartDate).format('MM / DD / YYYY')}</td>
                    <td onClick={classSelect}>{item.currentPeople}</td>
                    <td>
                      <TestList status='E' dataList={item?.testHistoryScheduled} /> {/* Test Scheduled */}
                    </td>
                    <td>
                      <TestList status='P' dataList={item?.testHistoryTaken} /> {/* Test Taken */}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <Paging
          totalCount={classesInfo.totalCount}
          currentPage={classesInfo.currentPage}
          numPerPage={classesInfo.numPerPage}
          pagePerBlock={classesInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        />
      </article>
    </Layout>
  );
}
