import moment from 'moment';

export default function DateHeader(props) {
  if (props.selectedDate === moment(props.date).format('YYYY-MM-DD')) {
    return (
      <div style={{ textAlign: 'right' }}>
        <span
          style={{
            borderRadius: '50%',
            padding: '4px',
            backgroundColor: '#0068BD',
            color: 'white',
          }}>
          {props.label}
        </span>
      </div>
    );
  }

  return (
    <div>
      <p>{props.label}</p>
    </div>
  );
}
