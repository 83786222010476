import React, { useEffect } from 'react';
import 'styles/css/exam.css';

/** 연습 시험 준비 중 안내 화면 컴포넌트 */
const Progress = () => {
  useEffect(() => {
    document.body.classList.add('dsat-intro-3');

    return () => {
      document.body.classList.remove('dsat-intro-3');
    };
  }, []);

  return (
    <div className='com_popup type_normal active pop_progress'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='dsat-intro-3_main-flex-container'>
            <h1>We’re Preparing Your Practice Test</h1>
            <div>
              <div className='dsat-intro-3_loading-img-container'>
                <div className='dsat-intro-3_hourglass'></div>
                <div id='cloud1' className='dsat-intro-3_cloud'></div>
                <div id='cloud2' className='dsat-intro-3_cloud'></div>
              </div>
              <p>
                This may take up to a minute.
                <br />
                Please don’t refresh this page or quit the app.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Progress;
