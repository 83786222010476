import React, { useState, useEffect } from 'react';

import request from 'utils/Request.utils.js';
import { nvlNumber } from 'utils/Common.utils.js';
//img

//components
import QuestionStats from './_QuestionStats';

/** "/com/score" 페이지의 "Advanced Report" 영역 컴포넌트 */
export default function AdvancedReport({ mode, filterValue, setFilterValue, weeklyScores }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const [activeSatBox, setActiveSatBox] = useState('rw');

  const [barChartData, setBarChartData] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const [barChartData2, setBarChartData2] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const barChartDataAvg = barChartData[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / barChartData[0].data.length;
  const barChartData2Avg = barChartData2[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / barChartData2[0].data.length;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.classSeq]);

  useEffect(() => {
    if (filterValue.customSetSeq > 0) getCategoriesRate();
    else {
      setBarChartData2((prev) => [{ ...prev, data: [0, 0, 0, 0], gatePlusData: [0, 0, 0, 0] }]);
      setBarChartData((prev) => [{ ...prev, data: [0, 0, 0, 0], gatePlusData: [0, 0, 0, 0] }]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.customSetSeq]);

  useEffect(() => {
    getCategoriesRate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.userSeq, filterValue.uthSeq]);

  const getCategoriesRate = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let classAnswer = mode === 'class' ? response.result.classAnswerRates : response.result.personal;
        let overall = response.result.overall;

        if (classAnswer != null && overall?.length > 0) {
          let CAS = classAnswer?.filter((item) => item.type === 'CAS');
          let IAI = classAnswer?.filter((item) => item.type === 'IAI');
          let SEC = classAnswer?.filter((item) => item.type === 'SEC');
          let EOI = classAnswer?.filter((item) => item.type === 'EOI');

          let ALG = classAnswer?.filter((item) => item.type === 'ALG');
          let AM = classAnswer?.filter((item) => item.type === 'AM');
          let PSADA = classAnswer?.filter((item) => item.type === 'PSADA');
          let GAT = classAnswer?.filter((item) => item.type === 'GAT');

          let CAS_ = overall?.filter((item) => item.type === 'CAS');
          let IAI_ = overall?.filter((item) => item.type === 'IAI');
          let SEC_ = overall?.filter((item) => item.type === 'SEC');
          let EOI_ = overall?.filter((item) => item.type === 'EOI');

          let ALG_ = overall?.filter((item) => item.type === 'ALG');
          let AM_ = overall?.filter((item) => item.type === 'AM');
          let PSADA_ = overall?.filter((item) => item.type === 'PSADA');
          let GAT_ = overall?.filter((item) => item.type === 'GAT');

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.per), nvlNumber(IAI[0]?.per), nvlNumber(SEC[0]?.per), nvlNumber(EOI[0]?.per)],
              gatePlusData: [nvlNumber(CAS_[0]?.per), nvlNumber(IAI_[0]?.per), nvlNumber(SEC_[0]?.per), nvlNumber(EOI_[0]?.per)],
            },
          ]);
          setBarChartData2((prev) => [
            {
              ...prev,
              data: [nvlNumber(ALG[0]?.per), nvlNumber(AM[0]?.per), nvlNumber(PSADA[0]?.per), nvlNumber(GAT[0]?.per)],
              gatePlusData: [nvlNumber(ALG_[0]?.per), nvlNumber(AM_[0]?.per), nvlNumber(PSADA_[0]?.per), nvlNumber(GAT_[0]?.per)],
            },
          ]);
        }
      }
    };
    if (mode === 'class') {
      request
        .get(
          `/api/dsat/rate/class/category/accuracy?ecSeq=${userInfo.ecSeq}&classSeq=${filterValue.classSeq}&customSetSeq=${filterValue.customSetSeq}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error));
    } else {
      request
        .get(`/api/dsat/accuracy/category?ecSeq=${userInfo.ecSeq}&uthSeq=${filterValue.uthSeq}&userSeq=${filterValue.userSeq}`, null, successHandler)
        .catch((error) => console.error('', error));
    }
  };

  useEffect(() => {
    if (filterValue.setSubject === 'M') {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'M', moduleNum: '1' }));
      setActiveSatBox('math');
    } else {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'E', moduleNum: '1' }));
      setActiveSatBox('rw');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.setSubject]);

  // sat 박스 흐리게 아니게...
  const handleClickGraphItemField = (gubun) => {
    if (filterValue.setSubject === 'F') {
      setActiveSatBox(gubun);
      let sub = gubun === 'rw' ? 'E' : 'M';
      setFilterValue((prev) => {
        return {
          ...prev,
          tmSubject: sub,
          color: `${sub === 'E' ? '#008CFF' : '#1FDF9F'}`,
          moduleNum: '1',
          className: `custom-tooltip${sub === 'E' ? '' : '-gate'}`,
        };
      });
    }
  };

  return (
    <>
      <section className='box report full'>
        <article className='com_sec_tit'>
          <h2 className='tit'>
            <i className='svg_icon icon_graph black'>&nbsp;</i>
            ADVANCED REPORT
          </h2>
        </article>
        {/*<article className='box_graph per50 active'>/!*활성화 시 addClass active*!/*/}
        <article className={`box_graph per50 blue ${activeSatBox === 'rw' ? 'active' : 'disable'}`} onClick={() => handleClickGraphItemField('rw')}>
          {/*활성화 시 addClass active*/}
          <div className='top_area'>
            <p className='tit_field'>Correct answer rate by Categories</p>
            <div className='type_field'>
              <p className='name'>RW</p>
              <p className='data'>{barChartDataAvg.toFixed(2)}%</p>
            </div>
          </div>
          {/* <BarChart barChartData={barChartData}/>    */}
          <div className='com_answer_grah'>
            {/*Craft and Structure*/}
            <div className='item type01'>
              <p className='tit_field'>
                <i className='color'>&nbsp;</i>CAS
              </p>
              <div className='grah_field'>
                <div className='graph_wrap' style={{ width: `${barChartData[0].data[0]}%` }}>
                  <div
                    className={`rate my ${barChartData[0].data[0] < barChartData[0].gatePlusData[0] ? 'low' : ''} ${barChartData[0].data[0] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    <p className='num'>{barChartData[0].data[0]}%</p>
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>Craft and Structure :&nbsp;
                    <b>{barChartData[0].data[0]}%</b>
                  </div>
                </div>
                <div
                  className='graph_wrap'
                  style={{
                    width: `${barChartData[0].data[0] === barChartData[0].gatePlusData[0] ? `calc(${barChartData[0].data[0]} + 10px)%` : `${barChartData[0].gatePlusData[0]}%`}`,
                  }}>
                  <div
                    className={`rate average ${barChartData[0].data[0] > barChartData[0].gatePlusData[0] ? 'low' : ''} ${barChartData[0].gatePlusData[0] === 0 ? 'zero' : ''}`}>
                    {/*수치가 더 낮은 rate에 addClass low*/}
                    <div className='bar'>&nbsp;</div>
                    {/* <p className="num">28.57%</p> */}
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                    <b>{barChartData[0].gatePlusData[0]}%</b>
                  </div>
                </div>
                <div className='bar'>&nbsp;</div>
              </div>
            </div>
            {/*Information and Ideas*/}
            <div className='item type02'>
              <p className='tit_field'>
                <i className='color'>&nbsp;</i>IAI
              </p>
              <div className='grah_field'>
                <div className='graph_wrap' style={{ width: `${barChartData[0].data[1]}%` }}>
                  <div
                    className={`rate my ${barChartData[0].data[1] < barChartData[0].gatePlusData[1] ? 'low' : ''} ${barChartData[0].data[1] === 0 ? 'zero' : ''}`}>
                    {/*수치가 더 낮은 rate에 addClass low*/}
                    <div className='bar'>&nbsp;</div>
                    <p className='num'>{barChartData[0].data[1]}%</p>
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>Information and Ideas :&nbsp;
                    <b>{barChartData[0].data[1]}%</b>
                  </div>
                </div>
                <div
                  className='graph_wrap'
                  style={{
                    width: `${barChartData[0].data[1] === barChartData[0].gatePlusData[1] ? `calc(${barChartData[0].data[1]} + 10px)%` : `${barChartData[0].gatePlusData[1]}%`}`,
                  }}>
                  <div
                    className={`rate average ${barChartData[0].data[1] > barChartData[0].gatePlusData[1] ? 'low' : ''} ${barChartData[0].gatePlusData[1] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    {/* <p className="num">54%</p> */}
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                    <b>{barChartData[0].gatePlusData[1]}%</b>
                  </div>
                </div>
                <div className='bar'>&nbsp;</div>
              </div>
            </div>
            {/*Standard English Conventions*/}
            <div className='item type03'>
              <p className='tit_field'>
                <i className='color'>&nbsp;</i>SEC
              </p>
              <div className='grah_field'>
                <div className='graph_wrap' style={{ width: `${barChartData[0].data[2]}%` }}>
                  <div
                    className={`rate my ${barChartData[0].data[2] < barChartData[0].gatePlusData[2] ? 'low' : ''} ${barChartData[0].data[2] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    <p className='num'>{barChartData[0].data[2]}%</p>
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>Standard English Conventions :&nbsp;<b>{barChartData[0].data[2]}%</b>
                  </div>
                </div>
                <div
                  className='graph_wrap'
                  style={{
                    width: `${barChartData[0].data[2] === barChartData[0].gatePlusData[2] ? `calc(${barChartData[0].data[2]} + 10px)%` : `${barChartData[0].gatePlusData[2]}%`}`,
                  }}>
                  {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
                  <div
                    className={`rate average ${barChartData[0].data[2] > barChartData[0].gatePlusData[2] ? 'low' : ''} ${barChartData[0].gatePlusData[2] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    {/* <p className="num">70%</p> */}
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                    <b>{barChartData[0].gatePlusData[2]}%</b>
                  </div>
                </div>
                <div className='bar'>&nbsp;</div>
              </div>
            </div>
            {/*Expression of Ideas*/}
            <div className='item type04'>
              <p className='tit_field'>
                <i className='color'>&nbsp;</i>EOI
              </p>
              <div className='grah_field'>
                <div className='graph_wrap' style={{ width: `${barChartData[0].data[3]}%` }}>
                  <div
                    className={`rate my ${barChartData[0].data[3] < barChartData[0].gatePlusData[3] ? 'low' : ''} ${barChartData[0].data[3] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    <p className='num'>{barChartData[0].data[3]}%</p>
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>Expression of Ideas :&nbsp;
                    <b>{barChartData[0].data[3]}%</b>
                  </div>
                </div>
                <div
                  className='graph_wrap'
                  style={{
                    width: `${barChartData[0].data[3] === barChartData[0].gatePlusData[3] ? `calc(${barChartData[0].data[3]} + 10px)%` : `${barChartData[0].gatePlusData[3]}%`}`,
                  }}>
                  {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
                  <div
                    className={`rate average ${barChartData[0].data[3] > barChartData[0].gatePlusData[3] ? 'low' : ''} ${barChartData[0].gatePlusData[3] === 0 ? 'zero' : ''}`}>
                    <div className='bar'>&nbsp;</div>
                    {/* <p className="num">25%</p> */}
                  </div>
                  <div className='layer'>
                    <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                    <b>{barChartData[0].gatePlusData[3]}%</b>
                  </div>
                </div>
                <div className='bar'>&nbsp;</div>
              </div>
            </div>
          </div>
        </article>
        {/* math */}
        <article className={`box_graph per50 blue ${activeSatBox === 'math' ? 'active' : 'disable'}`} onClick={() => handleClickGraphItemField('math')}>
          {/*비활성화시 addClass disable*/}
          <div className='top_area'>
            <p className='tit_field'>Correct answer rate by Categories</p>
            <div className='type_field'>
              <p className='name'>MATH</p>
              <p className='data'>{barChartData2Avg.toFixed(2)}%</p>
            </div>
          </div>
          <div className='content_area'>
            {/* alg */}
            <div className='com_answer_grah'>
              <div className='item type01'>
                <p className='tit_field'>
                  <i className='color'>&nbsp;</i>Alg
                </p>
                <div className='grah_field'>
                  <div className='graph_wrap' style={{ width: `${barChartData2[0].data[0]}%` }}>
                    <div
                      className={`rate my ${barChartData2[0].data[0] < barChartData2[0].gatePlusData[0] ? 'low' : ''} ${barChartData2[0].data[0] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      <p className='num'>{barChartData2[0].data[0]}%</p>
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>Algebra :&nbsp;
                      <b>{barChartData2[0].data[0]}%</b>
                    </div>
                  </div>
                  <div
                    className='graph_wrap'
                    style={{
                      width: `${barChartData2[0].data[0] === barChartData2[0].gatePlusData[0] ? `calc(${barChartData2[0].data[0]} + 10px)%` : `${barChartData2[0].gatePlusData[0]}%`}`,
                    }}>
                    <div
                      className={`rate average ${barChartData2[0].data[0] > barChartData2[0].gatePlusData[0] ? 'low' : ''} ${barChartData2[0].gatePlusData[0] === 0 ? 'zero' : ''}`}>
                      {/*수치가 더 낮은 rate에 addClass low*/}
                      <div className='bar'>&nbsp;</div>
                      {/* <p className="num">28.57%</p> */}
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                      <b>{barChartData2[0].gatePlusData[0]}%</b>
                    </div>
                  </div>
                  <div className='bar'>&nbsp;</div>
                </div>
              </div>
              {/* am */}
              <div className='item type02'>
                <p className='tit_field'>
                  <i className='color'>&nbsp;</i>AM
                </p>
                <div className='grah_field'>
                  <div className='graph_wrap' style={{ width: `${barChartData2[0].data[1]}%` }}>
                    <div
                      className={`rate my ${barChartData2[0].data[1] < barChartData2[0].gatePlusData[1] ? 'low' : ''} ${barChartData2[0].data[1] === 0 ? 'zero' : ''}`}>
                      {/*수치가 더 낮은 rate에 addClass low*/}
                      <div className='bar'>&nbsp;</div>
                      <p className='num'>{barChartData2[0].data[1]}%</p>
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>Advanced Math :&nbsp;
                      <b>{barChartData2[0].data[1]}%</b>
                    </div>
                  </div>
                  <div
                    className='graph_wrap'
                    style={{
                      width: `${barChartData2[0].data[1] === barChartData2[0].gatePlusData[1] ? `calc(${barChartData2[0].data[1]} + 10px)%` : `${barChartData2[0].gatePlusData[1]}%`}`,
                    }}>
                    <div
                      className={`rate average ${barChartData2[0].data[1] > barChartData2[0].gatePlusData[1] ? 'low' : ''} ${barChartData2[0].gatePlusData[1] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      {/* <p className="num">54%</p> */}
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                      <b>{barChartData2[0].gatePlusData[1]}%</b>
                    </div>
                  </div>
                  <div className='bar'>&nbsp;</div>
                </div>
              </div>
              {/* psada */}
              <div className='item type03'>
                <p className='tit_field'>
                  <i className='color'>&nbsp;</i>PSADA
                </p>
                <div className='grah_field'>
                  <div className='graph_wrap' style={{ width: `${barChartData2[0].data[2]}%` }}>
                    <div
                      className={`rate my ${barChartData2[0].data[2] < barChartData2[0].gatePlusData[2] ? 'low' : ''} ${barChartData2[0].data[2] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      <p className='num'>{barChartData2[0].data[2]}%</p>
                    </div>
                    <div className={`layer ${barChartData2[0].data[2] > 50 ? 'psada-layer' : ''}`}>
                      <i className='color'>&nbsp;</i>Problem-Solving and Data Analysis :&nbsp;<b>{barChartData2[0].data[2]}%</b>
                    </div>
                  </div>
                  <div
                    className='graph_wrap'
                    style={{
                      width: `${barChartData2[0].data[2] === barChartData2[0].gatePlusData[2] ? `calc(${barChartData2[0].data[2]} + 10px)%` : `${barChartData2[0].gatePlusData[2]}%`}`,
                    }}>
                    <div
                      className={`rate average ${barChartData2[0].data[2] > barChartData2[0].gatePlusData[2] ? 'low' : ''} ${barChartData2[0].gatePlusData[2] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      {/* <p className="num">70%</p> */}
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                      <b>{barChartData2[0].gatePlusData[2]}%</b>
                    </div>
                  </div>
                  <div className='bar'>&nbsp;</div>
                </div>
              </div>
              {/* gat */}
              <div className='item type04'>
                <p className='tit_field'>
                  <i className='color'>&nbsp;</i>GAT
                </p>
                <div className='grah_field'>
                  <div className='graph_wrap' style={{ width: `${barChartData2[0].data[3]}%` }}>
                    <div
                      className={`rate my ${barChartData2[0].data[3] < barChartData2[0].gatePlusData[3] ? 'low' : ''} ${barChartData2[0].data[3] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      <p className='num'>{barChartData2[0].data[3]}%</p>
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>Geometry and Trigonometry :&nbsp;<b>{barChartData2[0].data[3]}%</b>
                    </div>
                  </div>
                  <div
                    className='graph_wrap'
                    style={{
                      width: `${barChartData2[0].data[3] === barChartData2[0].gatePlusData[3] ? `calc(${barChartData2[0].data[3]} + 10px)%` : `${barChartData2[0].gatePlusData[3]}%`}`,
                    }}>
                    {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
                    <div
                      className={`rate average ${barChartData2[0].data[3] > barChartData2[0].gatePlusData[3] ? 'low' : ''} ${barChartData2[0].gatePlusData[3] === 0 ? 'zero' : ''}`}>
                      <div className='bar'>&nbsp;</div>
                      {/* <p className="num">25%</p> */}
                    </div>
                    <div className='layer'>
                      <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                      <b>{barChartData2[0].gatePlusData[3]}%</b>
                    </div>
                  </div>
                  <div className='bar'>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </article>
        {/*Correct answer (rate) by Questions*/}
        <QuestionStats
          mode={mode}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          handleClickGraphItemField={handleClickGraphItemField}
          weeklyScores={weeklyScores}
        />
      </section>
    </>
  );
}
