import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SimpleDonutChart = ({ donutChartData }) => {
  const series = donutChartData.data;
  const width = donutChartData.width;
  const options = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,
      },
    },
    legend: donutChartData.legend,
    labels: ['Currently tasking', 'Not taking test', 'Completed tests'],
    colors: ['#0068BD', '#000000', '#E3E7ED'],
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val;
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex }) {
        const customClass = 'custom-tooltip-' + (seriesIndex === 0 ? 'ct' : seriesIndex === 1 ? 'ntt' : 'tests');

        return `<div className="${customClass}"><span>${options.labels[seriesIndex]}: ${series[seriesIndex]}</span></div>`;
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.7,
        },
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={options} series={series} type='donut' width={width} />
    </div>
  );
};

export default SimpleDonutChart;
