import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import request from 'utils/Request.utils';
import { getRestTimeFromLocalStorage } from '../_utils/functions/timerLocalStorageFunctions';

/** 브라우저 화면이 눈에 보이지 않는 경우 (창 최소화, 노트북 닫혔을 때, 테블릿에서 다른 작업 등) 행위 설정용 Hook */
function useCheckBrowserVisibility() {
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();

  /** 브라우저가 현재 보이는 상태인지 여부 */
  const [isBrowserVisible, setIsBrowserVisible] = useState(false);

  /** addEventListener 로 추가 된 이벤트 핸들러에서 사용 할 값을 실시간으로 반영하기 위한 ref (state는 addEventListener 방식으로 실시간 갱신 안 된다.) */
  const stateExamInfoRef = useRef(stateExamInfo);

  /** 브라우저가 안 보이는 상태를 DB에 기록하기 위한 API 요청 */
  const fetch = (isVisible) => {
    if (typeof isVisible !== 'string') {
      console.error('isVisible 의 타입은 string 타입이어야 합니다.');
      return;
    }

    const params = {
      uthSeq: stateExamInfoRef.current.uthSeq,
      testModuleSeq: stateExamInfoRef.current.questionList[stateExamInfoRef.current.currentNum]?.testModuleSeq, // 모듈 고유 값
      questionSeq: stateExamInfoRef.current.questionList[stateExamInfoRef.current.currentNum]?.questionSeq, // 문제 고유 값
      remainingTime: restTime, // 남은 시간
      visibilityStatus: isVisible, // or visible (현재 브라우저 문서가 보이는 상태인지 여부)
    };

    const successHandler = (response) => {
      if (response.code !== 200) {
        console.error('브라우저 visibilitychange API 요청 응답 코드가 200 이 아님');
      }
    };

    request.post('/api/exam/visibility/hidden', params, successHandler).catch((error) => {
      console.error(error);
      alert('시험 화면 보이는 상태인지 여부를 기록하는 API 응답 실패');
    });
  };

  /** 브라우저 Visibilitychange 이벤트 핸들러 */
  const handleVisibilitychange = (event) => {
    fetch(document.visibilityState);
    if (document.visibilityState === 'hidden') {
      setIsBrowserVisible(false);
      // 시험 브라우저 창이 보이지 않을 때
    } else if (document.visibilityState === 'visible') {
      setIsBrowserVisible(true);
      // 시험 브라우저 창이 보일 때
    } else {
      console.error('visibilitychange 이벤트가 예상하지 못한 결과를 반환함');
    }
  };

  useEffect(() => {
    window.addEventListener('blur', function () {
      console.log('창이 포커스를 잃었습니다.');
    });
    document.addEventListener('visibilitychange', handleVisibilitychange);
    return () => {
      // 리액트 환경에서 addEventListener 방식은 꼭 remove 해 주어야 한다.
      document.removeEventListener('visibilitychange', handleVisibilitychange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // addEventListener 로 추가 된 이벤트 핸들러에서 사용 할 값을 실시간으로 반영 (state는 addEventListener 방식으로 실시간 갱신 안 된다.)
    stateExamInfoRef.current = stateExamInfo;
  }, [stateExamInfo]);

  return { isBrowserVisible };
}

export default useCheckBrowserVisibility;
