import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import LocalStorage from 'utils/LocalStorage.utils';
import { nvl } from 'utils/Common.utils';
import clearAppData from 'utils/clearAppData';
import { useDispatch } from 'react-redux';
import request from 'utils/Request.utils';

/** 외부 도메인에서 로그아웃 요청 받을 라우트 페이지 컴포넌트 */
export default function ExternalSignout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  /** URL 쿼리를 객체 형태로 파싱 */
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  /** 현재 로그인 된 유저 정보 확인 */
  const userSession = LocalStorage.getItemJsonParse('userSession');
  /** 현재 로그인 상태 여부 */
  const isSignin = nvl(userSession?.accessToken) !== '';

  useEffect(() => {
    if (query.isExternalSign && query.lastRoute) {
      // 정식 외부 로그인 요청 받식으로 접근한 않은 경우
      if (isSignin) {
        // 로그인 된 상태라면, 로그아웃 시키기
        clearAppData(dispatch, request.tokenDecoder?.userId);
      }
      const responseLink = `${query.isExternalSign}/sign/signout/?lastRoute=${query.lastRoute}`;

      window.location.href = responseLink;
    } else {
      // 정식 외부 로그 아웃 요청 받식으로 접근하지 않은 경우 : 대시보드 페이지로 리다이렉트
      console.log('정상적이지 않은 접근');
      navigate('/', { replace: true });
    }
  }, [userSession, isSignin, location, query, navigate, dispatch]);
}
