import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDFViewerPage({ close, path, scale }) {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentError = (error) => {
    console.log('pdf viewer error', error, path);
  };

  const onDocumentLocked = () => {
    console.log('pdf locked');
  };

  return (
    <div className='com_popup type_full active'>
      <div className='pop_container'>
        <section className='pop_body'>
          <Document file={path} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentError} onPassword={onDocumentLocked}>
            {Array.from(new Array(numPages), (_, index) => {
              return <Page key={`page_${index + 1}`} pageNumber={index + 1} width={700} renderAnnotationLayer={false} scale={scale} />;
            })}
          </Document>
        </section>
      </div>
      <div className='pop_footer com_btn_wrap'>
        <button className='com_btn point m' onClick={close}>
          Close
        </button>
      </div>
    </div>
  );
}
