import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from "redux-persist/lib/storage/session"; // session storage
import storage from 'redux-persist/lib/storage'; // local storage

import Customer from './Customer';
import MenuManager from './MenuManager';
import stateSetTests from './setTests/reducer';
import stateGateKeys from './gateKeys/reducer';
import stateExamInfo from './exam/examInfo/reducer';
import stateExamComponents from './exam/examComponents/reducer';

const persistConfig = {
  key: 'dsat_root',
  storage,
  // whitelist: ['Customer'], // storage에 저장
  // blacklist: ['any'] // 제외
};

export const rootReducer = combineReducers({
  Customer,
  MenuManager,
  stateExamInfo,
  stateExamComponents,
  stateSetTests,
  stateGateKeys,
});

export default persistReducer(persistConfig, rootReducer);
