import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RouteChangeTracker from './RouteChangeTracker';

// import './App.css';
import './styles/scss/styles.scss';
import './styles/scss/pages/gate_member/gate_member.scss';

import AcademyRoute from './routes/AcademyRoute';
import MainRoute from './routes/MainRoute';
import StudentRoute from './routes/StudentRoute';
import TestRoute from './routes/TestRoute';
import ExamRoute from './routes/ExamRoute';
import DSATInfoRoute from './routes/DSATInfoRoute';
import TestgateNewsRoute from './routes/TestgateNewsRoute';
import SigninRoute from './routes/SigninRoute';
import SignupRoute from './routes/SignupRoute';
import CommonRoute from './routes/CommonRoute';
import ParentRoute from './routes/ParentRoute';
import ErrorPage from 'components/errors/Page';

import ExternalSignin from 'components/user/external-signin/Page';
import ExternalSignout from 'components/user/external-signout/Page';

// const ExamRoute = lazy(() => import('./routes/ExamRoute'))

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      document.title = 'DSAT Development';
    } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      document.title = 'DSAT Staging';
    } else {
      document.title = 'DSAT';
    }
  }, []);
  return (
    <Router>
      <RouteChangeTracker />
      <Routes>
        {/* <Route path="/" element={ <Navigate to='/main' /> } /> */}
        <Route path='/' element={<Navigate to='/signin' />} />,
        <Route path='/signup/*' element={<SignupRoute />} />
        <Route path='/signin/*' element={<SigninRoute />} />
        <Route path='/com/*' element={<CommonRoute />} />
        <Route path='/main/*' element={<MainRoute />} />
        <Route path='/academy/*' element={<AcademyRoute />} />
        <Route path='/parent/*' element={<ParentRoute />} />
        <Route path='/student/*' element={<StudentRoute />} /> {/* 학생용 */}
        <Route path='/test/*' element={<TestRoute />} /> {/* 시험관리 */}
        <Route path='/exam/*' element={<ExamRoute />} /> {/* 시험 모듈 */}
        <Route path='/dsat/info/*' element={<DSATInfoRoute />} /> {/* DSATInfo */}
        <Route path='/gateplus/*' element={<TestgateNewsRoute />} /> {/* testgateNews */}
        <Route path='*' element={<Navigate to='/signin' />} />
        <Route path='/external-signin' element={<ExternalSignin />} />
        <Route path='/external-signout' element={<ExternalSignout />} />
        <Route path='/404' element={<ErrorPage />} /> {/* 현재는 시험을 URL로 접근하려 했을때만 노출 보이게 설정 */}
      </Routes>
    </Router>
  );
}

export default App;
