import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionArea from './QuestionArea';
import ReviewPopup from './ReviewPopup';
import MathQuestion from './MathQuestion';

/** 수학 시험 화면 본문 영역 컴포넌트 */
export default function ExamBody({ tryAnswerRef, isLoading, setIsLoading }) {
  const dispatch = useDispatch();
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);

  const isRenderMathJaxRef = useRef(false);
  const timerRef = useRef(null);
  /** 문제 영역 ( MathJax 적용을 위해 따로 State로 분리 ) */
  const [questionArea, setQuestionArea] = useState(null);
  /** 시험 정보 전역 상태 */
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });

  /** 문제 좌우 확장 버튼 핸들러 ( useEffect 내부에서만 사용되므로 useCallback으로 메모지에이션 ) */
  const wrapButtonHandler = useCallback((arrow) => {
    setWrap((prev) => {
      if (arrow === 'left') {
        if (prev.leftArrow) return { ...prev, leftArrow: false };
        if (!prev.leftArrow && prev.rightArrow) return { ...prev, rightArrow: false };
        return { ...prev, leftArrow: true };
      } else {
        if (prev.rightArrow) return { ...prev, rightArrow: false };
        if (!prev.rightArrow && prev.leftArrow) return { ...prev, leftArrow: false };
        return { ...prev, rightArrow: true };
      }
    });
  }, []);

  // 초기 시험창 진입시 MathJax 렌더링용 useEffect - 최초 진입시 MathJax 랜더링이 발생하지 않아 추가함
  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 300);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** MathJax 타입캐스팅 결과에 따라 타이머 갱신 + 상태 변경하는 함수 */
  const updateMathJaxStatus = useCallback((value) => {
    isRenderMathJaxRef.current = value;

    const setMathJaxTimer = (currentValue) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (currentValue) {
          // console.log('🚀 MathJax 타입캐스팅 성공 🚀');
        } else {
          console.log('🚫 MathJax 타입캐스팅 실패 🚫');
          dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: true } });
        }
      }, 1500);
    };

    setMathJaxTimer(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 문제 이동시 MathJax 관련 상태 초기화
  useEffect(() => {
    updateMathJaxStatus(false);
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamInfo.currentNum]);

  // 타이머와 관계없는 속성들이 바뀔때만 렌더링
  useEffect(() => {
    setQuestionArea(
      <QuestionArea
        wrap={wrap}
        stateExamInfo={stateExamInfo}
        wrapButtonHandler={wrapButtonHandler}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        tryAnswerRef={tryAnswerRef}
        updateMathJaxStatus={updateMathJaxStatus}
      />
    );
  }, [isInitialRender, updateMathJaxStatus, setQuestionArea, wrap, stateExamInfo, wrapButtonHandler, isLoading, setIsLoading, tryAnswerRef]);

  // 리뷰 팝업 선택시
  if (stateExamComponents.reviewDisplay) {
    return (
      <section className='pop_body'>
        <ReviewPopup />
      </section>
    );
  }

  // 문제 랜더링
  return (
    <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
      {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat !== 'M' && <MathQuestion wrap={wrap} wrapButtonHandler={wrapButtonHandler} />}
      {questionArea}
    </section>
  );
}
