const { default: request } = require('utils/Request.utils');

/** 유저(B to B)가 가진 key를 불러오는 API 요청 함수 */
export const fetchGetRemainingKeyInBToB = (successHandler, errorHandler) => {
  request.get('/api/gatekey/amount/academy', null, successHandler).catch(errorHandler);
};
/** 유저(B to C)가 가진 key를 불러오는 API 요청 함수 */
export const fetchGetRemainingKeyInBToC = (successHandler, errorHandler) => {
  request.get('/api/gatekey/amount/b2c', null, successHandler).catch(errorHandler);
};
