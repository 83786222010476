import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import SessionStorage from 'utils/SessionStorage.utils';
import request from 'utils/Request.utils';
import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';

/** 전체 점수 */
const totalScore = 800;

/** 학생 대시보드 페이지의 "Your Most Recent Test" Section 컴포넌트 */
export default function YourMostRecentTestSection({ testResult, userTestHistoryList, setTableListActive, tableListActive, filterValue, childSeqState }) {
  const userInfo = request.tokenDecoder();

  const [isTestListActive, setIsTestListActive] = useState(false);
  const testListRef = useRef(null);

  const rwPercentage = (testResult.rwCurrentScore / totalScore) * 100;
  const mathPercentage = (testResult.mathCurrentScore / totalScore) * 100;

  const handleDocumentClick = (e) => {
    if (testListRef.current && !testListRef.current.contains(e.target)) {
      setIsTestListActive(false);
    }
  };

  const tableListToggleButtonClick = () => {
    setTableListActive(!tableListActive);
  };

  /** pdf view */
  const openTestView = (uthSeqStr, subject, diff) => {
    window.open(`/test/test/view?viewTest=${encodeURIComponent(uthSeqStr)}&subject=${subject}&diff=${diff}`, '_blank');
  };

  const reportCardOpen = () => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', filterValue.uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', childSeqState < 0 ? childSeqState : userInfo.userSeq);
    SessionStorage.setItem('reportCard', 'classSeq', testResult.classSeq);

    window.open(`/student/report`, '_blank');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [isTestListActive]);

  return (
    <>
      <article className='com_top' ref={testListRef}>
        <p className='date'>{testResult.trhDate && moment(testResult.trhDate).format('MMM DD, YYYY')}</p>
        {
          userTestHistoryList == null || userTestHistoryList?.length < 1 ? (
            <p className='tit'> No available test </p>
          ) : (
            <p className='tit'> {testResult.testName} </p>
          )
          /*<>
                        <div className="tit" onClick={ handleTitClick }>
                          {testResult.testName}
                          <div className={`layer ${isTestListActive ? 'active' : ''} `}>
                            {userTestHistoryList.map((item, index) => (
                              <button key={item.uthSeq} className="option" onClick={ (e) => handleOptionClick(e, item.uthSeq) }>{item.testName}</button>
                            ))}
                          </div>
                        </div>
                      </>*/
        }
        <button className='com_btn l black btn_list' onClick={tableListToggleButtonClick}>
          TEST LIST
          <i className='svg_icon after icon_list_white'>&nbsp;</i>
        </button>
      </article>
      <article className='com_list'>
        <table className='list gray'>
          <colgroup>
            {/*<col width="97px"/>*/}
            <col width='170px' />
            <col width='370px' />
            <col />
            <col width='108px' />
          </colgroup>
          <thead>
            <tr>
              <th>Class</th>
              <th>Section</th>
              <th colSpan={2}>Section score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className='class_name' scope='row'>
                {testResult.className == null ? (
                  <Tooltip01 text='This is a test you have set for yourself.'>
                    <ImageSVGIconStar />
                  </Tooltip01>
                ) : (
                  testResult.className
                )}
              </th>
              <td>
                {testResult.setSubject !== 'M' && (
                  <div className='td'>
                    <b className='subject'>Reading & Writing</b>
                    {/* <button className={`com_btn s green ${testResult.engDiff === 'E' ? '': 'line'}`}>EASY</button> */}
                    <button
                      className={`com_btn s green ${testResult.engDiff === 'E' ? '' : 'line'}`}
                      onClick={() =>
                        testResult.questionList && testResult.questionList.length > 0 ? openTestView(testResult.questionList[0].uthSeqStr, 'E', 'E') : null
                      }
                      disabled={testResult?.setDifficulty === 'H'}>
                      EASY
                    </button>
                    <button
                      className={`com_btn s red ${testResult.engDiff === 'H' ? '' : 'line'}`}
                      onClick={() =>
                        testResult.questionList && testResult.questionList.length > 0 ? openTestView(testResult.questionList[0].uthSeqStr, 'E', 'H') : null
                      }
                      disabled={testResult?.setDifficulty === 'E'}>
                      HARD
                    </button>
                    {/* <button className={`com_btn s red ${testResult.engDiff === 'H' ? '': 'line'}`}>HARD</button> */}
                  </div>
                )}
                {testResult.setSubject !== 'E' && (
                  <div className='td'>
                    <b className='subject'>Math</b>
                    {/* <button className={`com_btn s green ${testResult.mathDiff === 'E' ? '': 'line'}`}>EASY</button> */}
                    <button
                      className={`com_btn s green ${testResult.mathDiff === 'E' ? '' : 'line'}`}
                      onClick={() =>
                        testResult.questionList && testResult.questionList.length > 0 ? openTestView(testResult.questionList[0].uthSeqStr, 'M', 'E') : null
                      }
                      disabled={testResult?.setDifficulty === 'H'}>
                      EASY
                    </button>
                    <button
                      className={`com_btn s red ${testResult.mathDiff === 'H' ? '' : 'line'}`}
                      onClick={() =>
                        testResult.questionList && testResult.questionList.length > 0 ? openTestView(testResult.questionList[0].uthSeqStr, 'M', 'H') : null
                      }
                      disabled={testResult?.setDifficulty === 'E'}>
                      HARD
                    </button>
                    {/* <button className={`com_btn s red ${testResult.mathDiff === 'H' ? '': 'line'}`}>HARD</button> */}
                  </div>
                )}
              </td>
              <td className='no_line'>
                {testResult.setSubject !== 'M' && (
                  <div className='td'>
                    <div className='chart_area'>
                      <div className='bar_bg'>
                        <span className='bar' style={{ width: `${rwPercentage}%` }}>
                          <span className='my_score'>{testResult.rwCurrentScore}</span>
                        </span>
                      </div>
                      <div className='score'>
                        <span className='num'>0</span>
                        <span className='num'>800</span>
                      </div>
                    </div>
                  </div>
                )}
                {testResult.setSubject !== 'E' && (
                  <div className='td'>
                    <div className='chart_area'>
                      <div className='bar_bg'>
                        <span className='bar green' style={{ width: `${mathPercentage}%` }}>
                          <span className='my_score'>{testResult.mathCurrentScore}</span>
                        </span>
                      </div>
                      <div className='score'>
                        <span className='num'>0</span>
                        <span className='num'>800</span>
                      </div>
                    </div>
                  </div>
                )}
              </td>

              {/* <td>
                          <button className="com_btn s btn_score_report" onClick={ () => setPrintActive(true) } disabled={userTestHistoryList?.length < 1} >
                            <i className="svg_icon icon_score_report">&nbsp;</i>Score<br />Report
                          </button>
                        </td> */}

              <td className='alignC'>
                <button className='com_btn s btn_score_report' onClick={reportCardOpen} disabled={userTestHistoryList?.length < 1}>
                  <i className='svg_icon icon_score_report'>&nbsp;</i>
                  Score Report
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    </>
  );
}
