/** 시험 PDF 보기 화면의 헤더 버튼 */
export default function TestViewHeaderBtn({ btnImage, btnText, onClick, btnState = true, btnStateContent = [] }) {
  return (
    <button className='test_view_header_btn' onClick={onClick}>
      <div className='test_view_header_btn_inner'>
        {btnImage ? (
          <img src={btnState ? btnImage : btnStateContent[0]} alt='test view print image' />
        ) : (
          <div style={{ width: '1.75rem', height: '1.75rem' }} />
        )}

        <div>
          <span>{btnState ? btnText : btnStateContent[1]}</span>
        </div>
      </div>
    </button>
  );
}
