import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import GroupedBarChart from 'components/_common/chart/GroupedBarChart';

/** "/com/score" 페이지의 "Class vs. GATE+" 영역 컴포넌트 */
export default function ClassVsGatePlus({ gateButtons, clickHandler, percentileClick, scoreStats, classGateChartData, studentGateChartData }) {
  return (
    <article className='box per25'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_graph black'>&nbsp;</i>
          {gateButtons.filter((i) => i.active === true)[0].text} vs GATE
          <sup>+</sup>
        </h2>
      </div>
      <div className='com_tab dark'>
        {gateButtons?.map((item) => (
          <button
            key={'button_' + uuidv4()}
            className={item.active ? 'menu active' : 'menu'}
            onClick={() =>
              item.value === 0
                ? clickHandler(item.value, 'gate')
                : scoreStats.percentileList && scoreStats.percentileList.length > 0 && percentileClick(scoreStats.percentileList[0].userSeq)
            }>
            {item.text}
          </button>
        ))}
      </div>
      {gateButtons[0].active ? <GroupedBarChart groupedBarChart={classGateChartData} /> : <GroupedBarChart groupedBarChart={studentGateChartData} />}
    </article>
  );
}
