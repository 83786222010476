/** YYYY-MM-DD 문자열 형식의 날짜에 원하는 일 수를 더하여 반환하는 함수 */
export function addDaysToDate(dateString, daysToAdd) {
  // Step 1: 날짜 문자열을 Date 객체로 변환
  const date = new Date(dateString);

  // Step 2: Date 객체에 주어진 일수를 더함
  date.setDate(date.getDate() + daysToAdd);

  // Step 3: 다시 "YYYY-MM-DD" 형식의 문자열로 변환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
