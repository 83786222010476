import React, { useCallback } from 'react';

/** tests/scheduled 페이지의 상단 메인 텝 컴포넌트 */
export default function Tab({tabState, setTabState}) {
    const tab = [
        { text: 'By Individual Test', value: 0 },
        { text: 'By Test Set', value: 1 },
      ];

    const renderTab = useCallback(() => {
        return tab.map((v, idx) => {
          return (
            <button key={idx} className={`menu ${(tabState === v.value && 'active') || ''}`} onClick={() => setTabState(v.value)}>
              {v.text}
            </button>
          );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tab]);

  return <div className='com_tab type_with_box'>{renderTab()}</div>;
}
