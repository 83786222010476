import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GroupedBarChart = ({ groupedBarChart }) => {
  const series = groupedBarChart.series;

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // 수평 그래프를 수직 그래프로 변경
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ['RW', 'Math'],
      crosshairs: {
        show: false,
      },
      labels: {
        style: {
          colors: '#0068BD',
        },
      },
    },
    yaxis: {
      max: 800,
      min: 0,
      tickAmount: 4,
    },
    colors: groupedBarChart.colors,
    legend: {
      markers: {
        width: 40,
        height: 8,
        radius: 8,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.7,
        },
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={options} series={series} type='bar' height={options.chart.height} />
    </div>
  );
};

export default GroupedBarChart;
