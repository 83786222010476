import React from 'react';

//img
import IconOk from 'assets/img/icon_ok.svg';

/** tests/scheduled 페이지의 "학생 삭제 완료" 팝업 컴포넌트 */
function DeleteStudentAlert({ setDeleteStudentAlert }) {
  return (
    <div className={'com_popup type_alert active'}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>Successfully deleted</p>
          <div className='icon'>
            <img src={IconOk} alt='icon' />
          </div>
        </div>
        <div className='pop_footer com_btn_wrap'>
          <button className='com_btn point xl full' onClick={() => setDeleteStudentAlert(false)}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteStudentAlert;
