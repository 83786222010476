import parse from 'html-react-parser';
import { nvl } from 'utils/Common.utils';

export default function RwM2HardTestQuestion({ userLevel, rwTestDiff, idx, item }) {
  return (
    <article className={`question_sec ${item.isTooLong | item.lastOdd ? 'too-long' : ''} ${item.rightElement ? 'right' : ''}`} key={idx}>
      <div className='top_area'>
        <div className='num'>{item.question_order}</div>
      </div>
      <div className='question_area'>
        <div
          className='question'
          dangerouslySetInnerHTML={{
            __html: item.passage.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
          }}
        />
        <br /> <br />
        <div
          className='question'
          dangerouslySetInnerHTML={{
            __html: item.question.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
          }}
        />
      </div>
      <div className='answer_area'>
        <div className={`item ${item.correct_answer === 'A' ? 'correct_answer' : item.try_answer === 'A' ? 'wrong' : ''}`}>
          <span className='num'>A&#41;</span>
          <div>
            {parse(item.multiple_choice_items1)}
            {item.correct_answer !== item.try_answer && item.try_answer === 'A' && <div className='txt'>YOUR CHOICE</div>}
            {item.correct_answer === 'A' && <div className='txt'>CORRECT ANSWER</div>}
          </div>
        </div>
        <div className={`item ${item.correct_answer === 'B' ? 'correct_answer' : item.try_answer === 'B' ? 'wrong' : ''}`}>
          <span className='num'>B&#41;</span>
          <div>
            {parse(item.multiple_choice_items2)}
            {item.correct_answer !== item.try_answer && item.try_answer === 'B' && <div className='txt'>YOUR CHOICE</div>}
            {item.correct_answer === 'B' && <div className='txt'>CORRECT ANSWER</div>}
          </div>
        </div>
        <div className={`item ${item.correct_answer === 'C' ? 'correct_answer' : item.try_answer === 'C' ? 'wrong' : ''}`}>
          <span className='num'>C&#41;</span>
          <div>
            {parse(item.multiple_choice_items3)}
            {item.correct_answer !== item.try_answer && item.try_answer === 'C' && <div className='txt'>YOUR CHOICE</div>}
            {item.correct_answer === 'C' && <div className='txt'>CORRECT ANSWER</div>}
          </div>
        </div>
        <div className={`item ${item.correct_answer === 'D' ? 'correct_answer' : item.try_answer === 'D' ? 'wrong' : ''}`}>
          <span className='num'>D&#41;</span>
          <div>
            {parse(item.multiple_choice_items4)}
            {item.correct_answer !== item.try_answer && item.try_answer === 'D' && <div className='txt'>YOUR CHOICE</div>}
            {item.correct_answer === 'D' && <div className='txt'>CORRECT ANSWER</div>}
          </div>
        </div>
        {userLevel && nvl(item.try_answer) === '' && nvl(rwTestDiff) === 'H' && <div className='notice'>You have omitted this question.</div>}
      </div>
    </article>
  );
}
