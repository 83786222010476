import { UPDATE_GATE_KEY_AMOUNT } from './actionTypes';

/** "Gate Key" 관련 전역 상태 기본 값 */
const initialState = {
  remainingKey: 0,
};

/** "Gate Key" 관련 전역 상태 Reducer" */
const stateGateKeys = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GATE_KEY_AMOUNT:
      return {
        ...state,
        remainingKey: action.payload,
      };
    default:
      return state;
  }
};

export default stateGateKeys;
