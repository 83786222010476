import React, { useState } from 'react';
import { ReactComponent as ImageIconExamMore } from 'assets/img/svg/exam_more.svg';
import styled from 'styled-components';
import DeleteModal from 'components/_common/modals/DeleteModal';
import request from 'utils/Request.utils';

/** 더보기 버튼 컴포넌트 */
function BtnMore({ item, setUserExpectTestList, setUserInProgressTestList, setLoading }) {
  /** 더보기 활성화 여부 */
  const [isMoreOn, setIsMoreOn] = useState(false);
  /** 삭제 모달 활성화 여부 */
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  /** Upcoming Test 목록의 더보기 아이콘 (점 세개) 클릭 핸들러 */
  const handleMoreIconButton = ({ item }) => {
    setIsMoreOn((prev) => !prev);
  };
  /** Upcoming Test 목록의 더보기 아이콘 (점 세개) 클릭 후 제공 되는 Delete 버튼 클릭 핸들러 */
  const handleDeleteButton = () => {
    setIsDeleteModal(true);
  };
  /** 삭제 모달 취소 버튼 핸들러 */
  const handleDeleteCancelButton = () => {
    setIsMoreOn(false);
    setIsDeleteModal(false);
  };
  /** 삭제 모달 최종 삭제 확인 버튼 핸들러 */
  const handleDeleteConfirmButton = ({ item }) => {
    setLoading(true);

    const successHandler = (response) => {
      if (response.code === 200) {
        setIsMoreOn(false);
        setIsDeleteModal(false);

        setUserExpectTestList((current) => {
          const newTestListState = current.filter((testItemState) => {
            return testItemState.uthSeq !== item.uthSeq;
          });
          return newTestListState;
        });
        setUserInProgressTestList((current) => {
          const newTestListState = current.filter((testItemState) => {
            return testItemState.uthSeq !== item.uthSeq;
          });
          return newTestListState;
        });
        setLoading(false);
      } else {
        console.error('시험 삭제 API 응답 코드 200번 이외의 응답');
      }
    };

    request
      .del(`/api/gatekey/cancel/b2c?uthSeq=${item.uthSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 삭제 API 실패', error));
  };

  return (
    <S.Wrap>
      <button type='button' title='더 보기' onClick={() => handleMoreIconButton({ item })} className='btn-more'>
        <ImageIconExamMore />
      </button>
      {isMoreOn && (
        <S.Tooltip>
          <button type='button' onClick={handleDeleteButton}>
            Delete
          </button>
        </S.Tooltip>
      )}
      {isDeleteModal && (
        <DeleteModal
          title='Are you sure you want to delete'
          text='Your removing scheduled test. Your Gate Key will be refunded'
          hideModal={handleDeleteCancelButton}
          callback={() => handleDeleteConfirmButton({ item })}
        />
      )}
    </S.Wrap>
  );
}

export default BtnMore;

const S = {};

S.Wrap = styled.div`
  position: absolute;
  left: 0;
  display: inline-block;

  .btn-more {
    visibility: hidden;
  }
`;

S.Tooltip = styled.div`
  position: absolute;
  bottom: -0.4rem;
  right: -6.4rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
  padding: 1rem 2rem;
  z-index: 10;
`;
