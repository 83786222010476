import React, { useState, useEffect } from 'react';
import moment from 'moment';
import request from 'utils/Request.utils.js';

export default function ClassDetails({
  editClassInfo,
  classInfo,
  setProfileState,
  handleShowProfile,
  handleClassManageOpen,
  searchClasses,
  setAlert,
  checkedStudents,
  onCheckboxChange,
}) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const handleSubmitBtn = () => {
    if (!isClassInfoComplete) {
      setAlert({
        active: true,
        message: "Something's wrong.\nIf you're constantly experiencing this problem, contact your administrator",
      });
      return;
    }

    let params = {
      ecSeq: userInfo.ecSeq,
      className: classInfo.classTitle,
      classStartDate: moment(classInfo.startDate, 'MM.DD.YYYY').format('YYYY-MM-DD'),
      classEndDate: moment(classInfo.endDate, 'MM.DD.YYYY').format('YYYY-MM-DD'),
      seqStr: checkedStudents.length === 0 ? '0' : checkedStudents.map((student) => student.userSeq).join('|'),
      ...(editClassInfo ? { modiUserSeq: userInfo.ecSeq } : { regUserSeq: userInfo.userSeq }),
    };

    const updatedStudentList = checkedStudents.map((student) => student.userName);

    if (editClassInfo) {
      const successHandler = (response) => {
        if (response.code === 200) {
          // console.log(response)
          handleClassManageOpen(false);
          searchClasses();
          setAlert({
            active: true,
            message: `You have moved ${updatedStudentList.join(', ')} from their original class`,
          });
        }
      };
      request.put(`/api/academy/classes/${classInfo.classId}`, params, successHandler);
    } else {
      const successHandler = (response) => {
        if (response.code === 200) {
          // console.log(response)
          handleClassManageOpen(false);
          searchClasses();
          setAlert({
            active: true,
            message: `New class successfully created.\nYou have moved ${updatedStudentList.join(', ')} from their original class.`,
          });
        }
      };
      request.post('/api/academy/classes', params, successHandler);
    }
  };

  const isClassInfoComplete = !!classInfo?.classTitle && !!classInfo?.startDate && !!classInfo?.endDate && checkedStudents.length > 0;

  return (
    <div className='right'>
      <button className='btn_pop_close svg_icon black' onClick={() => handleClassManageOpen(false)}></button>
      {/*4.Class Details*/}
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon bullet_4 black'>&nbsp;</i>
          Class Details
        </h2>
      </article>
      <article className='class_info'>
        <div className='item'>
          <div className='tit'>Class</div>
          <div className='info'>
            <p className='name'>{classInfo?.classTitle ? classInfo.classTitle : '-'}</p>
          </div>
        </div>
        <div className='item'>
          <div className='tit'>Date</div>
          <div className='info'>
            <input type='text' value={classInfo?.startDate} className='input non-interactive-input' readOnly /> ~{' '}
            <input type='text' value={classInfo?.endDate} className='input non-interactive-input' readOnly />
          </div>
        </div>
      </article>
      <article className='com_list_box'>
        <div className='scroll'>
          <table className='list'>
            <colgroup>
              <col width='8%' />
              <col />
              <col width='6.25rem' />
            </colgroup>
            <thead>
              <tr className='sticky top'>
                <th colSpan={3} className='table_tit black'>
                  <div className='wrap'>
                    Selected Student List
                    <p className='num'>
                      <i className='svg_icon icon_person white'>&nbsp;</i>
                      {checkedStudents.length}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {classInfo &&
                classInfo.studentList.map((studentInfo, idx) => {
                  return (
                    <tr key={idx}>
                      <td className='alignC' style={{ paddingLeft: '.625rem' }}>
                        <input
                          type='checkbox'
                          id={`chk12-${idx}`}
                          className='com_chk'
                          checked={checkedStudents.includes(studentInfo)}
                          onChange={(e) => onCheckboxChange(studentInfo, !checkedStudents.includes(studentInfo))}
                        />
                        <label htmlFor={`chk12-${idx}`}></label>
                      </td>
                      <td className='alignL'>
                        <p className='ellipsis'>{studentInfo.userName}</p>
                      </td>
                      <td>
                        <button className='com_btn s line gray' onClick={() => handleShowProfile(studentInfo.userSeq)}>
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </article>
      <article className='com_btn_wrap bottom'>
        <button
          className='com_btn point l full'
          style={!isClassInfoComplete ? { backgroundColor: '#cccccc' } : {}}
          disabled={!isClassInfoComplete}
          onClick={handleSubmitBtn}>
          {editClassInfo ? 'Save Changes' : 'Create New Class'}
        </button>
      </article>
    </div>
  );
}
