///////////// 과목 별 시험 시간 관련 전역 상수 관리 파일 (한 번에 설정 하기 위한 인터페이스 역할)

// 🔍 검색 키워드 - #상수 #시험 #시간

/** 영어 시험 시간 상수 */
export const GLOBAL_EN_TEST_TIME = 1920;
/** 수학 시험 시간 상수 */
export const GLOBAL_MA_TEST_TIME = 2100;
/** 쉬는 시간 상수 */
export const GLOBAL_BREAKTIME_TIME = 600;
