import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { nvl } from 'utils/Common.utils';
import PassageArea from './PassageArea';

/** 영어 문제 영역 컴포넌트 */
export default function EnglishQuestion({
  wrap,
  wrapButtonHandler,
  setIsDragged,
  setAnnotateName,
  setAnnotateMemo,
  annotationId,
  setAnnotationId,
  setIsClickAnnotate,
}) {
  const restrictedWords = ['emphasized', 'importance of safeguarding'];

  const stateExamInfo = useSelector((state) => state.stateExamInfo);

  const passageRef = useRef();
  const tooltipRef = useRef();

  let currentPassage = stateExamInfo.questionList[stateExamInfo.currentNum - 1].passage.replace(
    '/common/commonImageView.do',
    `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
  );

  const annotationContent =
    stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.annotation != null &&
    stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.annotation.length > 0
      ? stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.annotation[0].annotationContent
      : '';

  if (nvl(annotationContent) !== '') currentPassage = annotationContent;

  useEffect(() => {
    const showTooltip = (event, memo, position) => {
      const tooltip = tooltipRef.current;
      tooltip.innerText = memo;

      tooltip.style.bottom = `${position.y}px`;
      tooltip.style.left = `${position.x}px`;
      tooltip.style.display = 'inline-block';
      tooltip.style.color = 'black';
      tooltip.style.transform = 'translate(-50%, 0)';
      tooltip.style.visibility = 'visible';
    };
    const hideTooltip = () => {
      const tooltip = tooltipRef.current;

      tooltip.style.display = 'none';
      tooltip.style.visibility = 'hidden';
    };

    const passageElement = passageRef.current;

    passageElement.addEventListener('mouseover', (event) => {
      if (event.target.hasAttribute('data-memo')) {
        const annotationId = event.target.getAttribute('data-annotation-id');
        const memoTitle = event.target.getAttribute('data-text');
        const selectedAnnotations = passageElement.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);
        const firstSelectedAnnotation = selectedAnnotations[0];

        selectedAnnotations.forEach((el) => {
          el.classList.add('rangy-highlight-hover');
        });

        const position = {
          x: firstSelectedAnnotation.getBoundingClientRect().left + firstSelectedAnnotation.getBoundingClientRect().width / 2,
          y:
            passageElement.parentNode.getBoundingClientRect().bottom -
            firstSelectedAnnotation.getBoundingClientRect().bottom +
            firstSelectedAnnotation.getBoundingClientRect().height -
            1,
        };
        showTooltip(event, event.target.getAttribute('data-memo'), position);
      }
    });

    passageElement.addEventListener('mouseout', (event) => {
      if (event.target.hasAttribute('data-memo')) {
        const annotationId = event.target.getAttribute('data-annotation-id');
        const memoTitle = event.target.getAttribute('data-text');
        const selectedAnnotations = passageElement.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

        selectedAnnotations.forEach((el) => {
          el.classList.remove('rangy-highlight-hover');
        });
        hideTooltip();
      }
    });

    passageElement.addEventListener('click', (event) => {
      if (event.target.hasAttribute('data-memo')) {
        const annotationId = event.target.getAttribute('data-annotation-id');
        const memoTitle = event.target.getAttribute('data-text');
        const annotationMemo = event.target.getAttribute('data-memo');
        const selectedAnnotations = passageElement.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

        passageRef.current.querySelectorAll('.rangy-highlight').forEach((el) => {
          el.classList.remove('rangy-highlight-selected');
        });

        selectedAnnotations.forEach((el) => {
          el.classList.add('rangy-highlight-selected');
        });

        setAnnotationId(annotationId);
        setAnnotateName(memoTitle);
        setAnnotateMemo(annotationMemo);

        setIsClickAnnotate(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPassage]);

  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      {' '}
      {/*icon_wrap 클릭 시 addClass "big"*/}
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div style={{ position: 'relative' }} className='inner'>
          <PassageArea
            setAnnotateMemo={setAnnotateMemo}
            setIsClickAnnotate={setIsClickAnnotate}
            annotationId={annotationId}
            setAnnotationId={setAnnotationId}
            setAnnotateName={setAnnotateName}
            setIsDragged={setIsDragged}
            tooltipRef={tooltipRef}
            passageRef={passageRef}
            passage={currentPassage}
          />
        </div>
      </div>
    </article>
  );
}
