import React from 'react';
import { nvl } from 'utils/Common.utils';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역)의 "Percentile within Gate+ and Grade Change from Past tests" 영역 컴포넌트 */
export default function PercentileWithinGatePlusAndGradeChangeFromPastTests({ getPercentileList, scoreStats, subjectTable, percentileClick }) {
  const ChangeSubjectTable = (e) => {
    // setSubjectTable(prev => !prev);
    if (scoreStats.setSubject === 'F') {
      let subject = e.target.checked ? 'M' : 'E';

      if (scoreStats.testSetSeq > 0) getPercentileList(scoreStats.classSeq, scoreStats.testSetSeq, subject);
    }
  };

  return (
    <article className='box per50 past_tests'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_graph black'>&nbsp;</i>
          Percentile within Gate+ and Grade Change from Past tests
        </h2>
        <div className='com_subject_switch__wrap'>
          <input type='checkbox' id='subjectSwitch' className='com_subject_switch' onChange={ChangeSubjectTable} checked={subjectTable} />
        </div>
      </div>
      <div className={`com_list_box ${subjectTable ? 'math' : 'rw'}`}>
        <div className='scroll'>
          <table className='list'>
            <thead className='sticky top'>
              <tr>
                <th></th>
                <th>Student Name</th>
                <th className='alignC'>Percentile</th>
                <th className='alignC'>3 tests</th>
                <th className='alignC'>5 tests</th>
                <th className='alignC'>10 tests</th>
              </tr>
            </thead>
            <tbody>
              {scoreStats.percentileList &&
                scoreStats.percentileList?.map((i, idx) => {
                  return (
                    <tr
                      key={`percentile_${idx}`}
                      onClick={() => {
                        percentileClick(i.userSeq);
                      }}
                      className={scoreStats.userSeq === i.userSeq ? 'active' : ''}>
                      <td>
                        <div className='com_profile_img'>
                          {i.upFileSeq > 0 ? (
                            <img
                              className='profile-img-background'
                              src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${i.userSeq}`}
                              alt='프로필이미지'
                            />
                          ) : (
                            <span className='name'>{i.userName[0]}</span>
                          )}
                          {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                        </div>
                      </td>
                      <td>
                        <span className='name'>{i.userName}</span>
                      </td>
                      <td className='alignC data_field'>{i.all}</td>
                      <td className={`alignC data_change ${i.tests3 >= 0 ? 'plus' : 'minus'}`}>{nvl(i.tests3) !== '' ? i.tests3 : '-'}</td>
                      <td className={`alignC data_change ${i.tests5 >= 0 ? 'plus' : 'minus'}`}>{nvl(i.tests5) !== '' ? i.tests5 : '-'}</td>
                      <td className={`alignC data_change ${i.tests10 >= 0 ? 'plus' : 'minus'}`}>{nvl(i.tests10) !== '' ? i.tests10 : '-'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
}
