import React from 'react';
import ImgIconClock from 'assets/img/exam/icon_clock.svg';
import ImgFileCheck from 'assets/img/exam/file_check_02.svg';
import ImgAssistiveTechnology from 'assets/img/exam/assistive_technology.svg';
import ImgLockUnlocked from 'assets/img/exam/lock_unlocked_01.svg';

/** 시험 : 튜토리얼 페이지의 Body 영역 */
export default function ExamTutorialBody() {
  return (
    <section className='pop_body'>
      <div className='tit'>Practice Test</div>
      <div className='box' style={{ width: '440px' }}>
        <ul>
          <li>
            <img src={ImgIconClock} alt='Timing' style={{ width: '30px' }} />
            <span className='fontWeight'>Timing</span>
          </li>
          <li className='txt'>
            You have 32 minutes for each module of Reading and Writing section and 35 minutes for each module of Math section for a total of 2 hours and 14
            minutes. If you exit the test, your work will be graded on what you have solved until that point and you will not be able to return to the test.
          </li>
          <li>
            <img src={ImgFileCheck} alt='Score' style={{ width: '30px' }} />
            <span className='fontWeight'>Scores</span>
          </li>
          <li className='txt'>
            When you finish the test, go to <b>Your Most Recent Test</b> section of your dashboard to see your scores. If you want to see past test scores,
            click on Test List and choose the test you want to see.
          </li>
          <li>
            <img src={ImgAssistiveTechnology} alt='Assistive Technology' style={{ width: '30px' }} />
            <span className='fontWeight'>Assistive Technology</span>
          </li>
          <li className='txt'>Currently, we do not support this feature. Please stay tuned for updates!</li>
          <li>
            <img src={ImgLockUnlocked} alt='No Device Lock' style={{ width: '30px' }} />
            <span className='fontWeight'>No Device Lock</span>
          </li>
          <li className='txt'>
            We don’t lock down our tests. If you accidentally close the testing window, your work will automatically be saved and you will be able to return to
            the exam but your test timer will continue ticking. On the actual test, you will not be able to access other programs or apps.
          </li>
        </ul>
      </div>
    </section>
  );
}
