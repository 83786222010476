import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

// hook
import useResponsiveFontSize from 'hooks/useResponsiveFontSize.js';
import useUserLevels from 'hooks/useUserLevels.js';

import request from 'utils/Request.utils.js';
import { nvl, nvlNumber } from 'utils/Common.utils.js';

// Images
import ImgVisual01 from 'assets/img/main_bg.png';
// banner img
import AcaBanner1 from 'assets/img/banner/academy-dashboard/aca_dash_banner_01.png';
import AcaBanner3 from 'assets/img/banner/academy-dashboard/aca_dash_banner_03.png';
import AcaBanner4 from 'assets/img/banner/academy-dashboard/aca_dash_banner_04.jpg';
import AcaBanner5 from 'assets/img/banner/academy-dashboard/aca_dash_banner_05.jpg';
import AcaBanner6 from 'assets/img/banner/academy-dashboard/aca_dash_banner_06.jpg';
import AcaBanner7 from 'assets/img/banner/academy-dashboard/aca_dash_banner_07.jpg';
import AcaBanner8 from 'assets/img/banner/academy-dashboard/aca_dash_banner_08.jpg';
//components
import MiniCalendar from 'components/_common/calendar/MiniCalendar';

import StackedColumnsChart from 'components/_common/chart/StackedColumnsChart';
import SimpleDonutChart from 'components/_common/chart/SimpleDonutChart';
import LineAreaChart from 'components/_common/chart/LineAreaChart';
import BoardPreView from 'components/board/_components/_boardPreView';
import MainLink from 'components/_common/button/_MainLinks';
import SwiperContainer from 'components/_common/lib/swiper/SwiperContainer';

/** 아케데미 대시보드 페이지 */
export default function AcademyDashboardPage() {
  const navigate = useNavigate();
  const [mark, setMark] = useState([]);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  const stateMenuManager = useSelector((state) => state.MenuManager);

  let { pathname } = window.location;

  const [firstClassSeq, setFirstClassSeq] = useState(0);
  const [currentClassSeq, setCurrentClassSeq] = useState(0);
  const [currentClassName, setCurrentClassName] = useState('');

  useEffect(() => {
    //body에 클래스 추가
    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTestStatusCnt = (classSeq) => {
    const successHandler = (response) => {
      // console.log("요고 확인", response);

      if (response.code === 200) {
        let testStatusCnt = response.result.testStatusCnt;
        let classTestStatus = response.result.classTestStatusCnt;

        if (nvl(testStatusCnt) !== '' && testStatusCnt.length > 0) {
          setDonutChartData((prev) => {
            return {
              ...prev,
              data: [testStatusCnt[0].testStatusI, testStatusCnt[0].testStatusE, testStatusCnt[0].testStatusP],
            };
          });
        }

        let classTestStatusI = [];
        let classTestStatusE = [];
        let classTestStatusP = [];
        let className = [];
        let classSeq = [];

        if (classTestStatus && classTestStatus.length > 0) {
          setFirstClassSeq(classTestStatus[0].classSeq);

          for (let i = 0; i < classTestStatus.length; i++) {
            classTestStatusI.push(classTestStatus[i].testStatusI);
            classTestStatusE.push(classTestStatus[i].testStatusE);
            classTestStatusP.push(classTestStatus[i].testStatusP);
            className.push(classTestStatus[i].className);
            classSeq.push(classTestStatus[i].classSeq);
          }
        }

        const newSeries = [
          {
            name: 'Not taking the test',
            data: classTestStatusE,
            seq: classSeq,
          },
          {
            name: 'Currently taking',
            data: classTestStatusI,
            seq: classSeq,
          },
          {
            name: 'Completed tests',
            data: classTestStatusP,
            seq: classSeq,
          },
        ];

        setColChartData((prev) => ({
          ...prev,
          series: newSeries,
          xaxis: {
            ...prev.xaxis,
            categories: className,
          },
        }));
      }
    };

    request.get(`/api/dsat/test/status?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler);
  };

  const [colChartData, setColChartData] = useState({
    series: [
      {
        name: 'Not taking the test',
        data: [],
      },
      {
        name: 'Currently taking',
        data: [],
      },
      {
        name: 'Completed tests',
        data: [],
      },
    ],
    xaxis: {
      type: 'category',
      categories: [],
      title: {
        //text: 'Class',
        rotate: 0, // 레이블을 가로로 표시
        offsetX: 80, // 오른쪽으로 이동
        offsetY: -10, // 아래로 이동
      },
    },
    yaxis: {
      title: {
        //text: 'People', // y축 레이블
        rotate: 0, // 레이블을 가로로 표시
        offsetX: 5, // 오른쪽으로 이동
        offsetY: -150, // 아래로 이동
        style: {
          fontSize: '12px', // 글자 크기
          color: '#000000', // 글자색
        },
      },
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    colors: ['#000000', '#0068BD', '#E3E7ED'],
    enabled: false,
    // labels: false
    labels: [77, 88, 99],
  });

  const currentlyTotal = () => {
    return colChartData?.series[1].data.reduce((acc, value) => acc + value, 0);
  };

  const completedTotal = () => {
    return colChartData?.series[2].data.reduce((acc, value) => acc + value, 0);
  };

  const notTakingTotal = () => {
    return colChartData?.series[0].data.reduce((acc, value) => acc + value, 0);
  };

  // added by psk 20240131 - 반 선택했을 때 처리
  const [classColChartData, setClassColChartData] = useState({
    series: [
      {
        name: 'Not taking the test',
        data: [],
      },
      {
        name: 'Currently taking',
        data: [],
      },
      {
        name: 'Completed tests',
        data: [],
      },
    ],
  });

  const currentlyTotalByClass = () => {
    return classColChartData?.series[1].data.reduce((acc, value) => acc + value, 0);
  };

  const completedTotalByClass = () => {
    return classColChartData?.series[2].data.reduce((acc, value) => acc + value, 0);
  };

  const notTakingTotalByClass = () => {
    return classColChartData?.series[0].data.reduce((acc, value) => acc + value, 0);
  };

  const getTestStatusCntByClass = () => {
    const successHandler = (response) => {
      console.log('요고 확인', response);

      if (response.code === 200) {
        let testStatusCnt = response.result.testStatusCnt;
        let classTestStatus = response.result.classTestStatusCnt;

        let classTestStatusI = [];
        let classTestStatusE = [];
        let classTestStatusP = [];
        let className = [];

        setCurrentClassName(classTestStatus[0].className);

        for (let i = 0; i < classTestStatus.length; i++) {
          classTestStatusI.push(classTestStatus[i].testStatusI);
          classTestStatusE.push(classTestStatus[i].testStatusE);
          classTestStatusP.push(classTestStatus[i].testStatusP);
          className.push(classTestStatus[i].className);
        }

        const newSeries = [
          {
            name: 'Not taking the test',
            data: classTestStatusE,
          },
          {
            name: 'Currently taking',
            data: classTestStatusI,
          },
          {
            name: 'Completed tests',
            data: classTestStatusP,
          },
        ];

        setClassColChartData((prev) => ({
          ...prev,
          series: newSeries,
          xaxis: {
            ...prev.xaxis,
            categories: className,
          },
        }));
      }
    };

    request.get(`/api/dsat/test/status?ecSeq=${userInfo?.ecSeq}&classSeq=${currentClassSeq}`, null, successHandler);
  };

  useEffect(() => {
    if (currentClassSeq > 0) getTestStatusCntByClass();
    else getTestStatusCnt(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClassSeq]);

  const [donutChartData, setDonutChartData] = useState({
    data: [0, 0, 0],
    legend: {
      show: false,
    },
    width: 370,
  });

  // console.log("userInfo 확인 : ", userInfo, userInfo?.userName)

  // TOTAL STUDENTS AND TESTS STAT 차트
  const [examCountData, setExamCountData] = useState([]);
  const [studentCountData, setStudentCountData] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  const dailyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let dailyExamCount = response.result.dailyExamCount;

        let dailyTakerCountData = dailyExamCount?.map((i) => i.cumulativeCntStudents);
        let dailyExamCountData = dailyExamCount?.map((i) => i.cumulativeCntTests);
        let dateArrayData = Array.from({ length: dailyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(dailyExamCount[index].timezoneDate).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(dailyExamCountData);
        setStudentCountData(dailyTakerCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/daily?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const weeklyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let weeklyExamCount = response.result.weeklyExamCount;

        let weeklyTakersCountData = weeklyExamCount?.map((i) => i.cumulativeCntStudents);
        let weeklyExamCountData = weeklyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = Array.from({ length: weeklyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(weeklyExamCount[index].dt1).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(weeklyExamCountData);
        setStudentCountData(weeklyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/weekly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const fortnightlyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let fortnightlyExamCount = response.result.fortnightlyExamCount;

        let fortnightlyTakersCountData = fortnightlyExamCount?.map((i) => i.cumulativeCntStudents);
        let fortnightlyExamCountData = fortnightlyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = Array.from({ length: fortnightlyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(fortnightlyExamCount[index].dt1).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(fortnightlyExamCountData);
        setStudentCountData(fortnightlyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/fortnightly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const monthlyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let monthlyExamCount = response.result.monthlyExamCount;

        let monthlyTakersCountData = monthlyExamCount?.map((i) => i.cumulativeCntStudents);
        let monthlyExamCountData = monthlyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = monthlyExamCount?.map((i) => i.month1);

        setExamCountData(monthlyExamCountData);
        setStudentCountData(monthlyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/monthly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const [lineAreaChartData, setLineAreaChartData] = useState([
    {
      name: 'CUMULATIVE TESTS',
      type: 'area',
      data: examCountData,
    },
    {
      name: 'CUMULATIVE STUDENTS',
      type: 'line',
      data: studentCountData,
    },
  ]);

  const [buttons, setButtons] = useState([
    { text: 'Week', value: 0, active: true },
    { text: 'Month', value: 1, active: false },
    { text: '3 Month', value: 2, active: false },
    { text: 'Year', value: 3, active: false },
  ]);

  useEffect(() => {
    let activeButton = buttons.find((button) => button.active);

    if (activeButton.value === 0) dailyCountExam();
    if (activeButton.value === 1) weeklyCountExam();
    if (activeButton.value === 2) fortnightlyCountExam();
    if (activeButton.value === 3) monthlyCountExam();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons]);

  const handleClickTestStatus = () => {
    navigate('/com/tests/status', {
      state: {
        classSeq: nvlNumber(currentClassSeq) === 0 ? firstClassSeq : nvlNumber(currentClassSeq),
        seeEveryTest: true,
      },
    });
  };

  // useResponsiveFontSize 사용 코드(학원 이름 크기 조절)
  const textRef = useRef(null);
  const fontSize = useResponsiveFontSize(textRef, 48);

  const isAcademyMember = userInfo.ecSeq > 0;

  const displayName = isAcademyMember
    ? nvl(stateMenuManager?.academyName) !== ''
      ? stateMenuManager?.academyName
      : userInfo?.companyName
    : nvl(stateMenuManager?.userName) !== ''
      ? stateMenuManager?.userName
      : userInfo?.userName;
  const displayImg = isAcademyMember
    ? userInfo.academyUpFileSeq > 0 || nvl(stateMenuManager?.academyProfileImage) !== ''
      ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${userInfo.ecSeq}?${Date.now()}`
      : null
    : userInfo.upFileSeq > 0
      ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${userInfo.userSeq}?${Date.now()}`
      : null;

  return (
    <>
      <div className='p_main'>
        <section className='com_visual_area'>
          <div className='com_center_wrap'>
            <div className='right'>
              <img src={ImgVisual01} alt='illustration' />
            </div>
          </div>
        </section>
        <div className='com_center_wrap'>
          <section className='com_visual_text'>
            <div className='tit'>
              <div className='com_profile_img T big-profile-img'>
                {useLevels.isAcademy || useLevels.isTutor || useLevels.isTeacher ? (
                  userInfo.academyUpFileSeq > 0 || nvl(stateMenuManager?.academyProfileImage) !== '' ? (
                    <img className='profile-img-background' src={displayImg} alt='프로필이미지' />
                  ) : (
                    <span className='name'>{displayName[0]}</span>
                  )
                ) : userInfo.upFileSeq > 0 ? (
                  <img className='profile-img-background' src={displayImg} alt='프로필이미지' />
                ) : (
                  <span className='name'>{displayName[0]}</span>
                )}
              </div>
              Welcome,
              <span className='dashboard-text-section company-name-section'>
                <b
                  className='point name'
                  ref={textRef}
                  style={{
                    fontSize: `${fontSize}px`,
                    visibility: fontSize ? 'visible' : 'hidden',
                  }}>
                  &nbsp;{displayName}!
                </b>
              </span>
            </div>
          </section>
          <MainLink />
        </div>
        <section className='dashboard_sec com_center_wrap'>
          <div className='com_container left'>
            {/*CALENDAR*/}
            <article className='box full calendar'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_calendar black'>&nbsp;</i>
                  CALENDAR
                </h2>
              </div>
              <MiniCalendar mark={mark} pathname={pathname} />
            </article>
            {/*GATE+ News*/}
            <BoardPreView type='gate' />
            {/*DSAT Info*/}
            <BoardPreView type='dsat' />
            <SwiperContainer
              containerClass={'banner-container'}
              itemClass={'banner'}
              bannerContent={[
                // { link: 'https://forms.gle/GUN1FVtRqUGXn7dF9', image: AcaBanner6, visibleTo: 'B2B' },
                { link: 'https://pf.kakao.com/_prxbCG/106579635', image: AcaBanner7, visibleTo: 'B2B' },
                { link: 'http://pf.kakao.com/_prxbCG/106579515', image: AcaBanner8, visibleTo: 'B2B' },
                {
                  link: 'https://product.kyobobook.co.kr/detail/S000213501792',
                  image: AcaBanner1,
                  visibleTo: 'ALL',
                },
                { link: 'https://www.youtube.com/watch?v=YhTTOtyFlAU', image: AcaBanner3, visibleTo: 'ALL' },
                { link: 'https://pf.kakao.com/_prxbCG', image: AcaBanner4, visibleTo: 'ALL' },
                { link: 'https://www.gateplus.org', image: AcaBanner5, visibleTo: 'ALL' },
              ]}
            />
          </div>
          <div className='com_container right'>
            {/* TEST STATUS BY CLASSES */}
            <article className='box test_status_by_classes'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  TEST STATUS BY CLASSES
                </h2>
              </div>
              <div className='com_classes_chart'>
                {currentClassSeq > 0 ? (
                  <div className='className_area'>
                    <p className='tit'>class</p>
                    <p className='name'>{currentClassName}</p>
                  </div>
                ) : (
                  <div className='className_area'>
                    <p className='tit'>&nbsp;</p>
                    <p className='name'>&nbsp;</p>
                  </div>
                )}
                <Link
                  to='/com/tests/status'
                  state={{
                    classSeq: nvlNumber(currentClassSeq) === 0 ? firstClassSeq : nvlNumber(currentClassSeq),
                  }}
                  className='com_chart_name'>
                  <div className='item'>
                    <span className='color blue'>&nbsp;</span>
                    <p className='name'>Currently taking</p>
                    {currentClassSeq > 0 ? (
                      <CountUp start='0' end={currentlyTotalByClass()} duration='3' className='num' />
                    ) : (
                      <CountUp start='0' end={currentlyTotal()} duration='3' className='num' />
                    )}
                  </div>
                  <div className='item'>
                    <span className='color gray'>&nbsp;</span>
                    <p className='name'>Completed tests</p>
                    {currentClassSeq > 0 ? (
                      <CountUp start='0' end={completedTotalByClass()} duration='3' className='num' />
                    ) : (
                      <CountUp start='0' end={completedTotal()} duration='3' className='num' />
                    )}
                  </div>
                  <div className='item'>
                    <span className='color black'>&nbsp;</span>
                    <p className='name'>Not taking the test</p>
                    {currentClassSeq > 0 ? (
                      <CountUp start='0' end={notTakingTotalByClass()} duration='3' className='num' />
                    ) : (
                      <CountUp start='0' end={notTakingTotal()} duration='3' className='num' />
                    )}
                  </div>
                </Link>
                {/* 그래프 영역 */}
                {notTakingTotal() + currentlyTotal() + completedTotal() === 0 ? (
                  <> No scheduled tests for today </>
                ) : (
                  <StackedColumnsChart colChartData={colChartData} currentClassSeq={currentClassSeq} setCurrentClassSeq={setCurrentClassSeq} />
                )}
              </div>
              <div className='com_btn_wrap'>
                <button className='com_btn m point full' onClick={() => navigate('/com/students/classes')}>
                  Add more classes
                  <i className='svg_icon after icon_add white'>&nbsp;</i>
                </button>
              </div>
            </article>
            {/* TEST STATUS BY TOTAL*/}
            <article className='box test_status_by_total'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  TEST STATUS BY TOTAL
                </h2>
              </div>
              {/* 그래프 영역 */}
              <div className='com_donut_chart'>
                <div className='chart_area'>
                  <div className='total' onClick={() => handleClickTestStatus()}>
                    {donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2] === 0 ? (
                      <>No scheduled tests for today</>
                    ) : (
                      <>
                        <p className='tit_field'>TOTAL</p>
                        <CountUp start={0} end={donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2]} duration={3} className='num_field' />
                      </>
                    )}
                  </div>
                  <SimpleDonutChart donutChartData={donutChartData} />
                </div>
                <hr className='divider' />
                <ul className='com_chart_name'>
                  <li className='item' onClick={() => handleClickTestStatus()}>
                    <p className='name'>
                      Currently
                      <br />
                      taking
                    </p>
                    <CountUp start={0} end={donutChartData.data[0]} duration={3} className='num' />
                    <span className='color blue'>&nbsp;</span>
                  </li>
                  <li className='item' onClick={() => handleClickTestStatus()}>
                    <p className='name'>
                      Completed
                      <br />
                      tests
                    </p>
                    <CountUp start={0} end={donutChartData.data[2]} duration={3} className='num' />
                    <span className='color gray'>&nbsp;</span>
                  </li>
                  <li className='item' onClick={() => handleClickTestStatus()}>
                    <p className='name'>
                      Not
                      <br />
                      taking
                    </p>
                    <CountUp start={0} end={donutChartData.data[1]} duration={3} className='num' />
                    <span className='color black'>&nbsp;</span>
                  </li>
                </ul>
              </div>
            </article>
            {/* TOTAL STUDENTS AND TESTS STAT*/}
            <article className='box full total_students_tests'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  TOTAL STUDENTS AND TESTS STAT
                </h2>
              </div>
              <LineAreaChart
                buttons={buttons}
                setButtons={setButtons}
                lineAreaChartData={lineAreaChartData}
                dateArray={dateArray}
                examCountData={examCountData}
                studentCountData={studentCountData}
              />
            </article>
          </div>
        </section>
      </div>
    </>
  );
}
