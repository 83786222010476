import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

const FindEmail = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);

  const navigate = useNavigate();

  const [signupInfo, setSignupInfo] = useState({
    name: true,
    mobile: true,
    notFound: true,
  });

  const nameRef = useRef('');
  const mobileRef = useRef('');

  const nextBtnClick = () => {
    let allPassed = true;

    if (nvl(mobileRef.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, mobile: false };
      });
      allPassed = false;
      mobileRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, mobile: true };
      });

    if (nvl(nameRef.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, name: false };
      });
      allPassed = false;
      nameRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, name: true };
      });

    if (allPassed) {
      findId();
    }
  };

  const findId = () => {
    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        let findEmail = response.result.userId;

        if (nvl(findEmail) === '') {
          setSignupInfo((prev) => {
            return { ...prev, name: false, mobile: false, notFound: false };
          });
        } else {
          navigate('complete', { state: findEmail });
        }
      }
    };

    request.get(`/api/common/email/id?userName=${nameRef.current.value}&userMobile=${mobileRef.current.value}`, null, successHandler);
  };

  return (
    <div className='container'>
      <Header>
        <p className='desc'>The door to educational aspiration</p>
      </Header>
      <section className='forgot_email'>
        <h1 className='tit'>Forgot your email?</h1>
        <p className='txt'>
          Type in your name and mobile number
          <br />
          to find your email address.
        </p>
      </section>
      <section className='form'>
        {/*이름*/}
        <article className={`input_wrap ${signupInfo.name ? '' : 'error'}`}>
          {/*틀릴 경우 addClass error*/}
          <h2 className='form_title'>
            <span className='essential'>*</span>
            <span>NAME</span>
          </h2>
          <div className='box forgot_name'>
            <input className='input' type='text' ref={nameRef} />
          </div>
          <p className='error_message'>{signupInfo.notFound ? 'Please type in your name.' : 'Your name was not found. Please try again'}</p>
        </article>
        {/*전화번호*/}
        <article className={`input_wrap ${signupInfo.mobile ? '' : 'error'}`}>
          {/*틀릴 경우 addClass error*/}
          <h2 className='form_title'>
            <span className='essential'>*</span>
            <span>MOBILE NUMBER</span>
          </h2>
          <div className='box forgot_phone'>
            <input className='input' type='tel' ref={mobileRef} />
          </div>
          <p className='error_message'>{signupInfo.notFound ? 'Please type in your mobile number.' : 'Your mobile number was not found. Please try again'}</p>
        </article>
        <button className='btn btn_fill forgot_btn btn_big' onClick={() => nextBtnClick()}>
          Submit
        </button>
      </section>
    </div>
  );
};

export default FindEmail;
