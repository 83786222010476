import { useRef, useState } from 'react';
import styled from 'styled-components';
import imgArrow from './../../../assets/gate_member/image/chevron_down.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';

/** 
 * 커스텀 셀렉트 박스
 * react-hook-form과 같이 사용 하거나 일반적인 상태 관리 방식으로 사용 가능
 */
function SelectBox({ register = null, handleChange, placeholder = '', selectedItem, optionList = [{ name: '' }], fixedValue = null }) {
  const [isOpen, setIsOpen] = useState(false);

  /** 선택된 값의 상태 ( 고정값(fixedValue)이 존재한다면 해당 값으로 고정 ) */
  const [selectedValue, setSelectedValue] = useState(fixedValue ? fixedValue : selectedItem);

  /** 요소 선택시 호출되는 함수 - react-hook-form의 register가 없을 경우 일반적인 state 방식 사용 */
  const handleSelect = (item) => {
    if (register) {
      handleChange(register.name, item);
    } else {
      handleChange(item.name, item.value);
      // 선택된 값이 고정값이 아닐 경우, 선택된 값으로 변경
      setSelectedValue(fixedValue ? fixedValue : item.name);
    }
  };

  // 외부 영역 클릭 시, select box 닫기 Hook
  const refSelect = useRef();
  useOutsideClick(refSelect, () => setIsOpen((current) => !current), isOpen);

  return (
    <SelectBoxWrap>
      <div className='select_box' ref={refSelect} onClick={() => setIsOpen(!isOpen)}>
        <input
          type='text'
          readOnly
          {...(register
            ? register
            : {
                value: selectedValue,
              })}
          placeholder={placeholder}
          // onFocus={() => setIsOpen(true)}
          // onBlur={() => setIsOpen(false)}
          onChange={() => setIsOpen(false)}
        />
        <ul className={`option_wrap ${isOpen ? 'active' : ''}`}>
          {optionList &&
            optionList.map((item, index) => {
              return (
                <li key={`type${index + 1}`} className='option' onClick={() => handleSelect(item)}>
                  {item.name}
                </li>
              );
            })}
        </ul>
      </div>
    </SelectBoxWrap>
  );
}

export default SelectBox;
// --- styled-components ---
const S = {};

const SelectBoxWrap = styled.div`
  .select_box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    transition: 0.3s;
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0.625rem;
      top: calc(50% - 0.775rem);
      width: 1.5rem;
      height: 1.5rem;
      background: url(${imgArrow});
    }
    .select_value {
      line-height: 0.75rem;
      color: #000;
      font-size: 0.875rem;
    }
    .option_wrap {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      width: 100%;
      max-height: 9.375rem;
      overflow-y: auto;
      border-bottom: 1px solid #d2dbe2;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.1490196078);
      margin-top: 1px;
      text-align: left;
      display: none;
      &.active {
        display: block;
      }
      .option {
        font-size: 0.875rem;
        line-height: 0.75rem;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid #d2dbe2;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
  .error_message {
    display: none;
    text-align: left;
  }
`;
