import React, { useRef, useState, useEffect } from 'react';

import { allowImages, allowPdfFiles } from '../../utils/Common.utils';

import IconDel from '../../assets/img/icon_del.svg';

//component
const Upload = ({ type, uploadFileList, setUploadFileList, setAlertLayerPopup }) => {
  const [fileCount, setFileCount] = useState(0);

  const [deletePop, setDeletePop] = useState(false);

  const [isActive, setActive] = useState(false);

  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    // const currentFile = event.dataTransfer.files[0];
    const fileList = event.dataTransfer.files;

    // for(const file of fileList) {
    //   getFileInfo(file)
    // }

    getFileListInfo(fileList);

    setActive(false);
  };

  const handleFileInput = (e) => {
    const fileList = e.target.files;

    getFileListInfo(fileList);

    e.target.value = '';
  };

  const getFileListInfo = (fileList) => {
    let tempFileList = [...uploadFileList];

    for (const file of fileList) {
      const { name, size, type } = file;
      const mbSize = (size / (1024 * 1024)).toFixed(2) + 'mb';

      if (!allowImages(type) && !allowPdfFiles(type)) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'Please upload image file or pdf file.',
          };
        });

        return;
      }

      // ----------------------------------------------
      const fileExists = tempFileList.some((existingFile) => existingFile.fileName === name);

      if (!fileExists) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const readerResult = reader.result;

          tempFileList.push({
            fileName: name,
            fileSize: mbSize,
            mimeType: type,
            fileTextData: readerResult,
            fileData: file,
          });

          setUploadFileList(tempFileList);
          setFileCount(tempFileList.length);
        };
      }
    }
  };

  const deleteFile = (fileItem) => {
    let tempFileList = uploadFileList;

    let deleteFileList = tempFileList.filter((i) => i.fileName !== fileItem.fileName);
    setUploadFileList(deleteFileList);
  };

  return (
    <>
      <section className='contents'>
        <ul className='notice'>
          <li>We strive to foster a diverse and productive community of educators.</li>
          <li>To become a Gate⁺ Academy Administrator, you have to give us business credentials in order to verify your qualification.</li>
        </ul>
        <article className='guide'>
          <p className='tit'>Please upload a copy or a photo of your :</p>
          <ul className='list'>{type !== 'tutor' ? <li className='item'>Business Registration</li> : <li className='item'>Diploma or Student ID Card.</li>}</ul>
        </article>
        {/*파일 선택 전*/}

        {/* <div className={`${uploadFileList.length > 0 ? 'file_uploaded' : 'fileupload_area'}`}>
          <hr className="divider bar" />
          {
            uploadFileList.length > 0 && 
            <>
              <h2 className="tit">File Upload</h2>
              <div className="uploaded_files">
                <div className="file">
                  <i className="fileupload">&nbsp;</i>
                  <p className="name">img_2138712897-4er-b3479832742749.jpeg</p>
                  <button className="delete" onClick={ () => setDeletePop(true) }><i className="svg_icon icon_trashcan2 gray"></i></button>
                </div>
              </div>
            </>
          }
          <input type="file" className="upload_file" multiple id="fileInput" onChange={ e => handleFileInput(e) }/>
          {
            uploadFileList.length === 0 ? 
              <>
                <label htmlFor="fileInput" className="custom-file-label">첨부파일</label>
                <p className="txt"><b>Image</b> and/or <b>PDF</b> copy would suffice</p>
              </>
            :
              <label htmlFor="fileInput" className="custom-file-label uploaded">첨부파일</label>
          }
        </div> */}

        <div className={`${uploadFileList.length > 0 ? 'file_uploaded' : 'fileupload_area'}`}>
          {' '}
          {/* file_uploaded   fileupload_area */}
          <hr className='divider bar' />
          <h2 className='tit'>File Upload</h2>
          <div className='uploaded_files'>
            {uploadFileList != null && uploadFileList.length > 0
              ? uploadFileList?.map((fileItem, index) => (
                  <div key={`fileInfo_${index}`} className='file'>
                    <i className='fileupload'>&nbsp;</i>
                    <p className='name'>{fileItem.fileName}</p>
                    <button className='delete' onClick={() => deleteFile(fileItem)}>
                      <i className='svg_icon icon_trashcan2 gray'></i>
                    </button>
                  </div>
                ))
              : null}
          </div>
          <input type='file' className='upload_file' multiple id='fileInput' onChange={handleFileInput} />
          {uploadFileList == null || uploadFileList.length < 1 ? (
            <>
              <label
                htmlFor='fileInput'
                className='custom-file-label'
                onDragEnter={handleDragStart}
                onDragLeave={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                첨부파일
              </label>
              <p className='txt'>
                <b>Image</b> and/or <b>PDF</b> copy would suffice
              </p>
            </>
          ) : (
            <label
              htmlFor='fileInput'
              className='custom-file-label uploaded'
              onDragEnter={handleDragStart}
              onDragLeave={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}>
              첨부파일
            </label>
          )}
        </div>
      </section>
      {/*첨부파일 삭제 클릭 시 팝업*/}
      {deletePop && (
        <div className='popup type_alert active'>
          {' '}
          {/*활성화 시 addClass active*/}
          <div className='pop_container'>
            <div className='pop_body ok_contents'>
              <p className='alert_title'>
                Are you sure you want to
                <br />
                remove the file?
              </p>
              <div className='icon'>
                <img src={IconDel} alt='icon' />
              </div>
            </div>
            <div className='pop_footer many'>
              <button className='btn btn_stroke btn_middle' onClick={() => setDeletePop(false)}>
                Cancel
              </button>
              <button className='btn btn_fill btn_middle'>Delete</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Upload;
