import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { v4 as uuidv4 } from 'uuid';

const LineAreaChart = ({ buttons, setButtons, lineAreaChartData, dateArray, examCountData, studentCountData }) => {
  const getMaxValue = (data) => {
    const maxValue = Math.ceil(data[data.length - 1] / 10) * 10;
    return maxValue === 0 ? 10 : maxValue;
  };

  const studentCountMax = getMaxValue(studentCountData);
  const examCountMax = getMaxValue(examCountData);

  const [chartOptions, setChartOptions] = useState(
    {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        fontFamily: 'Roboto, Arial, sans-serif',
      },
      series: lineAreaChartData,
      colors: ['#D1D5DB', '#0068BD'],
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.7,
          stops: [0, 100],
        },
      },
      xaxis: {
        type: 'category',
        categories: dateArray,
        tickAmount: dateArray.length,
        tickPlacement: 'on',
        position: 'bottom',
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          title: {
            text: 'Students',
            rotate: 0, // 레이블을 가로로 표시
            offsetX: 47, // 오른쪽으로 이동
            offsetY: -160, // 아래로 이동
            style: {
              color: '#7F8998',
              //fontSize:'14px'
            },
          },
          labels: {
            formatter: function (value) {
              return Number.isInteger(value) ? value : value.toFixed(1);
              // return value.toFixed(1);
            },
          },
          min: 0,
          max: studentCountMax,
          tickAmount: 10,
        },
        {
          opposite: true,
          title: {
            text: 'Test',
            rotate: 0,
            offsetX: -30,
            offsetY: -160,
            style: {
              color: '#7F8998',
            },
          },
          labels: {
            formatter: function (value) {
              // return Math.round(value);
              return Number.isInteger(value) ? value : value.toFixed(1);
            },
          },
          min: 0,
          // tickAmount: examCountData[-1],
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0);
            }
            return y;
          },
        },
        annotations: [],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'top',
        fontSize: '12px',
        fontWeight: '700',
        color: '#000',
        fontFamily: 'Roboto, Arial, sans-serif',
        offsetX: 15,
        labels: {
          offsetX: 10,
        },
        markers: {
          width: 35,
          height: 6,
          offsetX: -2,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      grid: {
        strokeDashArray: 6,
      },
    },
    [buttons]
  );

  const changeData = (value) => {
    setButtons((prevButtons) =>
      prevButtons.map((button) => ({
        ...button,
        active: button.value === value,
      }))
    );
  };

  useEffect(() => {
    setChartOptions((prevOptions) => {
      return {
        ...prevOptions,
        xaxis: {
          categories: dateArray,
        },
        yaxis: [
          {
            ...prevOptions.yaxis[0],
            max: studentCountMax,
          },
          {
            ...prevOptions.yaxis[1],
            max: examCountMax,
          },
        ],
        series: [
          {
            name: 'CUMULATIVE STUDENTS',
            type: 'area',
            data: studentCountData,
          },
          {
            name: 'CUMULATIVE TESTS',
            type: 'area',
            data: examCountData,
          },
        ],
      };
    });
  }, [dateArray, studentCountData, examCountData, studentCountMax, examCountMax]);

  return (
    <div>
      <div className='com_btn_wrap'>
        <div className='com_tab'>
          {buttons?.map((item) => (
            <button key={'button_' + uuidv4()} className={item.active ? 'menu active' : 'menu'} onClick={() => changeData(item.value)}>
              {item.text}
            </button>
          ))}
        </div>
      </div>
      <ReactApexChart
        style={{ marginTop: '15px', marginBottom: '-25px' }}
        options={chartOptions}
        series={chartOptions.series}
        type='area'
        height={350}
        width={750}
        className='line_area_chart'
      />
    </div>
  );
};

export default LineAreaChart;
