import React, { useState } from 'react';
// import Calendar from 'react-calendar';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { getRBCEventColor } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';

// components
import Toolbar from './CalendarToolbar';
import DateHeader from './CalendarDateHeader';
import DateCellWrapper from './CalendarDateCellWrapper';

//css
import 'react-big-calendar/lib/css/react-big-calendar.css';

import dayjs from 'dayjs';
import dayjsTZ from 'utils/functions/time/dayjs-config';

dayjs.locale('en');
const localizer = dayjsLocalizer(dayjs);

export default function CalendarTable({ currentDate, setCurrentDate, events, setEvents, classInfo, setClassInfo }) {
  const [selectedEventTitle, setSelectedEventTitle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const today = dayjsTZ();

  const location = useLocation();

  const eventsForThisDay = (slotInfo) => {
    const { action, start, end, slots } = slotInfo;

    if (slots.length > 1) {
      slots.forEach((item) => {
        // console.log("기간 : " + dayjsTZ(item).format("YYYY-MM-DD"))
      });
    } else {
      // console.log("하루 : " + dayjsTZ(start).format("YYYY-MM-DD"))
    }

    setSelectedEventTitle(false);
    setSelectedEvent(null);
  };

  const selectDate = (event) => {
    setSelectedEventTitle(event.title);
    setSelectedEvent(event);
  };

  return (
    <div className='CalendarTable'>
      <Calendar
        defaultDate={location.state ? dayjsTZ(location.state.selectedDate).toDate() : currentDate}
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        defaultView='month'
        style={{ minHeight: 974 }}
        components={{
          toolbar: (props) => <Toolbar {...props} classInfo={classInfo} setClassInfo={setClassInfo} events={events} setEvents={setEvents} />,
          month: {
            dateHeader: DateHeader,
          },
          dateCellWrapper: DateCellWrapper,
        }} // 이 부분 삭제하면 default 옵션이 나옴
        onSelectSlot={(slotInfo) => {
          // console.log(slotInfo)

          eventsForThisDay(slotInfo);
        }}
        eventPropGetter={(event) => {
          const barColorInfo = dayjsTZ(event.end).isBefore(today)
            ? {
                name: 'gray',
                backgroundColor: '#CFCFCF',
                color: '#606060',
                borderColor: '#BEBEBE',
              }
            : getRBCEventColor().find((item) => item.name === event.classColor);

          return {
            style: {
              ...barColorInfo,
              marginBottom: '4px',
              borderWidth: '2px',
              borderStyle: 'solid',
              outline: event === selectedEvent ? '2px solid rgb(13, 101, 255, 0.7)' : 'none',
            },
          };
        }}
        onSelectEvent={selectDate}
        onDragStart={() => {
          return;
        }}
        selectable
        popup={true}
        onNavigate={(date, _) => {
          setCurrentDate(dayjsTZ(date).toDate());
        }}
        messages={{ showMore: (count) => `${count} More...` }}
      />
    </div>
  );
}
