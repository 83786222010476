import {
  UPDATE_SELECTED_USER_TYPE,
  UPDATE_SELECTED_CLASS,
  UPDATE_SELECTED_STUDENT_LIST,
  UPDATE_SELECTED_CONFIG,
  UPDATE_SELECTED_MODULES,
  UPDATE_SELECTED_DATE,
  CLEAR_SET_TESTS_STATE,
  UPDATE_TOTAL_GATE_KEY_STATE,
} from './actionTypes';

/** "Set Tests" 전역 상태 기본 값 */
const initialState = {
  setTests: {
    // Set Tests 사용자 선택 전역 상태
    selectedUserType: '', // Set Tests : 1단계 사용자 타입 선택 문자열 ("class" | "student")
    selectedClass: {
      // Set Tests : 2단계 반 선택
      classSeq: 0, // 반 seq
      className: '', // 반 이름 문자열
    },
    selectedStudents: [], // Set Tests : 3단계 학생 목록 선택 (기존 : 사용자 Seq -> 사용자 데이터 전체)
    selectedConfig: {
      // Set Tests : 4 과목 선택
      testConfiguration: 'F', // 과목 약자 문자열 ("F" = "Full" | "M" = "Math" | "RW" = "Reading and Writing")
      testType: true, // 난이도 Type boolean (true = "Adaptive" | false = "fixed")
      testRwDiff: false, // 영어 난이도 약자 문자열 ("E" = "Easy" | "H" = "Hard")
      testMathDiff: false, // 수학 난이도 약자 문자열 ("E" = "Easy" | "H" = "Hard")
    },
    selectedModules: [], // Set Tests : 5단계 모듈 선택 및 6단계 날짜/시간 선택
    totalGateKey: 0, // Set Tests 에서 선택한 상품의 총 가격(Key)
  },
};

/** "Set Tests" 전역 상태 Reducer" */
const stateSetTests = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_USER_TYPE:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedUserType: action.payload,
        },
      };
    case UPDATE_SELECTED_CLASS:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedClass: {
            ...state.setTests.selectedClass,
            ...action.payload,
          },
        },
      };
    case UPDATE_SELECTED_STUDENT_LIST:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedStudents: action.payload,
        },
      };
    case UPDATE_SELECTED_CONFIG:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedConfig: {
            ...state.setTests.selectedConfig,
            ...action.payload,
          },
        },
      };
    case UPDATE_SELECTED_MODULES:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedModules: action.payload,
        },
      };
    case UPDATE_SELECTED_DATE:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          selectedModules: state.setTests.selectedModules.map((item, index) => ({
            ...item,
            testTimeInfo: action.payload[index],
          })),
        },
      };
    case UPDATE_TOTAL_GATE_KEY_STATE:
      return {
        ...state,
        setTests: {
          ...state.setTests,
          totalGateKey: action.payload,
        },
      };
    case CLEAR_SET_TESTS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default stateSetTests;
