import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DirectionsPopup from './DirectionsPopup';
import useExitQuestion from 'components/exam/dsat/_hooks/useExitQuestion';
import useOutsideClick from 'hooks/useOutsideClick';
import Timer from 'components/exam/dsat/_components/timer/Timer';
import { deleteFullTimeToLocalStorage, deleteLocalStorageTime } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';

/** 수학 시험 화면 Header 영역 컴포넌트 */
export default function ExamHeader({ setAlertLayerPopup, tryAnswerRef, currentExamInfoRef, sendTimeRef }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const { fetch: fetchExitResultHistory } = useExitQuestion();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const examDivRef = useRef();
  const moreTooltipRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 타이머 종료 핸들러 */
  const timeOver = () => {
    console.log('수학 타임 오버');
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });

    deleteLocalStorageTime();

    if (stateExamInfo['currentTest'].mm1 === 'I' && stateExamInfo['currentTest'].mm2 === 'R') {
      // 모듈 1 타이머 종료
      // alert('타이머 종료 1');
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Time has run out. You have moved onto next section.',
        };
      });

      fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });
      // navigate('/exam/dsat/math', { state: { currentNum: 0 } }); // openQuestion 반응하게 하기 위함

      tryAnswerRef.current = [];
      currentExamInfoRef.current = [];

      /** 다음 시험 모듈 세팅 */
      let currentExamInfo = stateExamInfo.currentTest;
      currentExamInfo = { ...currentExamInfo, mm1: 'D', mm2: 'I' };
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'moduleOverDisplay', data: true } });

      // ----------------------------------------------------
    } else if (stateExamInfo['currentTest'].mm2 === 'I') {
      // 모듈 2 타이머 종료
      // alert('타이머 종료 2');

      let currentExamInfo = stateExamInfo.currentTest;
      currentExamInfo = { ...currentExamInfo, mm2: 'D' };
      deleteFullTimeToLocalStorage();
      console.log('모듈 2 타이머 종료');
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });

      navigate('/exam/dsat/completion', {
        replace: true,
      }); // 완료 처리는 Completion 페이지에서. 원래 endTest();
    }
  };

  // Hook - 툴팁 외부 클릭시 닫히게
  const handleCloseMoreTooltip = () => dispatch({ type: 'setExamComponents', payload: { fieldName: 'moreDisplay', data: false } });

  useOutsideClick(moreTooltipRef, handleCloseMoreTooltip, stateExamComponents.moreDisplay);
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  return (
    <section ref={examDivRef} className='pop_header'>
      <div className='tit_area'>
        <div className='tit'>Section 2, Module {stateExamInfo.questionList[stateExamInfo.currentNum - 1].moduleNum}: Math</div>
        <button
          className='btn'
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: !stateExamComponents.directionDisplay } })}>
          Directions<i className='svg_icon icon_arrow black'></i>
        </button>
        {stateExamComponents.directionDisplay && <DirectionsPopup dispatch={dispatch} stateExamComponents={stateExamComponents} examDivRef={examDivRef} />}
      </div>
      <div className={`time_area ${stateExamComponents.timeAreaDisplay ? '' : 'hide'}`}>
        <div className={`time ${stateExamComponents.runOutTime ? 'end' : ''}`}>
          <Timer timeOver={timeOver} sendTimeRef={sendTimeRef} />
        </div>
        <button
          className='btn_time'
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'timeAreaDisplay', data: !stateExamComponents.timeAreaDisplay } })}>
          Hide
        </button>
      </div>
      <div className='btn_area'>
        <button
          className={`btn ${stateExamComponents.cpDisplay ? 'active' : ''}`}
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'cpDisplay', data: !stateExamComponents.cpDisplay } })}>
          <i className='svg_icon icon_calculator'>&nbsp;</i>
          Calculator
        </button>
        <button
          className={`btn ${stateExamComponents.rspDisplay ? 'active' : ''}`}
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'rspDisplay', data: !stateExamComponents.rspDisplay } })}>
          <i className='svg_icon icon_reference'>&nbsp;</i>
          Reference
        </button>
        <button
          className={`btn ${stateExamComponents.moreDisplay ? 'active' : ''}`}
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'moreDisplay', data: !stateExamComponents.moreDisplay } })}>
          <i className='svg_icon icon_more'>&nbsp;</i>
          More
        </button>
        {stateExamComponents.moreDisplay && (
          <div className='more_layer' ref={moreTooltipRef}>
            <button className='item' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'helpDisplay', data: true } })}>
              <i className='svg_icon icon_help'>&nbsp;</i>Help
            </button>
            <button className='item' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: true } })}>
              <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
