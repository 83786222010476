import styled from 'styled-components';

// import SimpleModalBase from './_SimpleModalBase'

import iconDel from '../../../assets/img/icon_del.svg';
import SimpleModalBase from './_SimpleModalBase';
import { useState } from 'react';
import request from 'utils/Request.utils';
import LoadingBar from 'utils/LoadingBar';

const defaultTitle = 'Are you sure you want to delete?';

/** 삭제 모달 공통 컴포넌트 */
function DeleteModal({ title = defaultTitle, text, hideModal, callback }) {
  return (
    <SimpleModalBase onEscape={hideModal}>
      <h2>{title}</h2>
      <div className='modal_img_wrapper'>
        <img src={iconDel} />
      </div>
      {text && (
        <div className='modal_text_container'>
          <p>{text}</p>
        </div>
      )}

      <div className='modal_btn_container'>
        <button onClick={hideModal}>Back</button>
        <button onClick={callback} className='btn_fill'>
          Delete
        </button>
      </div>
    </SimpleModalBase>
  );
}

export default DeleteModal;
