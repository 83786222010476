import React, { useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import PopTestList from '../../../_PopTestList';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역) - 상단 영역 (셀렉트, Test List, 텝 등) 컴포넌트 */
function TopArea({ setScoreStats, classTestTaken, scoreStats, getPercentileList }) {
  const [classList, setClassList] = useState(false);
  const [tableListActive, setTableListActive] = useState(false);

  // 외부 클릭시 닫히는 훅
  const classListBox = useRef(null);

  /** 반 선택 */
  const handleClass = (e, classSeq, className) => {
    e.stopPropagation();
    setScoreStats((prev) => {
      return { ...prev, className: className };
    });
    setClassList(false);

    classTestTaken(classSeq);
  };

  /** 시험 선택 */
  const tableListToggleButtonClick = () => {
    setTableListActive((current) => !current);
  };

  useOutsideClick(classListBox, () => setClassList(false));

  return (
    <>
      <article className='com_top'>
        <div className={`com_select_layer type_tit type_big ${classList ? 'active' : ''}`} onClick={() => setClassList((prev) => !prev)} ref={classListBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            <p className='tit_field'>Class</p>
            <input type='text' className='selected' placeholder={scoreStats.className} />
          </section>
          <section className='layer'>
            {scoreStats.classList &&
              scoreStats.classList?.map((item, index) => (
                <p key={item.classSeq} className='option' onClick={(e) => handleClass(e, item.classSeq, item.className)}>
                  {item.className}
                </p>
              ))}
          </section>
        </div>
        {scoreStats?.takenTest == null || scoreStats.takenTest?.length < 1 ? (
          <p className='tit'>No available test</p>
        ) : (
          <p className='tit'>{scoreStats?.testName}</p>
        )}
        <button className='com_btn l black btn_list' onClick={tableListToggleButtonClick}>
          TEST LIST<i className='svg_icon after icon_list_white'>&nbsp;</i>
        </button>
      </article>
      {tableListActive && (
        <PopTestList
          close={() => setTableListActive(false)}
          className={scoreStats.className}
          testTakenList={scoreStats.takenTest}
          clickEvent={getPercentileList}
        />
      )}
    </>
  );
}

export default TopArea;
