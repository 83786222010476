import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants';

export default function MiniCalendar({ pathname, child }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const [dateValue, setDateValue] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(moment(new Date()));
  const [mark, setMark] = useState([
    {
      testStartData: '2024-02-10',
      testEndDate: '2024-02-12',
      color: 'red',
    },
  ]);

  const childEcSeq = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        const childInfo = response.result.childInfo;

        if (nvl(childInfo) !== '' && childInfo.length > 0) {
          const childEcSeq = childInfo[0].ecSeq;
          const childUserSeq = childInfo[0].userSeq;

          getCalendarData(childEcSeq, childUserSeq);
        }
      }
    };

    request.get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler);
  };

  const getCalendarData = (ecSeq, userSeq = userInfo.userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result.scheduleInfo;

        setMark(
          scheduleInfo.map((item) => {
            return {
              testStartDate: item.testStartDate.split(' ')[0],
              testEndDate: item.testEndDate.split(' ')[0],
              classColor: item.classColor,
            };
          })
        );
      }
    };
    const searchStartDate = currentDate.clone().subtract(1, 'months').format('YYYY-MM') + '-01';
    const searchEndDate = currentDate.clone().add(2, 'months').format('YYYY-MM') + '-01';

    if (userInfo.userLevel === USER_LEVELS.ACADEMY || userInfo.userLevel === USER_LEVELS.TUTOR || userInfo.userLevel === USER_LEVELS.TEACHER) {
      // 학원관리자, 선생님, 튜터
      request.get(`/api/dsat/calendar/schedules?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`, null, successHandler);
    } else {
      // 학부모, 학생
      request.get(
        `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&userSeq=${userSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`,
        null,
        successHandler
      );
    }
  };

  useEffect(() => {
    if (userInfo.userLevel === USER_LEVELS.PARENT) childEcSeq();
    else getCalendarData(userInfo.ecSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markMapping = {};
  const getDatesBetween = (startDate, endDate, color) => {
    if (markMapping[startDate] === undefined) markMapping[startDate] = [];
    if (moment(endDate) < dateValue) return;

    markMapping[startDate] = [color, ...markMapping[startDate]];
  };
  mark.forEach((item) => {
    getDatesBetween(item.testStartDate, item.testEndDate, item.classColor);
  });

  return (
    <div className='com_calendar_wrap'>
      <Calendar
        onChange={setDateValue}
        onActiveStartDateChange={({ activeStartDate }) => {
          setCurrentDate(moment(activeStartDate));
        }}
        formatDay={(locale, date) => moment(date).format('DD')}
        locale='en-EN'
        value={dateValue}
        className='calendar'
        tileContent={({ date, view }) => {
          const formattedDate = moment(date).format('YYYY-MM-DD');
          if (markMapping[formattedDate]) {
            return (
              <div className='icon_schedule__wrap'>
                {markMapping[formattedDate].slice(0, 3).map((color, index) => {
                  return <div key={index} className={`icon_schedule ${color}`}></div>;
                })}
              </div>
            );
          }
        }}
        onClickDay={(date) => {
          // console.log("클릭한 날짜:", moment(date).format("YYYY-MM-DD"));
          if (pathname.indexOf('dashboard') !== -1) {
            // pathname에 "main"이 포함되어 있을 때만 navigate 실행
            navigate('/com/tests/calendar', {
              state: { selectedDate: moment(date).format('YYYY-MM-DD') },
            });
          }
        }}
      />
    </div>
  );
}
