import React, { useCallback, useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import PopTestList from '../_PopTestList';

/** "/com/score" 페이지 상단 영역 (셀렉트, Test List, 텝 등) 컴포넌트 */
function TopArea({ setScorePageInfo, classTestTaken, scorePageInfo, classAvgPoint, tabState, setTabState, handleSelectStudent }) {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 ////////////
  const tab = [
    { text: 'Class', value: 0 },
    { text: 'Student', value: 1 },
    { text: 'Lecture', value: 2 },
  ];
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [tableListActive, setTableListActive] = useState(false);
  const [classList, setClassList] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const classListBox = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const handleClass = (e, classSeq, className) => {
    e.stopPropagation();
    setScorePageInfo((prev) => {
      return { ...prev, className: className };
    });
    setClassList(false);

    classTestTaken(classSeq);
  };

  const tableListToggleButtonClick = () => {
    setTableListActive(!tableListActive);
  };

  const tabBtn = (tab) => {
    if (tab === 1) {
      if (scorePageInfo.memberList && scorePageInfo.memberList.length > 0) {
        handleSelectStudent(
          scorePageInfo.memberList[0].uthSeq,
          scorePageInfo.memberList[0].userSeq,
          scorePageInfo.memberList[0].rwPoint,
          scorePageInfo.memberList[0].mathPoint
        );
      }
    }
    setTabState(tab);
  };

  const renderTab = useCallback(() => {
    return tab?.map((v, idx) => {
      return (
        <button key={idx} className={`menu ${tabState === v.value ? 'active' : ''}`} onClick={() => tabBtn(v.value)}>
          {v.text}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 //////////////////
  // 외부 클릭시 닫히는 훅
  useOutsideClick(classListBox, () => setClassList(false));
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  return (
    <>
      <article className='com_top'>
        <div className={`com_select_layer type_tit type_big ${classList ? 'active' : ''}`} onClick={() => setClassList((prev) => !prev)} ref={classListBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            <p className='tit_field'>Class</p>
            <input type='text' className='selected' placeholder={scorePageInfo.className} />
          </section>
          <section className='layer'>
            {scorePageInfo.classList &&
              scorePageInfo.classList?.map((item, index) => (
                <p key={item.classSeq} className='option' onClick={(e) => handleClass(e, item.classSeq, item.className)}>
                  {item.className}
                </p>
              ))}
            {/* <p className="option" onClick={ (e) => handleOptionClick(e, 0, "Class All") }>Class All</p> */}
          </section>
        </div>
        {scorePageInfo?.takenTest == null || scorePageInfo.takenTest?.length < 1 ? (
          <p className='tit'>No available test</p>
        ) : (
          <p className='tit'>{scorePageInfo.testName} </p>
        )}
        <button className='com_btn l black btn_list' onClick={tableListToggleButtonClick}>
          TEST LIST<i className='svg_icon after icon_list_white'>&nbsp;</i>
        </button>

        <div className='com_tab'>{renderTab()}</div>
      </article>
      {tableListActive && (
        <PopTestList
          close={() => setTableListActive(false)}
          className={scorePageInfo.className}
          testTakenList={scorePageInfo?.takenTest}
          clickEvent={classAvgPoint}
        />
      )}
    </>
  );
}

export default TopArea;
