import LocalStorage from 'utils/LocalStorage.utils.js';

//////////////////////  타이머 관련 로컬 스토리지 유틸 함수들 /////////////

/** 로컬 스토리지에 "전체" 시간 저장하기 */
export const setFullTimeToLocalStorage = ({ fullTime }) => {
  // console.log('setFullTimeToLocalStorage');
  LocalStorage.setItem('testGateExamTimerFullTime', 'fullTime', `${fullTime}`);
};
/** 로컬 스토리지에 "남은" 시간 저장하기 */
export const setRestTimeToLocalStorage = ({ restTime }) => {
  // console.log('setRestTimeToLocalStorage');
  LocalStorage.setItem('testGateExamTimerRestTime', 'restTime', `${restTime}`);
};
/** 로컬 스토리지에 "지난" 시간 저장하기 */
export const setTimeDiffToLocalStorage = ({ timeDiff }) => {
  // console.log('setTimeDiffToLocalStorage');
  LocalStorage.setItem('testGateExamTimerTimeDiff', 'timeDiff', `${timeDiff}`);
};
/** 로컬 스토리지에 "재 접속 시 남은" 시간 저장하기 */
export const setInProgressToLocalStorage = ({ inProgressTime }) => {
  // console.log('setTimeDiffToLocalStorage');
  LocalStorage.setItem('testGateExamTimerInProgress', 'inProgressTime', `${inProgressTime}`);
};
/** 로컬 스토리지에 "진입" 시간 저장하기 */
export const setEntryTimeToLocalStorage = ({ entryTime }) => {
  console.log('setEntryToLocalStorage');
  LocalStorage.setItem('testGateExamTimerEntryTime', 'entryTime', `${entryTime}`);
};

/** 로컬 스토리지에서 "전체" 시간 가져오기 */
export const getFullTimeFromLocalStorage = () => {
  const fullTime = LocalStorage.getItemJsonParse('testGateExamTimerFullTime')?.fullTime;
  return fullTime ? parseInt(fullTime, 10) : null;
};
/** 로컬 스토리지에서 "남은" 시간 가져오기 */
export const getRestTimeFromLocalStorage = () => {
  const restTime = LocalStorage.getItemJsonParse('testGateExamTimerRestTime')?.restTime;
  return restTime ? parseInt(restTime, 10) : null;
};
/** 로컬 스토리지에서 "지난" 시간 가져오기 */
export const getTimeDiffFromLocalStorage = () => {
  const timeDiff = LocalStorage.getItemJsonParse('testGateExamTimerTimeDiff')?.timeDiff;
  return timeDiff ? parseInt(timeDiff, 10) : null;
};
/** 로컬 스토리지에서 "재 접속 시 남은" 시간 가져오기 */
export const getTimeInProgressLocalStorage = () => {
  const inProgressTime = LocalStorage.getItemJsonParse('testGateExamTimerInProgress')?.inProgressTime;
  return inProgressTime ? parseInt(inProgressTime, 10) : null;
};
/** 로컬 스토리지에서 "진입" 시간 가져오기 */
export const getTimeEntryTimeLocalStorage = () => {
  const entryTime = LocalStorage.getItemJsonParse('testGateExamTimerEntryTime')?.entryTime;
  return entryTime ? parseInt(entryTime, 10) : null;
};

/** 로컬 스토리지에 "전체" 시간 지우기 */
export const deleteFullTimeToLocalStorage = () => {
  // console.log('deleteFullTimeToLocalStorage');
  LocalStorage.clearItem('testGateExamTimerFullTime', 'fullTime');
};
/** 로컬 스토리지에 "남은" 시간 지우기 */
export const deleteRestTimeToLocalStorage = () => {
  LocalStorage.clearItem('testGateExamTimerRestTime', 'restTime');
};
/** 로컬 스토리지에 "지난" 시간 지우기 */
export const deleteTimeDiffToLocalStorage = () => {
  LocalStorage.clearItem('testGateExamTimerTimeDiff', 'timeDiff');
};
/** 로컬 스토리지에 "재 접속 시 남은" 시간 지우기 */
export const deleteInProgressToLocalStorage = () => {
  LocalStorage.clearItem('testGateExamTimerInProgress', 'inProgressTime');
};
/** 로컬 스토리지에 "재 접속 시 남은" 시간 지우기 */
export const deleteEntryToLocalStorage = () => {
  LocalStorage.clearItem('testGateExamTimerEntryTime', 'entryTime');
};

/** 로컬 스토리지에 시간 관련 데이터를 지우는 함수 모음 */
export const deleteLocalStorageTime = () => {
  deleteRestTimeToLocalStorage();
  deleteTimeDiffToLocalStorage();
  deleteFullTimeToLocalStorage();
  deleteInProgressToLocalStorage();
  deleteEntryToLocalStorage();
};