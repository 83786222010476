import React, { useState, useEffect, useRef } from 'react';
import convert24Hour from 'utils/functions/time/convert24Hour';

const timeModalBackgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function CalendarSetTimeModal({ close, confirm, btnType, setTimeLayer, setSelectedTime }) {
  const layerRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (layerRef.current && !layerRef.current.contains(event.target)) {
        setTimeLayer(false);
        setSelectedTime('00:00');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [timeSelect, setTime] = useState(false); //time select box
  const [positionStyle, setPositionStyle] = useState({});
  const [selectTime, setSelectTime] = useState('12:00 AM');

  const handleSetTime = (event) => {
    setSelectTime(event.target.textContent);
    setSelectedTime(event.target.textContent);
    // setSelectedTime(convert24Hour(event.target.textContent));
  };

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? '00' : '30';
    return `${hour % 12 === 0 ? 12 : hour % 12 < 10 ? '0' + (hour % 12) : hour % 12}:${minute} ${hour < 12 || hour === 24 ? 'AM' : 'PM'}`;
  });

  return (
    <div style={timeModalBackgroundStyle}>
      <div className='com_time_layer active' onClick={(e) => e.stopPropagation()} style={positionStyle} ref={layerRef}>
        <div className='layer_tit'>Start Time</div>
        <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => setTime((prev) => !prev)}>
          <section className='selected_wrap'>
            <input className='selected' placeholder={selectTime || '12 : 00 AM'} />
          </section>
          <section className='layer scroll' onClick={handleSetTime}>
            {timeOptions.map((time, index) => (
              <p key={index} className='option'>
                {time}
              </p>
            ))}
          </section>
        </div>

        <div className='com_btn_wrap bottom'>
          {btnType === 'skip' ? (
            <button className='com_btn line point m full' onClick={close}>
              Skip
            </button>
          ) : (
            <></>
          )}
          <button className='com_btn point m full' onClick={confirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalendarSetTimeModal;
