import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

/** MathJax의 onTypeset가 호출되지 않았을 때 ( 수학 공식 랜더링 안됨 ), 알림 컴포넌트 */
function MathJaxAlert() {
  const dispatch = useDispatch();
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  const handleCloseBtn = () => {
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
  };
  const handleRefreshBtn = () => {
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
    window.location.reload();
  };

  return (
    <S.MathJaxAlertContainer className={`exam_alert ${stateExamComponents.isMathJaxError ? 'active' : ''}`}>
      <div className='tit'>Issues with Display?</div>
      <div>Please click on Refresh button to refresh the page to resolve the display issue.</div>
      <div>If you are not having an issue, you can ignore this message.</div>
      <S.AlertButtonSection>
        <button className='cancel' onClick={handleCloseBtn}>
          Cancel
        </button>
        <button onClick={handleRefreshBtn}>Refresh</button>
      </S.AlertButtonSection>
    </S.MathJaxAlertContainer>
  );
}

export default MathJaxAlert;
// --- --- ---

const S = {}; // styled-components 객체(S-dot 컨벤션용)

S.MathJaxAlertContainer = styled.div`
  display: none;
  position: absolute;
  top: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  z-index: 9991;
  width: 34.5rem;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  .tit {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &.active {
    display: block;
  }
`;

S.AlertButtonSection = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  button {
    border-radius: 5px;
    background-color: white;
    width: 80%;
    margin: 0 auto;
    padding: 0.5rem 0;

    &.cancel {
      background-color: black;
      color: white;
      border: 1px solid white;
    }
  }
`;
