/**
 * props로 이미지와 alertText와 title을 포함하고, 사용할 텍스트와 이벤트를 받아 랜더링한다.
 * @param {{ alertType:string; modalImage: string; alertText: string; alertTitle: string; buttonTextAndOnClick: Array<{text: string, onClick: () => void}>; showModal: Boolean }} props
 * @returns {JSX.Element}
 */

export default function ImageModal({ alertType = 'okOnly', modalImage, alertTitle, alertText, buttonTextAndOnClick, showModal }) {
  const renderButtons = () => {
    if (alertType === 'confirm' && buttonTextAndOnClick.length >= 2) {
      return buttonTextAndOnClick.slice(0, 2).map((button, index) => (
        <button key={index} className='com_btn point xl' onClick={button.onClick}>
          {button.text}
        </button>
      ));
    } else if (alertType === 'okOnly' && buttonTextAndOnClick.length > 0) {
      return (
        <button className='com_btn point xl' onClick={buttonTextAndOnClick[0].onClick}>
          {buttonTextAndOnClick[0].text}
        </button>
      );
    }
    return null;
  };

  const renderAlertText = () => {
    // '\\n'을 실제 줄바꿈으로 변환(이유 모를 에러 때문에 추가함)
    const processedText = alertText.replace(/\\n/g, '\n');

    return processedText.split('\n').map((line, index) => (
      <p key={index} style={{ whiteSpace: 'pre-line' }}>
        {line}
      </p>
    ));
  };

  return (
    <div className={`com_popup type_alert ${showModal ? 'active' : ''}`}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>{alertTitle}</p>
          <div style={{ margin: '2rem 0' }}>
            <img src={modalImage} alt='icon' />
          </div>
          <div className='alert_text'>{renderAlertText()}</div>
        </div>
        <div className='pop_footer com_btn_wrap many'>{renderButtons()}</div>
      </div>
    </div>
  );
}
