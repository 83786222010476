import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import request from 'utils/Request.utils';
import { getRestTimeFromLocalStorage } from '../../_utils/functions/timerLocalStorageFunctions';

/** "영어 시험" 페이지의 "Exit" 팝업 컴포넌트 */
export default function ExitPopup({ isLoading, setIsLoading }) {
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();

  /** 시험 그만 하기 버튼 핸들러 */
  const interruptedExam = () => {
    let params = {
      uthSeq: stateExamInfo.uthSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } });
        if (stateExamComponents.exitExamDisplay && restTime > 1) exitExam();
      }
    };

    if (!isLoading) {
      setIsLoading(true);
      // 중단한 시험 처리
      request.post('/api/exam/interruptedExam', params, successHandler).catch((error) => {
        console.error(error);
        alert('영어 시험 - 시험 그만 하기 버튼 API 응답 실패');
      });
    }
  };

  /** 시험 종료 및 페이지 닫기 실제 동작 */
  const exitExam = () => {
    let params = {
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch({ type: 'clearExamInfo' });
        window.self.close();
        setIsLoading(false);

        if (window.opener) window.opener.location.reload();
      }
    };

    // 시험 일정 수정
    request.put(`/api/exam/userTestHistory/${stateExamInfo.uthSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('영어 시험 - 시험 종료 및 페이지 닫기 API 응답 실패');
    });
  };

  return (
    <div className='com_popup type_alert active'>
      <div className='pop_container l'>
        <button
          className='svg_icon btn_pop_close'
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } })}>
          &nbsp;
        </button>

        <div className='pop_body'>
          <div className='alert_title'>Do you really want to exit the exam?</div>
          <div className='alert_text'>
            If you exit now, you will be graded only on what you have solved until this point, and will <b>NOT</b> be able to return to the test.
          </div>
        </div>
        <div className='pop_footer com_btn_wrap'>
          <button className='com_btn m gray oval' onClick={interruptedExam} disabled={isLoading}>
            I understand. Exit
          </button>
          <button
            className='com_btn m yellow oval'
            onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: false } })}>
            Continue the exam
          </button>
        </div>
      </div>
    </div>
  );
}
