// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingBar {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9998;
  margin-left: -21px;
  margin-top: -21px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/LoadingBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".LoadingBar {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  z-index: 9998;\n  margin-left: -21px;\n  margin-top: -21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
