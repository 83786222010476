// Packages
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MathJax } from 'better-react-mathjax';
// Assets
import noInternetIcon from 'assets/img/svg/no_internet_icon.svg';
import 'styles/css/exam.css';
// Components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import ModuleOver from '../_components/ModuleOver';
import HelpPopup from '../_components/popups/HelpPopup';
import ReferenceSheetPopup from './_components/popups/ReferenceSheetPopup';
import CalculatorPopup from './_components/popups/CalculatorPopup';
import ExitPopup from './_components/popups/ExitPopup';
import FiveMinutesAlert from '../_components/alerts/FiveMinutesAlert';
import useExitQuestion from '../_hooks/useExitQuestion';
import ExamHeader from './_components/examLayout/examHeader/ExamHeader';
import ExamBody from './_components/examLayout/examBody/ExamBody';
import ExamFooter from './_components/examLayout/examFooter/ExamFooter';
// Components - Modal
import ImageModal from 'components/_common/modals/ImageModal';
import ExamLoadingModal from 'components/_common/modals/ExamLoadingModal';
// Functions
import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils.js';
// hook
import useOnlineStatus from 'hooks/useOnlineStatus.js';
import useCheckBrowserVisibility from '../_hooks/useCheckBrowserVisibility';
import IntroModal from './_components/IntroModal';

/** 수학 문제 주관식만 푸는 페이지 */
export default function ExamMathPage() {
  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지

  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////////
  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /** 오프라인시 창 닫기 알림 */
  const offlineModalInfo = {
    modalImage: noInternetIcon,
    alertTitle: 'Unstable network connection',
    alertText:
      'Your network connection is low or unstable.\nTest window will be closed, but all your work will be saved.\nYou can continue your test when your network becomes\nstable.',
    buttonTextAndOnClick: [{ text: 'OK', onClick: () => window.close() }],
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  /** 온/오프라인 상태 체크 */
  const isOnline = useOnlineStatus();
  const userInfo = request.tokenDecoder();
  const { fetch: fetchExitResultHistory } = useExitQuestion();
  /** 로컬 스토리지 : sendParams */
  const sendParams = LocalStorage.getItemJsonParse('sendParams');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  /** 수학 주관식 문제만 있는 시험 */
  const [userOnlySubjectTestList, setUserOnlySubjectTestList] = useState([]);
  /** 현재 페이지 컴포넌트의 모든 로딩 상태(한 번에 하나의 로딩만 진행 되는 동기적 요청 로딩) */
  const [isLoading, setIsLoading] = useState(false);
  /** Intro Modal 보여주는지 여부 */
  const [isIntroModal, setIsIntroModal] = useState(true);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 제출 시간 (다음 모듈로 넘어 갈 때, 시간을 다 썼을 때, 쉬는 시간 끝났 을 때, 시험 창 재진입 할 때 설정 됨) */
  const sendTimeRef = useRef(LocalStorage.getItemJsonParse('sendTime'));
  const tryAnswerRef = useRef([]);
  /** 문제 번호 별 기억용 */
  const currentExamInfoRef = useRef([]);

  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 실행문 시작 ////////////
  useCheckBrowserVisibility();
  /////////////////// 외부 패키지 및 기타 React Hook 실행문 끝 ////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setIsIntroModal(false);
  };
  /** 문제 목록 전체 불러오기 API 요청 */
  const getQuestionList = ({ sendParams }) => {
    setIsLoading(true);

    const successHandler = (response) => {
      if (response.code === 200) {
        let simpleExamList = response.result.simpleExamList;

        setUserOnlySubjectTestList(simpleExamList);
        dispatch({
          type: 'setExamQuestionList',
          payload: simpleExamList,
        });

        setIsLoading(false);
      } else if (response.code === 403) {
        // navigate("/student/dashboard");
        console.log('권한없음');
      }
    };

    request.get(`/api/exam/subjective?uthSeq=${sendParams.uthSeq}`, null, successHandler).catch((error) => {
      console.error(error);
      alert('시험 - 수학 주관식만 보는 시험 - 문제 목록 전체 불러오기 API 응답 실패');
    });
  };

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 다음 모듈 & 다음 색션 넘어 갈 때 동작 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });

      if (alertLayerPopup.activateMode === 'nextSection') {
        // 시험 끝났을 때
        fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });

        let currentExamInfo = stateExamInfo.currentTest;
        currentExamInfo = { ...currentExamInfo, mm2: 'D' };
        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });

        navigate('/exam/dsat/completion', {
          replace: true,
        }); // 완료 처리는 Completion 페이지에서
      }
    }
  };

  /** 모듈 1이 끝나고, 시간 설정 */
  const resetTime = (currentTime) => {
    sendTimeRef.current.time = currentTime;
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    // 문제 불러오기
    navigate('/exam/dsat/mathSubjective', { state: { currentNum: 1 } }); // openQuestion 반응하게 하기 위함
    dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: 1 } });

    getQuestionList({ sendParams: sendParams });

    return () => {
      // 현재 페이지 컴포넌트 언마운트 useEffect
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 모듈 내에서 시험 문제를 이동 할 때 useEffect

    return () => {
      if (nvlNumber(state?.currentNum) > 0) {
        fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.currentNum]);

  useEffect(() => {
    console.log('🚨 alertLayerPopup :', alertLayerPopup, '🚨');
  }, [alertLayerPopup]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <MathJax hideUntilTypeset='first'>
        <div
          onContextMenu={(e) => e.preventDefault()}
          className={`com_popup pop_exam active ${stateExamComponents.directionDisplay ? 'direction_on' : ''} ${stateExamComponents.cpDisplay && stateExamComponents[`${stateExamComponents.fieldName}`]?.questionFormat !== 'S' ? 'calculator_on' : ''}`}>
          <div className='pop_container'>
            <ExamHeader setAlertLayerPopup={setAlertLayerPopup} tryAnswerRef={tryAnswerRef} currentExamInfoRef={currentExamInfoRef} sendTimeRef={sendTimeRef} />
            {userOnlySubjectTestList && userOnlySubjectTestList.length > 0 && (
              <>
                <ExamBody tryAnswerRef={tryAnswerRef} isLoading={isLoading} setIsLoading={setIsLoading} dummyData={userOnlySubjectTestList} />
                <ExamFooter isLoading={isLoading} setAlertLayerPopup={setAlertLayerPopup} dummyData={userOnlySubjectTestList} />
              </>
            )}
            {/*완료 5분전 alert*/}
            <FiveMinutesAlert />
          </div>
        </div>
        {/*help 팝업*/}
        {stateExamComponents.helpDisplay && <HelpPopup />}
        {/*exit 팝업*/}
        {stateExamComponents.exitExamDisplay && <ExitPopup isLoading={isLoading} setIsLoading={setIsLoading} />}
        {alertLayerPopup.visible && (
          <CustomAlert
            onClose={closeCustomAlert}
            alertType={alertLayerPopup.alertType}
            alertMessage={alertLayerPopup.alertMessage}
            returnValue={returnAlertValue}
          />
        )}
        {stateExamComponents.moduleOverDisplay && <ModuleOver closeCustomAlert={closeCustomAlert} subject='M' resetTime={resetTime} />}
        <CalculatorPopup />
        {stateExamComponents.rspDisplay && <ReferenceSheetPopup />}
      </MathJax>
      <ImageModal
        modalImage={offlineModalInfo.modalImage}
        buttonTextAndOnClick={offlineModalInfo.buttonTextAndOnClick}
        alertTitle={offlineModalInfo.alertTitle}
        alertText={offlineModalInfo.alertText}
        showModal={!isOnline}
      />
      {isLoading && <ExamLoadingModal />}
      {isIntroModal && <IntroModal handleClose={handleClosePopup} />}
    </>
  );
}
