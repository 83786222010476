import React from 'react';
import CountUp from 'react-countup';
import ScoreRadialBarChart from 'components/_common/chart/ScoreRadialBarChart';

/** "/com/score" 페이지의 "Scores" 차트 영역 컴포넌트 */
export default function ScoresChartSection({ testResult, scoreChartData1, scoreChartData2 }) {
  return (
    <article className='box score'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_graph black'>&nbsp;</i>
          Scores
        </h2>
      </div>
      <div className='com_donut_chart'>
        <div className='chart_area'>
          <div className='total'>
            <CountUp start={0} end={testResult?.rwCurrentScore} duration={3} className='num_field' />
            <p className='subject'>RW</p>
          </div>
        </div>
        <ScoreRadialBarChart scoreChartData={scoreChartData1} />
      </div>
      <div className='com_donut_chart'>
        <div className='chart_area'>
          <div className='total green'>
            <CountUp start={0} end={testResult?.mathCurrentScore} duration={3} className='num_field' />
            <p className='subject'>Math</p>
          </div>
        </div>
        <ScoreRadialBarChart scoreChartData={scoreChartData2} />
      </div>
    </article>
  );
}
