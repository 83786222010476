import { useRef, useState } from 'react';
import styled from 'styled-components';
import imgArrow from './../../../assets/gate_member/image/chevron_down.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';

/** 임시 : School Location 셀렉트 박스를 위한 컴포넌트 */
export default function SchoolLocationSelectBox({ register, handleChange, placeholder = '', optionList = [{ name: '' }] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [locationCode, setLocationCode] = useState('');
  const [schoolLocation, setSchoolLocation] = useState('');
  const refSelect = useRef();
  const schoolLocationRef = useRef('');
  const handleLocationSelect = (selectedLocation, locationCode) => {
    schoolLocationRef.current.value = selectedLocation;
    setLocationCode(locationCode);
    setIsOpen(!isOpen);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && schoolLocationRef.current.value.length > 0) {
      const filterList =
        optionList.filter((location) => location.schoolLocation.toLowerCase().includes(schoolLocationRef.current.value.toLowerCase()))[0] || [];
      handleLocationSelect(filterList.schoolLocation, filterList.commonCode);
    }
  };
  const changeHandleLocation = () => {
    setSchoolLocation(schoolLocationRef.current.value);
  };

  // 외부 영역 클릭 시, select box 닫기 Hook
  useOutsideClick(refSelect, () => setIsOpen((current) => !current), isOpen);

  return (
    <SelectBoxWrap>
      <div className='select_box' ref={refSelect} onClick={() => setIsOpen(!isOpen)}>
        <input type='text' {...register} ref={schoolLocationRef} placeholder={placeholder} onKeyDown={handleKeyDown} onChange={changeHandleLocation} />
        <ul className={`option_wrap ${isOpen ? 'active' : ''}`}>
          {optionList &&
            optionList
              .filter((location) => location.schoolLocation.toLowerCase().includes(schoolLocation.toLowerCase()))
              .map((item, index) => (
                <li
                  className='option'
                  key={`location_${index}`}
                  onClick={() => {
                    handleLocationSelect(item.schoolLocation, item.commonCode);
                    handleChange(register.name, item);
                  }}>
                  {item.schoolLocation}
                </li>
              ))}
        </ul>
      </div>
    </SelectBoxWrap>
  );
}

const SelectBoxWrap = styled.div`
  .select_box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    transition: 0.3s;
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0.625rem;
      top: calc(50% - 0.775rem);
      width: 1.5rem;
      height: 1.5rem;
      background: url(${imgArrow});
    }
    .select_value {
      line-height: 0.75rem;
      color: #000;
      font-size: 0.875rem;
    }
    .option_wrap {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      width: 100%;
      max-height: 9.375rem;
      overflow-y: auto;
      border-bottom: 1px solid #d2dbe2;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.1490196078);
      margin-top: 1px;
      text-align: left;
      display: none;
      &.active {
        display: block;
      }
      .option {
        font-size: 0.875rem;
        line-height: 0.75rem;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid #d2dbe2;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
  .error_message {
    display: none;
    text-align: left;
  }
`;
