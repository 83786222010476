import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ScoreRadialBarChart = ({ scoreChartData }) => {
  const options = {
    series: scoreChartData.series,
    chart: {
      type: 'radialBar',
      colors: ['#949494'],
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: false,
            offsetY: 6,
            fontSize: '13px',
            color: '#111',
            fontWeight: 'normal',
          },
          value: {
            show: false,
          },
        },
        hollow: {
          size: '50%', //그래프 두께 조절
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '5px',
      },
    },
    fill: {
      colors: scoreChartData.colors,
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={options} series={options.series} type='radialBar' height={253} />
    </div>
  );
};

export default ScoreRadialBarChart;
