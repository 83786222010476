import { nvl } from 'utils/Common.utils';

export default function MathM2EasyTestQuestion({ userLevel, item, idx, mathTestDiff, answerCheck }) {
  return (
    <article className={`question_sec ${item.isTooLong | item.lastOdd ? 'too-long' : ''} ${item.rightElement ? 'right' : ''}`} key={idx}>
      <div className='top_area'>
        <div className='num'>{item.question_order}</div>
      </div>
      <div className='question_area'>
        <div
          dangerouslySetInnerHTML={{
            __html: item.question.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
          }}
        />
      </div>
      {item.question_format === 'M' ? (
        <div className='answer_area'>
          <div className={`item ${item.correct_answer === 'A' ? 'correct_answer' : item.try_answer === 'A' ? 'wrong' : ''}`}>
            <span className='num'>A&#41;</span>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.multiple_choice_items1?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                }}
              />
              {item.correct_answer !== item.try_answer && item.try_answer === 'A' && <div className='txt'>YOUR CHOICE</div>}
              {item.correct_answer === 'A' && <div className='txt'>CORRECT ANSWER</div>}
            </div>
          </div>
          <div className={`item ${item.correct_answer === 'B' ? 'correct_answer' : item.try_answer === 'B' ? 'wrong' : ''}`}>
            <span className='num'>B&#41;</span>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.multiple_choice_items2?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                }}
              />
              {item.correct_answer !== item.try_answer && item.try_answer === 'B' && <div className='txt'>YOUR CHOICE</div>}
              {item.correct_answer === 'B' && <div className='txt'>CORRECT ANSWER</div>}
            </div>
          </div>
          <div className={`item ${item.correct_answer === 'C' ? 'correct_answer' : item.try_answer === 'C' ? 'wrong' : ''}`}>
            <span className='num'>C&#41;</span>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.multiple_choice_items3?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                }}
              />
              {item.correct_answer !== item.try_answer && item.try_answer === 'C' && <div className='txt'>YOUR CHOICE</div>}
              {item.correct_answer === 'C' && <div className='txt'>CORRECT ANSWER</div>}
            </div>
          </div>
          <div className={`item ${item.correct_answer === 'D' ? 'correct_answer' : item.try_answer === 'D' ? 'wrong' : ''}`}>
            <span className='num'>D&#41;</span>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.multiple_choice_items4?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                }}
              />
              {item.correct_answer !== item.try_answer && item.try_answer === 'D' && <div className='txt'>YOUR CHOICE</div>}
              {item.correct_answer === 'D' && <div className='txt'>CORRECT ANSWER</div>}
            </div>
          </div>
          {userLevel && nvl(item.try_answer) === '' && nvl(mathTestDiff) === 'E' && <div className='notice'>You have omitted this question.</div>}
        </div>
      ) : (
        <div className='answer_area'>
          {nvl(mathTestDiff) === 'E' && userLevel && (
            <div className={`answer_input ${userLevel && answerCheck(item.correct_answer, item.try_answer) && nvl(item.try_answer) !== '' ? '' : 'wrong'}`}>
              <div className='input_box '>
                <input type='text' className='input' value={userLevel ? nvl(item.try_answer) : ''} readOnly />
              </div>
              <div className='txt'> {nvl(item.try_answer) === '' ? 'You have omitted this question.' : 'Your choice'}</div>
            </div>
          )}
          <div className='answer_input'>
            <div className='input_box correct_answer'>
              <input type='text' className='input' value={nvl(item.correct_answer)} readOnly />
            </div>
            <span className='txt'>Correct Answer</span>
          </div>
        </div>
      )}
    </article>
  );
}
