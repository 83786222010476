export default function TabMenu(props) {
  const { tabState, setTabState, tab } = props;

  return tab.map((item, idx) => {
    return (
      <button key={idx} className={`menu ${(tabState === item.text && 'active') || ''}`} onClick={() => setTabState(item.text)}>
        {item.text}
      </button>
    );
  });
}
