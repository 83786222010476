import styled from 'styled-components';

const { nvlNumber } = require('utils/Common.utils');

/** 학생 목록의 요소 컴포넌트 */
export default function StudentListItem({ setDeleteSelection, item, handleShowProfile }) {
  return (
    <S.Wrap className='item'>
      <div className='com_profile_img'>
        {nvlNumber(item?.upFileSeq) > 0 ? (
          <img
            className='profile-img-background'
            src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${item.userSeq}`}
            alt='profile'
          />
        ) : (
          <span className='name'>{item.userName?.[0]}</span>
        )}
      </div>
      <p className='name'>{item.userName}</p>
      <button className='btn' onClick={() => handleShowProfile(item.userSeq)}>
        {/* 자세히 보기 버튼 */}
        <i className='svg_icon icon_search gray'>&nbsp;</i>
      </button>
      <button className='btn' onClick={setDeleteSelection}>
        {/* 삭제 버튼 */}
        <i className='svg_icon icon_trashcan2 gray'>&nbsp;</i>
      </button>
    </S.Wrap>
  );
}

const S = {};

/** 해당 컴포넌트의 전체 영역 Styled-components */
S.Wrap = styled.div`
  width: 50%;
  padding: 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .name {
    margin-inline: 8px auto;
  }
  &:nth-child(2n + 1) {
    &:after {
      content: '';
      background: #ddd;
      height: 1px;
      width: 200%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &:nth-last-child(-n + 2) {
    border: none;
  }
  .btn {
    height: 26px;
    align-items: center;
    .svg_icon {
      height: 26px;
      &.icon_search {
        background-position: center 8px;
      }
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
