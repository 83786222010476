import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Image
import noticeIcon from '../../assets/gate_member/image/notice_icon.svg';

import usePressESC from '../../hooks/usePressESC';

function PopBack({ closePopBack, confirmCallback = null }) {
  usePressESC(() => closePopBack());

  const navigate = useNavigate();

  if (confirmCallback === null) {
    confirmCallback = () => {
      navigate(-1);
      closePopBack();
    };
  }

  const handleConfirm = () => {
    closePopBack();
    confirmCallback();
  };

  return (
    <BackModalLayer>
      <BackModalContainer>
        <div className='back_modal_img_wrapper'>
          <img src={noticeIcon} alt='icon' />
        </div>
        <div className='back_modal_text_container'>
          <p>Are you sure you want to go back?</p>
          <p>Changes will NOT be saved.</p>
        </div>
        <div className='back_modal_btn_container'>
          <button onClick={handleConfirm}>Confirm</button>
          <button className='btn_fill' onClick={closePopBack}>
            Cancel
          </button>
        </div>
      </BackModalContainer>
    </BackModalLayer>
  );
}

export default PopBack;

// --- --- ---

const BackModalLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackModalContainer = styled.div`
  z-index: 999;
  padding: 3rem !important; // gate_reset.scss 덮어 쓰기
  background-color: #ffffff;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 2rem;
  width: 33rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  .back_modal_img_wrapper {
    margin: 0 auto;
  }
  .back_modal_text_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > p {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
    }
  }
  .back_modal_btn_container {
    display: flex;

    gap: 1.25rem;
    > button {
      flex: 1;
      width: 100%;
      padding: 1.25rem;
      border-radius: 6px;
      border: 1px solid #0068bd;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      &.btn_fill {
        background-color: #0068bd;
        color: white;
      }
    }
  }
`;
