import {
  CLEAR_TIMER,
  SET_TIMER,
  CLEAR_EXAM_INFO,
  INIT_EXAM_INFO,
  SET_EXAM_INFO,
  CLEAR_EXAM_QUESTION_LIST,
  ADD_EXAM_QUESTION_LIST,
  EDIT_EXAM_QUESTION_LIST,
  SET_EXAM_QUESTION_LIST,
} from './actionType';

/** 시험 세트 당 정보 전역 상태 초기 값 */
const stateExamInfoInit = {
  uthSeq: 0, // 시험 세트 고유 값
  customSetSeq: 0,
  setUniqueCode: '',
  testName: '',
  testStartDate: '',
  setSubject: '', // Set Tests에서 설정한 과목 종류
  setDifficulty: '',
  lastInsertSeq: 0,

  currentTest: {
    // 현재 진행 중인 과목 상태
    em1: 'R', // Ready, Ing, Done
    em2: 'R',
    em2d: '', // Difficulty(모듈 1에 따른 모듈 2의 난이도) E(Easy), H(Hard)
    mm1: 'R',
    mm2: 'R',
    mm2d: '',
  }, // 현재 테스트 단계 EM1, EM2(E, H), MM1, MM2(E, H)

  timer: {
    // 타이머 관련
    fullTime: 0, // 모듈 당 주어진 전체 시간
  },

  currentNum: 1, // 현재 문제 번호

  questionList: [
    // 현재 모듈 문제 목록
    {
      customSetSeq: 0,
      setUniqueCode: '',
      testName: '',
      setSubject: '',
      setDifficulty: '',
      setRegUserSeq: 0,
      setRegDate: '',
      setModiUserSeq: 0,
      setModiDate: '',
      testModuleSeq: 0,
      moduleUniqueCode: '',
      moduleSubject: '',
      moduleNum: 0,
      moduleDifficulty: '',
      qmhSeq: 0,
      questionSeq: 0,
      multipleChoiceItems1: '',
      multipleChoiceItems2: '',
      multipleChoiceItems3: '',
      multipleChoiceItems4: '',
      questionSubject: '',
      testHow: '',
      questionFormat: '',
      questionDifficulty: '',
      fieldOfStudy: 0,
      researcher: 0,

      tryAnswer: '', // 기입한 답

      annotation: [
        {
          // 주석 부분
          annotationStart: 0,
          annotationEnd: 0,
          selected_words: '',
          annotationContent: '',
        },
      ],

      bookMark: 'N', // 북마크 Y,N

      entryTime: '', // 진입 시점
      exitTime: '', // 이탈 시점

      underLine1: 'N', // 취소선
      underLine2: 'N',
      underLine3: 'N',
      underLine4: 'N',

      passage: '',
      question: '',
    },
  ],
};

/** 시험 관련 전역 상태 리듀서 */
const stateExamInfo = (state = stateExamInfoInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_EXAM_INFO:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.data,
      };

    case SET_EXAM_QUESTION_LIST:
      return {
        ...state,
        questionList: action.payload,
      };
    // case ADD_EXAM_QUESTION_LIST:
    //   return {
    //     ...state,
    //     questionList: state.questionList.concat(action.payload),
    //   };
    case EDIT_EXAM_QUESTION_LIST:
      return {
        ...state,
        questionList: state.questionList.map((item, index) => (index === action.payload.index ? action.payload.data : item)),
      };
    case CLEAR_EXAM_QUESTION_LIST:
      return {
        ...state,
        questionList: stateExamInfoInit.questionList,
      };

    case CLEAR_EXAM_INFO:
      return stateExamInfoInit;

    case INIT_EXAM_INFO:
      return {
        ...stateExamInfoInit,
        uthSeq: state.uthSeq,
        customSetSeq: state.customSetSeq,
        currentTest: state.currentTest,
        setSubject: state.setSubject, // Full | Reading and Writing | Math
        setDifficulty: state.setDifficulty,
        questionList: state.questionList,
      };

    case SET_TIMER:
      return {
        ...state,
        timer: {
          ...state.timer,
          [action.payload.fieldName]: action.payload.data,
        },
      };
    case CLEAR_TIMER:
      return {
        ...state,
        timer: stateExamInfoInit.timer,
      };

    default:
      return state;
  }
};

export default stateExamInfo;
