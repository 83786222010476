import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

/** 수학 시험 화면 Footer 영역 컴포넌트 */
export default function ExamFooter({ isLoading, setAlertLayerPopup, dummyData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const userInfo = request.tokenDecoder();

  const miniReviewToggleRef = useRef();

  /** "다음 문제" 버튼 핸들러 */
  const nextButtonClick = () => {
    if (isLoading) return;

    // review 화면(즉, 마지막 단계) 이 아닐 경우

    console.log('🚨 dummyData.length :', dummyData.length, '🚨');
    console.log('🚨 stateExamInfo.currentNum :', stateExamInfo.currentNum, '🚨');
    console.log('🚨 stateExamComponents.reviewDisplay :', stateExamComponents.reviewDisplay, '🚨');

    if (stateExamInfo.currentNum < dummyData.length) {
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: stateExamInfo.currentNum + 1 } });
      // 마지막 문제가 아닐 경우,
      navigate('/exam/dsat/mathSubjective', {
        state: { currentNum: stateExamInfo.currentNum + 1 },
        replace: true,
      });
    } else if (stateExamInfo.currentNum === dummyData.length) {
      console.log('마지막 문제?');
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertType: 'confirm',
          activateMode: 'nextSection',
          alertMessage: 'Are you sure you want to move on to next Section?<br> Make sure you have answered all the questions.',
        };
      });
    }
  };

  return (
    <section className='pop_footer'>
      <div className='name'>{nvl(userInfo?.userName)}</div>
      <article className='btn_area'>
        <button className='btn' onClick={nextButtonClick}>
          Next
        </button>
      </article>
    </section>
  );
}
