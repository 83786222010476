import { SET_EXAM_COMPONENTS, CLEAR_EXAM_COMPONENTS } from './actionType';

const stateExamComponentsInit = {
  rspDisplay: false,
  repExpand: false,
  cpDisplay: false,
  cpExpand: false,
  cpState: {},
  directionDisplay: true,
  timeAreaDisplay: true,
  annotationDisplay: false,
  annotationEmptyDisplay: false,
  annotationSelectedWords: false,
  moreDisplay: false,
  helpDisplay: false,
  helpItemDisplay: false,
  exitExamDisplay: false,
  miniReviewDisplay: false,
  reviewDisplay: false,
  isFiveMinutesRemaining: false,
  isMathJaxError: false,
  runOutTime: false,
  // mathQuestionDisplay: false,
  // englishQuestionDisplay: false,
  moduleOverDisplay: false,
};

const stateExamComponents = (state = stateExamComponentsInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_EXAM_COMPONENTS:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data,
      };

      return newState;

    case CLEAR_EXAM_COMPONENTS:
      return stateExamComponentsInit;

    default:
      return state;
  }
};

export default stateExamComponents;
