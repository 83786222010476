import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import { useSelector, useDispatch } from 'react-redux';

import ImgReferenceExpand from 'assets/img/exam/reference_image.jpg';
import ImgReference from 'assets/img/exam/reference_image2.jpg';
import examples from 'assets/img/exam/examples.png';

// --- Reference Sheet 팝업 메소드
const ReferenceSheetPopup = () => {
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  const dispatch = useDispatch();

  const nodeRef = useRef(null);

  const [buttonOn, setButtonOn] = useState(false);

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  const onStart = () => {
    setState({ ...state, activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ ...state, activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart, onStop };

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <div
        ref={nodeRef}
        className={stateExamComponents.rspDisplay ? 'com_popup type_move active pop_reference_sheet' : 'com_popup type_move pop_reference_sheet'}>
        {' '}
        {/*addClass 'active' 시 노출*/}
        {/*1130 처음 팝업 시 우측으로 붙어야합니다, Expand/Collapse버튼 클릭 시에도 우측으로 붙어야합니다.*/}
        <div className={stateExamComponents.repExpand ? 'pop_container expand' : 'pop_container'}>
          {' '}
          {/*expand 클릭시 addClass 'expand'*/}
          <strong className='cursor'>
            <div className='pop_header' onMouseDown={() => setButtonOn(true)} onMouseUp={() => setButtonOn(false)}>
              <div className='pop_tit'>Reference Sheet</div>
              <button className={buttonOn ? 'svg_icon btn_move active' : 'svg_icon btn_move'}>&nbsp;</button>
              <div className='btn_area'>
                <button
                  className='btn_size'
                  onClick={() =>
                    dispatch({
                      type: 'setExamComponents',
                      payload: {
                        fieldName: 'repExpand',
                        data: !stateExamComponents.repExpand,
                      },
                    })
                  }>
                  <i className='svg_icon icon_expand'>&nbsp;</i>Expand
                </button>
                <button
                  className='svg_icon btn_pop_close white'
                  onClick={() =>
                    dispatch({
                      type: 'setExamComponents',
                      payload: { fieldName: 'rspDisplay', data: false },
                    })
                  }>
                  &nbsp;
                </button>
              </div>
            </div>
          </strong>
          <div className='pop_body'>
            <img src={ImgReferenceExpand} className='expand_img' alt='Reference Sheet' />
            <img src={ImgReference} className='collapse_img' alt='Reference Sheet' />

            <div className='info_txt'>
              The number of degrees of arc in a circle is 360°.
              <br />
              the number of radians of arc in a circle is 2π.
              <br />
              The sum of the measures in degrees of the angles of a triangle is 180°.
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default ReferenceSheetPopup;
