import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import request from 'utils/Request.utils';
//component
import Header from 'components/user/_Header';
import Upload from 'components/user/_Upload';
import { nvl } from 'utils/Common.utils';
import CustomAlert from 'components/_common/alerts/CustomAlert';

import { USER_LEVELS } from 'utils/constants';
import LoadingBar from 'utils/LoadingBar';

const JoinUpload = () => {
  const navigate = useNavigate();
  const stateCustomer = useSelector((state) => state.Customer);
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_upload'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_upload'); //다른 페이지에선 removeClass
    };
  }, []);

  // 알랏
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        alertType: 'alert',
        visible: false,
        alertMessage: '',
        activateMode: '',
        value: 0,
      };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      //
    }
  };

  const [uploadFileList, setUploadFileList] = useState([]);
  const [incorrectCode, setIncorrectCode] = useState(true);
  const acaCodeRef = useRef();

  const acaCodeCheck = () => {
    if (acaCodeRef.current.value.length > 0) {
      const successHandler = (response) => {
        if (response.code === 200) {
          let checkCode = response.result.checkCode;

          if (checkCode) {
            signup('teacher');
          } else {
            acaCodeRef.current.focus();
          }
          setIncorrectCode(checkCode);
        }
      };

      request.get(`/api/common/academy/uniquecode/check?&uniqueCode=${acaCodeRef.current.value}&userEmail=${stateCustomer.userEmail}`, null, successHandler);
    } else {
      acaCodeRef.current.focus();
      setIncorrectCode(false);
    }
  };

  const signup = (level) => {
    setLoading(true);
    let params = {
      userId: stateCustomer.userEmail,
      userPasswd: stateCustomer.userPasswd,
      userEmail: stateCustomer.userEmail,
      userName: stateCustomer.userName,
      userNickname: stateCustomer.userName,
      userLevel: level === 'tutor' ? USER_LEVELS.TUTOR : USER_LEVELS.TEACHER, // 학원 관리자, 강사
      userMobile: stateCustomer.userMobile,
      acceptReceivingMail: stateCustomer.mailYn,
      uniqueCode: acaCodeRef.current.value,
      userStatus: level === 'tutor' ? '005001000000000' : '005003000000000',
      regUserSeq: 1,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let userSeq = response.result.userSeq;
        if (level === 'tutor') {
          uploadActivate(userSeq);
        } else {
          setLoading(false);
          navigate('/signup/teacher/complete');
        }

        request.del(`/api/common/authen/code/${params.userEmail}`);
      }
    };

    request.post('/api/common/user', params, successHandler);
  };

  const uploadActivate = (userSeq) => {
    // console.log("전송", uploadFileList)

    const formData = new FormData();

    Object.values(uploadFileList).forEach((file) => formData.append('uploadFiles', file.fileData));

    // for(const fileInfo of uploadFileList) {
    //   console.log(fileInfo.fileTextData, fileInfo.fileData)
    // }

    const successHandler = (response) => {
      if (response.code === 200) {
        setLoading(false);
        navigate('/signup/tutor/complete');
      }
    };

    request.postMultipart(`/api/common/upload/files/tutor/${userSeq}`, formData, successHandler);
  };

  const submitBtn = () => {
    if (uploadFileList.length > 0) {
      signup('tutor');
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please upload the file.',
        };
      });
    }
  };

  return (
    <>
      <div className='container__wrap both'>
        <div className='container'>
          <Header>
            <p className='sub_title'>Become a GATE +</p>
            <h1>ACADEMY TEACHER</h1>
            <hr className='divider' />
          </Header>
          <div className='contents-wrap'>
            <section className='contents'>
              <p className='verify'>Already a part of an Academy?</p>
              <p className='upload tutor'>
                If you have an Academy Code, you do not need to submit additional files to complete sign up. Please enter the code below.
              </p>
              <div className='form_title'>
                <span className='essential'>*</span>
                <span>ACADEMY CODE</span>
              </div>
              {/* Academy Code 입력 */}
              <div className={`academy_code ${incorrectCode ? '' : 'error'}`}>
                {/*  코드가 유효하지 않을 시 class에 error 추가 */}
                <div className='box'>
                  <input type='text' className='input error' ref={acaCodeRef} />
                </div>
                <span className='error_message'>Incorrect code. Please try again.</span>
              </div>
              <article className='note'>
                <p className='tit'>NOTE</p>
                <div className='txt'>
                  If you do not submit additional files, you will only be able to use your account as part of an Academy. If you are both academy teacher and a
                  private tutor, we recommend signing up as Private Tutor first then later link your account to the Academy in Profile Settings.
                </div>
              </article>
            </section>
            <section className='btns'>
              <button className='btn btn_fill btn_middle' onClick={() => acaCodeCheck()}>
                Submit
              </button>
            </section>
          </div>
        </div>
        <p className='txt_or'>OR</p>
        <div className='container'>
          <Header>
            <p className='sub_title'>Become a GATE +</p>
            <h1>PRIVATE TUTOR</h1>
            <hr className='divider' />
          </Header>
          <div className='contents-wrap'>
            <Upload type='tutor' uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} setAlertLayerPopup={setAlertLayerPopup} />
            <section className='btns'>
              {/* <button className="btn btn_stroke btn_middle">Back</button> */}
              <button className='btn btn_fill btn_middle' onClick={submitBtn}>
                Submit
              </button>
            </section>
          </div>
        </div>
      </div>
      {isLoading && <LoadingBar />}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
};

export default JoinUpload;
