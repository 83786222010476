import React, { useEffect, useRef, useState } from 'react';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils.js';

import useOutsideClick from 'hooks/useOutsideClick';

import MiniAlert from 'components/_common/alerts/MiniAlert';
import BasicColumnChart from 'components/_common/chart/BasicColumnChart';

/** "/com/score" 페이지의 "Advanced Report" 영역의 "Correct answer (rate) by Questions" 영역 컴포넌트 */
export default function QuestionStats({ mode, filterValue, setFilterValue, handleClickGraphItemField, weeklyScores }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [moduleInfoList, setModuleInfoList] = useState([]);
  const [currentModuleSeq, setCurrentModuleSeq] = useState(0);
  const [alert, alertSet] = useState(false);

  const [questionStatsInfo, setQuestionStatsInfo] = useState({
    questionAccuracyList: [],
    questionList: [],
  });

  const [perSubject, setPerSubject] = useState(false);
  const [perModuleNum, setPerModuleNum] = useState(false);

  const [questionSortList1, setQuestionSortList1] = useState([]);
  const [questionSortList2, setQuestionSortList2] = useState([]);

  const [timePerQuestionData, setTimePerQuestionData] = useState({
    series: [
      {
        name: 'You',
        data: [],
        color: '#0068BD',
      },
      {
        name: 'GATE⁺',
        data: [],
        color: '#E3E7ED',
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const xAxisValue = w.globals.labels[dataPointIndex];
          return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontFamily: 'Roboto, Arial, sans-serif',
        markers: {
          width: 25,
          height: 8,
          radius: 8,
        },
        itemMargin: {
          horizontal: 15,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7,
          },
        },
      },
    },
  });

  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'state01 pointer';
    else if (contentDomain === '008002000000000') return 'state02 pointer';
    else if (contentDomain === '008003000000000') return 'state03 pointer';
    else if (contentDomain === '008004000000000') return 'state04 pointer';
    else if (contentDomain === '009001000000000') return 'state01 pointer';
    else if (contentDomain === '009002000000000') return 'state02 pointer';
    else if (contentDomain === '009003000000000') return 'state03 pointer';
    else if (contentDomain === '009004000000000') return 'state04 pointer';

    return '';
  };

  useEffect(() => {
    if (currentModuleSeq > 0) {
      accuracyByQuestion();
      timePerQuestion();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModuleSeq, filterValue.classSeq]);

  useEffect(() => {
    if (currentModuleSeq > 0 && filterValue.uthSeq > 0) {
      alertSet(true);
      accuracyByQuestion();
      timePerQuestion();
    } else {
      alertSet(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.uthSeq]);

  const perSubActive = () => {
    setPerSubject((prev) => !prev);
  };

  const perNumActive = () => {
    setPerModuleNum((prev) => !prev);
  };

  const questionCheck = (e, item) => {
    // console.log("암호화", item.seqStr, nvl(filterValue.classSeq)) // 작업중
    SessionStorage.clearItem('review');
    SessionStorage.setItem('review', 'classSeq', nvl(filterValue.classSeq)); // 선생님 북마크 작업 중

    if (mode === 'class') window.open(`/student/review?viewQuestion=${encodeURIComponent(item.seqStr)}`, '_blank');
    else window.open(`/student/review?viewQuestion=${encodeURIComponent(item.uthSeqStr + 'GATEPLUS' + item.seqStr)}`, '_blank');
  };

  const selectedTimeSubject = () => {
    return filterValue.tmSubject === 'E' ? 'RW' : filterValue.tmSubject === 'M' ? 'MATH' : '-';
  };

  const selectedTimeModuleNum = () => {
    let module;
    if (filterValue.moduleNum === '1') module = 'Module 1';
    else if (filterValue.moduleNum === '2' && filterValue.tmDifficulty === 'E') module = 'Module 2 Easy';
    else if (filterValue.moduleNum === '2' && filterValue.tmDifficulty === 'H') module = 'Module 2 Hard';
    else if (filterValue.moduleNum === '2') module = 'Module 2';
    else module = '-';
    return module;
  };

  useEffect(() => {
    if (mode !== 'class' && currentModuleSeq > 0 && questionStatsInfo.questionList != null && questionStatsInfo.questionList.length > 0) {
      const sortedQuestionList = questionStatsInfo.questionList.sort((a, b) => a.questionOrder - b.questionOrder);

      const updatedList = sortedQuestionList?.filter((item) => item.moduleNum === filterValue.moduleNum && item.tmSubject === filterValue.tmSubject);

      setQuestionSortList1(updatedList);
    }

    if (questionStatsInfo.questionAccuracyList != null) {
      const sortedAccuracyList = questionStatsInfo.questionAccuracyList.sort((a, b) => a.questionOrder - b.questionOrder);

      setQuestionSortList2(sortedAccuracyList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionStatsInfo]);

  useEffect(() => {
    if (filterValue.customSetSeq > 0) getModuleSeq();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.customSetSeq]);

  useEffect(() => {
    if (currentModuleSeq && nvl(moduleInfoList) !== '' && moduleInfoList.length > 0) {
      let moduleSeq;

      if (filterValue.moduleNum === '1')
        moduleSeq = moduleInfoList.filter((i) => i.tmSubject === filterValue.tmSubject && i.moduleNum === '1')[0]?.testModuleSeq;
      else
        moduleSeq = moduleInfoList.filter((i) => i.tmSubject === filterValue.tmSubject && i.tmDifficulty === filterValue.tmDifficulty && i.moduleNum === '2')[0]
          ?.testModuleSeq;

      setCurrentModuleSeq(moduleSeq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const getModuleSeq = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let moduleInfoList = response.result.moduleInfoList;

        if (!!moduleInfoList) {
          setModuleInfoList(moduleInfoList);

          if (moduleInfoList[0]?.setSubject !== 'M') {
            setCurrentModuleSeq(moduleInfoList.filter((i) => i.tmSubject === 'E' && i.moduleNum === '1')[0].testModuleSeq);
            handleClickGraphItemField('rw');
          } else {
            setCurrentModuleSeq(moduleInfoList.filter((i) => i.tmSubject === 'M' && i.moduleNum === '1')[0].testModuleSeq);
            handleClickGraphItemField('math');
          }
        } else {
          getModuleSeq();
        }
      }
    };

    request.get(`/api/dsat/customsets/${filterValue.customSetSeq}/modules`, null, successHandler).catch((error) => console.error('', error));
  };

  const accuracyByQuestion = () => {
    const successHandler = (response) => {
      // console.log('a',response);

      if (response.code === 200) {
        let questionAccuracyList = response.result.questionAccuracyList;

        setQuestionStatsInfo((prev) => {
          return { ...prev, questionAccuracyList: questionAccuracyList };
        });

        if (mode !== 'class') getModuleAnswer();
      }
    };

    request
      .get(
        `/api/dsat/rate/class/questions/accuracy?testModuleSeq=${currentModuleSeq}&classSeq=${filterValue.classSeq}&ecSeq=${userInfo?.ecSeq}&regUserSeq=${userInfo?.userSeq}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  const [moduleDiff, setModuleDiff] = useState({
    rw: null,
    math: null,
  });
  const getModuleAnswer = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let questionList = response.result.questionList;

        setQuestionStatsInfo((prev) => {
          return {
            ...prev,
            questionList: questionList,
            userSeq: questionList[0].userSeq,
            userName: questionList[0].userName,
            studentGrade: questionList[0].studentGrade,
            trhDate: questionList[0].trhRegDate,
          };
        });
        setFilterValue((prev) => {
          return {
            ...prev,
            uthSeq: filterValue.uthSeq,
            userSeq: questionList[0].userSeq,
          };
        });

        setModuleDiff({
          rw: questionList.filter((i) => i.tmSubject === 'E' && i.moduleNum === '2')[0]?.tmDifficulty,
          math: questionList.filter((i) => i.tmSubject === 'M' && i.moduleNum === '2')[0]?.tmDifficulty,
        });

        weeklyScores(filterValue.classSeq, questionList[0].testStartDate.split(' ')[0], questionList[0].userSeq);
      }
    };

    request
      .get(
        `/api/exam/moduleAnswer?uthSeq=${filterValue.uthSeq}&classSeq=${filterValue.classSeq}&ecSeq=${userInfo?.ecSeq}&regUserSeq=${userInfo?.userSeq}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  const timePerQuestion = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let gatePlusDurationList = response.result.personalDurationListAll;
        let personalDurationList = response.result.classDurationList;

        const gateUseSeconds = gatePlusDurationList?.map((item) => item.secAvg);
        const classUseSeconds = personalDurationList?.map((item) => item.secAvg);

        if (classUseSeconds && classUseSeconds != null) {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              {
                name: mode === 'student' ? 'Student' : 'Class',
                data: classUseSeconds,
                color: filterValue.color,
              },
              { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
            ],
            options: {
              ...prevData.options,
              xaxis: {
                categories: classUseSeconds?.map((_, index) => index + 1),
              },
              tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                  const xAxisValue = w.globals.labels[dataPointIndex];
                  const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

                  if ((seriesName === 'CLASS' || seriesName === 'STUDENT') && seriesIndex === 0) {
                    return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
                    return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  }
                },
              },
            },
          }));
        } else if (gateUseSeconds && gateUseSeconds != null) {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              {
                name: mode === 'student' ? 'Student' : 'Class',
                data: [null],
                color: filterValue.color,
              },
              { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
            ],
            options: {
              ...prevData.options,
              xaxis: {
                categories: gateUseSeconds?.map((_, index) => index + 1),
              },
              tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                  const xAxisValue = w.globals.labels[dataPointIndex];
                  const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

                  if ((seriesName === 'CLASS' || seriesName === 'STUDENT') && seriesIndex === 0) {
                    return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
                    return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  }
                },
              },
            },
          }));
        } else {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              {
                name: mode === 'student' ? 'Student' : 'Class',
                data: [null],
                color: filterValue.color,
              },
              { name: 'GATE⁺', data: [null], color: '#E3E7ED' },
            ],
          }));
        }
      }
    };

    request
      .get(
        `/api/dsat/time/class/durations?ecSeq=${userInfo.ecSeq}&classSeq=${filterValue.classSeq}&uthSeq=${nvlNumber(filterValue.uthSeq)}&userSeq=${nvlNumber(filterValue.userSeq)}&testModuleSeq=${currentModuleSeq}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  // 외부 클릭시 닫히는 훅
  // hook
  const subjectBox = useRef(null);
  useOutsideClick(subjectBox, () => setPerSubject(false));
  const moduleBox = useRef(null);
  useOutsideClick(moduleBox, () => setPerModuleNum(false));

  // 리뷰 팝업창에서 북마크 클릭시 호출
  window.getParentQuestion = () => {
    accuracyByQuestion();
  };

  return (
    <>
      <article className='box_graph blue active correct_answer_sec'>
        <div className='top_area'>
          <p className='tit_field'>Correct answer (rate) by Questions</p>
          <div className={`com_select_layer ${perSubject ? 'active' : ''}`} onClick={perSubActive} ref={subjectBox}>
            {/*클래스 active 포함 시 옵션 노출*/}
            <section className='selected__wrap'>
              <input type='text' className='selected' placeholder={selectedTimeSubject()} size='5' />
            </section>
            <section className='layer'>
              {moduleInfoList[0]?.setSubject !== 'M' && (
                <p className='option' onClick={() => handleClickGraphItemField('rw')}>
                  RW
                </p>
              )}
              {moduleInfoList[0]?.setSubject !== 'E' && (
                <p className='option' onClick={() => handleClickGraphItemField('math')}>
                  MATH
                </p>
              )}
            </section>
          </div>
          <div className={`com_select_layer type_tit ${perModuleNum ? 'active' : ''}`} onClick={perNumActive} ref={moduleBox}>
            {/*클래스 active 포함 시 옵션 노출*/}
            <section className='selected__wrap'>
              {/* <p className="tit_field">Module</p> */}
              <input type='text' className='selected' placeholder={selectedTimeModuleNum()} size='15' />
            </section>
            <section className='layer'>
              {mode === 'class' ? (
                <>
                  <p
                    className='option'
                    onClick={() =>
                      setFilterValue((prev) => {
                        return { ...prev, moduleNum: '1' };
                      })
                    }>
                    Module 1
                  </p>
                  <p
                    className={`option ${moduleInfoList[0]?.setDifficulty === 'H' ? 'disabled' : ''}`}
                    onClick={() =>
                      moduleInfoList[0]?.setDifficulty !== 'H'
                        ? setFilterValue((prev) => {
                            return {
                              ...prev,
                              moduleNum: '2',
                              tmDifficulty: 'E',
                            };
                          })
                        : null
                    }>
                    Module 2 Easy
                  </p>
                  <p
                    className={`option ${moduleInfoList[0]?.setDifficulty === 'E' ? 'disabled' : ''}`}
                    onClick={() =>
                      moduleInfoList[0]?.setDifficulty !== 'E'
                        ? setFilterValue((prev) => {
                            return {
                              ...prev,
                              moduleNum: '2',
                              tmDifficulty: 'H',
                            };
                          })
                        : null
                    }>
                    Module 2 Hard
                  </p>
                </>
              ) : (
                <>
                  <p
                    className='option'
                    onClick={() =>
                      setFilterValue((prev) => {
                        return { ...prev, moduleNum: '1' };
                      })
                    }>
                    Module 1
                  </p>
                  <p
                    className='option'
                    onClick={() =>
                      setFilterValue((prev) => {
                        return {
                          ...prev,
                          moduleNum: '2',
                          tmDifficulty: filterValue.tmSubject === 'E' ? moduleDiff.rw : moduleDiff.math,
                        };
                      })
                    }>
                    Module 2
                  </p>
                </>
              )}
            </section>
          </div>
        </div>
        <div className='content_area'>
          <table className='table'>
            <thead>
              <tr>
                <th className='first'>Question No.</th>
                {mode === 'class' &&
                  questionSortList2.map((item, index) => (
                    <th key={index} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
                      {item.questionOrder}
                      {item.classBookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
                    </th>
                  ))}

                {mode !== 'class' &&
                  questionSortList1.map((item, index) => (
                    <th key={index} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
                      {item.questionOrder}
                      {item.classBookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='first'>Class Average</td>
                {questionSortList2.map((item, index) =>
                  item.per <= 50 ? (
                    <td key={index} className='rate01'>
                      {item.per}%
                    </td>
                  ) : item.per <= 75 ? (
                    <td key={index} className='rate02'>
                      {item.per}%
                    </td>
                  ) : (
                    <td key={index}>{item.per}%</td>
                  )
                )}
              </tr>
              <tr>
                {mode !== 'class' && (
                  <>
                    <td>Result</td>
                    {questionSortList1.map((item, index) => (
                      <td key={index}>
                        {item.correctAnswerCnt === 1 ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}
                      </td>
                    ))}
                  </>
                )}
              </tr>
            </tbody>
          </table>
          {/*Time per Question*/}
          <div className='box_graph active time_per_question'>
            <div className='top_area'>
              <span className='tit_field'>Time per Question</span>
            </div>
            <div className='chart_area'>
              <BasicColumnChart timePerQuestionData={timePerQuestionData} />
            </div>
          </div>
        </div>
      </article>
      {alert && <MiniAlert text='Student Selected' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={1500} />}
    </>
  );
}
