import React, { useEffect, useMemo, useState } from 'react';
import SessionStorage from 'utils/SessionStorage.utils.js';

/** "/com/score" 페이지의 "Student List" 의 목록 개별 요소 컴포넌트 */
function MemberListItem({ item, handleSelectStudent, filterValue, tabState, setPrintActive, setLoading }) {
  /** 현재 선택 된 상태인지 검증 */

  const isActive = item.uthSeq === filterValue.uthSeq;

  /** 선택 된 학생 표시 */
  const reportCardOpen = (uthSeq, userSeq) => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', userSeq);

    setPrintActive(true);
  };

  /** 학생 목록의 요소 클릭 이벤트 */
  const handleClickStudent = ({ item }) => {
    setLoading(true);
    setTimeout(() => {
      handleSelectStudent(item.uthSeq, item.userSeq, item.rwPoint, item.mathPoint);
      setLoading(false);
    }, 1000);
  };

  return (
    <tr className={isActive ? 'active' : ''} onClick={() => handleClickStudent({ item })}>
      {/*To do 선택 시 addClass 'active*/}
      <td>
        <div className='com_profile_img'>
          {item.upFileSeq > 0 ? (
            <img
              className='profile-img-background'
              src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${item.userSeq}`}
              alt='profile'
            />
          ) : (
            <span className='name'>{item.userName?.[0]}</span>
          )}
        </div>
      </td>
      <td>
        <span className='name'>{item.userName}</span>
      </td>
      {/*To Do : 8글자 제한*/}
      <td className='alignC data_field'>{tabState === 2 || item.rwPoint === 0 ? '-' : item.rwPoint}</td>
      <td className='alignC data_field'>{tabState === 2 || item.mathPoint === 0 ? '-' : item.mathPoint}</td>
      <td className='alignC'>
        <button className='com_btn_report' onClick={() => reportCardOpen(item.uthSeq, item.userSeq)}>
          <i className='svg_icon icon_score_report s black'>&nbsp;</i>
        </button>
      </td>
    </tr>
  );
}

export default MemberListItem;
