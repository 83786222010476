import { Navigate, Route, Routes } from 'react-router-dom';
//components
import Footer from '../layout/Footer'; //Footer
import Gnb from '../layout/Gnb';
import Lnb from '../layout/Lnb'; //Lnb
//import StudentList from "../components/student/List";
import StudentDashboard from '../components/dashboard/Page';
import Profile from '../components/user/profile/Page';
import request from '../utils/Request.utils';

const StudentRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  console.log('pathname', pathname);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      // 로그인 한 사람만 사용
      const authRoutes = [
        { path: '/dashboard', element: <StudentDashboard /> }, // component 수정해야 함
        { path: '/profile', element: <Profile /> }, // component 수정해야 함

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unauthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unauthRoutes);
    }
  };

  const PathStudents = pathname.toLowerCase().startsWith('/parent/dashboard');
  const PathReview = pathname.toLowerCase().startsWith('/parent/review');
  const PathStudentsList = pathname.toLowerCase().startsWith('/parent');

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathStudents || PathStudentsList) return <Lnb />;
    else return <></>;
  };
  const renderFooter = () => {
    //경로에 따른 Footer 노출 설정
    if (PathReview) return <></>;
    else return <Footer />;
  };

  return (
    <>
      <Gnb />
      {renderLnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default StudentRoute;
