// Packages
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// Assets
import 'styles/css/exam.css';
// Functions
import request from 'utils/Request.utils.js';
import { nvl, nvlNumber } from 'utils/Common.utils.js';
import LocalStorage from 'utils/LocalStorage.utils.js';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';
import {
  deleteFullTimeToLocalStorage,
  deleteRestTimeToLocalStorage,
  deleteTimeDiffToLocalStorage,
  setInProgressToLocalStorage,
} from '../_utils/functions/timerLocalStorageFunctions';

/** InProgress (진행 중인 시험 재진입 시, 안내 페이지) 페이지 */
const InProgress = () => {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const sendParams = LocalStorage.getItemJsonParse('sendParams');

  /** 토큰 새로고침 후 페이지 이동 */
  const refreshTokenInInprogress = () => {
    const userToken = LocalStorage.getItemJsonParse('userSession');

    const successHandler = (response) => {
      // console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        const { accessToken, refreshToken } = response.result;

        // 토큰 저장
        LocalStorage.setItem('userSession', 'accessToken', accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', refreshToken);

        // API 호출 성공 시 시험 초기화 함수 실행
        resumeExam(sendParams?.uthSeq);
      }
    };

    request.post('/api/member/token/refresh', { refreshToken: userToken?.refreshToken }, successHandler).catch((error) => {
      console.log('refresh token error! : ', error);
      alert('토큰 갱신 실패');
    });
  };

  /** 세트 내 마지막 문제들 조회 API 요청 */
  const resumeExam = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        if (response.result?.latestTestResultAll != null) {
          const latestTestResultAll = response.result.latestTestResultAll;
          const questionOrder = response.result.latestQuestionOrder;
          const remainingTime = response.result.remainingTime;

          const subject = latestTestResultAll[0].subject;
          const moduleNum = parseInt(latestTestResultAll[0].moduleNum);
          const diff = latestTestResultAll[0].difficulty;

          console.log('🚨 remainingTime :', remainingTime, '🚨');
          setInProgressToLocalStorage({ inProgressTime: remainingTime });

          if (remainingTime < 300)
            dispatch({
              type: 'setExamComponents',
              payload: { fieldName: 'runOutTime', data: true },
            });
          else
            dispatch({
              type: 'setExamComponents',
              payload: { fieldName: 'runOutTime', data: false },
            });

          attachPriorData(latestTestResultAll, subject, moduleNum, diff, questionOrder);
        }
      }
    };

    request.get(`/api/exam/latestTestResultAll?uthSeq=${uthSeq}`, null, successHandler).catch((error) => {
      console.error(error);
      alert('in progress - 세트 내 마지막 문제들 조회 API 응답 실패');
    });
  };

  /** 이전 데이터를 배열에 담아서 다음 API 요청 함수(문제 목록 얻어오기)에 넘기는 함수 */
  const attachPriorData = (latestTestResultAll, subject, moduleNum, diff, questionOrder) => {
    let arr = [];

    latestTestResultAll.forEach((item, index) => {
      arr.push({
        tryAnswer: item.tryAnswer,
        bookMark: item.bookmarkYn,
        underLine1: item.underLine1,
        underLine2: item.underLine2,
        underLine3: item.underLine3,
        underLine4: item.underLine4,
      });
    });

    getQuestionList(arr, subject, moduleNum, diff, questionOrder);
  };

  /** 문제 목록 얻어오기 API 요청 */
  const getQuestionList = (arr, subject, moduleNum, diff, questionOrder) => {
    let pms = {
      uthSeq: sendParams.uthSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const paramsList = getQuestionListFilter(subject, moduleNum, diff, response.result.customSetFullInfo);

        testInit(arr, subject, questionOrder, paramsList[0]);

        let currentExamInfo1 = stateExamInfo.currentTest; // 리덕스에 난이도 추가

        if (nvl(subject) === 'E' && moduleNum === 2)
          currentExamInfo1 = {
            ...currentExamInfo1,
            em1: 'D',
            em2: 'I',
            em2d: diff,
            mm1: 'R',
            mm2: 'R',
            mm2d: '',
          };
        else if (nvl(subject) === 'E') currentExamInfo1 = { ...currentExamInfo1, em1: 'I', em2: 'R', em2d: '', mm1: 'R', mm2: 'R', mm2d: '' };
        else if (nvl(subject) === 'M' && moduleNum === 2)
          currentExamInfo1 = {
            ...currentExamInfo1,
            em1: 'D',
            em2: 'D',
            mm1: 'D',
            mm2: 'I',
            mm2d: diff,
          };
        else if (nvl(subject) === 'M')
          currentExamInfo1 = {
            ...currentExamInfo1,
            em1: 'D',
            em2: 'D',
            mm1: 'I',
            mm2: 'R',
            mm2d: '',
          };

        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'currentTest', data: { ...currentExamInfo1 } },
        });
      }
    };

    request.get(`/api/exam/customSetFullInfo?uthSeq=${pms.uthSeq}`, null, successHandler).catch((error) => {
      console.error(error);
      alert('in progress - 문제 목록 얻어오기 API 응답 실패');
    });
  };

  /** 얻어 온 문제 필터링 */
  const getQuestionListFilter = (subject, moduleNum, diff, questionList) => {
    if (questionList != null && questionList.length > 0) {
      return questionList.map((outerItem, outerIndex) => {
        const parsedInfo = JSON.parse(outerItem?.customSetQuestionInfo);

        return parsedInfo.filter(
          (innerItem) =>
            innerItem != null &&
            innerItem.module_subject === nvl(subject) &&
            parseInt(innerItem.module_num) === moduleNum &&
            innerItem.module_difficulty === diff
        );
      });
    }
  };

  /** 필터링 된 시험 목록을 전역 상태에 갱신 */
  const testInit = (arr, subject, questionOrder, filteredList) => {
    deleteFullTimeToLocalStorage();
    deleteRestTimeToLocalStorage();
    deleteTimeDiffToLocalStorage();

    filteredList.forEach((item, index) => {
      if (index === 0) {
        // 세트 정보에다가 넣기
        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'setSubject', data: item.set_subject },
        });
        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'setDifficulty', data: item.set_difficulty },
        });
      }

      arr[index] = {
        ...arr[index],
        customSetSeq: item.custom_set_seq,
        setUniqueCode: item.set_unique_code,
        testName: item.test_name,
        setSubject: item.set_subject,
        setDifficulty: item.set_difficulty,
        setRegUserSeq: item.set_reg_user_seq,
        setRegDate: item.set_reg_date,
        setModiUserSeq: item.set_modi_user_seq,
        setModiDate: item.set_modi_date,
        testModuleSeq: item.test_module_seq,
        moduleUniqueCode: item.module_unique_code,
        moduleSubject: item.module_subject,
        moduleNum: parseInt(item.module_num),
        moduleDifficulty: item.module_difficulty,
        qmhSeq: item.qmh_seq,
        questionSeq: item.question_seq,
        multipleChoiceItems1: item.multiple_choice_items1,
        multipleChoiceItems2: item.multiple_choice_items2,
        multipleChoiceItems3: item.multiple_choice_items3,
        multipleChoiceItems4: item.multiple_choice_items4,
        questionSubject: item.question_subject,
        testHow: item.test_how,
        questionFormat: item.question_format,
        questionDifficulty: item.question_difficulty,
        fieldOfStudy: item.field_of_study,
        researcher: item.researcher,
        passage: item.passage,
        question: item.question,
        annotation: [
          // added by psk 20240228
          {
            annotationStart: 0,
            annotationEnd: 0,
            selected_words: '',
            annotationContent: item.annotation_content,
          },
        ],
      };
    });

    /** 전역 상태 갱신용 새로운 시험 문제 배열 */
    const newQuestionList = arr
      .map((innerItem, innerIndex) => {
        if (innerItem.questionSeq) {
          let currentExamInfo2 = stateExamInfo.questionList[innerIndex + 1];
          currentExamInfo2 = {
            ...currentExamInfo2,
            customSetSeq: innerItem.customSetSeq,
            setUniqueCode: innerItem.setUniqueCode,
            testName: innerItem.testName,
            setSubject: innerItem.setSubject,
            setDifficulty: innerItem.setDifficulty,
            setRegUserSeq: innerItem.setRegUserSeq,
            setRegDate: innerItem.setRegDate,
            setModiUserSeq: innerItem.setModiUserSeq,
            setModiDate: innerItem.setModiDate,
            testModuleSeq: innerItem.testModuleSeq,
            moduleUniqueCode: innerItem.moduleUniqueCode,
            moduleSubject: innerItem.moduleSubject,
            moduleNum: parseInt(innerItem.moduleNum),
            moduleDifficulty: innerItem.moduleDifficulty,
            qmhSeq: innerItem.qmhSeq,
            questionSeq: innerItem.questionSeq,
            multipleChoiceItems1: innerItem.multipleChoiceItems1,
            multipleChoiceItems2: innerItem.multipleChoiceItems2,
            multipleChoiceItems3: innerItem.multipleChoiceItems3,
            multipleChoiceItems4: innerItem.multipleChoiceItems4,
            questionSubject: innerItem.questionSubject,
            testHow: innerItem.testHow,
            questionFormat: innerItem.questionFormat,
            questionDifficulty: innerItem.questionDifficulty,
            fieldOfStudy: innerItem.fieldOfStudy,
            researcher: innerItem.researcher,
            passage: innerItem.passage,
            question: innerItem.question,
            tryAnswer: nvl(innerItem.tryAnswer),
            bookMark: innerItem.bookMark,
            underLine1: innerItem.underLine1,
            underLine2: innerItem.underLine2,
            underLine3: innerItem.underLine3,
            underLine4: innerItem.underLine4,
            annotation: innerItem.annotation,
          };
          return currentExamInfo2;
        }
      })
      .filter((item) => item != undefined);

    dispatch({
      type: 'setExamQuestionList',
      payload: newQuestionList,
    });

    // ----------------------------------------------------------------------------------------------
    // added by psk 20240119 - 전달 파라메터 날리기
    LocalStorage.clearItem('sendParams');

    if (nvl(subject) === 'E') {
      navigate('/exam/dsat/rw', {
        state: { currentNum: questionOrder },
        replace: true,
      });
    } else if (nvl(subject) === 'M') {
      navigate('/exam/dsat/math', {
        state: { currentNum: questionOrder },
        replace: true,
      });
    }
  };

  useEffect(() => {
    document.body.classList.add('dsat-intro-3');
    // modified by psk 20240119
    if (nvlNumber(sendParams?.uthSeq) > 0) {
      dispatch({
        type: 'setExamInfo',
        payload: { fieldName: 'uthSeq', data: sendParams?.uthSeq },
      });

      LocalStorage.setItem('sendTime', 'time', moment());

      refreshTokenInInprogress();
    } else {
      if (userInfo != null && nvl(userInfo?.userId) !== '' && (stateExamInfo['currentTest'].em1 === 'I' || stateExamInfo['currentTest'].em2 === 'I')) {
        navigate('/exam/dsat/rw', {
          state: { currentNum: stateExamInfo.currentNum },
          replace: true,
        });
      } else if (userInfo != null && nvl(userInfo?.userId) !== '' && (stateExamInfo['currentTest'].mm1 === 'I' || stateExamInfo['currentTest'].mm2 === 'I')) {
        navigate('/exam/dsat/math', {
          state: { currentNum: stateExamInfo.currentNum },
          replace: true,
        });
      }
    }

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인

    return () => {
      document.body.classList.remove('dsat-intro-3');
      window.removeEventListener('message', handleMessageEvent);

      LocalStorage.clearItem('sendParams'); // breaktime 벗어낫을 때 전달 파라메터 날리기
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='com_popup type_normal active pop_progress'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='dsat-intro-3_main-flex-container'>
            <h1>We’re Preparing Your Practice Test</h1>
            <div>
              <div className='dsat-intro-3_loading-img-container'>
                <div className='3_hourglass'></div>
                <div id='cloud1' className='dsat-intro-3_cloud'></div>
                <div id='cloud2' className='dsat-intro-3_cloud'></div>
              </div>
              <p>
                This may take up to a minute.
                <br />
                Please don’t refresh this page or quit the app.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InProgress;
