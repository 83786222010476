import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';

import request from 'utils/Request.utils.js';
import { nvlNumber } from 'utils/Common.utils.js';
import LoadingBar from 'utils/LoadingBar.js';

//components
//chart
import LineChart from 'components/_common/chart/LineChart.js';

import TopArea from './_components/layouts/TopArea';
import ScoresChartSection from './_components/sections/ScoresChartSection';
import StudentListSection from './_components/sections/studentListSection/StudentListSection';
import AdvancedReport from './_components/sections/advancedReportSection/AdvancedReport';
import ScoreStatsSection from './_components/sections/scoreStatsSection/ScoreStats';
import MainLink from 'components/_common/button/_MainLinks';

/** com/score/ 페이지 */
export default function ScoreMainPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////

  const [printActive, setPrintActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scorePageInfo, setScorePageInfo] = useState({
    classSeq: 0,
    testSetSeq: 0,
    classList: [],
    takenTest: [],
    memberList: [],
    memberList2: [],
    className: '',
    testName: '',
    startDate: '',
    endDate: '',
    weeklyEngScores: [],
    weeklyMathScores: [],
    weeklyDate: [],
  });
  const [scoreChartData1, setScoreChartData1] = useState({
    series: [0],
    legend: {
      show: false,
    },
    colors: ['#008CFF'],
  });

  const [scoreChartData2, setScoreChartData2] = useState({
    series: [0],
    legend: {
      show: false,
    },
    colors: ['#1FDF9F'],
  });

  /** Report Card data */
  const [testResult, setTestResult] = useState({
    //Report Card data
    userSeq: '',
    userName: '',
    studentGrade: '',
    questionList: [],
    questionAccuracyList: [],
    firstTestDate: '',
    rwCurrentScore: 0,
    mathCurrentScore: 0,

    engDiff: '',
    mathDiff: '',
    testStartDate: '',
    dateForm: '',
    engAllRate: 0,
    mathAllRate: 0,
    testName: '',
    className: '',
  });

  const [filterValue, setFilterValue] = useState({
    uthSeq: 0,
    userSeq: 0,
    customSetSeq: 0,
    classSeq: 0,
    moduleNum: '',
    setSubject: '',
    tmSubject: '',
    tmDifficulty: '',
    color: '#008CFF',
    subjectOption: false,
    moduleNumOption: false,
    className: 'custom-tooltip',
  });

  /** Class, Student, Lecture 탭 */
  const [tabState, setTabState] = useState(0);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const getClassList = () => {
    setLoading(true);
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let classList = response.result.classList || [];
        let latestClassSeq = response.result.maxClass || 0;

        let latestClass = classList?.find((item) => item.classSeq === latestClassSeq);

        setScorePageInfo((prev) => {
          return {
            ...prev,
            classSeq: latestClassSeq,
            testSetSeq: latestClass?.customSetSeq,
            classList: classList,
            className: latestClass?.className,
          };
        });

        if (latestClassSeq > 0) classTestTaken(latestClassSeq);
        else setLoading(false);
      }
    };

    request.get(`/api/dsat/score/class?ecSeq=${userInfo?.ecSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  const classTestTaken = (classSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      // console.log('여기 들었냐',response);

      if (response.code === 200) {
        let takenTest = response.result.takenTest;

        setLoading(false);
        if (takenTest.length > 0) {
          const shortEndDate = takenTest.sort((a, b) => dayjs(b.testEndDate) - dayjs(a.testEndDate));
          setScorePageInfo((prev) => {
            return {
              ...prev,
              classSeq: classSeq,
              testSetSeq: takenTest[0]?.customSetSeq,
              takenTest: shortEndDate,
              testName: takenTest[0]?.testName,
            };
          });
          classAvgPoint(classSeq, takenTest[0].customSetSeq);
        } else {
          setScorePageInfo((prev) => {
            return {
              ...prev,
              classSeq: 0,
              testSetSeq: 0,
              takenTest: [],
              testName: '',
              memberList: [],
              memberList2: [],
            };
          });
          setTestResult((prev) => {
            return {
              ...prev,
              rwCurrentScore: 0,
              mathCurrentScore: 0,
              engDiff: '',
              mathDiff: '',
              startDate: '',
              endDate: '',
            };
          });
          setFilterValue((prev) => {
            return { ...prev, classSeq: 0, customSetSeq: 0 };
          });
          setScoreChartData1((prev) => {
            return { ...prev, series: [0] };
          });
          setScoreChartData2((prev) => {
            return { ...prev, series: [0] };
          });
          setScorePageInfo((prev) => {
            return {
              ...prev,
              weeklyEngScores: [null, null, null, null, null, null, null, null],
              weeklyMathScores: [null, null, null, null, null, null, null, null],
            };
          });
        }
      }
    };

    request.get(`/api/dsat/score/class/taken?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  const classAvgPoint = (classSeq, customSetSeq) => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let rwAvgPoint = response.result.rwAvgPoint;
        let rwDiff = response.result.rwDiff;
        let mathAvgPoint = response.result.mathAvgPoint;
        let mathDiff = response.result.mathDiff;
        let memberList = response.result.memberTakerList;
        let memberList2 = response.result.memberList;

        setScorePageInfo((prev) => {
          return {
            ...prev,
            classSeq: classSeq,
            testSetSeq: customSetSeq,
            memberList: memberList,
            memberList2: memberList2,
            testName: memberList[0]?.testName,
            startDate: memberList[0]?.testStartDate,
            endDate: memberList[0]?.testEndDate,
          };
        });
        setTestResult((prev) => {
          return {
            ...prev,
            rwCurrentScore: rwAvgPoint,
            mathCurrentScore: mathAvgPoint,
            engDiff: rwDiff,
            mathDiff: mathDiff,
            setDifficulty: memberList[0]?.setDifficulty,
            testStartDate: memberList[0]?.testEndDate,
            testName: memberList[0]?.testName,
          };
        });
        setScoreChartData1((prev) => {
          return { ...prev, series: [(rwAvgPoint / 800) * 100] };
        });
        setScoreChartData2((prev) => {
          return { ...prev, series: [(mathAvgPoint / 800) * 100] };
        });

        weeklyScores(classSeq, memberList[0].testStartDate.split(' ')[0]);
        setFilterValue((prev) => {
          return {
            ...prev,
            tmSubject: memberList[0]?.setSubject !== 'M' ? 'E' : 'M',
            classSeq: classSeq,
            customSetSeq: customSetSeq,
            setSubject: memberList[0]?.setSubject,
            color: '#008CFF',
          };
        });

        if (tabState === 1) {
          setTabState(0);
        } else {
          setFilterValue((prev) => {
            return { ...prev, uthSeq: 0, userSeq: 0 };
          });
        }
      }
    };

    request
      .get(`/api/dsat/average/class?ecSeq=${userInfo?.ecSeq}&customSetSeq=${customSetSeq}&classSeq=${classSeq}`, null, successHandler)
      .catch((error) => console.error('', error));
  };

  const weeklyScores = (classSeq, testStartDate, userSeq) => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let weeklyScores = response.result.weeklyScores;

        let engAvg = weeklyScores?.filter((i) => i.subject === 'English');
        let mathAvg = weeklyScores?.filter((i) => i.subject === 'Math');

        let engAvgPoint = engAvg?.map((i) => i.data);
        let mathAvgPoint = mathAvg?.map((i) => i.data);
        let weeklyDate = engAvg?.map((i) => dayjs(i.mondayDt).format('MM/DD'));

        setScorePageInfo((prev) => {
          return {
            ...prev,
            weeklyEngScores: engAvgPoint,
            weeklyMathScores: mathAvgPoint,
            weeklyDate: weeklyDate,
          };
        });
      }
    };

    request
      .get(
        `/api/dsat/score/weekly?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}&userSeq=${nvlNumber(userSeq)}&testStartDate=${testStartDate}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  /** 학생 선택 */
  const handleSelectStudent = (uthSeq, userSeq, rwPoint, mathPoint) => {
    setTabState(1);
    setTestResult((prev) => {
      return { ...prev, rwCurrentScore: rwPoint, mathCurrentScore: mathPoint };
    });
    setScoreChartData1((prev) => {
      return { ...prev, series: [(rwPoint / 800) * 100] };
    });
    setScoreChartData2((prev) => {
      return { ...prev, series: [(mathPoint / 800) * 100] };
    });

    setFilterValue((prev) => {
      return { ...prev, uthSeq: uthSeq, userSeq: userSeq };
    });
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (userInfo?.ecSeq > 0) getClassList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabState !== 1 && nvlNumber(scorePageInfo.classSeq) > 0) classAvgPoint(scorePageInfo.classSeq, scorePageInfo.testSetSeq);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  useEffect(() => {
    if (printActive) {
      window.open(`/com/reports`, '_blank');
      setPrintActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printActive]);

  // useEffect(() => {
  //   console.log(`🚨 scorePageInfo :`, scorePageInfo, `🚨`);
  // }, [scorePageInfo]);
  // useEffect(() => {
  //   console.log(`🚨 filterValue :`, filterValue, `🚨`);
  // }, [filterValue]);
  // useEffect(() => {
  //   console.log(`🚨 tabState :`, tabState, `🚨`);
  // }, [tabState]);
  // useEffect(() => {
  //   console.log(`🚨 scorePageInfo.weeklyDate.length > 0 :`, scorePageInfo.weeklyDate.length > 0, `🚨`);
  // }, [scorePageInfo.weeklyDate.length]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='p_score_main'>
        <MainLink type='score' />
        <div className='com_container com_center_wrap'>
          <TopArea
            setScorePageInfo={setScorePageInfo}
            classTestTaken={classTestTaken}
            scorePageInfo={scorePageInfo}
            classAvgPoint={classAvgPoint}
            tabState={tabState}
            setTabState={setTabState}
            handleSelectStudent={handleSelectStudent}
          />
          {/*Scores*/}
          <ScoresChartSection testResult={testResult} scoreChartData1={scoreChartData1} scoreChartData2={scoreChartData2} />
          {/*Student List*/}
          <StudentListSection
            scorePageInfo={scorePageInfo}
            filterValue={filterValue}
            handleSelectStudent={handleSelectStudent}
            tabState={tabState}
            setPrintActive={setPrintActive}
            setLoading={setLoading}
          />
          {/*Average Weekly Scores*/}
          <article className='box weekly'>
            <div className='com_sec_tit'>
              <h2 className='tit'>
                <i className='svg_icon icon_graph black'>&nbsp;</i>
                Average Weekly Scores
              </h2>
            </div>
            <LineChart engScores={scorePageInfo?.weeklyEngScores} mathScores={scorePageInfo?.weeklyMathScores} xaxis={scorePageInfo?.weeklyDate} />
          </article>
          {/*ADVANCED REPORT*/}
          {
            filterValue.customSetSeq > 0 && (
              <AdvancedReport
                mode={tabState === 1 ? 'student' : 'class'}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                weeklyScores={weeklyScores}
              />
            ) /* advancedCard */
          }
        </div>
        {tabState !== 2 && <ScoreStatsSection />}
      </div>

      {loading && <LoadingBar />}
      {/*report card*/}
      {/* { printActive && <ReportCard setPrintActive={ setPrintActive } testResult={ testResult } barChartData={ barChartData } barChartData2={ barChartData2 } timePerQuestionData={timePerQuestionData} filterValue={filterValue} setFilterValue={setFilterValue}/> } */}
    </>
  );
}
