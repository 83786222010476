import styled from 'styled-components';

import GateKeyIcon from 'assets/img/svg/icon_gate_key.svg';

import LocalStorage from 'utils/LocalStorage.utils';

/** GateKeyPopup의 Header 컴포넌트  */
function GateKeyHeader({ remainingKey }) {
  const userSession = LocalStorage.getItemJsonParse('userSession');

  const responseLink = `${process.env.REACT_APP_MAINGATE_ROOT_URL}/sign/signin/?lastRoute=/en-US/pricing&accessToken=${userSession.accessToken}&refreshToken=${userSession.refreshToken}`;

  return (
    <S.GateKeyHeader>
      <div className='gate-key-header'>
        <h3>Gate Key</h3>
      </div>
      <div className='gate-keys'>
        <p className='remaining-key'>
          <span className='key-icon'>
            <img src={GateKeyIcon} />
          </span>
          <span className='remaining-key-section'>
            <span>Remaining</span>
            <span>:</span>
            <span className='remaining-keys'>{remainingKey}</span>
            <span>Key</span>
          </span>
        </p>
        <a href={responseLink} target='_blank' rel='noopener noreferrer'>
          Buy More
        </a>
      </div>
    </S.GateKeyHeader>
  );
}

export default GateKeyHeader;

const S = {}; // S-dot 문법용 객체

S.GateKeyHeader = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .gate-key-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .gate-keys {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid #eaeaea;

    .remaining-key {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .key-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 1.6rem;
        height: 0.875rem;
      }

      span {
        font-size: 0.875rem;
        font-weight: 400;
      }
      .remaining-key-section {
        display: flex;
        align-items: center;
        gap: 0.35rem;

        span {
          font-size: 1.125rem;
          font-weight: 600;
        }

        .remaining-keys {
          color: #0068bd;
          font-weight: 700;
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3182f6;
      border: none;
      border-radius: 3.75rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 700;
      width: 5.5rem;
      height: 2.25rem;
    }
  }
`;
