import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import 'react-calendar/dist/Calendar.css';

//components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MiniCalendar from 'components/_common/calendar/MiniCalendar';
import PopTestList from 'components/com/score/_components/_PopTestList';

// hook
import { USER_LEVELS } from 'utils/constants';
import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LoadingBar from 'utils/LoadingBar.js';

import ModalHaveToLinkWithAcademyOrTutor from 'components/_common/modals/HaveToLinkWithAcademyOrTutor';
import UpcomingTestSection from './_components/layouts/sections/upcomingTest/UpcomingTestSection';
import TimeLineSection from './_components/layouts/sections/setTimeLine/TimeLineSection';
import YourMostRecentTestSection from './_components/layouts/sections/yourMostRecentTest/YourMostRecentTestSection';
import AdvancedReportSection from './_components/layouts/sections/advancedReport/AdvancedReportSection';
import VisualArea from './_components/layouts/visualArea/VisualArea';
import VisualTextArea from './_components/layouts/visualArea/VisualTextArea';
import ScoreTrendsSection from './_components/layouts/sections/scoreTrends/ScoreTrendsSection';
import DsatInfoArea from './_components/layouts/dsatInfo/DsatInfoArea';

/** 대시보드 페이지 (Student, Parent 라우트에서 공유하는 페이지) */
export default function DashboardPage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 ///////////////////
  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝  ///////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ///////
  let { pathname } = window.location;
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////////

  /////////////////// React useState 선언 영역 시작 //////////////////////
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [dateValue, setDateValue] = useState();
  const [tableListActive, setTableListActive] = useState(false);
  // const [advancedCard, setAdvancedCard] = useState(false)
  const [childSeqState, setChildSeq] = useState(0);
  const [childEcSeq, setChildEcSeq] = useState(0);
  const [testResult, setTestResult] = useState({
    //Report Card data
    userName: '',
    studentGrade: '',
    questionList: [],
    questionAccuracyList: [],
    firstTestDate: '',
    rwCurrentScore: 0,
    mathCurrentScore: 0,
    rwCurrentRank: 0,
    mathCurrentRank: 0,
    rwRank: 0,
    mathRank: 0,

    subject: '',
    difficulty: '',
    engDiff: '',
    mathDiff: '',
    trhDate: '',
    dateForm: '',
    engAllRate: 0,
    mathAllRate: 0,
    testName: '',
    className: '',
    ecSeq: 0,
    academyUpFileSeq: 0,
    companyName: null,
  });
  const [barChartData, setBarChartData] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const [barChartData2, setBarChartData2] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const [userExpectTestList, setUserExpectTestList] = useState([]);
  const [userInProgressTestList, setUserInProgressTestList] = useState([]);
  /** 수학 주관식 문제만 있는 시험 */
  const [userOnlySubjectTestList, setUserOnlySubjectTestList] = useState();
  const [userTestHistoryList, setUserTestHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [filterValue, setFilterValue] = useState({
    uthSeq: 0,
    moduleNum: '',
    tmSubject: '',
    color: '#008CFF',
    subjectOption: false,
    moduleNumOption: false,
    className: 'custom-tooltip',
  });
  const [timePerQuestionData, setTimePerQuestionData] = useState({
    series: [
      {
        name: 'You',
        data: [],
        color: '#0068BD',
      },
      {
        name: 'GATE⁺',
        data: [],
        color: '#E3E7ED',
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const xAxisValue = w.globals.labels[dataPointIndex];
          return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontFamily: 'Roboto, Arial, sans-serif',
        markers: {
          width: 25,
          height: 8,
          radius: 8,
        },
        itemMargin: {
          horizontal: 15,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7,
          },
        },
      },
    },
  });
  const [scoreTrends, setScoreTrends] = useState({
    mathAvg: [],
    engAvg: [],
    dateArray: [],
  });

  const [isModalHaveToLinkWithAcademyOrTutor, setIsModalHaveToLinkWithAcademyOrTutor] = useState(false);
  /////////////////// React useState 선언 영역 끝 //////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const tResultSeqRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 커스텀 알럿 창 닫기 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 커스텀 알럿 창이 반환하는 값 핸들러 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  /** 학생 시퀀스 얻어오기 API 요청 */
  const getChildSeq = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let childInfo = response.result.childInfo;
        if (childInfo) {
          let childSeq = childInfo.familySeq;
          let childEcSeq = childInfo.ecSeq;
          setChildSeq(childSeq);
          setChildEcSeq(childEcSeq);
          getTodayDate(childSeq, childEcSeq);
        }
      }
    };

    request
      .get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 학생 시퀀스 얻어오기 API 실패', error));
  };

  /** 시험 목록 가져오기 */
  const getTestList = (currentDateValue, userSeq, ecSeq, reload) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let userTestList = response.result.userTestHistoryList;

        setLoading(false);
        setUserInProgressTestList(
          userTestList?.filter((i) => (i.testStatus === 'I' || i.testStatus === 'B') && moment(i.testEndDate) >= moment(currentDateValue))
        ); // 보던 시험
        setUserExpectTestList(userTestList?.filter((i) => i.testStatus === 'E' && moment(i.testEndDate) >= moment(currentDateValue))); // 볼 시험

        setUserOnlySubjectTestList(userTestList.filter((item) => item.simpleExamSeq));

        if (!reload) getTestHistoryList(userSeq, ecSeq);
      }
    };

    request
      .get(`/api/dsat/history/test/detail?userSeq=${userSeq}&ecSeq=${ecSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 목록 가져오기 API 실패', error));
  };

  /** 현재 시간 얻어오기 API 요청 */
  const getTodayDate = (userSeq, ecSeq = userInfo.ecSeq, reload = false) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let todayDate = response.result.todayDate;

        setDateValue(todayDate);

        if (nvl(todayDate) !== '') getTestList(todayDate, userSeq, ecSeq, reload);
      }
    };

    request.get('/api/common/today/time', null, successHandler).catch((error) => console.error('학생 대시보드 - 현재 시간 얻어오기 API 실패', error));
  };

  /** 시험 일정 호출 API */
  const getTestHistoryList = (userSeq, ecSeq) => {
    let pms = {
      userSeq: userSeq,
      ecSeq: ecSeq,
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let userTestHistoryList = response.result.userTestHistoryList;

        if (userTestHistoryList != null && userTestHistoryList?.length > 0) {
          setUserTestHistoryList(userTestHistoryList);
          setTestResult((prev) => {
            return {
              ...prev,
              testName: userTestHistoryList[0].testName,
              className: userTestHistoryList[0].className,
              firstTestDate: userTestHistoryList[userTestHistoryList?.length - 1].testStartDate,
            };
          });

          getTestPointOX(userTestHistoryList[0].uthSeq, userSeq);
          dailyAveragePoint(userTestHistoryList[userTestHistoryList?.length - 1].testStartDate, userSeq);
        }

        setLoading(false);
      }
    };

    request
      .get(`/api/exam/userTestHistory?userSeq=${pms.userSeq}&ecSeq=${pms.ecSeq}&testStatus=${pms.testStatus}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 일정 호출 API 실패', error));
  };

  /** 시험 정오답 내역 조회 API 요청 */
  const getTestPointOX = (uthSeq = userInfo.ecSeq, userSeq = userInfo.userSeq) => {
    // setAdvancedCard(false)
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const { testInfo, eDiff, mDiff, mathPoint, mathRank, rwPoint, rwRank } = response.result;
        setTestResult((prev) => {
          return {
            ...prev,
            ...testInfo,
            mathDiff: mDiff,
            engDiff: eDiff,
            mathCurrentScore: mathPoint,
            mathCurrentRank: 100 - mathRank,
            rwCurrentScore: rwPoint,
            rwCurrentRank: 100 - rwRank,
          };
        });
        setFilterValue((prev) => {
          return { ...prev, uthSeq: uthSeq, color: '#008CFF' };
        });

        const fetchedUserSeq = userInfo.userLevel === USER_LEVELS.STUDENT ? userSeq : childSeqState; // 학생일 경우 userSeq, 학부모일 경우 childSeqState
        getCategoriesRate(uthSeq, fetchedUserSeq);
      }
    };

    request
      .get(`/api/dsat/history/test/score?uthSeq=${uthSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 정오답 내역 조회 API 실패', error));
  };

  /** 시험 유형 별 정답율 조회 API 요청 */
  const getCategoriesRate = (uthSeq, userSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let personal = response.result.personal;
        let overall = response.result.overall;

        if (personal != null && overall?.length > 0) {
          let CAS = personal?.filter((item) => item.type === 'CAS');
          let IAI = personal?.filter((item) => item.type === 'IAI');
          let SEC = personal?.filter((item) => item.type === 'SEC');
          let EOI = personal?.filter((item) => item.type === 'EOI');

          let ALG = personal?.filter((item) => item.type === 'ALG');
          let AM = personal?.filter((item) => item.type === 'AM');
          let PSADA = personal?.filter((item) => item.type === 'PSADA');
          let GAT = personal?.filter((item) => item.type === 'GAT');

          let CAS_ = overall?.filter((item) => item.type === 'CAS');
          let IAI_ = overall?.filter((item) => item.type === 'IAI');
          let SEC_ = overall?.filter((item) => item.type === 'SEC');
          let EOI_ = overall?.filter((item) => item.type === 'EOI');

          let ALG_ = overall?.filter((item) => item.type === 'ALG');
          let AM_ = overall?.filter((item) => item.type === 'AM');
          let PSADA_ = overall?.filter((item) => item.type === 'PSADA');
          let GAT_ = overall?.filter((item) => item.type === 'GAT');

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.per), nvlNumber(IAI[0]?.per), nvlNumber(SEC[0]?.per), nvlNumber(EOI[0]?.per)],
              gatePlusData: [nvlNumber(CAS_[0]?.per), nvlNumber(IAI_[0]?.per), nvlNumber(SEC_[0]?.per), nvlNumber(EOI_[0]?.per)],
            },
          ]);
          setBarChartData2((prev) => [
            {
              ...prev,
              data: [nvlNumber(ALG[0]?.per), nvlNumber(AM[0]?.per), nvlNumber(PSADA[0]?.per), nvlNumber(GAT[0]?.per)],
              gatePlusData: [nvlNumber(ALG_[0]?.per), nvlNumber(AM_[0]?.per), nvlNumber(PSADA_[0]?.per), nvlNumber(GAT_[0]?.per)],
            },
          ]);
        }
      }
    };

    request
      .get(`/api/dsat/accuracy/category?uthSeq=${uthSeq}&userSeq=${userSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 유형 별 정답율 조회 API 실패', error));
  };

  /** 시험 정오표 조회 API 요청 */
  const getQuestionErrata = (uthSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.questionList || [];

        setTestResult((prev) => {
          return {
            ...prev,
            questionList: questionList,
            trhDate: questionList[questionList.length - 1]?.trhRegDate,
          };
        });

        accuracyByQuestion(uthSeq);
      }
    };

    request
      .get(`/api/dsat/question/errata?uthSeq=${uthSeq}&tmSubject=${filterValue.tmSubject}&moduleNum=${filterValue.moduleNum}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 정오표 조회 API 실패', error));
  };

  /** 시험 문제 별 정확도 조회 API 요청 */
  const accuracyByQuestion = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionAccuracyList = response.result.questionAccuracyList;

        setTestResult((prev) => {
          return { ...prev, questionAccuracyList: questionAccuracyList };
        });

        setLoading(false);
        setLoadData((prev) => !prev);
      }
    };

    request
      .get(
        `/api/dsat/accuracy/question?uthSeq=${uthSeq}&ecSeq=${childEcSeq > 0 ? childEcSeq : userInfo.ecSeq}&classSeq=${testResult.classSeq}`,
        null,
        successHandler
      )
      .catch((error) => console.error('학생 대시보드 - 시험 문제 별 정확도 조회 API 실패', error));
  };

  /** 시험 문제 별 소요 시간 조회 API */
  const timePerQuestion = (uthSeq) => {
    let retryCount = 0; // 재시도 횟수

    let pms = {
      userSeq: childSeqState > 0 ? childSeqState : userInfo?.userSeq,
      moduleNum: filterValue.moduleNum,
      subject: filterValue.tmSubject,
      difficulty: nvl(filterValue.moduleNum === '1' ? '' : filterValue.tmSubject !== 'E' ? testResult.mathDiff : testResult.engDiff),
    };

    const successHandler = (response) => {
      let count = 0;
      if (response.code === 200) {
        let gatePlusDurationList = response.result.personalDurationListAll;
        let personalDurationList = response.result.personalDurationList;

        const youUseSeconds = personalDurationList?.map((item) => item.useSeconds);
        const gateUseSeconds = gatePlusDurationList?.map((item) => item.secAvg);
        if (youUseSeconds && gateUseSeconds) {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              { name: 'You', data: youUseSeconds, color: filterValue.color },
              { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
            ],
            options: {
              ...prevData.options,
              xaxis: {
                categories: youUseSeconds?.map((_, index) => index + 1),
              },
              tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                  const xAxisValue = w.globals.labels[dataPointIndex];
                  const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

                  if (seriesName === 'YOU' && seriesIndex === 0) {
                    return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
                    return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  }
                },
              },
            },
          }));
          setLoadData((prev) => !prev);
        } else if (retryCount++ < 5) timePerQuestion(uthSeq);
      }
    };

    request
      .get(
        `/api/dsat/duration/question?uthSeq=${uthSeq}&userSeq=${pms.userSeq}&moduleNum=${pms.moduleNum}&subject=${pms.subject}&difficulty=${pms.difficulty}`,
        null,
        successHandler
      )
      .catch((error) => console.error('학생 대시보드 - 시험 문제 별 소요 시간 조회 API 실패', error));
  };

  /** 중복 제거 */
  const removeDuplicates = (arr, prop) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };

  /** 시험 일일 평균 점수 조회 API 요청 */
  const dailyAveragePoint = (firstTestDate, userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let dailyAverageList = response.result.dailyAverageList;

        /** dt 중복제거 */
        const dtList = removeDuplicates(dailyAverageList, 'dt');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }

        if (firstDtDateIndex !== -1) {
          if (moment(dtList[0].dt).isAfter(moment(firstTestDate))) {
            const engScore = Array.from({ length: 7 }, (_, index) => {
              const dailyItems = dailyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 7 }, (_, index) => {
              const dailyItems = dailyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate = moment(dtList[index].dt).format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          } else {
            const engScore = Array.from({ length: 7 }, (_, index) => {
              const dailyItems = dailyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = index < 7 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 7 }, (_, index) => {
              const dailyItems = dailyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = index < 7 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate =
                index < dtList.length - firstDtDateIndex
                  ? moment(dtList[firstDtDateIndex + index].dt).format('MM/DD')
                  : moment(dtList[firstDtDateIndex].dt).add(index, 'days').format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          }
        } else {
          const dateArray = Array.from({ length: 7 }, (_, index) => {
            const dailyDate = index < 7 ? moment(dtList[index].dt).format('MM/DD') : moment(dtList[index].dt).add(index, 'days').format('MM/DD');

            return dailyDate;
          });

          setScoreTrends((prev) => {
            return { ...prev, engAvg: [], mathAvg: [], dateArray: dateArray };
          });
        }
      }
    };

    request
      .get(`/api/dsat/average/point/daily?userSeq=${userSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 일일 평균 점수 조회 API 실패', error));
  };

  /** 시험 주 별 평균 점수 조회 API 요청 */
  const weeklyAveragePoint = (firstTestDate) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let weeklyAverageList = response.result.weeklyAverageList;

        /** dt 중복제거 */
        const dtList = removeDuplicates(weeklyAverageList, 'dt2');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }

        if (firstDtDateIndex !== -1) {
          if (moment(weeklyAverageList[0].dt2).isAfter(moment(firstTestDate))) {
            const engScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate = moment(dtList[index].dt2).format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          } else {
            const engScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = index < 8 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = index < 8 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate =
                index < dtList.length - firstDtDateIndex - 1
                  ? moment(dtList[firstDtDateIndex + index].dt2).format('MM/DD')
                  : moment(dtList[firstDtDateIndex].dt2)
                      .add(index * 7, 'days')
                      .format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          }
        }
      }
    };

    request
      .get(`/api/dsat/average/point/weekly?userSeq=${userInfo?.userLevel === USER_LEVELS.STUDENT ? userInfo?.userSeq : childSeqState}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 주 별 평균 점수 조회 API 실패', error));
  };

  /** 시험 월 별 평균 점수 조회 API 요청 */
  const monthlyAveragePoint = (firstTestDate) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let monthlyAverageList = response.result.monthlyAverageList;

        const dtList = removeDuplicates(monthlyAverageList, 'dt');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }

        if (firstDtDateIndex !== -1) {
          if (moment(monthlyAverageList[0].dt).isAfter(moment(firstTestDate))) {
            const engScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate = moment(dtList[index].dt).format('YY-MMM');
              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          } else {
            const engScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = index < 12 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = index < 12 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate =
                index < dtList.length - firstDtDateIndex - 1
                  ? moment(dtList[firstDtDateIndex + index].dt).format('YYYY MMM.')
                  : moment(dtList[firstDtDateIndex].dt).add(index, 'months').format('YYYY MMM.');

              const monthFormat = dailyDate.includes('Jan') ? dailyDate : dailyDate.substring(5);
              return monthFormat;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          }
        }
      }
    };

    request
      .get(`/api/dsat/average/point/monthly?userSeq=${userInfo?.userLevel === USER_LEVELS.STUDENT ? userInfo?.userSeq : childSeqState}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 월 별 평균 점수 조회 API 실패', error));
  };

  /** 학생 - 학원 연결 팝업 닫기 기능 */
  const handleModalHaveToLinkWithAcademyOrTutorClose = () => {
    setIsModalHaveToLinkWithAcademyOrTutor(false);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ///////////////////////
  useEffect(() => {
    if (userInfo.ecSeq > 0) {
      console.log('학원 연결 됨');
      setIsModalHaveToLinkWithAcademyOrTutor(false);
    } else {
      console.log('학원 연결 안 됨');
      // 연결 된 학원 목록이 0개 이하인 경우, 모달 노출
      setIsModalHaveToLinkWithAcademyOrTutor(true);
    }

    if (userInfo.userLevel === USER_LEVELS.STUDENT) getTodayDate(userInfo.userSeq, userInfo.ecSeq);
    else getChildSeq();

    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (filterValue.tmSubject !== '' && filterValue.uthSeq > 0) {
      timePerQuestion(filterValue.uthSeq);
      getQuestionErrata(filterValue.uthSeq);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.tmSubject, filterValue.uthSeq, filterValue.moduleNum]);
  /////////////////// React useEffect 영역 끝 ///////////////////////

  /////////////////// window 조작 영역 시작 //////////////////
  // added by psk 20240117 - 팝업창과 통신
  window.parentLoadData = () => {
    getTodayDate(userInfo.userSeq, userInfo.ecSeq, true);
  };

  window.tResultSeqRefUpdate = (tResultSeq) => {
    tResultSeqRef.current = tResultSeq;
  };
  // 리뷰 팝업창에서 북마크 클릭시 호출
  window.getParentModule = (uthSeq) => {
    getQuestionErrata(uthSeq);
  };
  /////////////////// window 조작 영역 끝 //////////////////

  return (
    <>
      <div className='p_student_dashboard'>
        <VisualArea />
        <div className='com_center_wrap'>
          <VisualTextArea />
          <div className='dashboard_wrap'>
            <div className='com_container left'>
              {/*CALENDAR*/}
              <section className='box full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_calendar black'>&nbsp;</i>
                    CALENDAR
                  </h2>
                </article>
                <div className='com_calendar_wrap'>
                  <MiniCalendar pathname={pathname} />
                </div>
              </section>
            </div>
            <div className='com_container right'>
              <section className='box full upcoming_test_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    Upcoming Test
                  </h2>
                </article>
                <UpcomingTestSection
                  setLoading={setLoading}
                  userExpectTestList={userExpectTestList}
                  setUserExpectTestList={setUserExpectTestList}
                  userInProgressTestList={userInProgressTestList}
                  setUserInProgressTestList={setUserInProgressTestList}
                  userOnlySubjectTestList={userOnlySubjectTestList}
                  dateValue={dateValue}
                  setAlertLayerPopup={setAlertLayerPopup}
                  getTodayDate={getTodayDate}
                  tResultSeqRef={tResultSeqRef}
                />
              </section>
              <section className='box full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    SAT Timeline
                  </h2>
                </article>
                <TimeLineSection />
              </section>
            </div>
            <div className='com_container'>
              {/*Your Most Recent Test*/}
              <section className='box full recent_test_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_graph black'>&nbsp;</i>
                    Your Most Recent Test
                  </h2>
                </article>
                <YourMostRecentTestSection
                  testResult={testResult}
                  userTestHistoryList={userTestHistoryList}
                  setTableListActive={setTableListActive}
                  tableListActive={tableListActive}
                  filterValue={filterValue}
                  childSeqState={childSeqState}
                />
              </section>
              <section className='box report full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_graph black'>&nbsp;</i>
                    ADVANCED REPORT
                  </h2>
                </article>
                <AdvancedReportSection
                  testResult={testResult}
                  barChartData={barChartData}
                  barChartData2={barChartData2}
                  timePerQuestionData={timePerQuestionData}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  loadData={loadData}
                />
              </section>
              {/*Score Trends */}
              <section className='box full score_trends_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_graph black'>&nbsp;</i>
                    Score Trends
                  </h2>
                </article>
                <ScoreTrendsSection
                  testResult={testResult}
                  childSeqState={childSeqState}
                  dailyAveragePoint={dailyAveragePoint}
                  weeklyAveragePoint={weeklyAveragePoint}
                  monthlyAveragePoint={monthlyAveragePoint}
                  scoreTrends={scoreTrends}
                />
              </section>
            </div>
            <div className='com_container'>
              <DsatInfoArea />
            </div>
          </div>
        </div>
      </div>
      {/* { printActive && <ReportCard setPrintActive={ setPrintActive } testResult={ testResult } barChartData={ barChartData } barChartData2={ barChartData2 } timePerQuestionData={ timePerQuestionData } filterValue={ filterValue } setFilterValue={ setFilterValue }/> } */}
      {tableListActive && (
        <PopTestList close={() => setTableListActive(false)} className={testResult.className} testTakenList={userTestHistoryList} clickEvent={getTestPointOX} />
      )}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {loading && <LoadingBar />}

      {/* Modals area Start */}
      {isModalHaveToLinkWithAcademyOrTutor && <ModalHaveToLinkWithAcademyOrTutor close={handleModalHaveToLinkWithAcademyOrTutorClose} />}
      {/* Modals area End */}
    </>
  );
}
