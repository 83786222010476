import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/** 하단 annotation 영역 컴포넌트 */
export default function AnnotationBottom({
  setIsClickAnnotate,
  annotateName,
  annotateInputRef,
  annotateMemo,
  setAnnotateMemo,
  setIsDragged,
  annotationId,
  setAnnotateName,
  setAnnotationId,
}) {
  const dispatch = useDispatch();

  const [annotateDelete, setAnnotateDelete] = useState(false);
  const [localAnnotateMemo, setLocalAnnotateMemo] = useState(annotateMemo);

  useEffect(() => {
    setLocalAnnotateMemo(annotateMemo);
  }, [annotateMemo]);

  const handleMemoChange = (event) => setLocalAnnotateMemo(event.target.value);

  return (
    <section className='annotate_sec'>
      <article className='top'>
        <div className='txt'>
          <b>New Annotation:</b> "{annotateName}"
        </div>
        <button
          className='btn_layer_close'
          onClick={() => {
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${annotateName}"]`);

            selectedAnnotations.forEach((el) => {
              if (annotateMemo?.length === 0) {
                const parent = el.parentNode;
                const textNode = document.createTextNode(el.textContent);
                parent.replaceChild(textNode, el);
              } else {
                el.classList.remove('rangy-highlight-selected');
              }
            });

            dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });

            setIsDragged(false);
            setIsClickAnnotate(false);
            setAnnotateMemo('');
            setAnnotationId('');
            setAnnotateName('');
          }}>
          CLOSE<i className='svg_icon btn_pop_close white'></i>
        </button>
      </article>
      {
        <>
          {!annotateDelete ? (
            <article className='contents'>
              <ul className='info'>
                <li className='item'>
                  Highlight Color: <i className='svg_icon icon_highlight'>&nbsp;</i>
                </li>
                <li className='item'>
                  Underline style: <i className='svg_icon icon_underline'>&nbsp;</i>
                </li>
              </ul>
              <textarea className='textarea' ref={annotateInputRef} defaultValue={annotateMemo} onChange={handleMemoChange}></textarea>
              <button
                className='btn'
                onClick={() => {
                  if (annotateInputRef.current.value === '') {
                    const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${annotateName}"]`);

                    selectedAnnotations.forEach((el) => {
                      if (annotateMemo?.length === 0) {
                        const parent = el.parentNode;
                        const textNode = document.createTextNode(el.textContent);
                        parent.replaceChild(textNode, el);
                      } else {
                        el.classList.remove('rangy-highlight-selected');
                      }
                    });

                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationEmptyDisplay', data: false } });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });
                    setIsDragged(false);
                    setIsClickAnnotate(false);
                    setAnnotateMemo('');
                    setAnnotationId('');
                    setAnnotateName('');

                    return;
                  }
                  setIsClickAnnotate(false);
                  dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });
                  dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationSelectedWords', data: true } });

                  const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');

                  highlightedElements.forEach((el) => {
                    el.setAttribute('data-memo', annotateInputRef.current.value);
                    el.classList.remove('rangy-highlight-selected');
                  });
                }}>
                Save
              </button>
              {annotateMemo === '' ? (
                <button
                  className='btn cancel'
                  onClick={() => {
                    const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                    highlightedElements.forEach((el) => {
                      const parent = el.parentNode;
                      const textNode = document.createTextNode(el.textContent);
                      parent.replaceChild(textNode, el);
                    });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });

                    setIsDragged(false);
                    setIsClickAnnotate(false);
                  }}>
                  Cancel
                </button>
              ) : (
                <button
                  className='btn del'
                  onClick={() => {
                    setAnnotateDelete(!annotateDelete);
                  }}>
                  <i className='svg_icon icon_trashcan before'>&nbsp;</i>
                  Delete
                </button>
              )}
            </article>
          ) : (
            <article className='contents notice'>
              <div className='tit'>Are You Sure You Want To Delete This Annotation?</div>
              <div className='txt'>Once you delete this annotation, any notes you've made will no longer be available.</div>
              <div className='com_btn_wrap bottom'>
                <button
                  className='btn keep'
                  onClick={() => {
                    const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                    highlightedElements.forEach((el) => {
                      el.classList.remove('rangy-highlight-selected');
                    });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationSelectedWords', data: true } });

                    setIsDragged(false);
                    setIsClickAnnotate(false);
                  }}>
                  Keep Annotation
                </button>
                <button
                  className='btn yellow'
                  onClick={() => {
                    const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                    highlightedElements.forEach((el) => {
                      const parent = el.parentNode;
                      const textNode = document.createTextNode(el.textContent);
                      parent.replaceChild(textNode, el);
                    });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: false } });
                    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationSelectedWords', data: true } });

                    setIsDragged(false);
                    setIsClickAnnotate(false);
                    setAnnotateMemo('');
                    setAnnotationId('');
                    setAnnotateName('');
                  }}>
                  Delete Annotation
                </button>
              </div>
            </article>
          )}
        </>
      }
    </section>
  );
}
