import ReactApexChart from 'react-apexcharts';

export default function ScoreCircleChart({ rank, size = '4.25rem' }) {
  const options = {
    series: [`${rank}`],
    chart: {
      height: '100%',
      width: '100%',
      type: 'radialBar',
      sparkline: { enabled: true }, // 차트 외 모든 요소 숨김, 작은 차트에 최적화
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '45%', // 내부 원의 크기를 조절합니다. 값이 클수록 그래프 얇아짐
        },
        dataLabels: {
          // 차트 안에 표시되는 라벨 스타일 속성
          name: {
            show: true,
            offsetY: 4, // 라벨 위에서부터 거리
            fontSize: '10px',
            color: '#111',
            fontWeight: 'bold',
          },
          value: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '5px',
      },
    },

    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          {
            offset: 0, //그라데이션의 위치 0~100
            color: '#0068BD', // 시작 색상
            opacity: 0.7, //그라데이션의 투명도 0~1
          },
          {
            offset: 100,
            color: '#1D4ED8', // 끝 색상
            opacity: 1,
          },
        ],
      },
    },
    labels: [`${rank}${rank !== '-' ? '%' : ''}`],
  };

  return (
    <div
      id='chart'
      style={{
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'scale(1.5)',
      }}>
      <ReactApexChart options={options} series={options.series} type='radialBar' width='100%' height='100%' />
    </div>
  );
}
