import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Draggable from 'react-draggable';
import Desmos from 'desmos';

// ---
/** 계산기 컴포넌트 */
const CalculatorPopup = () => {
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const dispatch = useDispatch();

  const [buttonOn, setButtonOn] = useState(false);
  const [calculator, setCalculator] = useState();
  const [desmosArea, setDesmosArea] = useState();

  const nodeRef = useRef(null);
  const expressionRef = useRef(null);

  const makeDesmosCalculator = () => {
    if (!calculator) return;

    if (stateExamComponents.cpExpand) {
      desmosArea.style.width = '820px';
      desmosArea.style.height = '645px';
    } else {
      desmosArea.style.width = '410px';
      desmosArea.style.height = '580px';
    }

    if (expressionRef?.current != null && expressionRef?.current.length > 0) {
      expressionRef.current.map((expression, index) => {
        if (expression.latex != null) {
          calculator.setExpression({
            id: expression.id,
            color: expression.color,
            latex: expression.latex,
          });
        } else {
          calculator.setExpression({
            id: expression.id,
            color: expression.color,
          });
        }

        return null;
      });
    }

    document.getElementById('desmosCalculator').prepend(desmosArea);
  };

  useEffect(() => {
    setDesmosArea(document.createElement('div'));
  }, []);

  useEffect(() => {
    if (desmosArea) {
      setCalculator(Desmos.GraphingCalculator(desmosArea));
    }
  }, [desmosArea]);

  useEffect(() => {
    if (calculator) {
      makeDesmosCalculator();

      calculator.observeEvent('change', function () {
        let currentState = calculator.getState();

        let newList = [];
        currentState.expressions.list?.map((item, index) => newList.push({ ...item, id: `g_${index + 1}` }));
        expressionRef.current = newList;

        dispatch({
          type: 'setExamComponents',
          payload: { fieldName: 'cpState', data: newList },
        });
      });

      return () => {
        calculator.unobserveEvent('change');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.cpExpand, calculator, dispatch]);

  useEffect(() => {
    expressionRef.current = stateExamComponents.cpState;

    // let newList = []
    // examComponents.cpState?.map((item, index) => (newList.push({...item, id: (index + 1)})));
    // expressionRef.current = newList;

    // examComponents.cpState?.map((item, index) => (calculator.removeExpression({id: index + 1})));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.cpExpand, calculator]);

  // --------------------- 요기부터 dragable

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  const onStart = () => {
    setState({ ...state, activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ ...state, activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart, onStop };

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <div ref={nodeRef} className={`com_popup type_move pop_calculator ${stateExamComponents.cpDisplay ? 'active' : ''}`}>
        {' '}
        {/*addClass 'active' 시 노출*/}
        <div className={stateExamComponents.cpExpand ? 'pop_container expand' : 'pop_container'}>
          {/*expand 클릭시 addClass 'expand'*/}
          <strong className='cursor'>
            <div className='pop_header handle' onMouseDown={() => setButtonOn(true)} onMouseUp={() => setButtonOn(false)}>
              <div className='pop_tit'>Calculator</div>
              <button className={buttonOn ? 'svg_icon btn_move active' : 'svg_icon btn_move'}>&nbsp;</button>
              <div className='btn_area'>
                <button
                  className='btn_size'
                  onClick={() =>
                    dispatch({
                      type: 'setExamComponents',
                      payload: {
                        fieldName: 'cpExpand',
                        data: !stateExamComponents.cpExpand,
                      },
                    })
                  }>
                  <i className='svg_icon icon_expand'>&nbsp;</i>Expand
                </button>
                <button
                  className='svg_icon btn_pop_close white'
                  onClick={() =>
                    dispatch({
                      type: 'setExamComponents',
                      payload: { fieldName: 'cpDisplay', data: false },
                    })
                  }>
                  &nbsp;
                </button>
              </div>
            </div>
          </strong>
          <div id='desmosCalculator' className='pop_body' style={{ overflow: 'hidden' }} draggable='false' dragstart='false'>
            {/* 계산기 영역 */}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default CalculatorPopup;
