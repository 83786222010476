import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import usePressESC from 'hooks/usePressESC.js';
import ProfilePopup from './PopupProfile';

export default function DraggableProfilePopup({ setProfileState, studentInfo }) {
  usePressESC(() => setProfileState(false));

  const dragWrapperRef = useRef(null);
  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });

  const onStart = () => setState({ ...state, activeDrags: state.activeDrags + 1 });
  const onStop = () => setState({ ...state, activeDrags: state.activeDrags - 1 });
  const dragHandlers = { onStart, onStop };

  return (
    <Draggable nodeRef={dragWrapperRef} {...dragHandlers}>
      <div ref={dragWrapperRef} className='com_popup active pop_profile student'>
        <div className='pop_container scroll' style={{ maxHeight: '728px' }}>
          <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={studentInfo} />
        </div>
      </div>
    </Draggable>
  );
}
