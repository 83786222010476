import React, { useState, useEffect } from 'react';
//img
import ImgOk from 'assets/img/icon_ok.svg'; //

const MiniAlert = ({ active, inactive, text, type, timeOut }) => {
  useEffect(() => {
    let timer = setTimeout(inactive, timeOut);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {type === 'onlyText' ? (
        <div className={`com_text_alert ${active ? 'active' : ''}`}>{text}</div>
      ) : (
        <div className={`com_popup type_banner ${active ? 'active' : ''}`}>
          <div className='pop_container'>
            <img src={ImgOk} alt='icon' className='icon' />
            <p className='txt'>{text}</p>
          </div>
        </div>
      )}
    </>
  );
};
export default MiniAlert;
