import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import MiniReviewPage from './MiniReviewPage';
import { TOTAL_QUESTION_NUMBER_EN } from 'components/exam/dsat/_utils/constants/totalQuestionNumberSetting';

/** 영어 시험 화면 Footer 영역 컴포넌트 */
export default function ExamFooter({ setAlertLayerPopup }) {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const stateExamInfo = useSelector((state) => state.stateExamInfo);

  const miniReviewToggleRef = useRef();

  /** "이전 문제" 버튼 핸들러 */
  const backButtonClick = () => {
    if (stateExamComponents.reviewDisplay) {
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: false } });
    } else if (stateExamInfo.currentNum > 1) {
      navigate('/exam/dsat/rw', {
        state: { currentNum: stateExamInfo.currentNum - 1 },
        replace: true,
      });
    }
  };
  /** "다음 문제" 버튼 핸들러 */
  const nextButtonClick = () => {
    if (stateExamComponents.reviewDisplay) {
      // review 화면(즉, 마지막 단계) 일 경우
      if (stateExamInfo.questionList[stateExamInfo.currentNum - 1].moduleNum === 2) {
        // 모듈 2일 경우
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            alertType: 'confirm',
            activateMode: 'nextSection',
            visible: true,
            alertMessage: 'Are you sure you want to move on to next Section?<br> Make sure you have answered all the questions.',
          };
        });
      } else {
        // 모듈 1일 경우
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            alertType: 'confirm',
            activateMode: 'nextModule',
            visible: true,
            alertMessage: 'Are you sure you want to move on to next Module?<br> Make sure you have answered all the questions.',
          };
        });
      }
    } else {
      // review 화면(즉, 마지막 단계) 이 아닐 경우
      if (stateExamInfo.currentNum < TOTAL_QUESTION_NUMBER_EN) {
        // 마지막 문제가 아닐 경우,
        navigate('/exam/dsat/rw', {
          state: { currentNum: stateExamInfo.currentNum + 1 },
          replace: true,
        });
      } else if (stateExamInfo.currentNum === TOTAL_QUESTION_NUMBER_EN) {
        // 마지막 문제 일 경우,
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: true } });
      }
    }
  };

  return (
    <section className='pop_footer'>
      <div className='name'>{nvl(userInfo?.userName)}</div>
      {!stateExamComponents.reviewDisplay && (
        <button
          className={stateExamComponents.miniReviewDisplay ? 'btn_question' : 'btn_question active'}
          ref={miniReviewToggleRef}
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'miniReviewDisplay', data: !stateExamComponents.miniReviewDisplay } })}>
          Question {stateExamInfo.currentNum} of {TOTAL_QUESTION_NUMBER_EN}
          <i className='svg_icon icon_arrow white'>&nbsp;</i>
        </button>
      )}
      <article className='btn_area'>
        <button className='btn' onClick={backButtonClick}>
          Back
        </button>
        <button className='btn' onClick={nextButtonClick}>
          Next
        </button>
      </article>
      {stateExamComponents.miniReviewDisplay && <MiniReviewPage miniReviewToggleRef={miniReviewToggleRef} />}
    </section>
  );
}
