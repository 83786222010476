import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

//components
import ConfirmCode from 'components/user/ConfirmCode'; //코드입력
import SignupComplete from 'components/user/sign-up/complete/Page'; //가입완료
import SuccessSubmit from 'components/user/sign-up/success-submit/Page'; //제출완료

import JoinType from 'components/user/sign-up/join-type/Page'; // 가입 회원 선택

import AcademyJoin from 'components/user/sign-up/academy/join/Page'; //Academy 회원가입
import AcademyUpload from 'components/user/sign-up/academy/upload/Page'; //Academy 회원 파일 upload
import TutorUpload from 'components/user/sign-up/tutor/upload/Page'; //tutor 회원 파일 upload

import StudentJoin from 'components/user/sign-up/student/join/Page'; // 학생 회원가입
import TutorJoin from 'components/user/sign-up/tutor/join/Page'; // tutor 회원가입
import ParentJoin from 'components/user/sign-up/parent/join/Page'; // 부모 회원가입

const MainRoute = () => {
  // let { pathname } = window.location
  // let authBool = false;

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인을 하지 않아도 접근 가능
    const unAuthRoutes = [
      { path: '/', element: <Navigate to='/signup/user/type' /> },
      { path: '/user/type', element: <JoinType /> },

      { path: '/academy', element: <AcademyJoin /> },
      { path: '/academy/confirm', element: <ConfirmCode /> },
      { path: '/academy/upload', element: <AcademyUpload /> },
      { path: '/academy/complete', element: <SuccessSubmit /> },

      { path: '/tutor', element: <TutorJoin /> },
      { path: '/tutor/confirm', element: <ConfirmCode /> },
      { path: '/tutor/upload', element: <TutorUpload /> },
      { path: '/tutor/complete', element: <SuccessSubmit /> }, //file upload 한 경우
      { path: '/teacher/complete', element: <SignupComplete /> }, // code 제출 한 경우

      { path: '/student', element: <StudentJoin /> },
      { path: '/student/confirm', element: <ConfirmCode /> },
      { path: '/student/complete', element: <SignupComplete /> },

      { path: '/parent', element: <ParentJoin /> },
      { path: '/parent/confirm', element: <ConfirmCode /> },
      { path: '/parent/complete', element: <SignupComplete /> },
    ];

    return returnRoutes(unAuthRoutes);
  };

  return (
    <>
      <Routes> {AuthorizationRoutes()} </Routes>
      <p className='copyright'>Copyright © 2023 Gate⁺ All rights reserved.</p>
    </>
  );
};

export default MainRoute;
