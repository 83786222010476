import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { v4 as uuidv4 } from 'uuid';

const SplineChart = ({ dateArray, mathAvg, engAvg }) => {
  const isScatterType = mathAvg.filter((val) => val !== null).length === 1 && engAvg.filter((val) => val !== null).length === 1;
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'RW',
        data: [],
      },
      {
        name: 'MATH',
        data: [],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    xaxis: {
      type: 'category',
      categories: dateArray,
      tickAmount: dateArray.length,
      tickPlacement: 'on',
      position: 'bottom',
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 800,
      tickAmount: 8,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      x: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.7,
        stops: [0, 100],
      },
    },
    grid: {
      strokeDashArray: 6,
    },
    // legend: {
    //   show: false,
    // },
    markers: {
      size: 5,
      hover: {
        size: 7,
        sizeOffset: 3,
      },
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '10px',
      fontWeight: '700',
      fontFamily: 'Noto Sans',
      offsetY: -97,
      labels: {
        offsetX: 10,
        colors: ['#008CFF', '#10B981'],
      },
      markers: {
        width: 6,
        height: 6,
        offsetX: -2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
  });

  useEffect(() => {
    setChartOptions((prevOptions) => {
      return {
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          type: isScatterType ? 'scatter' : 'area',
        },
        xaxis: {
          ...prevOptions.xaxis,
          categories: dateArray,
        },
        series: [
          {
            name: 'RW',
            data: engAvg.length > 0 ? engAvg : [null, null, null, null, null, null, null],
          },
          {
            name: 'MATH',
            data: mathAvg.length > 0 ? mathAvg : [null, null, null, null, null, null, null],
          },
        ],
        fill: {
          ...prevOptions.fill,
          type: isScatterType ? 'solid' : 'gradient',
        },
      };
    });
  }, [dateArray, mathAvg, engAvg, isScatterType]);

  return (
    <div id='chart'>
      <div className='com_btn_wrap'>&nbsp;</div>
      <ReactApexChart options={chartOptions} series={chartOptions.series} type={chartOptions.chart.type} height={350} />
    </div>
  );
};

export default SplineChart;
