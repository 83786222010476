import React, { useState, useEffect, useRef } from 'react';

export default function SetTimeLayer({ close, confirm, btnType, mousePosition, setTimeLayer, setSelectedTime }) {
  const layerRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (layerRef.current && !layerRef.current.contains(event.target)) {
        setTimeLayer(false);
        setSelectedTime('00:00');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [timeSelect, setTime] = useState(false); //time select box
  const [positionStyle, setPositionStyle] = useState({});
  const [selectTime, setSelectTime] = useState('12 : 00 AM');

  useEffect(() => {
    if (layerRef.current) {
      const layerHeight = layerRef.current.offsetHeight;
      const screenHeight = window.innerHeight;
      let newYPosition = mousePosition.y;

      const threshold = newYPosition + layerHeight - screenHeight;
      if (threshold > 0) {
        newYPosition -= threshold + 20;
      }

      setPositionStyle({
        top: newYPosition,
        left: mousePosition.x,
      });
    }
  }, [mousePosition]);

  const convert24Hour = (timeStr) => {
    let [hours, , minutes, modifier] = timeStr.split(' ');

    hours = (modifier === 'PM' ? (hours % 12) + 12 : hours % 12).toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };

  const handleSetTime = (event) => {
    setSelectTime(event.target.textContent);
    setSelectedTime(convert24Hour(event.target.textContent));
  };

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? '00' : '30';
    return `${hour % 12 === 0 ? 12 : hour % 12 < 10 ? '0' + (hour % 12) : hour % 12} : ${minute} ${hour < 12 || hour === 24 ? 'AM' : 'PM'}`;
  });

  return (
    <div className='com_time_layer active' onClick={(e) => e.stopPropagation()} style={positionStyle} ref={layerRef}>
      <div className='layer_tit'>Start Time</div>
      <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => setTime((prev) => !prev)}>
        <section className='selected_wrap'>
          <input className='selected' placeholder={selectTime || '12 : 00 AM'} />
        </section>
        <section className='layer scroll' onClick={handleSetTime}>
          {timeOptions.map((time, index) => (
            <p key={index} className='option'>
              {time}
            </p>
          ))}
          {/* <input type='text' className="selected" placeholder={selectedClass?.classTitle} />
          <div className="layer" onClick={(e) => setSelectedClass(classInfo.find(info => info.classTitle === e.target.textContent))}>
            {classInfo.map((classInfo, idx) => <p className="option" key={idx}>{classInfo.classTitle}</p>)}
          </div> */}
        </section>
      </div>
      <div className='com_btn_wrap bottom'>
        {btnType === 'skip' ? (
          <button className='com_btn line point m full' onClick={close}>
            Skip
          </button>
        ) : (
          <></>
        )}
        <button className='com_btn point m full' onClick={confirm}>
          Confirm
        </button>
      </div>
    </div>
  );
}
