import styled from 'styled-components';
import HistoryCard from './HistoryCard';
import KeyHistoryDate from './KeyHistoryDate';

/** GateKeyPopup의 Header 컴포넌트 */
function GateKeyHistoryList({ sortedGroupByDate }) {
  return (
    // 날짜 - 데이터 형식으로 List 생성
    <S.HistoryList className='scroll'>
      {sortedGroupByDate.length == 0 ? (
        <h3>You don't have a payment history yet</h3>
      ) : (
        Object.keys(sortedGroupByDate).map((item, index) => {
          return (
            <li key={`his_${index}`}>
              <KeyHistoryDate date={item} /> {/* history가 생성된 Date */}
              <ul>
                {sortedGroupByDate[item].map((history, idx) => (
                  <HistoryCard key={`${history.ugSeq}-${idx}`} history={history} />
                ))}
              </ul>
            </li>
          );
        })
      )}
    </S.HistoryList>
  );
}

export default GateKeyHistoryList;

const S = {}; // S-dot 문법용 객체

S.HistoryList = styled.ul`
  max-height: calc(100vh - 365px);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.7rem;
`;
