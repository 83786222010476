import React, { useState, useEffect } from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

import request from 'utils/Request.utils';
import { nvlNumber } from 'utils/Common.utils.js';

//components

import ReferenceSheetPopup from 'components/_common/modals/ReferenceSheetPopup';
import SessionStorage from 'utils/SessionStorage.utils.js';
import ExamEnReview from './_components/ExamEnReview';
import ExamMaReview from './_components/ExamMaReview';

/** 시험 - Review 페이지 */
const ExamMain = () => {
  const query = new URLSearchParams(window.location.search);
  const viewQuestion = query.get('viewQuestion');
  const [examMain, setExamMain] = useState({
    examEnReviewDisplay: false,
    examMaReviewDisplay: false,
  });

  const [examComponents, setExamComponents] = useState({
    rspDisplay: false,
    repExpand: false,
    cpDisplay: false,
    cpExpand: false,
    directionDisplay: false,
    timeAreaDisplay: true,
    annotationDisplay: false,
    annotationEmptyDisplay: false,
    moreDisplay: false,
    helpDisplay: false,
    helpItemDisplay: false,
    exitExamDisplay: false,
    miniReviewDisplay: false,
    reviewDisplay: false,
    isFiveMinutesRemaining: false,
    isMathJaxError: false,

    mathQuestionDisplay: false,
    englishQuestionDisplay: false,
  });

  const [currentQuestion, setCurrentQuestion] = useState({
    questionSeq: 0,
    multipleChoiceItems1: '',
    multipleChoiceItems2: '',
    multipleChoiceItems3: '',
    multipleChoiceItems4: '',
    questionSubject: '',
    testHow: '',
    questionFormat: '',
    questionDifficulty: '',
    fieldOfStudy: 0,
    researcher: 0,

    tryAnswer: '', // 기입한 답
    tryAnswerCntList: [],

    annotation: [
      {
        // 주석 부분
        annotationStart: 0,
        annotationEnd: 0,
        annotationContent: '',
      },
    ],

    bookMark: '', // 북마크 Y,N
    reviewBookmark: '',

    entryTime: '',
    exitTime: '',

    underLine1: '',
    underLine2: '',
    underLine3: '',
    underLine4: '',

    passage: '',
    question: '',
  });

  const review = SessionStorage.getItemJsonParse('review');

  const getCurrentQuestion = () => {
    let pms = {
      viewQuestion: viewQuestion,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let innerItem = response.result?.question;
        let tryAnswerCntList = response.result?.tryAnswerCntList;

        setCurrentQuestion((prev) => {
          return {
            ...prev,
            uthSeq: innerItem?.uthSeq,
            questionSeq: innerItem?.questionSeq,
            multipleChoiceItems1: innerItem.multipleChoiceItem1 ? innerItem.multipleChoiceItem1 : innerItem.multipleChoiceItems?.split('||')[0],
            multipleChoiceItems2: innerItem.multipleChoiceItem2 ? innerItem.multipleChoiceItem2 : innerItem.multipleChoiceItems?.split('||')[1],
            multipleChoiceItems3: innerItem.multipleChoiceItem3 ? innerItem.multipleChoiceItem3 : innerItem.multipleChoiceItems?.split('||')[2],
            multipleChoiceItems4: innerItem.multipleChoiceItem4 ? innerItem.multipleChoiceItem4 : innerItem.multipleChoiceItems?.split('||')[3],
            questionSubject: innerItem?.metaData ? JSON.parse(innerItem.metaData)?.question_subject : innerItem?.subject,
            testHow: innerItem?.testHow,
            questionFormat: innerItem?.metaData ? JSON.parse(innerItem.metaData)?.question_format : innerItem?.questionFormat,
            questionDifficulty: innerItem?.questionDifficulty,
            fieldOfStudy: innerItem?.fieldOfStudy,
            bookMark: innerItem?.bookmarkYn,
            reviewBookmark: innerItem?.reviewBookmarkYn,
            researcher: innerItem?.researcher,
            passage: innerItem?.passage,
            question: innerItem?.question,
            correctAnswer: innerItem?.correctAnswer,
            questionOrder: innerItem?.questionOrder,
            tryAnswer: innerItem?.tryAnswer,
            tryAnswerCntList: tryAnswerCntList,
            underLine1: innerItem?.underLine1,
            underLine2: innerItem?.underLine2,
            underLine3: innerItem?.underLine3,
            underLine4: innerItem?.underLine4,
            classBookmarkYn: innerItem?.classBookmarkYn,
            classSeq: review?.classSeq || 0,
          };
        });
      }
    };

    request
      .get(`/api/common/question/review?viewQuestion=${encodeURIComponent(pms.viewQuestion)}&classSeq=${nvlNumber(review?.classSeq)}`, null, successHandler)
      .catch((error) => {
        console.error(error);
        alert('시험 - Review - 현재 문제 얻어오기 API 응답 실패');
      });
  };

  useEffect(() => {
    if (currentQuestion?.questionSubject === 'E')
      setExamMain((prev) => {
        return { ...prev, examEnReviewDisplay: true };
      });
    else if (currentQuestion?.questionSubject === 'M')
      setExamMain((prev) => {
        return { ...prev, examMaReviewDisplay: true };
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion?.questionSubject]);

  useEffect(() => {
    //main태그 그라데이션 bg 제거
    document.body.classList.add('bg_none'); //현재페이지에서만 addClass
    getCurrentQuestion();
    return () => {
      document.body.classList.remove('bg_none'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {examMain.examEnReviewDisplay && ( // 영어 리뷰
        <ExamEnReview
          setExamMain={setExamMain}
          examComponents={examComponents}
          setExamComponents={setExamComponents}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          userLevel={viewQuestion.includes('GATEPLUS')}
        />
      )}
      {examMain.examMaReviewDisplay && ( // 수학 리뷰
        <ExamMaReview
          setExamMain={setExamMain}
          examComponents={examComponents}
          setExamComponents={setExamComponents}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          userLevel={viewQuestion.includes('GATEPLUS')}
        />
      )}
      {examComponents.rspDisplay && ( // Reference Sheet 팝업
        <ReferenceSheetPopup setExamComponents={setExamComponents} rspDisplay={examComponents.rspDisplay} repExpand={examComponents.repExpand} />
      )}
      <DotLottiePlayer // 로딩중화면
        src='https://lottie.host/71b6f326-7243-49d7-9fa9-5b847b3a30e0/VbwMr5mad0.json'
        autoplay
        loop
        direction='1'
        mode='normal'
        background='transparent'
        speed='2'
      />
    </>
  );
};

export default ExamMain;
