import dayjsTZ, { convertTZ, getDateOnlyDayjs, isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import { useState } from 'react';
import Calendar from 'react-calendar';

/** 팝업 달력 컴포넌트 */
export default function PopupCalendarPeriod({ setSearchInfo, tit, size }) {
  /** 달력 팝업 View - State */
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  /** 현재 선택된 시간의 dayjs 객체 - State */
  const [selectedDayjsObj, setSelectedDayjsObj] = useState([null, null]);
  /** 달력에 표시될 date 객체 - State */
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');

  /** 날짜 클릭 핸들러 */
  const onClickDay = (value) => {
    // 클릭된 날짜를 지정된 타임존의 시간대로 변경 ( value <-> html 간의 차이로 차이나는 시간만큼 조정 )
    const adjustedDate = getDateOnlyDayjs(convertTZ(value.valueOf(), 'custom').toDate());

    // 시작일이 선택되지 않았거나, 이미 선택된 범위가 있을 경우 - 새로운 시작일 설정
    if (!selectedRange[0] || (selectedRange[0] && selectedRange[1])) {
      setSelectedDayjsObj([adjustedDate, null]);
      setSelectedRange([adjustedDate.toDate(), null]);
    } else {
      // 종료일 설정 (시작일이 이미 선택된 경우)
      const startDate = selectedDayjsObj[0];
      const endDate = adjustedDate;

      // 시작일과 종료일을 올바른 순서로 설정
      const [newStartDate, newEndDate] = startDate.isAfter(endDate) ? [endDate, startDate] : [startDate, endDate];

      setSelectedDayjsObj([newStartDate, newEndDate]);
      setSelectedRange([newStartDate.toDate(), newEndDate.toDate()]);
      updateDates([newStartDate, newEndDate]);
    }
  };

  /** 선택된 날짜 ([시작일, 종료일]) 범위 업데이트 및 검색 정보 설정 */
  const updateDates = ([start, end]) => {
    const selectedStartDate = isoTimeToDayjsTZ(start.format());
    const selectedEndDate = isoTimeToDayjsTZ(end.format());

    // 검색 정보 설정
    setSearchInfo((prev) => {
      return {
        ...prev,
        isDateRangeSelected: true,
        searchStartDate: selectedStartDate.startOf('day').format(),
        searchEndDate: selectedEndDate.endOf('day').format(),
      };
    });

    setFormattedStartDate(selectedStartDate.format('MM.DD.YYYY'));
    setFormattedEndDate(selectedEndDate.format('MM.DD.YYYY'));
    setIsShowCalendar(false);
  };

  return (
    <div className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker search_area_input'
        onClick={() => setIsShowCalendar(true)}
        readOnly
        placeholder={tit}
        value={formattedStartDate && formattedEndDate ? `${formattedStartDate} ~ ${formattedEndDate}` : ''}
        size={size}
      />
      {isShowCalendar && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button type='button' className='svg_icon btn_pop_close black' onClick={() => setIsShowCalendar(false)}>
              &nbsp;
            </button>
          </div>

          <Calendar
            className='calendar'
            onChange={() => {}} // 날짜 변경은 onClickDay에서 처리하므로 빈 함수로 설정
            onClickDay={onClickDay}
            selectRange={true}
            // 타임존에 맞게 날짜 표시 변경
            formatDay={(locale, date) => dayjsTZ(date).format('DD')}
            // 타임존에 맞게 요일 표시 변경
            formatShortWeekday={(locale, date) => dayjsTZ(date).format('ddd')}
            locale='en-EN'
          />
        </div>
      )}
    </div>
  );
}
