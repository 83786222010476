export default function UserDetails(props) {
  return (
    <ul className='input_area'>
      {props.renderingData.map((item, index) => {
        return (
          <li className='item' key={item.value + index}>
            <div className='tit_field'>{item.name}</div>
            <div className='input_field'>
              <input type='text' defaultValue={item.value} readOnly />
            </div>
          </li>
        );
      })}
    </ul>
  );
}
