import React from 'react';
import ModuleNum from '../common/ModuleNum';
import ImgReference from 'assets/img/exam/reference_image.jpg';
import Imgexamples from 'assets/img/examples.png';

/** 모듈 안내 : Math Module 1 */
export default function ModuleDirectionsMath({ testList }) {
  return (
    testList &&
    testList[0].set_subject !== 'E' && (
      <section className='module_sec'>
        <ModuleNum number='1' />
        <article className='tit_area'>
          <div className='test_name'>Math</div>
          <div className='question_num'>22 Questions</div>
          <div className='module_name'>Module 1</div>
        </article>
        <article className='notice_area'>
          <div className='tit_field'>Directions</div>
          <div className='txt_field'>
            The questions in this section address a number of important math skills. Use of a calculator is permitted for all questions. A reference sheet,
            calculator, and these directions can be accessed throughout the test
          </div>
          <div className='tit_field'>NOTES</div>
          <div className='txt_field'>
            Unless otherwise indicated:
            <ul className='ul'>
              <li>All variables and expressions represent real numbers.</li>
              <li>Figures provided are drawn to scale.</li>
              <li>All figures lie in a plane.</li>
              <li>The domain of a given function f is the set of all real numbers x for which f(x) is a real number.</li>
            </ul>
          </div>
          <div className='tit_field'>REFERENCE</div>
          <div className='txt_field'>
            <div className='img'>
              <img src={ImgReference} alt='Reference Sheet' />
            </div>
            The number of degrees of arc in a circle is 360°.
            <br />
            The number of radians of arc in a circle is 2π.
            <br />
            The sum of the measures in degrees of the angles of a triangle is 180°.
            <br />
            <br />
            For <b>multiple-choice questions</b>, solve each problem, choose the correct answer from the choices provided. Each multiple-choice question has a
            single correct answer. <br />
            <br />
            For <b>student-produced response</b> questions, solve each problem and enter your answer as described below.
            <ul className='ul'>
              <li>
                If you find <b>more than one correct answer,</b> enter only one answer.
              </li>
              <li>
                You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
              </li>
              <li>
                If you answer is a <b>fraction</b> that doesn’t fit in the provided space, enter the decimal equivalent.
              </li>
              <li>
                If your answer is a <b>decimal</b> that doesn’t fit in the provided space, enter it by truncating or rounding at the fourth digit.
              </li>
              <li>
                If your answer is a <b>mixed number</b> (such as 31 2), enter it as an improper fraction (7/2) or its decimal equivalent (3.5).
              </li>
              <li>
                Don’t enter <b>symbols</b> such as a percent sign, comma or dollar sign.
              </li>
              <li>
                Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
                count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
              </li>
            </ul>
            <div className='example'>
              <div>Examples</div>
              <img src={Imgexamples} alt='examples' />
            </div>
          </div>
        </article>
      </section>
    )
  );
}
