import MemberListItem from './_components/MemberListItem';
import MemberList02Item from './_components/MemberList02Item';

/** "/com/score" 페이지의 "Student List" 영역 컴포넌트 */
function StudentListSection({ scorePageInfo, filterValue, handleSelectStudent, tabState, setPrintActive, setLoading }) {
  // const getIsActive = (item, filterValue) => item.uthSeq === filterValue.uthSeq;

  // useEffect(() => {
  //   console.log(`🚨 컴포넌트 자체는 몇 번 ? :`, `🚨`);
  // }, []);
  // useEffect(() => {
  //   console.log(`🚨 filterValue.uthSeq :`, filterValue.uthSeq, `🚨`);
  // }, [filterValue.uthSeq]);
  // useEffect(() => {
  //   console.log(`🚨 scorePageInfo.memberList :`, scorePageInfo.memberList, `🚨`);
  // console.log(`🚨 scorePageInfo.memberList.length :`, scorePageInfo.memberList.length, `🚨`);
  // console.log(`🚨 scorePageInfo.memberList.length > 0 :`, scorePageInfo.memberList.length > 0, `🚨`);
  // }, [scorePageInfo.memberList]);

  return (
    <article className='box student_list'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_ol black'>&nbsp;</i>
          Student List
          {console.log('JSX 자체 렌더링이 몇 번 ?')}
        </h2>
      </div>
      <div className='com_list_box'>
        <div className='scroll'>
          <table className='list'>
            <colgroup>
              <col width='56px' />
              <col />
              <col width='44px' />
              <col width='44px' />
              <col width='81px' />
            </colgroup>
            <thead>
              <tr className='sticky top'>
                <th>&nbsp;</th>
                <th>Name</th>
                <th className='alignC'>RW</th>
                <th className='alignC'>Math</th>
                <th className='alignC'>Report Card</th>
              </tr>
            </thead>
            <tbody>
              {scorePageInfo.memberList &&
                scorePageInfo.memberList.length > 0 &&
                scorePageInfo.memberList.map((item, idx) => {
                  return (
                    <MemberListItem
                      key={`student-list${idx}`}
                      item={item}
                      handleSelectStudent={handleSelectStudent}
                      filterValue={filterValue}
                      tabState={tabState}
                      setPrintActive={setPrintActive}
                      setLoading={setLoading}
                    />
                  );
                })}
              {scorePageInfo.memberList2 &&
                scorePageInfo.memberList2?.map((item, idx) => {
                  return <MemberList02Item key={`not-taken-student-${idx}`} item={item} />;
                })}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
}

export default StudentListSection;
