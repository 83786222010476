import { useEffect, useRef, useState } from 'react';

/** 타이머 hook : 초단위 타이머, 초와 시작, 정지 버튼 등을 반환한다. */
const useTimer = () => {
  /** 타이머 시간 (초) */
  const [time, setTime] = useState(0);
  /** 타이머 활성화 여부 */
  const [isActive, setIsActive] = useState(false);
  /** 타이머 시작 시간 */
  const startTimeRef = useRef(null);

  useEffect(() => {
    let interval = null;

    if (isActive) {
      if (!startTimeRef.current) {
        /** 현재 시간 (moment()) 과 최초 시간을 비교해서 검증? 해주는 코드 */
        startTimeRef.current = Date.now() - time * 1000;
      }

      interval = setInterval(() => {
        /** 현재 시간 (moment()) 과 최초 시간을 비교해서 검증? 해주는 코드 */
        // console.log(`\n┏━━━ 💡 💡 Date.now() 💡 💡 ━━━\n`, Date.now(), `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
        // console.log(`\n┏━━━ 💡 💡 startTimeRef.current 💡 💡 ━━━\n`, startTimeRef.current, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
        // console.log(`\n┏━━━ 💡 💡 (Date.now() - startTimeRef.current) 💡 💡 ━━━\n`, Date.now() - startTimeRef.current, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

        // startTimeRef.current가 null일때 시간 업데이트를 하지 않게 설정 ( 종종 17억초가 들어가는 버그 확인됨 )
        if (!startTimeRef.current) return;

        const elapsedTime = Math.floor((Date.now() - startTimeRef.current) / 1000);
        // console.log(`\n┏━━━ 💡 💡 elapsedTime 💡 💡 ━━━\n`, elapsedTime, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

        setTime(elapsedTime);
      }, 500);
    } else {
      if (interval) clearInterval(interval);
      startTimeRef.current = null;
    }

    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const handleStart = () => {
    setIsActive(true);
  };

  const handleStop = () => {
    setIsActive(false);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    startTimeRef.current = null;
    console.log('🚨 handleReset :', '🚨');
  };

  return { time, handleStart, handleStop, handleReset };
};

export default useTimer;
