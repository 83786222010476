import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//component
import Header from './_Header';
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
//img
import ImgOk from 'assets/img/message_icon.svg';

import request from 'utils/Request.utils.js';
import { nvl, validateEmail } from 'utils/Common.utils.js';

import useUserLevels from 'hooks/useUserLevels';
import LoadingBar from 'utils/LoadingBar';

const ConfirmCode = () => {
  const stateCustomer = useSelector((state) => state.Customer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(stateCustomer.userLevel);

  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);

  const [alert, alertSet] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [userEmail, setUserEmail] = useState(stateCustomer.userEmail);
  const [authenCodeCorrect, setAuthenCodeCorrect] = useState(true);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const emailRef = useRef();
  const confirmCodeRef = useRef();

  const editEmail = () => {
    const newValue = emailRef.current.value;
    setUserEmail(newValue);

    dispatch({
      type: 'setCustomer',
      payload: { fieldName: 'userEmail', data: emailRef.current.value },
    });
    setIsEdit(false);
  };

  const checkEmailDuplicate = () => {
    if (!validateEmail(nvl(emailRef.current.value))) {
      setIsValidEmail(false);
      emailRef.current.focus();
      setDuplicateEmail(false);
      return;
    }

    let params = {
      userEmail: emailRef.current.value,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let emailDuplicate = response.result.idDuplicate;

        if (emailDuplicate) {
          setDuplicateEmail(true);
          setIsValidEmail(false);
        } else {
          sendMail();
          editEmail();
          setIsValidEmail(true);
        }
      }
    };

    request.get(`/api/common/email/duplicate?&userEmail=${params.userEmail}`, null, successHandler);
  };

  const sendMail = () => {
    let params = {
      userEmail: emailRef.current.value,
      userName: stateCustomer.userName,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        alertSet(true);
      }
    };

    request.post('/api/common/email', params, successHandler);
  };

  const authenCodeCheck = () => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        if (useLevels.isAcademy) navigate('/signup/academy/upload');
        else if (useLevels.isTutor) navigate('/signup/tutor/upload');
        else if (useLevels.isStudent) signup();
        else if (useLevels.isParent) signup();
      } else if (response.code === 401) {
        setAuthenCodeCorrect(false);
        setLoading(false);
      }
    };

    request.get(`/api/common/authen/code?authenCode=${confirmCodeRef.current.value}&userEmail=${stateCustomer.userEmail}`, null, successHandler);
  };

  const submitBtn = () => {
    if (!duplicateEmail && confirmCodeRef.current.value !== '') {
      authenCodeCheck();
    } else {
      confirmCodeRef.current.focus();
      setAuthenCodeCorrect(false);
    }
  };

  const signup = () => {
    let params = {
      userId: emailRef.current.value,
      userPasswd: stateCustomer.userPasswd,
      userEmail: emailRef.current.value,
      userName: stateCustomer.userName,
      userNickname: stateCustomer.userName,
      userLevel: stateCustomer.userLevel,
      userMobile: stateCustomer.userMobile,
      schoolType: stateCustomer.schoolType,
      studentGrade: stateCustomer.studentGrade,
      studentSchool: stateCustomer.studentSchool,
      schoolLocation: stateCustomer.schoolLocation,
      acceptReceivingMail: stateCustomer.mailYn,
      acceptMarketingMail: stateCustomer.marketingYn,
      familyPhone: stateCustomer.parentsPhone,
      userStatus: '005003000000000',
      regUserSeq: 1,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        setLoading(false);
        if (useLevels.isStudent) navigate('/signup/student/complete');
        else if (useLevels.isParent) navigate('/signup/parent/complete');

        request.del(`/api/common/authen/code/${params.userEmail}`);
      }
    };

    request.post('/api/common/user', params, successHandler);
  };

  return (
    <>
      <div className='container'>
        <Header>
          <hr className='divider' />
        </Header>
        <div className='confirmation_code'>
          <h1 className='code_title'>Enter the code from your email</h1>
          <img className='code_message_illust' src={ImgOk} alt='' />
          <p className='code_desc'>Confirmation code has been sent to the email address. Please check your email and enter the confirmation code.</p>
          {/* 이메일 입력 */}
          <div className={`input_wrap ${isValidEmail ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>EMAIL</span>
            </h2>
            <div className='box input_email box_button'>
              <input className='input' type='text' defaultValue={userEmail} readOnly={!isEdit} ref={emailRef} />
            </div>
            {!isEdit ? (
              <button className='btn btn_small btn_stroke' onClick={() => setIsEdit(true)}>
                EDIT
              </button>
            ) : (
              <button className='btn btn_small btn_fill' onClick={() => checkEmailDuplicate()}>
                SEND
              </button>
            )}
            <p className='error_message'>
              {duplicateEmail ? 'The email is already in use. Please use another email.' : 'Email format is not acceptable. Please check again.'}
            </p>
          </div>
          {/* 코드 입력 */}
          <div className={`input_wrap ${authenCodeCorrect ? '' : 'error'}`}>
            {/*틀릴 경우 addClass error*/}
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>CONFIRMATION CODE</span>
            </h2>
            <div className='box'>
              <input className='input' type='text' ref={confirmCodeRef} />
            </div>
            <p className='error_message'>Incorrect code. Please try again. </p>
            <p className='tip'>
              Didn't Receive An Email?&nbsp;&nbsp;&nbsp;
              <button className='btn_txt' onClick={() => sendMail()}>
                RESEND
              </button>
            </p>
          </div>
        </div>
        <section className='btns'>
          <button className='btn btn_fill btn_big' onClick={() => submitBtn()}>
            Submit
          </button>
        </section>
      </div>
      {/*텍스트 형 알럿*/}
      {isLoading && <LoadingBar />}
      {alert && <MiniAlert text='Email sent successfully' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={2000} />}
      {/* <div className="com_text_alert active">
        Email sent successfully
      </div> */}
    </>
  );
};

export default ConfirmCode;
