import ColumnChart from 'components/_common/chart/ColumnChart';
import GroupedBarChart from 'components/_common/chart/GroupedBarChart';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/** "/com/score" 페이지의 "Class vs Class" 영역 컴포넌트 */
export default function ClassVsClass({ classButtons, clickHandler, percentileClick, scoreStats, classData, studentClassData }) {
  return (
    <article className='box per25'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_graph black'>&nbsp;</i>
          {classButtons.filter((i) => i.active === true)[0].text} vs Class
        </h2>
        {/*메뉴 설명*/}
        {/* <div className="com_tip_layer">
      <i className="svg_icon icon_tip gray">&nbsp;</i>
      <section className="layer">
        <p className="tit">
          <i className="svg_icon icon_tip black">&nbsp;</i>
          Class vs. Class
        </p>
        <p className="txt">
          메뉴에 사용에 대한 설명글메뉴에 사용에 대한 설명글 메뉴에 사용에 대한 설명글메뉴에 사용에 대한 설명글
        </p>
      </section>
    </div> */}
      </div>
      <div className='com_tab dark'>
        {classButtons?.map((item) => (
          <button
            key={'button_' + uuidv4()}
            className={item.active ? 'menu active' : 'menu'}
            onClick={() =>
              item.value === 0
                ? clickHandler(item.value, 'class')
                : scoreStats.percentileList && scoreStats.percentileList.length > 0 && percentileClick(scoreStats.percentileList[0].userSeq)
            }>
            {item.text}
          </button>
        ))}
      </div>
      {classButtons[0].active ? <ColumnChart classData={classData} subject={scoreStats.setSubject} /> : <GroupedBarChart groupedBarChart={studentClassData} />}
    </article>
  );
}
