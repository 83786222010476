import React from 'react';

//img
import { ReactComponent as ImgLog } from 'assets/img/svg/logo_gateplus_row_01.svg';
import ImgFacebook from '../assets/img/icon_facebook.png';
import { ReactComponent as ImgInsta } from 'assets/img/svg/icon-instagram.svg';
import { ReactComponent as ImgKakao } from 'assets/img/svg/icon-kakaotalk.svg';
import ImgBlog from '../assets/img/icon_blog.png';
import { ReactComponent as ImgMail } from 'assets/img/svg/icon-mail.svg';
import { ReactComponent as ImgPhone } from 'assets/img/svg/icon-phone.svg';
import { ReactComponent as ImgClock } from 'assets/img/svg/icon-clock.svg';
import { ReactComponent as ImgMap } from 'assets/img/svg/icon-marker-pin-01.svg';
import styled from 'styled-components';

const Footer = () => {
  return (
    <S.Footer className='footer'>
      <div className='com_center_wrap'>
        <div className='top_wrap'>
          <h1 className='logo'>
            <ImgLog />
          </h1>
          <div className='sns'>
            {/* <button className="link" onClick={() => window.open("https://blog.naver.com/gate_plus")}><img src={ImgBlog} alt="naver blog"/></button> */}
            <button className='link' onClick={() => window.open('https://pf.kakao.com/_prxbCG')} title='Open in new tab'>
              <ImgKakao />
            </button>
            <button className='link' onClick={() => window.open('https://www.instagram.com/gateplus_official/')} title='Open in new tab'>
              <ImgInsta />
            </button>
            {/* <button className="link" onClick={() => window.open("https://www.facebook.com/profile.php?id=61552643307566")}><img src={ImgFacebook} alt="facebook"/></button> */}
          </div>
        </div>
        <div className='bottom_wrap'>
          <div>
            <div>
              <div className='icon_area'>
                <div className='field'>
                  <a href='mailto:info@gateplus.org' title='Write an email'>
                    <ImgMail />
                    info@gateplus.org
                  </a>
                </div>
                <div className='field'>
                  <a href='tel:+82-2-6951-0904' title='Call this number'>
                    <ImgPhone />
                    +82-2-6951-0904
                  </a>
                </div>
                <div className='field'>
                  <ImgClock />
                  10AM ~ 7PM KST <br />
                  Mon ~ Fri
                </div>
                <div className='field'>
                  <ImgMap />
                  101-101,21, Nonhyeon-ro 97-gil, Gangnam-gu, <br />
                  Seoul, Republic of Korea
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='txt_area'>
              <dl>
                <dt>CEO :</dt>
                <dd>Byeongcheol Jung</dd>
              </dl>
              <dl>
                <dt>Business Registration :</dt>
                <dd>244-81-03038</dd>
              </dl>
              <dl>
                <dt>Mail Order Sales Registration :</dt>
                <dd>제 2024-서울강남-01895호</dd>
              </dl>
            </div>
            <div className='copy'>Copyright &copy; 2024 Gate+ All rights reserved.</div>
          </div>
          <article className='btn_area'>
            <button className='btn' onClick={() => window.open('https://www.gateplus.org')} title='Open in new tab'>
              GATE <sup>+</sup>
              <i className='svg_icon icon_go'>&nbsp;</i>
            </button>
          </article>
        </div>
        <nav className='fnb'>
          <a
            href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'
            target='_blank'
            rel='noopener noreferrer'
            title='Open in new tab'>
            Terms of Service
          </a>
          <a
            href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3'
            target='_blank'
            rel='noopener noreferrer'
            title='Open in new tab'>
            Privacy policy
          </a>
        </nav>
      </div>
    </S.Footer>
  );
};

export default Footer;

const S = {};

S.Footer = styled.footer`
  background: #d1e9ff;
  color: #393939;
  margin-top: 3rem;
  h1 {
    height: fit-content;
  }
  .com_center_wrap {
    padding: 2.5rem 1rem;
  }
  .top_wrap {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #393939;
    .sns {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      .link {
        width: 34px;
      }
    }
  }
  .bottom_wrap {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 1.25rem 0;
    & > div {
      display: flex;
      flex-direction: column;
      width: 50%;
      .icon_area {
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 1.25rem;
        row-gap: 1rem;
        color: #393939;
        font-size: 0.75rem;
        font-weight: 400;
        .field {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          a {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }

      .txt_area {
        dt,
        dd {
          display: inline;
          font-size: 0.75rem;
        }
      }
    }
  }

  .btn_area {
    .btn {
      width: 142px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #7b8a97;
      color: #fff;
      font-weight: 500;
      sup {
        font-size: 10px;
        margin-top: -4px;
      }
      .svg_icon {
        margin-left: 0.875rem;
      }
    }
  }

  .copy {
    color: #393939;
    font-size: 0.75rem;
    width: 100%;
    margin-top: 1.25rem;
  }

  .fnb {
    font-size: 0.75rem;
    a {
      &:hover {
        color: #0068bd;
      }
    }
    & > a ~ a {
      margin-left: 2rem;
    }
  }
`;
