import examples from 'assets/img/exam/examples.png';

/** 수학 문제 영역 컴포넌트 */
export default function MathQuestion({ wrap, wrapButtonHandler }) {
  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div className='inner'>
          <h2>
            <b>Student-produced response directions</b>
          </h2>
          <ul className='list'>
            <li>
              If you find <b>more than one correct answer,</b> enter only one answer.
            </li>
            <li>
              You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
            </li>
            <li>
              If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
            </li>
            <li>
              If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
            </li>
            <li>
              If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
            </li>
            <li>
              Don't enter <b>symbols</b> such as a percent sign. comma or dollar sign.
            </li>
            <li>
              Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
              count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
            </li>
          </ul>
          <img src={examples} alt='examples' />
        </div>
      </div>
    </article>
  );
}
