import React from 'react';
import { nvlNumber } from 'utils/Common.utils';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역) - "Deviations from Average*" 영역 컴포넌트 */
export default function DeviationsFromAverage({ belowClassAverage }) {
  return (
    <article className='box full average'>
      <div className='com_sec_tit'>
        <div className='tit__wrap'>
          <h2 className='tit'>
            <i className='svg_icon icon_graph black'>&nbsp;</i>
            Deviations from Average
          </h2>
          <span className='tip_txt'>Consider above/below average students for class transfer</span>
        </div>
        {/* <div className="com_select_layer type_tit ">{/*클래스 active 포함 시 옵션 노출  
      <section className="selected__wrap">
        <p className="tit_field">Test</p><input type='text' className="selected" placeholder="RW10E-M10H"/>
      </section>
      <section className="layer">
        <p className="option">RW10E-M10H</p>
      </section>
    </div> */}
      </div>
      <div className='wrap'>
        <p className='tit_box'>
          Past 5 RW tests Cumulative Average
          <span className='num'>{nvlNumber(belowClassAverage?.rwAvg)} / 800</span>
        </p>
        <div className='com_list_box'>
          <div className='scroll'>
            <table className='list'>
              <colgroup>
                <col width='20%' />
                <col width='50%' />
                <col />
              </colgroup>
              <thead>
                <tr className='sticky top'>
                  <th colSpan={3} className='table_tit gray'>
                    Higher than average
                    <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {belowClassAverage.rwHigh.map((i, idx) => {
                  return (
                    <tr key={`rw_${idx}`}>
                      <td>
                        <div className='com_profile_img'>
                          <span className='name'>{i.userName?.substring(0, 1)}</span>
                          {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                        </div>
                      </td>
                      <td>
                        <span className='name'>{i.userName}</span>
                      </td>
                      <td className='alignC data_change plus'>+ {i.avg - belowClassAverage?.rwAvg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='com_list_box'>
          <div className='scroll'>
            <table className='list'>
              <colgroup>
                <col width='20%' />
                <col width='50%' />
                <col />
              </colgroup>
              <thead>
                <tr className='sticky top'>
                  <th colSpan={3} className='table_tit gray'>
                    Lower than average
                    <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {belowClassAverage.rwLow.map((i, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className='com_profile_img'>
                          <span className='name'>{i.userName?.substring(0, 1)}</span>
                          {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                        </div>
                      </td>
                      <td>
                        <span className='name'>{i.userName}</span>
                      </td>
                      <td className='alignC data_change minus'>- {belowClassAverage.rwAvg - i.avg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='wrap'>
        <p className='tit_box green'>
          Past 5 Math tests Cumulative Average
          <span className='num'>{nvlNumber(belowClassAverage?.mathAvg)} / 800</span>
        </p>
        <div className='com_list_box'>
          <div className='scroll'>
            <table className='list'>
              <colgroup>
                <col width='20%' />
                <col width='50%' />
                <col />
              </colgroup>
              <thead>
                <tr className='sticky top'>
                  <th colSpan={3} className='table_tit gray'>
                    Higher than average
                    <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {belowClassAverage.mathHigh.map((i, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className='com_profile_img'>
                          <span className='name'>{i.userName?.substring(0, 1)}</span>
                          {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                        </div>
                      </td>
                      <td>
                        <span className='name'>{i.userName}</span>
                      </td>
                      <td className='alignC data_change plus'>+ {i.avg - belowClassAverage?.mathAvg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='com_list_box'>
          <div className='scroll'>
            <table className='list'>
              <colgroup>
                <col width='20%' />
                <col width='50%' />
                <col />
              </colgroup>
              <thead>
                <tr className='sticky top'>
                  <th colSpan={3} className='table_tit gray'>
                    Lower than average
                    <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {belowClassAverage.mathLow.map((i, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className='com_profile_img'>
                          <span className='name'>{i.userName?.substring(0, 1)}</span>
                          {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                        </div>
                      </td>
                      <td>
                        <span className='name'>{i.userName}</span>
                      </td>
                      <td className='alignC data_change minus'>- {belowClassAverage.mathAvg - i.avg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </article>
  );
}
