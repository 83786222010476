import IconLink from 'assets/img/icon_link_notice.svg';

import request from 'utils/Request.utils';

import usePressESC from 'hooks/usePressESC';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

function RequestLinkModal({
  userInfo,
  useLevels,
  setLinkPop,
  authenCodeRef,
  requestLink,
  setRequestLink,
  requestCompanyName,
  setConfirmCodeError,
  setAlert,
  setReloadData,
  requestEcSeq,
}) {
  usePressESC(() => setRequestLink(false), requestLink);
  // 학원 연결 요청
  const requestLinked = () => {
    let params = {
      senderSeq: userInfo.userSeq,
      userName: userInfo.userName,
      title: 'Connection',
    };

    if (authenCodeRef.current && authenCodeRef.current.value.length > 0) {
      params.code = authenCodeRef.current.value;
    } else params.ecSeq = requestEcSeq;

    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        setLinkPop(false);
        setReloadData((prev) => !prev);
        setRequestLink(false);
        setConfirmCodeError(false);
        setAlert({ active: true, message: 'Successfully sent link request.' });
      } else if (response.code === 400) {
        setConfirmCodeError(true);
      }
    };

    request.post(`/api/member/profile/request/${useLevels.isTeacher ? 'academy/teacher' : 'academy/student'}`, params, successHandler);
  };

  return (
    <SimpleModalBase w='32rem' titleEllipsisWidth='13rem'>
      <h2 className='title-ellipsis '>
        Request link to <span className='com_txt_point text-ellipsis'>{requestCompanyName}</span> ?
      </h2>
      <div className='modal_img_wrapper'>
        <img src={IconLink} alt='icon' />
      </div>
      <div className='modal_text_container'>
        <p>
          The Academy / Tutor has to accept
          <br />
          your request in order to be linked.
        </p>
      </div>
      <div className='modal_btn_container'>
        <button onClick={() => setRequestLink(false)}>Cancel</button>
        <button className='btn_fill' onClick={requestLinked}>
          Confirm
        </button>
      </div>
    </SimpleModalBase>
  );
}

export default RequestLinkModal;
