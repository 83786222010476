import { useEffect } from 'react';

/**
 * Select Box 등 드롭다운 영역이 열려 있는 상태에서 외부 영역을 클릭 하였을 때 드롭다운 영역이 닫히는 기능을 구현하기 위한 커스텀 훅
 * 지정 영역 외부 클릭시 감지하고 콜백 실행
 *
 * @example
 * const 대상Ref = useRef(null)
 * useOutsideClick(대상Ref, () => callback(), 대상을보이게하는상태)
 */

const useOutsideClick = (ref, callback, isActive = true) => {
  useEffect(() => {
    if (!isActive) return;

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, isActive]);
};

export default useOutsideClick;
