import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import img from 'assets/img/illustration_link_man_woman.png';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

/** 이미 한 번 발생한 모달인지 확인 용 쿠키 이름 */
const IS_SHOWN_MODAL_COOKIE_NAME = 'isShownHaveToLinkWithAcademyOrTutorModalInTestGatePlus';

/** 학원 or 강사와 연결 안 됨 알림 모달 */
export default function ModalHaveToLinkWithAcademyOrTutor({
  close = () => {
    console.log('닫기 버튼 Props가 필요 합니다.');
  },
}) {
  /** 쿠키에서 이미 한 번 발생한 모달인지 확인 */
  const isModalShown = Cookies.get(IS_SHOWN_MODAL_COOKIE_NAME);

  if (isModalShown) return null;

  /** 모달 보여주기 여부 */
  const [isModal, setIsModal] = useState(true);

  const navigate = useNavigate();

  /** 이미 한 번 발생 한 모달인지 확인 할 쿠키 설정 함수 */
  const setIsShownModalCookie = () => {
    if (!isModalShown) {
      // 1년 동안 쿠키 유지
      Cookies.set(IS_SHOWN_MODAL_COOKIE_NAME, 'true', { expires: 365 });
    }
  };

  /** 닫기 버튼 클릭 핸들러 */
  const handleClose = ({ isModalShown }) => {
    setIsShownModalCookie();
    close();
  };

  /** 주요 버튼 클릭 핸들러 */
  const handlePrimaryConfirm = () => {
    // navigate('/student/profile?tab=academy')
    setIsShownModalCookie();
    navigate('/student/profile', {
      state: {
        tab: 'academy',
      },
    });
  };

  useEffect(() => {
    if (isModalShown) {
      setIsModal(false);
    }
  }, [isModalShown]);

  return (
    <S.ModalBackgroundWrap>
      <S.ModalBox>
        <div className='img_area'>
          <img src={img} alt='' />
        </div>
        <div className='txt_area'>
          <div className='hello'>Welcome to TESTGATE!</div>
          <div className='title_main'>Are you affiliated with an Academy or a Tutor?</div>
          <div className='title_sub'>We’ll help you link with an Academy or a Tutor!</div>
          <button type='button' className='btn_solid' onClick={handlePrimaryConfirm}>
            Link up with an Academy or a Tutor
          </button>
          <button type='button' className='btn_txt' onClick={() => handleClose({ isModalShown })}>
            No, I am not affiliated with any Academy or Tutor
          </button>
        </div>
      </S.ModalBox>
    </S.ModalBackgroundWrap>
  );
}

const S = {};

S.ModalBackgroundWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.ModalBox = styled.div`
  background: linear-gradient(53deg, #249cff 22.5%, #8cbaff 68.81%);
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  width: 25rem;
  height: 34.375rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  .img_area {
    img {
      margin-bottom: -2px;
    }
  }
  .txt_area {
    width: 100%;
    padding: 1.25rem;
    background-color: #ffffff;
    text-align: center;
    .hello {
      color: #0068bd;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.125rem; /* 158.333% */
      letter-spacing: -0.24px;
    }
    .title_main {
      margin-top: 0.5rem;
      color: var(--main-black, #000);
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.75rem; /* 155.556% */
      letter-spacing: -0.36px;
    }
    .title_sub {
      margin-top: 0.5rem;
      color: #4b5563;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.75rem; /* 142.857% */
      letter-spacing: -0.28px;
    }
    .btn_solid {
      margin-top: 1.5rem;
      width: 100%;
      padding: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: #008cff;
      color: #fff;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
    }
    .btn_txt {
      margin: 1.5rem 0;
      color: var(--sub-text, #7f8998);
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;
