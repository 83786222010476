import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

//components
import Login from 'components/user/sign-in/Page';
import FindEmail from 'components/user/sign-in/find/email/Page';
import FindEmailComplete from 'components/user/sign-in/find/email/complete/Page';
import FindPasswd from 'components/user/sign-in/find/password/Page';
import FindPasswdComplete from 'components/user/sign-in/find/password/complete/Page';
import PasswdReset from 'components/user/sign-in/password-reset/Page';
import PasswdResetComplete from 'components/user/sign-in/password-reset/complete/Page';
import request from 'utils/Request.utils';

import { USER_LEVELS } from 'utils/constants.js';
//css
// import "../styles/scss/pages/gate_member/gate_member.scss";

const SigninRoute = () => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      let redirectUrl;

      if (userInfo.userLevel === USER_LEVELS.STUDENT) {
        redirectUrl = '/student/dashboard';
      } else {
        redirectUrl = '/academy/dashboard';
      }

      const authRoutes = [
        { path: '/', element: <Navigate to={redirectUrl} /> },
        { path: '/login', element: <Navigate to={redirectUrl} /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/', element: <Login /> },
        { path: '/find/email', element: <FindEmail /> },
        { path: '/find/email/complete', element: <FindEmailComplete /> },
        { path: '/find/passwd', element: <FindPasswd /> },
        { path: '/find/passwd/complete', element: <FindPasswdComplete /> },
        { path: '/passwd/reset', element: <PasswdReset /> },
        { path: '/passwd/reset/complete', element: <PasswdResetComplete /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };
  return (
    <>
      <Routes> {AuthorizationRoutes()} </Routes>
      <div className='copyright'>Copyright © 2023 Gate⁺ All rights reserved.</div>
    </>
  );
};

export default SigninRoute;
