import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import CustomAlert from 'components/_common/alerts/CustomAlert';

const PasswdReset = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);

  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const resetId = query.get('reset_id');

  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  const [passwordError, setPasswordError] = useState({
    password: true,
    confirmPassword: true,
    passwordEight: false,
    passwordSpecial: false,
    passwordMismatch: false,
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  const passwordRef = useRef('');
  const confirmPasswordRef = useRef('');

  const passwordCheck = () => {
    if (nvl(passwordRef.current.value).length >= 8) {
      setPasswordError((prev) => {
        return { ...prev, password: true, passwordEight: true };
      });
    } else {
      setPasswordError((prev) => {
        return { ...prev, password: false, passwordEight: false };
      });
    }
    const patternSpecial = /[\W_]/;
    const patternNum = /[0-9]/;
    if (patternSpecial.test(nvl(passwordRef.current.value)) && patternNum.test(nvl(passwordRef.current.value))) {
      setPasswordError((prev) => {
        return { ...prev, password: true, passwordSpecial: true };
      });
    } else {
      setPasswordError((prev) => {
        return { ...prev, password: false, passwordSpecial: false };
      });
    }
    if (nvl(passwordRef.current.value) === nvl(confirmPasswordRef.current.value) && nvl(confirmPasswordRef.current.value).length !== 0) {
      setPasswordError((prev) => {
        return { ...prev, confirmPassword: true, passwordMismatch: true };
      });
    } else {
      setPasswordError((prev) => {
        return { ...prev, confirmPassword: false, passwordMismatch: false };
      });
    }
  };

  const nextBtnClick = () => {
    let allPassed = true;

    passwordCheck();

    if (passwordRef.current.value.length < 8 || !passwordError.password || !passwordError.confirmPassword) {
      allPassed = false;
    }

    if (allPassed) {
      updatePW();
    }
  };

  const updatePW = () => {
    let pms = {
      userPasswd: passwordRef?.current.value,
      userEmail: resetId,
      userId: resetId,
    };

    const successHandler = (response) => {
      console.log(response);
      if (response.code === 200) {
        navigate('complete');
      } else if (response.code === 444) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: "You've already changed your password.",
          };
        });
      }
    };

    request.put(`/api/common/reset/passwd`, pms, successHandler);
  };

  return (
    <>
      <div className='container'>
        <Header>
          <p className='desc'>The door to educational aspiration</p>
        </Header>
        <section className='forgot_email'>
          <h1 className='tit'>Reset your password</h1>
          <p className='txt'>You need to reset your password. Please type in new password and sign in with your new password.</p>
        </section>
        <section className='form'>
          {/* 비밀번호 */}
          <article className={`input_wrap ${passwordError.password ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>NEW PASSWORD</span>
            </h2>
            <div className='box input_pass '>
              <input className='input' type='password' ref={passwordRef} onChange={passwordCheck} />
            </div>
          </article>
          {/* 비밀번호 확인 */}
          <article className={`input_wrap ${passwordError.confirmPassword ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>CONFIRM NEW PASSWORD</span>
            </h2>
            <div className='box input_pass_confirm '>
              <input className='input' type='password' ref={confirmPasswordRef} onChange={passwordCheck} />
            </div>
            <div className='com_password_message'>
              <p className={`check ${passwordError.passwordEight ? 'valid' : ''}`} id='lengthMessage'>
                {/*유효할경우 addClass valid*/}
                Must be over 8 characters
              </p>
              <p className={`check ${passwordError.passwordSpecial ? 'valid' : ''}`} id='characterMessage'>
                Must have a number and a special character (!, @ , ?)
              </p>
              <p className={`check ${passwordError.passwordMismatch ? 'valid' : ''}`} id='matchMessage'>
                Passwords must match
              </p>
            </div>
          </article>
          <button className='btn btn_fill forgot_btn btn_big' onClick={() => nextBtnClick()}>
            NEXT
          </button>
        </section>
      </div>
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
};

export default PasswdReset;
