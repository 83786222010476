import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ColumnChart = ({ classData, subject }) => {
  const chartData = {
    series: classData.series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: classData?.xaxisCategories.length === 1 ? '40%' : '70%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        // categories: classData?.xaxisCategories.length > 3 ? (classData?.xaxisCategories.map(category => category.length > 3 ? category.slice(0, 3) + '...' : category)) : classData?.xaxisCategories,
        categories: classData?.xaxisCategories.map((category) => (category.length > 3 ? category.slice(0, 3) + '...' : category)),
      },
      yaxis: {
        show: true,
        title: {
          rotate: 0,
          offsetX: -5,
          offsetY: -170,
        },
        max: subject === 'F' ? 1600 : 800,
        min: 0,
        tickAmount: 4,
      },
      colors: ['#008CFF', '#1FDF9F'],
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            let originalLabel = classData?.xaxisCategories[dataPointIndex];

            if (classData && classData.series[seriesIndex]) {
              return originalLabel.replace('...', classData.series[seriesIndex].tooltipReplacement || '');
            }

            return value;
          },
        },
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={chartData.options} series={chartData.series} type='bar' height={350} />
    </div>
  );
};

export default ColumnChart;
