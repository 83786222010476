import React from 'react';

/** "/com/score" 페이지의 "Student List 02" 의 목록 개별 요소 컴포넌트 */
function MemberList02Item({ item }) {
  return (
    <tr>
      <td>
        <div className='com_profile_img'>
          {item.upFileSeq > 0 ? (
            <img
              className='profile-img-background'
              src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${item.userSeq}`}
              alt='profile'
            />
          ) : (
            <span className='name'>{item.userName?.[0]}</span>
          )}
        </div>
      </td>
      <td>
        <span className='name'>{item.userName}</span>
      </td>
      {/*To Do : 8글자 제한*/}
      <td className='alignC data_field'></td>
      <td className='alignC data_field'></td>
      <td className='alignC'></td>
    </tr>
  );
}

export default MemberList02Item;
