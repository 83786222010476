import React from 'react';

const TestListClass = ({ status, dataList }) => {
  // test list layer
  // console.log(dataList);

  return (
    <div className='com_btn_report has_layer'>
      <i className='svg_icon icon_score_report s black'>&nbsp;</i>
      <div className='com_list_layer'>
        <p className='tit_area'>Test List</p>
        <div className='list_area scroll'>
          {dataList != null ? (
            JSON.parse(dataList).map((item, index) => {
              return (
                <p className='item' key={`testClass_${item.custom_set_seq}_${index}`}>
                  <i className='svg_icon icon_report'>&nbsp;</i>
                  {item.test_name}
                </p>
              );
            })
          ) : (
            <p className='option'>{status === 'E' ? 'No scheduled test yet' : 'No available test'}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestListClass;
