import React, { useState, useEffect, useRef } from 'react';

import Draggable from 'react-draggable';
import Desmos from 'desmos';

// ---
const CalculatorPopup = ({ setExamComponents, examComponents, cpDisplay, cpExpand }) => {
  const nodeRef = useRef(null);
  const expressionRef = useRef(null);

  const [buttonOn, setButtonOn] = useState(false);

  const desmosArea = document.createElement('div');
  const calculator = Desmos.GraphingCalculator(desmosArea); // (desmosArea, { autosize: false });

  const makeDesmosCalculator = () => {
    if (cpExpand) {
      desmosArea.style.width = '820px';
      desmosArea.style.height = '645px';
    } else {
      desmosArea.style.width = '410px';
      desmosArea.style.height = '580px';
    }

    // calculator.setExpression({ id: 'graph1', latex: 'y=x^2' })
    // calculator.setExpression({ id: 'graph2', latex: 'z=y^3' })

    // if(examComponents.cpState) {
    //   if(examComponents.cpState != null) {
    //     examComponents.cpState.map((expression, index) => {
    //       console.log("속성 값 확인 : ", expression.id, expression.color, expression.latex)

    //       if(expression.latex != null) calculator.setExpression({ id: expression.id, color: expression.color, latex: expression.latex })
    //       else calculator.setExpression({ id: expression.id, color: expression.color })

    //       return null;
    //     })
    //   }
    // }

    if (expressionRef?.current != null && expressionRef?.current.length > 0) {
      expressionRef.current.map((expression, index) => {
        // console.log("속성 값 확인 : ", expression.id, expression.color, expression.latex)

        if (expression.latex != null)
          calculator.setExpression({
            id: expression.id,
            color: expression.color,
            latex: expression.latex,
          });
        else
          calculator.setExpression({
            id: expression.id,
            color: expression.color,
          });

        return null;
      });
    }

    document.getElementById('desmosCalculator').prepend(desmosArea);
  };

  useEffect(() => {
    // makeDesmosCalculator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    expressionRef.current = examComponents.cpState;

    // let newList = []
    // examComponents.cpState?.map((item, index) => (newList.push({...item, id: (index + 1)})));
    // expressionRef.current = newList;

    // examComponents.cpState?.map((item, index) => (calculator.removeExpression({id: index + 1})));

    makeDesmosCalculator();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpExpand]);

  calculator.observeEvent('change', function () {
    let currentState = calculator.getState();

    console.log('Change occurred => state 확인 !! : ==>', currentState.expressions);

    let newList = [];
    currentState.expressions.list?.map((item, index) => newList.push({ ...item, id: `g_${index + 1}` }));
    expressionRef.current = newList;

    console.log('결과', newList);

    setExamComponents((prev) => {
      return { ...prev, cpState: newList };
    });
  });

  // --------------------- 요기부터 dragable

  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
  });

  const onStart = () => {
    setState({ ...state, activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ ...state, activeDrags: --state.activeDrags });
  };

  const dragHandlers = { onStart, onStop };

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <div ref={nodeRef} className={cpDisplay ? 'com_popup type_move active pop_calculator' : 'com_popup type_move pop_calculator'}>
        {' '}
        {/*addClass 'active' 시 노출*/}
        <div className={cpExpand ? 'pop_container expand' : 'pop_container'}>
          {/*expand 클릭시 addClass 'expand'*/}
          <strong className='cursor'>
            <div className='pop_header handle' onMouseDown={() => setButtonOn(true)} onMouseUp={() => setButtonOn(false)}>
              <div className='pop_tit'>Calculator</div>
              <button className={buttonOn ? 'svg_icon btn_move active' : 'svg_icon btn_move'}>&nbsp;</button>
              <div className='btn_area'>
                <button
                  className='btn_size'
                  onClick={() => {
                    setExamComponents((prev) => {
                      return { ...prev, cpExpand: !cpExpand };
                    });
                  }}>
                  <i className='svg_icon icon_expand'>&nbsp;</i>Expand
                </button>
                <button
                  className='svg_icon btn_pop_close white'
                  onClick={() =>
                    setExamComponents((prev) => {
                      return { ...prev, cpDisplay: false };
                    })
                  }>
                  &nbsp;
                </button>
              </div>
            </div>
          </strong>
          <div id='desmosCalculator' className='pop_body' style={{ overflow: 'hidden' }} draggable='false' dragstart='false'>
            {/* 계산기 영역 */}
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default CalculatorPopup;
