import React from 'react';
import ModuleNum from '../common/ModuleNum';

/** 모듈 안내 : Reading and Writing Module 2 easy */
export default function ModuleDirectionsReadingAndWritingModule2Easy({ testList, rwTestDiff }) {
  return (
    testList &&
    testList[0].set_subject !== 'M' &&
    testList[0].set_difficulty !== 'H' &&
    rwTestDiff !== 'H' && (
      <section className='module_sec'>
        <ModuleNum number='2' />
        <article className='tit_area'>
          <div className='test_name'>Reading and Writing</div>
          <div className='question_num'>27 Questions</div>
          <div className='module_name'>Module 2 Easy</div>
        </article>
        <article className='notice_area'>
          <div className='tit_field'>Directions</div>
          <div className='txt_field'>
            The questions in this section address a number of important reading and writing skills. Each question includes one or more passages, which may
            include a table or graph. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s). All
            questions in this section are multiple-choice with four answer choices. Each question has a single best answer.
          </div>
        </article>
      </section>
    )
  );
}
