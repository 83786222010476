import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MathJax } from 'better-react-mathjax';

//img
import examples from 'assets/img/exam/examples.png';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import 'styles/css/exam.css';
import MathJaxViewer from 'components/_common/lib/mathJax/MathJaxViewer';

const ExamMaTms = ({ setExamMain, examMaDisplay, examComponents, setExamComponents, currentQuestion, setCurrentQuestion }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [loadingBarActive, setLoadingBarActive] = useState(false);
  const [isUndoActive, setUndoActive] = useState(false);
  const [bookmarkYn, setBookmarkYn] = useState(false);
  const [helpItem, setHelpItem] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
    item11: false,
    item12: false,
    item13: false,
  });

  const examDivRef = useRef();

  const bookmarkChangeHandler = (e) => {
    //console.log("target 확인 : ", e.target, e.target.value);

    setBookmarkYn(!bookmarkYn);
  };

  const radioAnswerChangeHandler = (e, no) => {
    //console.log("target 확인 : ", e.target, e.target.value);
  };

  const numberCheck = (e) => {
    let newValue = e.target.value;

    newValue = newValue.replace(/[^0-9-./]| /g, '');

    // Further processing for length and format
    if (newValue.length === 1 && newValue[0] !== '-') {
      newValue = newValue.replace(/[^0-9-./]/g, '');
    } else if (newValue.length > 1) {
      if (newValue[0] === '-') {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 5);
      } else {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 4);
      }
    }

    setCurrentQuestion((prev) => {
      return { ...prev, tryAnswer: newValue };
    });
  };

  const handleUndoButtonClick = () => {
    setUndoActive(!isUndoActive);
  };

  const dangerouslyToggle = (e, no) => {
    // undo_selected
    let newQuestion;

    let currentUnderLine = nvl(currentQuestion[`underLine${no}`]);
    let currentTryAnswer = nvl(currentQuestion.tryAnswer);

    if (currentUnderLine === 'N' || currentUnderLine === '') {
      newQuestion = {
        ...currentQuestion,
        [`underLine${no}`]: 'Y',
        tryAnswer: currentTryAnswer === e.target.value ? '' : currentTryAnswer,
      };
    } else {
      newQuestion = { ...currentQuestion, [`underLine${no}`]: 'N' };
    }

    setCurrentQuestion((prev) => {
      return { ...newQuestion };
    });
  };

  useEffect(() => {
    let divList = document.getElementById('answerArea')?.children;

    if (isUndoActive) {
      for (const divNode of divList) {
        // console.log("divNode : ", divNode)
        // console.log("add", divNode.childNodes)

        divNode.childNodes[1].style.width = 'calc(100% - 50px)';
      }
    } else {
      for (const divNode of divList) {
        // console.log("divNode : ", divNode)
        // console.log("remove", divNode.childNodes)

        divNode.childNodes[1].style.width = '100%';
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUndoActive]);

  useEffect(() => {
    document.body.classList.add('scroll_lock');

    return () => {
      document.body.classList.remove('scroll_lock');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------------------

  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });

  const moreRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreRef.current && !moreRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, moreDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents]);

  const exitExam = () => {
    // navigate('/main')
  };

  const wrapButtonHandler = (arrow) => {
    if (arrow === 'left') {
      if (wrap.leftArrow)
        setWrap((prev) => {
          return { ...prev, leftArrow: false };
        });
      else {
        if (!wrap.leftArrow && wrap.rightArrow)
          setWrap((prev) => {
            return { ...prev, rightArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, leftArrow: !wrap.leftArrow };
          });
      }
    } else {
      if (wrap.rightArrow)
        setWrap((prev) => {
          return { ...prev, rightArrow: false };
        });
      else {
        if (!wrap.rightArrow && wrap.leftArrow)
          setWrap((prev) => {
            return { ...prev, leftArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, rightArrow: !wrap.rightArrow };
          });
      }
    }
  };

  const helpTitleClick = (index) => {
    const item = `item${index}`;
    setHelpItem((prev) => {
      return { ...prev, [item]: !helpItem[item] };
    });
  };

  const helpExpandAll = () => {
    setExamComponents((prev) => {
      return { ...prev, helpItemDisplay: true };
    });
  };

  const helpCollapseAll = () => {
    setExamComponents((prev) => {
      return { ...prev, helpItemDisplay: false };
    });
    setHelpItem((prev) => Array.from({ length: Object.keys(prev).length }, () => false));
  };

  return (
    <>
      <div
        className={`com_popup pop_exam active ${examComponents.directionDisplay ? 'direction_on' : ''} ${examComponents.cpDisplay && currentQuestion?.questionFormat !== 'S' ? 'calculator_on' : ''}`}>
        {/*addClass 'active' 시 노출*/} {/*addClass 'direction_on' 시 Directions 레이어 노출*/} {/*계산기 노출 시 addClass 'calculator_on'*/}
        <div className='pop_container'>
          <section ref={examDivRef} className='pop_header'>
            <div className='tit_area'>
              <div className='tit'>{currentQuestion?.moduleUniqueCode}</div>
              <button
                className='btn'
                onClick={() =>
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      directionDisplay: !examComponents.directionDisplay,
                    };
                  })
                }>
                Directions<i className='svg_icon icon_arrow black'>&nbsp;</i>
              </button>
              {examComponents.directionDisplay && <DirectionsPopup setExamComponents={setExamComponents} examDivRef={examDivRef} />}
            </div>
            <div className={`time_area ${examComponents.timeAreaDisplay ? '' : 'hide'}`}>
              {/*btn_time 클릭 시 addClass 'hide'*/}
              <div className='time'>00 : 00</div>
              <button
                className='btn_time'
                onClick={() =>
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      timeAreaDisplay: !examComponents.timeAreaDisplay,
                    };
                  })
                }>
                Hide
              </button>
            </div>
            <div className='btn_area'>
              <button
                className={`btn ${examComponents.cpDisplay ? 'active' : ''}`}
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, cpDisplay: !prev.cpDisplay };
                  })
                }>
                <i className='svg_icon icon_calculator'>&nbsp;</i>
                Calculator
              </button>
              <button
                className={`btn ${examComponents.rspDisplay ? 'active' : ''}`}
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, rspDisplay: !examComponents.rspDisplay };
                  })
                }>
                <i className='svg_icon icon_reference'>&nbsp;</i>
                Reference
              </button>
              <button
                className={`btn ${examComponents.moreDisplay ? 'active' : ''}`}
                onClick={() => {
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      moreDisplay: !examComponents.moreDisplay,
                    };
                  });
                }}>
                {/*클릭 시 addClass 'active'*/}
                <i className='svg_icon icon_more'>&nbsp;</i>
                More
              </button>
              {examComponents.moreDisplay && (
                <div className='more_layer' ref={moreRef}>
                  <button
                    className='item'
                    onClick={() =>
                      setExamComponents((prev) => {
                        return { ...prev, helpDisplay: true };
                      })
                    }>
                    <i className='svg_icon icon_help'>&nbsp;</i>Help
                  </button>
                  <button className='item' onClick={() => exitExam()}>
                    <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
                  </button>
                </div>
              )}
            </div>
          </section>
          {!examComponents.reviewDisplay ? (
            <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
              {' '}
              {/*btn_wrap 클릭 시 addClass " change_wrap_width"*/}
              {currentQuestion?.questionFormat !== 'M' && <MathQuestion wrap={wrap} setWrap={setWrap} wrapButtonHandler={wrapButtonHandler} />}
              <article className={`wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''}`}>
                {/*btn_undo 클릭 시 addClass 'undo_active'*/}
                <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
                  &nbsp;
                </button>

                <MathJax>
                  <div className='scroll_wrap'>
                    <div className='inner'>
                      <div className='top_area'>
                        <div className='num'>{currentQuestion.currentNum}</div>
                        <div className='bookmark'>
                          <input
                            type='checkbox'
                            id='bookmark1'
                            className='btn_bookmark'
                            value={bookmarkYn}
                            onChange={(e) => {
                              bookmarkChangeHandler(e);
                            }}
                            checked={currentQuestion?.bookMark === 'Y'}
                          />
                          <label htmlFor='bookmark1'>Mark for Review</label>
                        </div>
                        {/*주관식에서 비노출*/}
                        {currentQuestion?.questionFormat !== 'S' && (
                          <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={handleUndoButtonClick}>
                            <i className='svg_icon icon_undo'>&nbsp;</i>
                            <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
                          </button>
                        )}
                      </div>
                      <div className='text'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion.question.replace(
                              '/common/commonImageView.do',
                              `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                            ),
                          }}
                        />
                        {/* <MathJaxViewer convertString={ nvl(currentQuestion.question) } /> */}
                      </div>
                      <div id='answerArea' className='answer_area'>
                        {/*객관식*/}
                        {currentQuestion?.questionFormat !== 'S' && nvl(currentQuestion?.multipleChoiceItems1) !== '' && (
                          <>
                            {/* <div className="item"> */}
                            {/* <div className={ nvl(currentQuestion?.underLine1) !== 'Y' ? 'item type_top' : 'item  type_top undo_selected' }> */}{' '}
                            {/*1130 item 높이 100넘을때 만 addClass type_top*/}
                            <div className={nvl(currentQuestion?.underLine1) !== 'Y' ? 'item' : 'item undo_selected'}>
                              <input
                                type='radio'
                                name='answer1'
                                id='answer1_1'
                                className='radio_answer'
                                value='A'
                                onChange={(e) => radioAnswerChangeHandler(e, 1)}
                                checked={currentQuestion?.tryAnswer === 'A'}
                              />
                              <label className='answer' htmlFor='answer1_1'>
                                <span className='num'>A</span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentQuestion?.multipleChoiceItems1.replace(
                                      '/common/commonImageView.do',
                                      `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                    ),
                                  }}
                                />
                                {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems1) } /> */}
                              </label>
                              <button className='num' onClick={(e) => dangerouslyToggle(e, 1)} value='A'>
                                A
                              </button>
                            </div>
                            <div className={nvl(currentQuestion?.underLine2) !== 'Y' ? 'item' : 'item undo_selected'}>
                              <input
                                type='radio'
                                name='answer1'
                                id='answer1_2'
                                className='radio_answer'
                                value='B'
                                onChange={(e) => radioAnswerChangeHandler(e, 2)}
                                checked={currentQuestion?.tryAnswer === 'B'}
                              />
                              <label className='answer' htmlFor='answer1_2'>
                                <span className='num'>B</span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentQuestion?.multipleChoiceItems2.replace(
                                      '/common/commonImageView.do',
                                      `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                    ),
                                  }}
                                />
                                {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems2) } /> */}
                              </label>
                              <button className='num' onClick={(e) => dangerouslyToggle(e, 2)} value='B'>
                                B
                              </button>
                            </div>
                            <div className={nvl(currentQuestion?.underLine3) !== 'Y' ? 'item' : 'item undo_selected'}>
                              <input
                                type='radio'
                                name='answer1'
                                id='answer1_3'
                                className='radio_answer'
                                value='C'
                                onChange={(e) => radioAnswerChangeHandler(e, 3)}
                                checked={currentQuestion?.tryAnswer === 'C'}
                              />
                              <label className='answer' htmlFor='answer1_3'>
                                <span className='num'>C</span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentQuestion?.multipleChoiceItems3.replace(
                                      '/common/commonImageView.do',
                                      `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                    ),
                                  }}
                                />
                                {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems3) } /> */}
                              </label>
                              <button className='num' onClick={(e) => dangerouslyToggle(e, 3)} value='C'>
                                C
                              </button>
                            </div>
                            <div className={nvl(currentQuestion?.underLine4) !== 'Y' ? 'item' : 'item undo_selected'}>
                              <input
                                type='radio'
                                name='answer1'
                                id='answer1_4'
                                className='radio_answer'
                                value='D'
                                onChange={(e) => radioAnswerChangeHandler(e, 4)}
                                checked={currentQuestion?.tryAnswer === 'D'}
                              />
                              <label className='answer' htmlFor='answer1_4'>
                                <span className='num'>D</span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentQuestion?.multipleChoiceItems4.replace(
                                      '/common/commonImageView.do',
                                      `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                                    ),
                                  }}
                                />
                                {/* <MathJaxViewer convertString={ nvl(currentQuestion.multipleChoiceItems4) } /> */}
                              </label>
                              <button className='num' onClick={(e) => dangerouslyToggle(e, 4)} value='D'>
                                D
                              </button>
                            </div>
                          </>
                        )}

                        {/*주관식*/}
                        {currentQuestion?.questionFormat !== 'M' && (
                          <div className='answer_input'>
                            <div className='input_box'>
                              <input type='text' className='input' value={currentQuestion?.tryAnswer} onChange={(e) => numberCheck(e)} />
                            </div>
                            <div className='preview'>
                              Answer preview : &nbsp;
                              {currentQuestion?.tryAnswer.includes('-') && currentQuestion?.tryAnswer.includes('/') ? (
                                <MathJaxViewer
                                  convertString={nvl(
                                    `$$-\\frac{${currentQuestion?.tryAnswer.split('/')[0].replace('-', '')}}{${currentQuestion?.tryAnswer.split('/')[1]}}$$`
                                  )}
                                />
                              ) : currentQuestion?.tryAnswer.includes('/') ? (
                                <MathJaxViewer
                                  convertString={nvl(`$$\\frac{${currentQuestion?.tryAnswer.split('/')[0]}}{${currentQuestion?.tryAnswer.split('/')[1]}}$$`)}
                                />
                              ) : (
                                <>{currentQuestion?.tryAnswer}</>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </MathJax>
              </article>
            </section>
          ) : (
            <section className='pop_body'></section>
          )}

          <section className='pop_footer'>
            <div className='name'>{nvl(userInfo?.userName)}</div>
            {/* <article className="btn_area">
              <button className="btn" onClick={ backButtonClick }>Back</button>
              <button className="btn" onClick={ nextButtonClick }>Next</button>
            </article> */}
          </section>
        </div>
      </div>
      {/*help 팝업*/}
      {examComponents.helpDisplay && (
        <div className={`com_popup type_dim pop_exam_help`}>
          {/*addClass 'active' 시 노출*/}
          <div className='pop_container xl'>
            <div className='pop_header'>
              Help
              <button
                className='svg_icon btn_pop_close'
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, helpDisplay: false };
                  })
                }>
                &nbsp;
              </button>
              <div className='btn_area'>
                <button className='btn' onClick={helpExpandAll}>
                  Expand All
                </button>
                <button className='btn' onClick={helpCollapseAll}>
                  Collapse All
                </button>
              </div>
            </div>
            <div className='pop_body'>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item1 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(1)}>
                  Zoom In/Out
                </div>
                <div className='info_field'>
                  Use keyboard shortcuts to zoom in or out on laptops and pinch to zoom on tablets. <br />
                  Windows and Chromebooks: <b>Control + Plus (+)</b> to zoom in and <b>Control + Minus (-)</b> to zoom out. <br />
                  Mac: <b>Command + Plus (+)</b> to zoom in and <b>Command + Minus (-)</b> to zoom out.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item2 ? 'active' : ''}`}>
                {/*클릭 시 addClass 'active' info_field 노출*/}
                <div className='tit_field' onClick={() => helpTitleClick(2)}>
                  In-App Calculator
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_calculator'>&nbsp;</i>
                  <b>Calculator:</b>On math questions, you will have access to graphing calculator built into the webpage. You can also use your own approved
                  caclulator.{' '}
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item3 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(3)}>
                  Testing Timers
                </div>
                <div className='info_field'>
                  During testing, a timer will let you know much time remains for each module or part of the test. <br />
                  <br />
                  At the end of section 1, you'll have a short break. During the break, we'll show you a timer counting down to the beginning of section 2.{' '}
                  <br />
                  <br />
                  When time runs out on section 2, the test will end, and your answers will be automatically submitted.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item4 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(4)}>
                  Annotate Tool
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_annotate'>&nbsp;</i>
                  <b>Annotation: </b>
                  On all non-math questions, you can highlight a passage and write notes to yourself. Simply select some text, then select <b>Annotate</b> from
                  the toolbar at the top right of the screen. Click <b>Save</b> to highlight the text, or add a note and then click <b>Save</b>. You can hover
                  over the annotation to read your note, or click into it to edit further. If you don't want to keep an annotation, just open it up and click{' '}
                  <b>Delete</b>. You can annotate text from questions, passages, stimuli, and source documents. You cannot annotate directions, pictures,
                  buttons, or content from answer options. If the content you annotate is shared across multiple questions, your annotations will be available
                  across all of those questions. Your annotations will not be graded.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item5 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(5)}>
                  Option-Eliminator
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_undo'>&nbsp;</i>
                  <b>Option Eliminator:</b>
                  On any multiple-choice question, you can cross out answer options you think are wrong. Above the answer, click the <b>ABC</b> button, then
                  you'll see a button next to each answer option, allowing you to cross it out. You can always undo this action.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item6 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(6)}>
                  Mark for Review
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_bookmark_off'>&nbsp;</i>
                  <b>Mark for Review:</b>Flag any questions you want to come back later.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item7 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(7)}>
                  Question Menu
                </div>
                <div className='info_field'>
                  At the bottom of each testing screen, you'll find a menu that allows you to navigate to any question in the section. You'll see which
                  questions you've answered, which you haven't, and any that you've marked for review.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item8 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(8)}>
                  No penalty for Guessing
                </div>
                <div className='info_field'>Points are never deducted for wrong answer. So, it's always better to guess than to leave an answer blank.</div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item9 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(9)}>
                  Reloading the Webpage
                </div>
                <div className='info_field'>
                  If your webpage crashes during your exam, just reload the webpage to get back to the question you were on. The testing timer will not be
                  paused, however. Same applies if you accidentally close the webpage; just relaunch the webpage. In case you exit the exam through the{' '}
                  <b>Exit</b> button, you will be graded on what you have solved until that point and will not be able to return to the exam.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item10 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(10)}>
                  Submitting Your Answers
                </div>
                <div className='info_field'>
                  During testing, the app will save all your work. When the exam is over, your answers will be submitted automatically. If you're still working
                  when time expires, your responses will be automatically saved and submitted. If your device is offline or your submission fails for any
                  reason, you'll have time to reconnect and submit your work.
                </div>
              </div>
              {/* <div className={`item ${examComponents.helpItemDisplay || helpItem.item11 ? 'active' : ''}`}>
                <div className="tit_field" onClick={() => helpTitleClick(11)}>Exiting the App on a Chromebook</div>
                <div className="info_field">If you're running the app on a Chromebook and you need to exit, hold down the power button for 1 second and then select <b>Sign Out</b>.</div>
              </div> */}
              {/* <div className={`item ${examComponents.helpItemDisplay || helpItem.item12 ? 'active' : ''}`}>
                <div className="tit_field" onClick={() => helpTitleClick(12)}>Screen Readers</div>
                <div className="info_field">
                  During testing, use the typical/native key commands that you normally use to navigate a webpage with your screen reader. Most exam pages are structured with the following regions. When applicable, you can navigate with these regions/landmarks:
                  <ul className="list">
                    <li style={{ listStyle:"inside" }}> Bluebook Controls </li>
                    <ul className="list">
                      <li style={{ listStyle:"inside" }}>Test timer</li>
                      <li style={{ listStyle:"inside" }}> test tools </li>
                    </ul>
                  </ul>
                  <ul>
                    <li style={{ listStyle:"inside" }}> Passage, image, or other source content </li>
                    <ul className="list">
                      <li style={{ listStyle:"inside" }}> Footnotes </li>
                      <li style={{ listStyle:"inside" }}> Referenced content </li>
                      <li style={{ listStyle:"inside" }}> Long description </li>
                    </ul>
                  </ul>
                  <ul>
                    <li style={{ listStyle:"inside" }}> Question and Answer </li>
                    <ul className="list">
                      <li style={{ listStyle:"inside" }}> Question number </li>
                      <li style={{ listStyle:"inside" }}> Answer options </li>
                      <li style={{ listStyle:"inside" }}> Long description </li>
                    </ul>
                    <li style={{ listStyle:"inside" }}> Questions Navigation </li>
                  </ul>
                  In general, the following test components are structured with a heading:
                  
                  <ul>
                    <li style={{ listStyle:"inside" }}> Test name </li>
                    <li style={{ listStyle:"inside" }}> Passage, image, or other source content </li>
                    <li style={{ listStyle:"inside" }}> Passage title, source documents, directions, etc. </li>
                    <li style={{ listStyle:"inside" }}> Question number </li>
                  </ul>
                  <b>Configuration</b> <br/><br/>Configure the settings for your assistive technology before you open Bluebook to take a practice test and on test day before you check in. These settings may include verbosity, punctuation, text processing, and other settings as needed. Users of assistive technology must configure settings prior to check-in to be prepared for test day.
                </div>
              </div> */}
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item13 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(13)}>
                  Screen Magnification
                </div>
                <div className='info_field'>
                  Screen magnification technology enlarges the content displayed on the computer screen in order to assist students with visual impairments.
                  Although the exam app supports some non-embedded screen magnifier tools from third parties, all users can zoom using their device's native
                  controls (Control +/- on a PC; Command +/- on a Mac; pinch and zoom on an iPad).
                </div>
              </div>
            </div>
            <div className='pop_footer'>
              <button
                className='com_btn m yellow oval'
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, helpDisplay: false };
                  })
                }>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/*exit 팝업*/}
      {examComponents.exitExamDisplay && (
        <div className='com_popup type_alert active'>
          {/*addClass 'active' 시 노출*/}
          <div className='pop_container l'>
            <button className='svg_icon btn_pop_close'>&nbsp;</button>
            <div className='pop_body'>
              <div className='alert_title'>Are You Sure You Want to Exit the Preview?</div>
              <div className='alert_text'>
                Test Previews are not timed or scored, and your progress isn't saved. If you exit and come back, you'll start over.
              </div>
            </div>
            <div className='pop_footer com_btn_wrap'>
              <button className='com_btn m gray oval'>Continue Test Preview</button>
              <button className='com_btn m yellow oval'>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Directions
const DirectionsPopup = ({ setExamComponents, examDivRef }) => {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, directionDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents, examDivRef]);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of important math skills.
        <br />
        Use of a calculator is permitted for all questions. A reference sheet, calculator, and these directions can be accessed throughout the test.
        <br />
        Unless otherwise indicated:
        <ul className='list'>
          <li>All variables and expressions represent real numbers.</li>
          <li>Figures provided are drawn to scale.</li>
          <li>All figures lie in a plane.</li>
          <li>
            The domain of a given function <i className='math'>f</i> is the set of all real numbers x for which <i className='math'>f(x)</i>is a real number:
          </li>
        </ul>
        For <b>multiple-choice questions,</b> solve each problem and choose the correct answer from the choices provided.
        <br />
        Each multiple choice question has a single correct answer
        <br />
        For <b>student-produced response questions,</b> solve each problem and enter your answer as described below.
        <ul className='list'>
          <li>
            If you find <b>more than one correct answer,</b> enter only one answer.
          </li>
          <li>
            You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
          </li>
          <li>
            If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
          </li>
          <li>
            If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
          </li>
          <li>
            If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
          </li>
          <li>
            Don't enter <b>symbols</b> such as a percent sign. comman, or dollar sign.
          </li>
          <li>
            Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
            count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
          </li>
        </ul>
        <img src={examples} alt='examples' />
      </div>
      <div className='btn_field'>
        <button
          className='btn close'
          onClick={() =>
            setExamComponents((prev) => {
              return { ...prev, directionDisplay: false };
            })
          }>
          Close
        </button>
      </div>
    </article>
  );
};

const MathQuestion = ({ wrap, setWrap, wrapButtonHandler }) => {
  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      {' '}
      {/*icon_wrap 클릭 시 addClass "big"*/}
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div className='inner question'>
          <h2>
            <b>Student-produced response directions</b>
          </h2>
          <ul className='list'>
            <li>
              If you find <b>more than one correct answer,</b> enter only one answer.
            </li>
            <li>
              You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters (including the negative sign) for a <b>negative</b> answer.
            </li>
            <li>
              If your answer is a <b>fraction</b> that doesn't fit in the provided space, enter the decimal equivalent.
            </li>
            <li>
              If your answer is a <b>decimal</b> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.
            </li>
            <li>
              If your answer is a <b>mixed number</b> (such as 3 1/2), enter it as an improper fraction (7/2) or its decimal equivalent(3.5)
            </li>
            <li>
              Don't enter <b>symbols</b> such as a percent sign. comma or dollar sign.
            </li>
            <li>
              Follow the directions strictly. If the question asks for an integer, put an integer. If the answer is 5, answers such as “05” or “5.0” will not
              count. If the question asks for fraction, put a fraction down, and decimal answers, such as 0.333 for 1/3 will not count as a correct answer.
            </li>
          </ul>
          <img src={examples} alt='examples' />
        </div>
      </div>
    </article>
  );
};

export default ExamMaTms;
