import React from 'react';
import { MathJax } from 'better-react-mathjax';

const MathJaxViewer = ({ convertString }) => {
  return (
    <MathJax>
      <div dangerouslySetInnerHTML={{ __html: convertString }} />
    </MathJax>
  );
};

export default MathJaxViewer;
