/** "Set Tests"의 단계 별로 PrimaryButtonArea에서 사용 할 데이터 */
export const ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA = [
  {
    // Class or Individual 선택 단계
    currentRouter: '/com/tests/set/selection/type',
    nextRoute: '/com/tests/set/selection/class',
  },
  {
    // class 선택 단계
    currentRouter: '/com/tests/set/selection/class',
    nextRoute: '/com/tests/set/selection/student',
  },
  {
    // 학생 선택 단계
    currentRouter: '/com/tests/set/selection/student',
    nextRoute: '/com/tests/set/selection/config',
  },
  {
    // 과목, 난이도 선택 단계
    currentRouter: '/com/tests/set/selection/config',
    nextRoute: '/com/tests/set/selection/tests',
  },
  {
    // Module 선택 단계
    currentRouter: '/com/tests/set/selection/tests',
    nextRoute: '/com/tests/set/selection/date',
  },
  {
    // Date 선택 단계
    currentRouter: '/com/tests/set/selection/date',
    nextRoute: '/com/tests/set/review',
  },
  {
    // Review 단계
    currentRouter: '/com/tests/set/review',
    nextRoute: '/com/tests/scheduled',
  },
];
