/** module 타이틀 */
export default function ModuleNum(props) {
  return (
    <article className='module_num'>
      <div className='num'>{props.number}</div>
      <div className='name'>
        Module
        <br />
        {props.number}
      </div>
      <div className='num'>{props.number}</div>
    </article>
  );
}
