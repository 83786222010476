import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from 'hooks/useOutsideClick.js';
import CorrectAnswerRateByCategoriesRw from './_components/sections/correctAnswerRateByCategories/CorrectAnswerRateByCategoriesRw';
import CorrectAnswerRateByCategoriesMa from './_components/sections/correctAnswerRateByCategories/CorrectAnswerRateByCategoriesMa';
import CorrectAnswerRateByQuestions from './_components/sections/correctAnswerRateByQuestions/CorrectAnswerRateByQuestions';

/** 학생 대시보드 페이지의 "Advanced Report" Section 컴포넌트 */
export default function AdvancedReportSection({ testResult, barChartData, barChartData2, timePerQuestionData, filterValue, setFilterValue, loadData }) {
  const [activeSatBox, setActiveSatBox] = useState(filterValue.tmSubject === 'E' ? 'rw' : 'math');
  const [filteredList, setFilteredList] = useState([]);
  const [filteredList2, setFilteredList2] = useState([]);
  const [perSubject, setPerSubject] = useState(false);
  const [perModuleNum, setPerModuleNum] = useState(false);

  // hook
  const subjectBox = useRef(null);
  useOutsideClick(subjectBox, () => setPerSubject(false));
  const moduleBox = useRef(null);
  useOutsideClick(moduleBox, () => setPerModuleNum(false));

  // sat 박스 흐리게 아니게...
  const handleClickGraphItemField = (gubun) => {
    if (testResult.subject === 'F') {
      setActiveSatBox(gubun);
      let sub = gubun === 'rw' ? 'E' : 'M';
      setFilterValue((prev) => {
        return {
          ...prev,
          tmSubject: sub,
          color: `${sub === 'E' ? '#008CFF' : '#1FDF9F'}`,
          moduleNum: '1',
          className: `custom-tooltip${sub === 'E' ? '' : '-gate'}`,
        };
      });
    }
  };

  useEffect(() => {
    // modified by psk 20231220 - 수정 (list 같은 경우 null 체크부터 하고 사용하기)
    if (testResult.questionList != null && testResult.questionList.length > 0) {
      const sortedQuestionList = testResult.questionList.sort((a, b) => a.questionOrder - b.questionOrder);

      setFilteredList(sortedQuestionList);
    }

    if (testResult.questionAccuracyList && testResult.questionAccuracyList.length > 0) {
      let filterList = [];

      const sortedAccuracyList = testResult.questionAccuracyList.sort((a, b) => a.questionOrder - b.questionOrder);

      if (filterValue.moduleNum === '2' && filterValue.tmSubject === 'E') {
        filterList = sortedAccuracyList.filter(
          (item) => item.moduleNum === filterValue.moduleNum && item.tmSubject === filterValue.tmSubject && item.tmDifficulty === testResult.engDiff
        );
      } else if (filterValue.moduleNum === '2' && filterValue.tmSubject === 'M') {
        filterList = sortedAccuracyList.filter(
          (item) => item.moduleNum === filterValue.moduleNum && item.tmSubject === filterValue.tmSubject && item.tmDifficulty === testResult.mathDiff
        );
      } else {
        filterList = sortedAccuracyList.filter((item) => item.moduleNum === filterValue.moduleNum && item.tmSubject === filterValue.tmSubject);
      }

      setFilteredList2(filterList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, loadData, testResult.questionAccuracyList, timePerQuestionData]);

  useEffect(() => {
    if (testResult.subject === 'M') {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'M', moduleNum: '1' }));
      setActiveSatBox('math');
    } else {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'E', moduleNum: '1' }));
      setActiveSatBox('rw');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResult.subject]);

  useEffect(() => {
    const currentSubject = filterValue.tmSubject === 'E' ? 'rw' : 'math';
    setActiveSatBox(currentSubject);
  }, [filterValue.tmSubject]);

  return (
    <>
      {/*<article className='box_graph per50 active'>/!*활성화 시 addClass active*!/*/}
      <CorrectAnswerRateByCategoriesRw
        barChartData={barChartData}
        handleClickGraphItemField={handleClickGraphItemField}
        activeSatBox={activeSatBox}
        testResult={testResult}
      />
      {/* math */}
      <CorrectAnswerRateByCategoriesMa
        barChartData2={barChartData2}
        activeSatBox={activeSatBox}
        handleClickGraphItemField={handleClickGraphItemField}
        testResult={testResult}
      />
      {/*Correct answer (rate) by Questions*/}
      <CorrectAnswerRateByQuestions
        perSubject={perSubject}
        setPerSubject={setPerSubject}
        setPerModuleNum={setPerModuleNum}
        subjectBox={subjectBox}
        filterValue={filterValue}
        testResult={testResult}
        handleClickGraphItemField={handleClickGraphItemField}
        perModuleNum={perModuleNum}
        moduleBox={moduleBox}
        setFilterValue={setFilterValue}
        filteredList={filteredList}
        filteredList2={filteredList2}
        timePerQuestionData={timePerQuestionData}
      />
    </>
  );
}
