import moment from 'moment';
import { useEffect, useState } from 'react';
import { nvl } from 'utils/Common.utils';
import SetTimeLayer from './setTimeCart';
import PopupCalendar from 'components/_common/modals/PopupCalendar';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateReduxStateSelectedModules } from 'reducers/setTests/action';
import request from 'utils/Request.utils';
import { addDaysToDate } from '../../selection/date/_utils/function/addDaysToDate';

/** 테스트 리스트 컴포넌트 */
export default function TestListItem({ customSetSeq, setDeleteSelection, testName, time, startDate, endDate, isB2c }) {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  /** 커스텀 알럿 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const dispatch = useDispatch();
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [isUpdateCart, setUpdateCart] = useState(false);
  const [updateStartDate, setUpdateStartDate] = useState(moment(startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'));
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [timeLayer, setTimeLayer] = useState(false);
  /** 서버 날짜 */
  const [dateValue, setDateValue] = useState();
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 시간 수정 모달 닫기 */
  const closeTimeLayer = () => {
    setTimeLayer(false);
  };

  /** 시간 수정 핸들러 */
  const handleUpdateTime = (newTime) => {
    const newTestSetState = stateSetTest.selectedModules.map((item) => {
      return item.customSetSeq === customSetSeq
        ? {
            ...item,
            testTimeInfo: {
              ...item.testTimeInfo,
              start: {
                ...item.testTimeInfo.start,
                time: newTime,
              },
              end: {
                ...item.testTimeInfo.end,
                time: newTime,
              },
            },
          }
        : item;
    });
    dispatch(updateReduxStateSelectedModules(newTestSetState));
    closeTimeLayer();
  };

  /** 날짜 수정 핸들러 */
  const handleUpdateDate = (newDate) => {
    if (moment(dateValue).format('YYYY-MM-DD') > moment(newDate).format('YYYY-MM-DD')) {
      // 오늘보다 이전 날짜를 선택 했을 시, 경고 모달 발생
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the start date of the exam.',
        };
      });
      return;
    }

    const newTestSetState = stateSetTest.selectedModules.map((item) => {
      return item.customSetSeq === customSetSeq
        ? {
            ...item,
            testTimeInfo: {
              ...item.testTimeInfo,
              start: {
                ...item.testTimeInfo.start,
                date: newDate,
              },
              end: {
                ...item.testTimeInfo.end,
                date: addDaysToDate(newDate, 6),
              },
            },
          }
        : item;
    });
    dispatch(updateReduxStateSelectedModules(newTestSetState));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  useEffect(() => {
    if (!isB2c) {
      const successHandler = (response) => {
        if (response.code === 200) {
          let todayDate = response.result.todayDate;

          setDateValue(todayDate);
        }
      };
      request.get('/api/common/today/time', null, successHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdateCart) {
      handleUpdateDate(updateStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCart, updateStartDate]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.Wrap className='item'>
        <div className='test_tit'>
          <i className='svg_icon icon_test blue'>&nbsp;</i>
          {testName}
        </div>
        {!isB2c && (
          <>
            <div className='time'>
              <button onClick={() => setTimeLayer(true)} className='btn'>
                <p className='tit_field'>Time</p> {time}
              </button>
              {/*시간 세팅*/}
              {timeLayer && <SetTimeLayer close={closeTimeLayer} confirm={handleUpdateTime} time={time} />}
            </div>
            <div className='date'>
              <p className='tit_field'>Date</p>
              {/* <PopupCalendarPeriod setSearchInfo={setUpdateDate} tit={startDate + ' ~ ' + endDate}/> */}
              <PopupCalendar tit={startDate + ' ~ ' + endDate} setUpdateStartDate={setUpdateStartDate} setUpdateCart={setUpdateCart} />
            </div>
          </>
        )}
        <button className='com_btn line xs btn_delete' onClick={setDeleteSelection}>
          Delete
        </button>
      </S.Wrap>
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {};

/** 해당 컴포넌트의 전체 영역 Styled-components */
S.Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 25px;
  gap: 16px;
  .test_tit {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    .svg_icon {
      border-radius: 40px;
      width: 40px;
      height: 40px;
      background-color: #eaf5ff;
      background-size: 20px auto;
    }
  }
  .select {
    border: 1px solid #000;
    height: 26px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-inline: 10px;
    color: #4c5664;
    .tit_field {
      padding-right: 5px;
      border-right: 1px solid #e1e1e1;
      font-size: 11px;
      margin-right: 5px;
      font-weight: 500;
      color: #000;
    }
  }
  .time {
    .btn {
      border: 1px solid #000;
      height: 26px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-inline: 10px;
      color: #4c5664;
      .tit_field {
        padding-right: 5px;
        border-right: 1px solid #e1e1e1;
        font-size: 11px;
        margin-right: 5px;
        font-weight: 500;
        color: #000;
      }
    }
    position: relative;
  }
  .date {
    border: 1px solid #000;
    height: 26px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-inline: 10px;
    color: #4c5664;
    .tit_field {
      padding-right: 5px;
      border-right: 1px solid #e1e1e1;
      font-size: 11px;
      margin-right: 5px;
      font-weight: 500;
      color: #000;
    }
    .datepicker {
      border: none;
      height: 24px;
      padding-inline: 0;
      background-position: center right;
      min-width: 90px;
      width: 160px;
      padding: 0 !important;
    }
  }
  .com_btn {
    min-width: 70px;
    &.btn_delete:hover {
      background-color: #000;
      color: #fff;
    }
  }
`;
