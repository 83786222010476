import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { nvl } from 'utils/Common.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

import moment from 'moment';
import 'styles/css/exam.css';
import { GLOBAL_EN_TEST_TIME, GLOBAL_MA_TEST_TIME } from '../_utils/constants/examTimeSetting';
import { useNavigate } from 'react-router-dom';

/** 영어 시험 (어떤 시험인지 파악 안됨) 시간 변수 */
let EN_TEST_TIME = GLOBAL_EN_TEST_TIME;
/** 수학 시험 (어떤 시험인지 파악 안됨) 시간 변수 */
let MA_TEST_TIME = GLOBAL_MA_TEST_TIME;

/** 모듈 이 끝났을 때, 잠시 노출되는 컴포넌트 */
const ModuleOver = ({ closeCustomAlert, subject, resetTime }) => {
  let insertCnt = 0;

  const navigate = useNavigate();
  const userInfo = request.tokenDecoder();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);

  const [processCompletion, setProcessCompletion] = useState(false);

  /** 문제 초기화 */
  const testInit = (filteredList) => {
    const paramsList = [];

    const newQuestionList = filteredList.map((innerItem, innerIndex) => {
      const meta = {
        question_subject: innerItem?.question_subject,
        question_format: innerItem?.question_format,
        question_difficulty: innerItem?.question_difficulty,
      };

      paramsList.push({
        uthSeq: stateExamInfo.uthSeq,
        questionSeq: innerItem.question_seq,
        testModuleSeq: innerItem.test_module_seq,
        remainingTime: nvl(subject) === 'E' ? EN_TEST_TIME : MA_TEST_TIME,
        regUserSeq: userInfo?.userSeq,
        metaData: JSON.stringify(meta),
      });

      return {
        customSetSeq: innerItem.custom_set_seq,
        setUniqueCode: innerItem.set_unique_code,
        testName: innerItem.test_name,
        setSubject: innerItem.set_subject,
        setDifficulty: innerItem.set_difficulty,
        setRegUserSeq: innerItem.set_reg_user_seq,
        setRegDate: innerItem.set_reg_date,
        setModiUserSeq: innerItem.set_modi_user_seq,
        setModiDate: innerItem.set_modi_date,
        testModuleSeq: innerItem.test_module_seq,
        moduleUniqueCode: innerItem.module_unique_code,
        moduleSubject: innerItem.module_subject,
        moduleNum: parseInt(innerItem.module_num),
        moduleDifficulty: innerItem.module_difficulty,
        qmhSeq: innerItem.qmh_seq,
        questionSeq: innerItem.question_seq,
        multipleChoiceItems1: innerItem.multiple_choice_items1,
        multipleChoiceItems2: innerItem.multiple_choice_items2,
        multipleChoiceItems3: innerItem.multiple_choice_items3,
        multipleChoiceItems4: innerItem.multiple_choice_items4,
        questionSubject: innerItem.question_subject,
        testHow: innerItem.test_how,
        questionFormat: innerItem.question_format,
        questionDifficulty: innerItem.question_difficulty,
        fieldOfStudy: innerItem.field_of_study,
        researcher: innerItem.researcher,
        passage: innerItem.passage,
        question: innerItem.question,
        tryAnswer: '',
      };
    });

    dispatch({
      type: 'setExamQuestionList',
      payload: newQuestionList,
    });

    const successHandler = (response) => {
      if (response.code === 200) {
        console.log('시험 기본 정보 저장 완료', stateExamInfo['currentTest']);

        dispatch({ type: 'setExamComponents', payload: { fieldName: 'moduleOverDisplay', data: false } });
        resetTime(moment());
        LocalStorage.setItem('sendTime', 'time', moment()); // modified by psk 20240123 - 위치 변경

        if (nvl(subject) === 'E') {
          navigate('/exam/dsat/rw', {
            state: { currentNum: 1 },
            replace: true,
          });
        } else if (nvl(subject) === 'M') {
          navigate('/exam/dsat/math', {
            state: { currentNum: 1 },
            replace: true,
          });
        }
      }
    };

    request.post('/api/exam/resultHistoryList', paramsList, successHandler).catch((error) => {
      console.error(error);
      alert('"문제 초기화" API 통신 실패');
    });
  };

  /** 문제 목록 필터링 */
  const getQuestionListFilter = (diff, questionList) => {
    if (questionList != null && questionList.length > 0) {
      return questionList.map((outerItem, outerIndex) => {
        const parsedInfo = JSON.parse(outerItem?.customSetQuestionInfo);

        return parsedInfo.filter(
          (innerItem) =>
            innerItem != null && innerItem.module_subject === nvl(subject) && parseInt(innerItem.module_num) === 2 && innerItem.module_difficulty === diff
        );
      });
    }
  };

  /** 문제 가져오기 API 요청 */
  const getQuestionList = (diff) => {
    let pms = {
      uthSeq: stateExamInfo.uthSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const paramsList = getQuestionListFilter(diff, response.result.customSetFullInfo);

        testInit(paramsList[0]);

        let currentExamInfo = stateExamInfo.currentTest; // 리덕스에 난이도 추가

        if (nvl(subject) === 'E') currentExamInfo = { ...currentExamInfo, em2d: diff };
        else if (nvl(subject) === 'M') currentExamInfo = { ...currentExamInfo, mm2d: diff };

        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'currentTest', data: { ...currentExamInfo } },
        });
      }
    };

    request
      .get(`/api/exam/customSetFullInfo?uthSeq=${pms.uthSeq}&tmSubject=${subject}&moduleNum=2&tmDifficulty=${diff}`, null, successHandler)
      .catch((error) => {
        console.error(error);
        alert('"문제 가져오기" API 통신 실패');
      });
  };

  /** 방금 시험 모듈에서 얼마나 정답 맞췄나 파악하여, 다음 모듈 난이도 결정 */
  const testCorrectAnswerCheck = async () => {
    /** API가 요구하는 형식에 맞추기 위한 데이터 수정(배열 -> 객체) */
    const newQuestionList = stateExamInfo.questionList.reduce((acc, curr, index) => {
      acc[`questionInfo${index + 1}`] = curr;
      return acc;
    }, {});

    /** 비구조화 할당을 사용하여, 전역 상태 stateExamInfo에서 questionList 키를 제외한 나머지 키를 새로운 객체로 만듦 */
    const { questionList, ...newObj } = stateExamInfo;

    /** API가 요구하는 형식에 맞춘 최종 파라미터 (얕은 병합) */
    const paramsNewStateExamInfo = { ...newObj, ...newQuestionList };

    const successHandler = (response) => {
      if (response.code === 200) {
        const incorrectCount = response.result.incorrectCount;

        // modified by psk 20231215 - 세트의 과목과 난이도로 문제 불러오기    <===== 요기가 문제인듯
        // if(stateExamInfo?.setSubject === 'F' && stateExamInfo?.setDifficulty === 'F') {
        if (stateExamInfo?.setDifficulty === 'F') {
          const diff = incorrectCount > 8 ? 'E' : 'H';
          getQuestionList(diff);
        } else if ((stateExamInfo?.setDifficulty === 'Y' && subject === 'E') || (stateExamInfo?.setDifficulty === 'D' && subject === 'M')) {
          getQuestionList('E');
        } else if ((stateExamInfo?.setDifficulty === 'Y' && subject === 'M') || (stateExamInfo?.setDifficulty === 'D' && subject === 'E')) {
          getQuestionList('H');
        } else if (nvl(stateExamInfo?.setDifficulty) !== '') {
          getQuestionList(stateExamInfo?.setDifficulty);
        } else {
          console.log('리덕스의 값이 날아간 상태', nvl(subject));

          // 일단 여기는 조금 더 시간을 두고 생각해보기
        }
      }
    };

    request.post('/api/exam/userTestCorrectAnswerCheck', paramsNewStateExamInfo, successHandler).catch((error) => {
      console.error(error);
      alert('"난이도 결정" API 통신 실패');
    });
  };

  useEffect(() => {
    document.body.classList.add('dsat-intro-2');

    closeCustomAlert();

    if (insertCnt === 0) {
      dispatch({ type: 'initExamInfo' });

      if (stateExamInfo.questionList[0].customSetSeq > 0) {
        testCorrectAnswerCheck().then(() => {
          // 처리 완료
          setProcessCompletion(true);
        });
      }

      insertCnt++;
    }

    return () => {
      document.body.classList.remove('dsat-intro-2');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='com_popup type_normal active pop_module_over'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='dsat-intro-2_main-flex-container'>
            <h1>This Module is over</h1>
            <p>
              All your work has been saved.
              <br />
              You’ll move on automatically in just a moment.
              <br />
              Do not refresh this page or quit the app.
            </p>
          </div>
          <div className='dsat-intro-2_loading-container'>
            <div className='dsat-intro-2_loader'>
              <div className='dsat-intro-2_loading-spinning-bubbles'>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ModuleOver;
