import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';

/** 달력 모달 컴포넌트 */
const PopupCalendarPeriod = ({ tit, setUpdateStartDate, setUpdateCart }) => {
  const [viewType, setViewType] = useState(false);
  const [date, setDate] = useState();
  const changeDate = (e) => {
    setUpdateCart(true);
    const DateFormat = moment(e).format('MM.DD.YYYY') + '~' + moment(e).add(6, 'days').format('MM.DD.YYYY');
    setUpdateStartDate(moment(e).format('YYYY-MM-DD'));
    setDate(DateFormat);
    setViewType(false);
  };
  const handleCalendar = (e) => {
    e.stopPropagation();
    setViewType((prev) => !prev);
  };
  return (
    <div className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker'
        onClick={handleCalendar}
        readOnly
        placeholder={tit}
        // value={date}
      />
      {viewType && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button type='button' className='svg_icon btn_pop_close black' onClick={handleCalendar}>
              &nbsp;
            </button>
          </div>
          <Calendar className='calendar' onChange={changeDate} formatDay={(locale, date) => moment(date).format('DD')} locale='en-EN' />
        </div>
      )}
    </div>
  );
};

export default PopupCalendarPeriod;
