import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';

import useOnlineStatus from 'hooks/useOnlineStatus';

import useTimer from '../../_hooks/useTimer';
import {
  getFullTimeFromLocalStorage,
  getRestTimeFromLocalStorage,
  getTimeDiffFromLocalStorage,
  setRestTimeToLocalStorage,
  setTimeDiffToLocalStorage,
} from '../../_utils/functions/timerLocalStorageFunctions';

/** 시험 타이머 컴포넌트 */
function Timer({ timeOver, sendTimeRef }) {
  const dispatch = useDispatch();
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const isOnline = useOnlineStatus();
  const { time: currentSecond, handleReset: handleTimerReset, handleStart: handleTimerStart, handleStop: handleTimerStop } = useTimer();
  /** 전체 남은 시험 시간 (number, 고정값) */
  const fullTime = getFullTimeFromLocalStorage();
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();
  /** 지나간 시간(음수) : 최초 시간 - 현재 시간(!!!현재 시간을 빼는것이 아니라, 이탈한 순간의 시간을 빼야 한다.) */
  const timeDiff = getTimeDiffFromLocalStorage();

  /** 화면에 표시 할 시간 */
  const [minutes, setMinutes] = useState(0);
  const [second, setSecond] = useState(0);

  /** 타이머 설정 */
  const timerManager = (currentSecond) => {
    /** 시간 업데이트 로직(lastUpdateTimeRef와 현재 시간의 차이를 확인해서 시간 업데이트) */
    console.log(`🚨 currentSecond :`, currentSecond, `🚨`);
    const updateTimer = (currentSecond) => {
      /** 최초 시간() : 고정 값  */
      const startTime = moment(sendTimeRef.current.time);
      /** 화면에 표실 될 시간 (분) */
      let remainingMinutes = Math.floor(restTime / 60);
      /** 화면에 표실 될 시간 (초) */
      let remainingSetSecond = Math.floor(restTime % 60);
      /** 진행 중인 시간 = 최초 시작 시간 + 타이머 적용 시간 */
      const currentSeconds = startTime.clone().add(currentSecond + 1, 'seconds');

      /** 업데이트 타이머 함수 내에서 선언해서 사용 할 timeDiff 값 */
      const timeDiffInTimer = startTime.diff(currentSeconds, 'seconds');

      /** 업데이트 타이머 함수 내에서 선언해서 사용 할 restTime 값 */
      const restTimeInTimer = fullTime + timeDiffInTimer;

      // 지난 시간 : 시험 시작 시간에서 타이머 초가 흐르고 있는 시간
      setTimeDiffToLocalStorage({ timeDiff: timeDiffInTimer });

      // 남은 시간 : 전체 시간 + 지나간 시간(음수)
      setRestTimeToLocalStorage({ restTime: restTimeInTimer });

      // 화면에 표시 되는 시간 갱신
      remainingMinutes = Math.floor(restTimeInTimer / 60);
      remainingSetSecond = Math.floor(restTimeInTimer % 60);
      setMinutes(remainingMinutes);
      setSecond(remainingSetSecond);
    };

    updateTimer(currentSecond);

    if (restTime === null) {
      // 타이머 초기 설정이 완료 되지 않은 상태에서, 타이머가 시작한 상황 (오류)
    }

    if (restTime === 300) {
      // 남은 시간이 5분 일 경우
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: true } });
    }

    if (restTime <= 300) {
      // 남은 시간이 5분 이하 일 경우
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: true } });
    } else {
      // 남은 시간이 5분 이상 일 경우
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });
    }

    // 남은 시간이 5분 이하 일 때 보이는 알림창이 15초 이후에 자동으로 닫히게 하는 로직
    if (restTime === 285) {
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } });
    }

    if (restTime <= 0 && restTime > -2 && restTime !== null) {
      // 시험 시간 종료
      timeOver();
      handleTimerReset();
    }

    if (restTime < -2 || restTime > fullTime) {
      // "남은 시간"이 "전체 시간"보다 크거나, 음수(혹시 모를 오작동 방지를 위해서 -2초까지는 여유 시간을 주도록 한다.) 일 경우 (비정상 적인 상태로 간주하여, 시험 종료)
      alert('알 수 없는 이유로 타이머에 비정상적인 시간이 입력 되었습니다. 관리자에게 문의 부탁 드립니다. 확인을 누르시면, 시험창이 닫힙니다.');
      window.close();
      handleTimerReset();
    }
  };

  useEffect(() => {
    timerManager(currentSecond); // 최초 시간 명시적으로 표시 하기 위하여 실행
    return () => {
      // 현재 페이지 컴포넌트 언마운트
      handleTimerStop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 타이머 초 증가
    timerManager(currentSecond); // 매 초마다 타이머 갱신
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSecond]);

  useEffect(() => {
    // 온/오프라인에 따른 타이머 시작/정지
    if (isOnline) {
      handleTimerStart();
    } else {
      // 네트워크 끊겼을 때, 타이머 종료
      handleTimerStop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (!stateExamComponents.moduleOverDisplay) {
      handleTimerStart();
    }

    return () => {
      // 모듈 넘어 갈 때, 타이머 리셋 (튜토리얼에서 넘어 올 때도 실행 된다.)
      handleTimerReset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.moduleOverDisplay]);

  return (
    <>
      {minutes} : {second < 10 ? `0${second}` : second}
    </>
  );
}

export default Timer;
