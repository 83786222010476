import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import LocalStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import useUserLevels from 'hooks/useUserLevels';

/** 외부 도메인에서 로그인 요청 받을 라우트 페이지 컴포넌트 */
function ExternalSignin() {
  const location = useLocation();
  const navigate = useNavigate();
  /** URL 쿼리를 객체 형태로 파싱 */
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  /** 현재 로그인 된 유저 정보 확인 */
  const userSession = LocalStorage.getItemJsonParse('userSession');

  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** 로그인 여부 상태 */
  const [isSignin, setIsSignin] = useState(false);
  /** 로딩 중 여부 상태 */
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userInfo) {
      /** 토큰이 유효한지 확인하는 용 API 요청 */
      const checkToken = () => {
        const successHandler = (response) => {
          if (response.result) {
            // 토큰 정상
            setIsSignin(true);
          }
        };

        request
          .get(`/api/etc/notifications?userSeq=${userInfo?.userSeq}`, null, successHandler)
          .catch((error) => {
            console.error(error, '외부 로그인 토큰 검증 용 API 에러');
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      checkToken();
    } else {
      // 토큰 없을 경우
      // 로그인 페이지로 리플레이스 시키기 받은 쿼리에 담아서 로그인 페이지로 리다이렉트
      navigate('/signin', {
        replace: true,
        state: {
          isExternalSign: query.isExternalSign,
          lastRoute: query.lastRoute,
          buttonType: query.buttonType,
        },
      });
    }
  }, [userInfo, navigate, query]);

  useEffect(() => {
    if (query.isExternalSign && query.lastRoute) {
      // 정식 외부 로그인 요청 받식으로 접근한 않은 경우
      if (!isLoading) {
        if (isSignin && userSession) {
          // 이미 로그인 되어 있는 경우
          // 현재 로컬 스토리지에 저장되어 있는 유저 엑세스 토큰 쿼리에 담아서 다시, main.gate 도메인으로 리다이렉트

          /** main.gateplus 로 다시 보낼 URL */
          const responseLink = `${query.isExternalSign}/sign/signin/?lastRoute=${query.lastRoute}&buttonType=${query.buttonType}&accessToken=${userSession.accessToken}&refreshToken=${userSession.refreshToken}`;

          if (!useLevelsIsLoading) {
            // 유저 상태 체크 로딩이 끝났을 경우
            if (useLevels.isParent) {
              // 학부모 계정 일 경우
              navigate('/parent/dashboard');
            } else {
              // 학부모 계정 이외의 경우
              window.location.replace(responseLink);
            }
          }
        } else {
          // 아직 로그인 되어 있지 않은 경우
          // 로그인 페이지로 리플레이스 시키기 받은 쿼리에 담아서 로그인 페이지로 리다이렉트
          navigate('/signin', {
            replace: true,
            state: {
              isExternalSign: query.isExternalSign,
              lastRoute: query.lastRoute,
              buttonType: query.buttonType,
            },
          });
        }
      }
    } else {
      // 정식 외부 로그인 요청 받식으로 접근하지 않은 경우 : 로그인 페이지 or 대시보드 페이지로 리다이렉트
      console.error('정상적이지 않은 접근');
      navigate('/', { replace: true });
    }
  }, [userSession, location, query, navigate, useLevels, useLevelsIsLoading, isLoading, isSignin]);
}

export default ExternalSignin;
