import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
//components
import Lnb from 'layout/Lnb'; //Lnb
import Gnb from 'layout/Gnb'; //Gnb
import Footer from 'layout/Footer'; //Footer
import request from 'utils/Request.utils';

import AllChartView from 'components/test/Page';
import TestView from 'components/test/view/Page';

const TestRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  console.log('pathname', pathname);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    const authRoutes = [
      { path: '/', element: <AllChartView /> },
      { path: '/test/view', element: <TestView /> },

      { path: '*', element: <Navigate to='/signin' /> },
    ];

    return returnRoutes(authRoutes);
  };

  const PathCalendar = pathname.startsWith('/test/calendar');

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathCalendar) return <></>;
    else return <Lnb />;
  };

  return (
    <>
      {renderLnb()}
      <Gnb />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default TestRoute;
