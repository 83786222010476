import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

//component
import ReviewNotes from 'components/student/_components/modals/ReviewNotes';

//img
import ImgLnbDashboard from '../assets/img/logo_white.png';
import ImgLnbCalendar from '../assets/img/lnb_calendar.png';
import ImgLnbTest from '../assets/img/lnb_test.png';
import ImgLnbMessage from '../assets/img/lnb_message.png';
import ImgLnbClasses from '../assets/img/lnb_classes.png';
import ImgLnbHome from '../assets/img/lnb_home.png';
import ImgLnbNote from '../assets/img/lnb_note.png';
import ImgLnbSchedule from '../assets/img/lnb_schedule.png';
import ImgLnbTestTaken from '../assets/img/lnb_test_taken.png';

// hook
import useUserLevels from '../hooks/useUserLevels.js';

import request from '../utils/Request.utils';
import FreezingKeyModal from 'components/_common/modals/FreezingKeyModal';
import LoadingBar from 'utils/LoadingBar';

const Lnb = () => {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent; // || useLevels.isParent;

  let { pathname } = useLocation();
  pathname = pathname.toLowerCase();

  //console.log("학생 페이지에서", pathname, pathname.indexOf("/test/set"))

  const [ReviewNotesActive, setReviewNotesActive] = useState(false); //review note popup

  const isStudentOrParent = useLevels.isStudent || useLevels.isParent;

  // set test 페이지로 이동
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const goToSetTest = () => {
    let url = isB2c ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';

    if (!isB2c) {
      setIsLoading(true);
      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { isFreezing } = response.result;

          setIsLoading(false);

          if (!isFreezing) navigate(url);
          else setShowPopup(true);
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    } else navigate(url);
  };
  // set test 페이지로 이동 끝

  return (
    <>
      <div className='com_lnb'>
        <Link to={`${useLevels.isStudent ? '/student/dashboard' : useLevels.isParent ? '/parent/dashboard' : '/academy/dashboard'}`} className='menu'>
          {/*link to="" 페이지로 이동시 addClass active*/}
          <img src={ImgLnbHome} alt='Home' className='icon' />
          Home
        </Link>

        {useLevels.isParent || (
          <button className={`menu ${pathname.includes('tests/set') ? 'active' : ''}`} onClick={goToSetTest}>
            <img src={ImgLnbTest} alt='Test' className='icon' />
            Set Tests
          </button>
        )}

        {isStudentOrParent && (
          <button className={`menu ${ReviewNotesActive ? 'active' : ''} `} onClick={() => setReviewNotesActive(true)}>
            {/*Student Dashboard*/}
            <img src={ImgLnbNote} alt='Review Notes' className='icon' />
            Review Notes
          </button>
        )}
        {
          <Link to='/com/tests/calendar' className={`menu ${pathname === '/com/tests/calendar' && !ReviewNotesActive ? 'active' : ''} `}>
            {/*Students/Classes/Tests/Test Scheduled/Test Taken/Calendar/Score/Set Tests/Student Dashboard */}
            <img src={ImgLnbCalendar} alt='Calendar' className='icon' />
            Calendar
          </Link>
        }
        <hr className='divider' />
        {(pathname.indexOf('/student') >= 0 || pathname.indexOf('/com/students/classes') >= 0) && !isStudentOrParent && (
          <Link to='/com/students/classes' className={`menu ${pathname === '/com/students/classes' ? 'active' : ''} `}>
            {/*Students, Classes */}
            <img src={ImgLnbClasses} alt='Classes' className='icon' />
            Classes
          </Link>
        )}
        {pathname.indexOf('/tests/set') < 0 && pathname.indexOf('/tests') >= 0 && !isStudentOrParent && (
          <Link to='/com/tests/scheduled' className={`menu ${pathname === '/com/tests/scheduled' ? 'active' : ''} `}>
            {/*Tests, Test Scheduled, Test Taken, Calendar*/}
            <img src={ImgLnbSchedule} alt='Schedule' className='icon' />
            Test Scheduled
          </Link>
        )}
        {pathname.indexOf('/tests/set') < 0 && pathname.indexOf('/tests') >= 0 && !isStudentOrParent && (
          <Link to='/com/tests/taken' className={`menu ${pathname === '/com/tests/taken' ? 'active' : ''} `}>
            {/*Tests, Test Scheduled, Test Taken, Calendar*/}
            <img src={ImgLnbTestTaken} alt='Test Taken' className='icon' />
            Test Taken
          </Link>
        )}
        <Link to={`${useLevels.isStudent ? '/student/dashboard' : '/academy/dashboard'}`} className='logo'>
          <img src={ImgLnbDashboard} alt='main' className='icon' />
        </Link>
      </div>
      {ReviewNotesActive && <ReviewNotes setReviewNotesActive={setReviewNotesActive} />}
      {showPopup && <FreezingKeyModal hideModal={() => setShowPopup(false)} />}
      {isLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
};

export default Lnb;
