import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

//components
import Gnb from 'layout/Gnb';
import Footer from 'layout/Footer'; //Footer
import request from 'utils/Request.utils';

// added by psk 20240125 - routes 이동
import TmsMain from 'components/main/Tms/Page';

const MainRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        { path: '/tms', element: <TmsMain /> }, // added by psk 20240119 - routes 이동

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/tms', element: <TmsMain /> }, // added by psk 20240119 - routes 이동
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  // console.log(pathname, authBool);

  return (
    <>
      <Gnb />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default MainRoute;
