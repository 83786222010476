import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { MathJax } from 'better-react-mathjax';

import request from 'utils/Request.utils';
import { nvl, nvlNumber } from 'utils/Common.utils';

import { getRestTimeFromLocalStorage } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';
import MathJaxViewer from 'components/_common/lib/mathJax/MathJaxViewer';

/** 수학 시험 문제 영역 컴포넌트 */
export default function QuestionArea({ wrap, stateExamInfo, wrapButtonHandler, isLoading, setIsLoading, tryAnswerRef, updateMathJaxStatus }) {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userInfo = request.tokenDecoder();
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();

  const [isUndoActive, setUndoActive] = useState(false);

  /** 취소선 업데이트 API 요청 */
  const updateUndoSelected = (uthSeq, questionSeq, no, currentUnderLine, tryAnswer) => {
    let params = {
      [`underLine${no}`]: currentUnderLine === 'N' || currentUnderLine === '' ? 'Y' : 'N',
      modiUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
      tryAnswer: tryAnswer,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
      }
      setIsLoading(false);
    };

    if (!isLoading) {
      setIsLoading(true);
      request.put(`/api/exam/resultHistory/${uthSeq}/${questionSeq}`, params, successHandler).catch((error) => {
        console.error(error);
        alert('수학 시험 - 문제 영역 - 취소선 업데이트 API 응답 실패');
      });
    }
  };

  /** 취소선(객관식 답변에) 버튼 동작 함수 */
  const dangerouslyToggle = (e, no) => {
    /** 현재 문제 번호 */
    const currentNum = stateExamInfo.currentNum - 1;
    /** 현재 선택 된 문제 */
    const currentExamInfo = stateExamInfo.questionList[currentNum];
    /** 현재 문제의 시퀀스 */
    const questionSeq = currentExamInfo?.questionSeq;
    /** 현재 선택 된 취소선 */
    const currentUnderLine = nvl(currentExamInfo[`underLine${no}`]);
    /** 현재 문제의 답변 상태 */
    const currentTryAnswer = nvl(currentExamInfo.tryAnswer);

    let currentQuestion;

    if (currentUnderLine === 'N' || currentUnderLine === '') {
      currentQuestion = {
        ...currentExamInfo,
        [`underLine${no}`]: 'Y',
        tryAnswer: currentTryAnswer === e.target.value ? '' : currentTryAnswer,
      };

      tryAnswerRef.current[state?.currentNum] = currentTryAnswer === e.target.value ? '' : currentTryAnswer; // useRef 값도 바꿈
    } else {
      currentQuestion = {
        ...currentExamInfo,
        [`underLine${no}`]: 'N',
      };
    }

    dispatch({ type: 'editExamQuestionList', payload: { index: currentNum, data: currentQuestion } });

    if (currentTryAnswer === e.target.value) updateUndoSelected(stateExamInfo.uthSeq, questionSeq, no, currentUnderLine, 'tryAnswerNull');
    else updateUndoSelected(stateExamInfo.uthSeq, questionSeq, no, currentUnderLine, currentTryAnswer);
  };

  /** 객관식 답변 상태 변경(react-redux) */
  const selectTryAnswer = (tryAnswer, no) => {
    /** 현재 문제 번호 */
    const currentNum = stateExamInfo.currentNum - 1;
    /** 현재 선택 된 문제 */
    const currentExamInfo = stateExamInfo.questionList[currentNum];

    /** 전역 상태에 갱신 할 새로운 문제 상태 */
    const newCurrentExamInfo = {
      ...currentExamInfo,
      tryAnswer: tryAnswer,
      [`underLine${no}`]: '',
    };

    dispatch({ type: 'editExamQuestionList', payload: { index: currentNum, data: newCurrentExamInfo } });
  };

  /** 객관식 답변 선택 API 요청 */
  const updateQuestion = (tryAnswer, no) => {
    /** 현재 문제 번호 */
    const currentNum = stateExamInfo.currentNum - 1;

    let params = {
      uthSeq: stateExamInfo.uthSeq,
      bookmarkYn: stateExamInfo.questionList[currentNum]?.bookMark,
      underLine1: tryAnswer === 'A' ? 'N' : nvl(stateExamInfo.questionList[currentNum]?.underLine1),
      underLine2: tryAnswer === 'B' ? 'N' : nvl(stateExamInfo.questionList[currentNum]?.underLine2),
      underLine3: tryAnswer === 'C' ? 'N' : nvl(stateExamInfo.questionList[currentNum]?.underLine3),
      underLine4: tryAnswer === 'D' ? 'N' : nvl(stateExamInfo.questionList[currentNum]?.underLine4),
      testModuleSeq: stateExamInfo.questionList[currentNum]?.testModuleSeq,
      modiUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
      tryAnswer: tryAnswer,
    };

    let questionSeq = stateExamInfo.questionList[currentNum]?.questionSeq;

    const successHandler = (response) => {
      if (response.code === 200) {
        let currentExamInfo = stateExamInfo.questionList[currentNum];

        currentExamInfo = {
          ...currentExamInfo,
          tryAnswer: tryAnswer === 'tryAnswerNull' ? '' : tryAnswer,
          [`underLine${no}`]: '',
        };

        dispatch({ type: 'editExamQuestionList', payload: { index: currentNum, data: currentExamInfo } });
      }
      setIsLoading(false);
    };

    if (!isLoading) {
      setIsLoading(true);
      request.put(`/api/exam/resultHistory/${stateExamInfo.uthSeq}/${questionSeq}`, params, successHandler).catch((error) => {
        console.error(error);
        alert('수학 시험 - 문제 영역 - 객관식 답변 선택 API 응답 실패');
      });
    }
  };

  /** 객관식 답변 선택 핸들러 */
  const radioAnswerChangeHandler = (e, no) => {
    let tryAnswer = e.target.value;

    tryAnswerRef.current[state?.currentNum - 1] = e.target.value;

    selectTryAnswer(tryAnswer, no);
    updateQuestion(tryAnswer, no);
  };

  /** 주관식 답변 Blur 핸들러 */
  const outSubjective = (e) => {
    let currentExamInfo = stateExamInfo.questionList[state?.currentNum - 1];

    tryAnswerRef.current[state?.currentNum - 1] = e.target.value;

    currentExamInfo = { ...currentExamInfo, tryAnswer: e.target.value };

    dispatch({ type: 'editExamQuestionList', payload: { index: state?.currentNum - 1, data: currentExamInfo } });

    // -----------------------------------------------------------------------
    // 주관식 업데이트 용 따로 만듦

    let params = {
      uthSeq: stateExamInfo.uthSeq,
      bookmarkYn: stateExamInfo.questionList[state?.currentNum - 1]?.bookMark,
      testModuleSeq: stateExamInfo.questionList[state?.currentNum - 1]?.testModuleSeq,
      regUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
      tryAnswer: tryAnswerRef.current[state?.currentNum - 1],
    };

    let questionSeq = stateExamInfo.questionList[state?.currentNum - 1]?.questionSeq;

    const successHandler = (response) => {
      if (response.code === 200) {
        let currentExamInfo = stateExamInfo.questionList[state?.currentNum - 1];
        currentExamInfo = {
          ...currentExamInfo,
          tryAnswer: nvl(tryAnswerRef.current[state?.currentNum - 1]) === 'tryAnswerNull' ? '' : tryAnswerRef.current[state?.currentNum - 1],
        };

        dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: currentExamInfo } });
      }
      setIsLoading(false);
    };

    if (nvlNumber(questionSeq) > 0) {
      if (!isLoading) {
        setIsLoading(true);
        request.put(`/api/exam/resultHistory/${stateExamInfo.uthSeq}/${questionSeq}`, params, successHandler).catch((error) => {
          console.error(error);
          alert('수학 시험 - 문제 영역 - 주관식 답변 Blur 핸들러 API 응답 실패');
        });
      }
    }
  };

  /** 주관식 문제 선택 핸들러 */
  const numberCheck = (e) => {
    let newValue = e.target.value;

    newValue = newValue.replace(/[^0-9-./]| /g, '');

    // Further processing for length and format
    if (newValue.length === 1 && newValue[0] !== '-') {
      newValue = newValue.replace(/[^0-9-./]/g, '');
    } else if (newValue.length > 1) {
      if (newValue[0] === '-') {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 8);
      } else {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 7);
      }
    }

    let currentExamInfo = stateExamInfo.questionList[stateExamInfo.currentNum - 1];
    currentExamInfo = { ...currentExamInfo, tryAnswer: newValue };

    dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: currentExamInfo } });
  };

  /** 북마크 갱신 API 요청 */
  const updateBookmark = (uthSeq, questionSeq, bookMarkYn) => {
    let params = {
      bookmarkYn: bookMarkYn,
      modiUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
      }
      setIsLoading(false);
    };

    if (!isLoading) {
      setIsLoading(true);
      request.put(`/api/exam/resultHistory/${uthSeq}/${questionSeq}`, params, successHandler).catch((error) => {
        console.error(error);
        alert('수학 시험 - 문제 영역 - 북마크 갱신 API 응답 실패');
      });
    }
  };

  /** 북마크 전역 상태 변경(react-redux) */
  const checkedBookmark = ({ currentExamInfo, nextBookMark }) => {
    /** 전역 상태에 갱신 할 새로운 문제 상태 */
    const newCurrentExamInfo = { ...currentExamInfo, bookMark: nextBookMark };

    dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: newCurrentExamInfo } });
  };

  /** 북마크 버튼 동작 핸들러 함수 */
  const bookmarkChangeHandler = (e) => {
    /** 현재 선택 된 문제 */
    const currentExamInfo = stateExamInfo.questionList[stateExamInfo.currentNum - 1];

    /** 앞으로 바뀔 북마크 상태 */
    const nextBookMark = currentExamInfo?.bookMark === 'Y' ? 'N' : 'Y';

    /** 현재 문제의 퀘스천 시퀀스 */
    const questionSeq = currentExamInfo.questionSeq;

    updateBookmark(stateExamInfo.uthSeq, questionSeq, nextBookMark);
    checkedBookmark({ currentExamInfo, nextBookMark });
  };

  useEffect(() => {
    let divList = document.getElementById('answerArea')?.children;

    if (divList && divList != null) {
      if (isUndoActive) {
        for (const divNode of divList) {
          if (divNode.childNodes[1]) divNode.childNodes[1].style.width = 'calc(100% - 50px)';
        }
      } else {
        for (const divNode of divList) {
          if (divNode.childNodes[1]) divNode.childNodes[1].style.width = '100%';
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUndoActive]);

  return (
    <MathJax
      className={
        `wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''} ` +
        (stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat === 'M' ? 'type_one' : '')
      }
      hideUntilTypeset='first'
      onTypeset={() => updateMathJaxStatus(true)}>
      <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
        &nbsp;
      </button>
      {/* TODO: 문제 풀이 영역 */}
      <div className='scroll_wrap'>
        <div className='inner'>
          <div className='top_area'>
            <div className='num'>{stateExamInfo.currentNum}</div>
            <div className='bookmark'>
              <input
                type='checkbox'
                id='bookmark1'
                className='btn_bookmark'
                onChange={() => {
                  bookmarkChangeHandler();
                }}
                checked={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.bookMark === 'Y'}
              />
              <label htmlFor='bookmark1'>Mark for Review</label>
            </div>
            {/*주관식에서 비노출*/}
            {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat !== 'S' && (
              <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={() => setUndoActive(!isUndoActive)}>
                <i className='svg_icon icon_undo'>&nbsp;</i>
                <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
              </button>
            )}
          </div>
          {/* 문제 영역(문제 내용) */}
          <div id='questionArea' name='questionArea' className='text'>
            <div
              dangerouslySetInnerHTML={{
                __html: stateExamInfo.questionList[stateExamInfo.currentNum - 1].question.replace(
                  '/common/commonImageView.do',
                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                ),
              }}
            />
            {/* <MathJaxViewer convertString={ nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.question) } /> */}
          </div>
          <div id='answerArea' name='answerArea' className='answer_area'>
            {/*객관식*/}
            {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat !== 'S' && (
              <>
                {' '}
                {/*1130 item 높이 100넘을때 만 addClass type_top*/}
                <div className={nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.underLine1) !== 'Y' ? 'item' : 'item undo_selected'}>
                  <input
                    type='radio'
                    name='answer1'
                    id='answer1_1'
                    className='radio_answer'
                    value='A'
                    onChange={(e) => radioAnswerChangeHandler(e, 1)}
                    checked={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer === 'A'}
                  />
                  <label className='answer' htmlFor='answer1_1'>
                    <span className='num'>A</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems1.replace(
                          '/common/commonImageView.do',
                          `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                        ),
                      }}
                    />
                    {/* <MathJaxViewer convertString={ nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems1) } /> */}
                  </label>
                  <button className='num' onClick={(e) => dangerouslyToggle(e, 1)} value='A'>
                    A
                  </button>
                </div>
                <div className={nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.underLine2) !== 'Y' ? 'item' : 'item undo_selected'}>
                  <input
                    type='radio'
                    name='answer1'
                    id='answer1_2'
                    className='radio_answer'
                    value='B'
                    onChange={(e) => radioAnswerChangeHandler(e, 2)}
                    checked={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer === 'B'}
                  />
                  <label className='answer' htmlFor='answer1_2'>
                    <span className='num'>B</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems2.replace(
                          '/common/commonImageView.do',
                          `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                        ),
                      }}
                    />
                    {/* <MathJaxViewer convertString={ nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems2) } /> */}
                  </label>
                  <button className='num' onClick={(e) => dangerouslyToggle(e, 2)} value='B'>
                    B
                  </button>
                </div>
                <div className={nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.underLine3) !== 'Y' ? 'item' : 'item undo_selected'}>
                  <input
                    type='radio'
                    name='answer1'
                    id='answer1_3'
                    className='radio_answer'
                    value='C'
                    onChange={(e) => radioAnswerChangeHandler(e, 3)}
                    checked={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer === 'C'}
                  />
                  <label className='answer' htmlFor='answer1_3'>
                    <span className='num'>C</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems3.replace(
                          '/common/commonImageView.do',
                          `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                        ),
                      }}
                    />
                    {/* <MathJaxViewer convertString={ nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems3) } /> */}
                  </label>
                  <button className='num' onClick={(e) => dangerouslyToggle(e, 3)} value='C'>
                    C
                  </button>
                </div>
                <div className={nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.underLine4) !== 'Y' ? 'item' : 'item undo_selected'}>
                  <input
                    type='radio'
                    name='answer1'
                    id='answer1_4'
                    className='radio_answer'
                    value='D'
                    onChange={(e) => radioAnswerChangeHandler(e, 4)}
                    checked={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer === 'D'}
                  />
                  <label className='answer' htmlFor='answer1_4'>
                    <span className='num'>D</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems4.replace(
                          '/common/commonImageView.do',
                          `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                        ),
                      }}
                    />
                    {/* <MathJaxViewer convertString={ nvl(stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.multipleChoiceItems4) } /> */}
                  </label>
                  <button className='num' onClick={(e) => dangerouslyToggle(e, 4)} value='D'>
                    D
                  </button>
                </div>
              </>
            )}

            {/*주관식*/}
            {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat !== 'M' && (
              <div className='answer_input'>
                <div className='input_box'>
                  <input
                    type='text'
                    className='input'
                    value={stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer}
                    onChange={(e) => numberCheck(e)}
                    onBlur={outSubjective}
                  />
                </div>
                <div className='preview'>
                  Answer preview : &nbsp;
                  {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.includes('-') &&
                  stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.includes('/') ? (
                    <MathJaxViewer
                      convertString={nvl(
                        `$$-\\frac{${stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[0].replace('-', '')}}{${stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[1]}}$$`
                      )}
                    />
                  ) : stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.includes('/') ? (
                    <MathJaxViewer
                      convertString={nvl(
                        `$$\\frac{${stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[0]}}{${stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[1]}}$$`
                      )}
                    />
                  ) : (
                    <>{stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.tryAnswer}</>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MathJax>
  );
}
