import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const CHANNEL_TALK_PLUGIN_KEY = '416ec5df-15df-4ba6-abc6-f32c1e4fdbd0';

const useChannelTalk = (userInfo) => {
  const location = useLocation();

  useEffect(() => {
    try {
      if (location.pathname.startsWith('/exam')) {
        ChannelService.shutdown();
      } else {
        ChannelService.boot({
          pluginKey: CHANNEL_TALK_PLUGIN_KEY,
          memberId: userInfo.userEmail,
          profile: {
            name: userInfo.userName,
            email: userInfo.userEmail,
          },
        });
      }
    } catch (error) {
      console.log('로그인해!!!');
    }
  }, [location, userInfo]);
};

export default useChannelTalk;
