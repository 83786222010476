import React, { useState, useEffect } from 'react';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import dayjs from 'dayjs';

//components
import MiniAlert from 'components/_common/alerts/MiniAlert';
import DeviationsFromAverage from './sections/_DeviationsFromAverage';
import TopArea from './layouts/TopArea';
import PercentileWithinGatePlusAndGradeChangeFromPastTests from './sections/PercentileWithinGatePlusAndGradeChangeFromPastTests';
import ClassVsGatePlus from './sections/ClassVsGatePlus';
import ClassVsClass from './sections/ClassVsClass';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역) 컴포넌트 */
export default function ScoreStatsSection() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [miniAlert, alertSet] = useState(false);
  const [scoreStats, setScoreStats] = useState({
    classSeq: 0,
    testSetSeq: 0,
    userSeq: 0,
    setSubject: '',
    classList: [],
    takenTest: [],
    percentileList: [],
    className: '',
    testName: '',
    startDate: '',
    endDate: '',
  });
  const [belowClassAverage, setBelowClassAverage] = useState({
    rwAvg: 0,
    mathAvg: 0,
    rwHigh: [],
    mathHigh: [],
    rwLow: [],
    mathLow: [],
  });
  /** 차트 데이터 */
  const [classGateChartData, setClassGateBarChartData] = useState({
    series: [
      {
        name: 'Class',
        data: [0, 0],
      },
      {
        name: 'GATE+',
        data: [0, 0],
      },
    ],
    colors: ['#0068BD', '#E3E7ED'],
  });
  const [studentGateChartData, setStudentGateChartData] = useState({
    series: [
      {
        name: 'Student',
        data: [0, 0],
      },
      {
        name: 'GATE+',
        data: [0, 0],
      },
    ],
    colors: ['#0068BD', '#E3E7ED'],
  });
  const [studentClassData, setStudentClassData] = useState({
    series: [
      {
        name: 'Student',
        data: [0, 0],
      },
      {
        name: 'Class',
        data: [0, 0],
      },
    ],
    colors: ['#0068BD', '#BEDEFA'],
  });
  const [classData, setClassData] = useState({
    series: [
      {
        name: 'RW',
        data: [0, 0],
      },
      {
        name: 'Math',
        data: [0, 0],
      },
    ],
    xaxisCategories: ['No data'],
  });
  const [classButtons, setClassButtons] = useState([
    { text: 'Class', value: 0, active: true },
    { text: 'Student', value: 1, active: false },
  ]);
  const [gateButtons, setGageButtons] = useState([
    { text: 'Class', value: 0, active: true },
    { text: 'Student', value: 1, active: false },
  ]);
  /** 과목 토글 */
  const [subjectTable, setSubjectTable] = useState(false);

  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /** 반 별 점수 불러오기 API 요청 */
  const getClassList = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let classList = response.result.classList;
        let latestClassSeq = response.result.maxClass;

        let latestClass = classList?.find((item) => item.classSeq === latestClassSeq);

        setScoreStats((prev) => {
          return {
            ...prev,
            classSeq: latestClassSeq,
            testSetSeq: latestClass?.customSetSeq,
            classList: classList,
            className: latestClass?.className,
          };
        });

        if (latestClassSeq > 0) classTestTaken(latestClassSeq);
      }
    };

    request.get(`/api/dsat/score/class?ecSeq=${userInfo?.ecSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  /** 반 별 응시 점수 가져오기 API 요청 */
  const classTestTaken = (classSeq) => {
    const successHandler = (response) => {
      // console.log('여기 들었냐',response);

      if (response.code === 200) {
        let takenTest = response.result.takenTest;

        if (takenTest.length > 0) {
          const shortEndDate = takenTest.sort((a, b) => dayjs(b.testEndDate) - dayjs(a.testEndDate));
          setScoreStats((prev) => {
            return {
              ...prev,
              classSeq: classSeq,
              testSetSeq: takenTest[0]?.customSetSeq,
              setSubject: takenTest[0]?.subject,
              takenTest: shortEndDate,
              testName: takenTest[0]?.testName,
              startDate: takenTest[0]?.testStartDate,
              endDate: takenTest[0]?.testEndDate,
            };
          });
          getPercentileList(classSeq, takenTest[0]?.customSetSeq, takenTest[0]?.subject !== 'M' ? 'E' : 'M');
        } else {
          setScoreStats((prev) => {
            return {
              ...prev,
              testSetSeq: 0,
              testName: '',
              percentileList: [],
              takenTest: [],
              startDate: '',
              endDate: '',
            };
          });
          setBelowClassAverage({
            rwAvg: 0,
            mathAvg: 0,
            rwHigh: [],
            mathHigh: [],
            rwLow: [],
            mathLow: [],
          });
          setStudentGateChartData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'Student', data: [0, 0] },
                { name: 'GATE+', data: [0, 0] },
              ],
            };
          });
          setClassGateBarChartData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'Student', data: [0, 0] },
                { name: 'GATE+', data: [0, 0] },
              ],
            };
          });
          setClassData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'RW', data: [0] },
                { name: 'Math', data: [0] },
              ],
              xaxisCategories: ['-'],
            };
          });
        }
      }
    };

    request.get(`/api/dsat/score/class/taken?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  /** 백분위 변화 API 요청 */
  const getPercentileList = (classSeq, customSetSeq, subject) => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let percentileList = response.result.percentileAndAvgDiff;
        let total = response.result.totalCnt;

        setSubjectTable(subject === 'M' ? true : false);
        if (!!percentileList)
          setScoreStats((prev) => {
            return {
              ...prev,
              testSetSeq: customSetSeq,
              startDate: percentileList[0]?.testStartDate,
              endDate: percentileList[0]?.testEndDate,
              testName: percentileList[0]?.testName,
              percentileList: percentileList,
            };
          });

        if (customSetSeq !== scoreStats.testSetSeq) {
          vsGateplus(classSeq, customSetSeq, 'class');
          getAverageTier(classSeq);
          setScoreStats((prev) => {
            return { ...prev, userSeq: 0 };
          });

          setGageButtons((prevButtons) =>
            prevButtons?.map((button) => ({
              ...button,
              active: button.value === 0,
            }))
          );
          setClassButtons((prevButtons) =>
            prevButtons?.map((button) => ({
              ...button,
              active: button.value === 0,
            }))
          );
        }
      }
    };

    request
      .get(
        `/api/dsat/rate/percentile/delta?ecSeq=${userInfo.ecSeq}&classSeq=${classSeq}&customSetSeq=${customSetSeq}&subject=${nvl(subject) === 'F' ? 'E' : subject}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  /** 전체 평균 대비 API 요청 */
  const vsGateplus = (classSeq, customSetSeq, mode, userSeq) => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let rwPoint = response.result.rwPoint || 0;
        let rwAvg = response.result.rwAvg || 0;
        let mathPoint = response.result.mathPoint || 0;
        let mathAvg = response.result.mathAvg || 0;

        if (mode === 'user')
          setStudentGateChartData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'Student', data: [rwPoint, mathPoint] },
                { name: 'GATE+', data: [rwAvg, mathAvg] },
              ],
            };
          });
        else {
          setClassGateBarChartData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'Class', data: [rwPoint, mathPoint] },
                { name: 'GATE+', data: [rwAvg, mathAvg] },
              ],
            };
          });
        }
        vsClass(classSeq, customSetSeq, mode, userSeq);
      }
    };

    request
      .get(
        `/api/dsat/average/vs/gateplus?ecSeq=${userInfo.ecSeq}&customSetSeq=${customSetSeq}&classSeq=${classSeq}&userSeq=${nvlNumber(userSeq)}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  /** 중복 제거 */
  const removeDuplicates = (arr, prop) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };

  /** 반 별 평균 대비 API 요청 */
  const vsClass = (classSeq, customSetSeq, mode, userSeq) => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        if (mode === 'user') {
          let rwPoint = response.result.rwPoint || 0;
          let rwAvg = response.result.rwAvg || 0;
          let mathPoint = response.result.mathPoint || 0;
          let mathAvg = response.result.mathAvg || 0;

          setStudentClassData((prev) => {
            return {
              ...prev,
              series: [
                {
                  name: 'Student',
                  data: [nvlNumber(rwPoint), nvlNumber(mathPoint)],
                },
                { name: 'Class', data: [rwAvg, mathAvg] },
              ],
            };
          });
        } else {
          let getClassData = response.result.vsClass;

          let rwPoint = getClassData?.filter((i) => i.subject === 'English').map((item) => item.point);
          let mathPoint = getClassData?.filter((i) => i.subject === 'Math').map((item) => item.point);

          let className = [];
          if (!!getClassData) className = removeDuplicates(getClassData, 'className');
          let classNameList = className.map((item) => item.className);

          setClassData((prev) => {
            return {
              ...prev,
              series: [
                { name: 'RW', data: rwPoint },
                { name: 'Math', data: mathPoint },
              ],
              xaxisCategories: classNameList,
            };
          });
        }
      }
    };

    request
      .get(
        `/api/dsat/average/vs/class?customSetSeq=${customSetSeq}&ecSeq=${userInfo.ecSeq}&classSeq=${classSeq}&userSeq=${nvlNumber(userSeq)}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  /** 등급 별 평균 점수 API 요청 */
  const getAverageTier = (classSeq) => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let aboveBelowClassAverage = response.result.aboveBelowClassAverage;

        let classMathAvg = aboveBelowClassAverage?.filter((i) => i.type === 'AVG' && i.subject === 'Math');
        let classRWAvg = aboveBelowClassAverage?.filter((i) => i.type === 'AVG' && i.subject === 'English');

        let classMathHigh = aboveBelowClassAverage?.filter((i) => i.type === 'HIGH' && i.subject === 'Math');
        let classRWHigh = aboveBelowClassAverage?.filter((i) => i.type === 'HIGH' && i.subject === 'English');

        let classMathLow = aboveBelowClassAverage?.filter((i) => i.type === 'LOW' && i.subject === 'Math');
        let classRWLow = aboveBelowClassAverage?.filter((i) => i.type === 'LOW' && i.subject === 'English');

        setBelowClassAverage({
          rwAvg: classRWAvg[0]?.avg,
          mathAvg: classMathAvg[0]?.avg,
          rwHigh: classRWHigh,
          mathHigh: classMathHigh,
          rwLow: classRWLow,
          mathLow: classMathLow,
        });
      }
    };

    request.get(`/api/dsat/average/tier?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  /** 학생 선택 */
  const clickHandler = (value, menu) => {
    setGageButtons((prevButtons) =>
      prevButtons?.map((button) => ({
        ...button,
        active: button.value === value,
      }))
    );
    setClassButtons((prevButtons) =>
      prevButtons?.map((button) => ({
        ...button,
        active: button.value === value,
      }))
    );
    setScoreStats((prev) => {
      return { ...prev, userSeq: 0 };
    });
  };

  /** 퍼센타일에서 학생 선택 */
  const percentileClick = (userSeq) => {
    setScoreStats((prev) => {
      return { ...prev, userSeq: userSeq };
    });

    setGageButtons((prevButtons) =>
      prevButtons?.map((button) => ({
        ...button,
        active: button.value === 1,
      }))
    );

    setClassButtons((prevButtons) =>
      prevButtons?.map((button) => ({
        ...button,
        active: button.value === 1,
      }))
    );

    vsGateplus(scoreStats.classSeq, scoreStats.testSetSeq, 'user', userSeq);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getClassList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='com_container com_center_wrap'>
        <TopArea setScoreStats={setScoreStats} classTestTaken={classTestTaken} scoreStats={scoreStats} getPercentileList={getPercentileList} />
        {/*Percentile within Gate+ and Grade Change from Past tests*/}
        <PercentileWithinGatePlusAndGradeChangeFromPastTests
          getPercentileList={getPercentileList}
          scoreStats={scoreStats}
          subjectTable={subjectTable}
          percentileClick={percentileClick}
        />
        {/*Class vs. GATE+*/}
        <ClassVsGatePlus
          gateButtons={gateButtons}
          clickHandler={clickHandler}
          percentileClick={percentileClick}
          scoreStats={scoreStats}
          classGateChartData={classGateChartData}
          studentGateChartData={studentGateChartData}
        />
        {/*Class vs Class*/}
        <ClassVsClass
          classButtons={classButtons}
          clickHandler={clickHandler}
          percentileClick={percentileClick}
          scoreStats={scoreStats}
          classData={classData}
          studentClassData={studentClassData}
        />
      </div>
      <div className='com_container com_center_wrap'>
        {/*Deviations from Average*/}
        <DeviationsFromAverage belowClassAverage={belowClassAverage} />
      </div>
      {miniAlert && <MiniAlert text='Student Changed' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={1500} />}
    </>
  );
}
