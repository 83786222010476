import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import SplineChart from 'components/_common/chart/SplineChart';
import { removeDuplicates } from 'utils/functions/removeDuplicates';

/** 학생 대시보드 페이지의 "Score Trends" section 컴포넌트 */
export default function ScoreTrendsSection({ testResult, childSeqState, dailyAveragePoint, scoreTrends, setScoreTrends }) {
  const userInfo = request.tokenDecoder();

  const [trendsButtons, setTrendsButtons] = useState([
    { text: 'Day', value: 0, active: true },
    { text: 'Week', value: 1, active: false },
    { text: 'Month', value: 2, active: false },
  ]);

  /** 시험 주 별 평균 점수 조회 API 요청 */
  const weeklyAveragePoint = (firstTestDate) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let weeklyAverageList = response.result.weeklyAverageList;

        /** dt 중복제거 */
        const dtList = removeDuplicates(weeklyAverageList, 'dt2');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }

        if (firstDtDateIndex !== -1) {
          if (dayjs(weeklyAverageList[0].dt2).isAfter(dayjs(firstTestDate))) {
            const engScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate = dayjs(dtList[index].dt2).format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          } else {
            const engScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = index < 8 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 8 }, (_, index) => {
              const dailyItems = weeklyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = index < 8 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate =
                index < dtList.length - firstDtDateIndex - 1
                  ? dayjs(dtList[firstDtDateIndex + index].dt2).format('MM/DD')
                  : dayjs(dtList[firstDtDateIndex].dt2)
                      .add(index * 7, 'days')
                      .format('MM/DD');

              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          }
        }
      }
    };

    request
      .get(`/api/dsat/average/point/weekly?userSeq=${userInfo?.userLevel === USER_LEVELS.STUDENT ? userInfo?.userSeq : childSeqState}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 주 별 평균 점수 조회 API 실패', error));
  };

  /** 시험 월 별 평균 점수 조회 API 요청 */
  const monthlyAveragePoint = (firstTestDate) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let monthlyAverageList = response.result.monthlyAverageList;

        const dtList = removeDuplicates(monthlyAverageList, 'dt');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }

        if (firstDtDateIndex !== -1) {
          if (dayjs(monthlyAverageList[0].dt).isAfter(dayjs(firstTestDate))) {
            const engScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = dailyItems[index].data;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate = dayjs(dtList[index].dt).format('YY-MMM');
              return dailyDate;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          } else {
            const engScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'English');
              const dailyItem = index < 12 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const mathScore = Array.from({ length: 12 }, (_, index) => {
              const dailyItems = monthlyAverageList.filter((item) => item.subject === 'Math');
              const dailyItem = index < 12 - firstDtDateIndex ? dailyItems[firstDtDateIndex + index].data : null;
              return dailyItem;
            });

            const dateArray = Array.from({ length: dtList.length }, (_, index) => {
              const dailyDate =
                index < dtList.length - firstDtDateIndex - 1
                  ? dayjs(dtList[firstDtDateIndex + index].dt).format('YYYY MMM.')
                  : dayjs(dtList[firstDtDateIndex].dt).add(index, 'month').format('YYYY MMM.');

              const monthFormat = dailyDate.includes('Jan') ? dailyDate : dailyDate.substring(5);
              return monthFormat;
            });

            setScoreTrends((prev) => {
              return {
                ...prev,
                mathAvg: mathScore,
                engAvg: engScore,
                dateArray: dateArray,
              };
            });
          }
        }
      }
    };

    request
      .get(`/api/dsat/average/point/monthly?userSeq=${userInfo?.userLevel === USER_LEVELS.STUDENT ? userInfo?.userSeq : childSeqState}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 월 별 평균 점수 조회 API 실패', error));
  };

  /** 테스트 결과의 날짜 얻기(월, 주, 일) */
  const getDateArray = (type) => {
    switch (type) {
      case 'day':
        dailyAveragePoint(testResult.firstTestDate, userInfo.userLevel === USER_LEVELS.STUDENT ? userInfo.userSeq : childSeqState);

        break;

      case 'week':
        weeklyAveragePoint(testResult.firstTestDate);
        break;

      case 'month':
        monthlyAveragePoint(testResult.firstTestDate);
        break;

      default:
        break;
    }
  };

  /** 날짜 변경 텝 핸들러 */
  const changeData = (value) => {
    setTrendsButtons((prevButtons) =>
      prevButtons.map((button) => ({
        ...button,
        active: button.value === value,
      }))
    );

    if (value === 0) getDateArray('day');
    else if (value === 1) getDateArray('week');
    else if (value === 2) getDateArray('month');
  };

  return (
    <>
      <div className='com_tab'>
        {trendsButtons?.map((item) => (
          <button key={'button_' + uuidv4()} className={item.active ? 'menu active' : 'menu'} onClick={() => changeData(item.value)}>
            {item.text}
          </button>
        ))}
      </div>
      <div className='graph_area'>
        <SplineChart dateArray={scoreTrends.dateArray} mathAvg={scoreTrends.mathAvg} engAvg={scoreTrends.engAvg} />
      </div>
    </>
  );
}
