import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import request from 'utils/Request.utils';

import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import Tooltip from './Tooltip';

/** 문제 영역 컴포넌트 */
const PassageArea = ({
  setAnnotateMemo,
  setIsClickAnnotate,
  annotationId,
  setAnnotationId,
  setAnnotateName,
  setIsDragged,
  tooltipRef,
  passageRef,
  passage,
  ...rest
}) => {
  const dispatch = useDispatch();
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const userInfo = request.tokenDecoder();

  useEffect(() => {
    if (stateExamComponents.annotationSelectedWords) {
      let currentExamInfo = stateExamInfo.questionList[stateExamInfo.currentNum - 1];
      let annotation = [
        {
          // 주석 부분
          annotationStart: 0,
          annotationEnd: 0,
          selected_words: '',
          annotationContent: passageRef.current.innerHTML,
        },
      ];

      currentExamInfo = { ...currentExamInfo, annotation: annotation };

      dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: currentExamInfo } });

      dispatch({
        type: 'setExamComponents',
        payload: { fieldName: 'annotationSelectedWords', data: false },
      });

      annotationContentSave(passageRef.current.innerHTML);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.annotationSelectedWords]);

  /** Annotations(주석) 저장 API */
  const annotationContentSave = (annotationContent) => {
    let params = {
      uthSeq: stateExamInfo.uthSeq,
      questionSeq: stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionSeq,
      annotationContent: annotationContent,
      regUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
      }
    };

    request.post('/api/exam/annotations', params, successHandler).catch((error) => {
      console.error(error);
      alert('영어 시험 - Annotations(주석) 저장 API 응답 실패');
    });
  };

  useEffect(() => {
    rangy.init();

    const createAnnotation = (selection) => {
      const uniqueId = `${1}-${Math.floor(Math.random() * 9000) + 1000}`; // userSeq
      const annotation = {
        id: uniqueId,
        text: selection.toString().length > 30 ? `${selection.toString().slice(0, 10)}...${selection.toString().slice(-10)}` : selection.toString(),
      };
      return annotation;
    };

    const applyAnnotation = (annotation) => {
      const classApplier = rangy.createClassApplier('rangy-highlight', {
        elementTagName: 'span',
        elementProperties: {
          className: 'rangy-highlight-selected',
          style: {
            // backgroundColor: '#FAD021',
          },
        },
        elementAttributes: {
          'data-annotation-id': annotation.id,
          'data-text': annotation.text,
          'data-memo': '',
        },

        onElementCreate: (element) => {
          element.addEventListener('mouseover', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);
            const firstSelectedAnnotation = selectedAnnotations[0];

            selectedAnnotations.forEach((el) => {
              el.classList.add('rangy-highlight-hover');
            });

            const position = {
              x: firstSelectedAnnotation.getBoundingClientRect().left + firstSelectedAnnotation.getBoundingClientRect().width / 2,
              y:
                firstSelectedAnnotation.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect().bottom -
                firstSelectedAnnotation.getBoundingClientRect().bottom +
                firstSelectedAnnotation.getBoundingClientRect().height -
                1,
            };
            showTooltip(event, element.getAttribute('data-memo'), position);
          });

          element.addEventListener('mouseout', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

            selectedAnnotations.forEach((el) => {
              el.classList.remove('rangy-highlight-hover');
            });
            hideTooltip();
          });

          element.addEventListener('click', (event) => {
            const annotationId = event.target.getAttribute('data-annotation-id');
            const memoTitle = event.target.getAttribute('data-text');
            const annotationMemo = event.target.getAttribute('data-memo');
            const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${memoTitle}"]`);

            selectedAnnotations.forEach((el) => {
              el.classList.add('rangy-highlight-selected');
            });

            setAnnotationId(annotationId);
            setAnnotateName(memoTitle);
            setAnnotateMemo(annotationMemo);

            setIsClickAnnotate(true);
          });
        },
      });
      classApplier.applyToSelection();
    };

    const showTooltip = (event, memo, position) => {
      const tooltip = tooltipRef.current;
      tooltip.innerText = memo;

      tooltip.style.bottom = `${position.y}px`;
      tooltip.style.left = `${position.x}px`;
      tooltip.style.display = 'inline-block';
      tooltip.style.color = 'black';
      tooltip.style.transform = 'translate(-50%, 0)';
      tooltip.style.visibility = 'visible';
    };

    const hideTooltip = () => {
      const tooltip = tooltipRef.current;

      tooltip.style.display = 'none';
      tooltip.style.visibility = 'hidden';
    };

    const applyHighlightIfAllowed = () => {
      if (stateExamComponents.annotationDisplay) {
        const selection = rangy.getSelection();
        if (!selection.isCollapsed) {
          const annotation = createAnnotation(selection);

          setAnnotateName(annotation.text);
          setAnnotationId(annotation.id);

          applyAnnotation(annotation);
          selection.removeAllRanges();
        }
      }
    };

    const handleMouseUp = () => {
      const selection = rangy.getSelection();
      if (!selection.isCollapsed) {
        setIsDragged(true);

        dispatch({
          type: 'setExamComponents',
          payload: { fieldName: 'annotationDisplay', data: false },
        });

        setIsClickAnnotate(false);
        setAnnotateMemo('');
        setAnnotationId('');
        setAnnotateName('');
      }
    };
    const editor = passageRef.current;
    editor.addEventListener('mouseup', handleMouseUp);

    applyHighlightIfAllowed();

    return () => {
      editor.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamComponents.annotationDisplay]);

  const passageAreaStyle = {
    WebkitUserSelect: 'text',
    MozUserSelect: 'text',
    MsUserSelect: 'text',
    userSelect: 'text',
  };

  return (
    <>
      <div style={passageAreaStyle} ref={passageRef} className='question' dangerouslySetInnerHTML={{ __html: passage }} {...rest} />
      <Tooltip ref={tooltipRef} />
    </>
  );
};

export default PassageArea;
