// Packages
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import ExamTutorialFooter from './_components/examTutorialLayout/examTutorialFooter/ExamTutorialFooter';
import ExamTutorialBody from './_components/examTutorialLayout/examTutorialBody/ExamTutorialBody';
import Progress from './_components/Progress';
// Functions
import { nvl } from 'utils/Common.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';
import { deleteRestTimeToLocalStorage, deleteTimeDiffToLocalStorage } from '../_utils/functions/timerLocalStorageFunctions';

/** 인트로 다음 튜토리얼 페이지 : 시험보는 방법 설명 */
export default function ExamTutorialPage() {
  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지

  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 시작 //////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const userInfo = request.tokenDecoder();
  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 끝 //////////////

  /////////////////// React State 선언 영역 시작 ///////////////////////
  const [examMain, setExamMain] = useState({
    filterCompletionBool: false,
    examInitBool: false,
    examEnStateBool: false,
    examMaStateBool: false,
  });
  const [loading, setLoading] = useState(true);
  /////////////////// React State 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const setSubjectRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 문제 목록 전체 불러오기 API 요청 */
  const getQuestionList = () => {
    setLoading(true);
    setExamMain((prev) => {
      return { ...prev, filterCompletionBool: false };
    });

    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.customSetFullInfo;

        FilteringQuestionList(questionList);
        setLoading(false);
      } else if (response.code === 403) {
        // navigate("/student/dashboard");
        console.log('권한없음');
      }
    };

    request.get(`/api/exam/customSetFullInfo?uthSeq=${stateExamInfo.uthSeq}&moduleNum=1`, null, successHandler).catch((error) => {
      console.error(error);
      alert('시험 - 튜토리얼 - 문제 목록 전체 불러오기 API 응답 실패');
    });
  };

  /** 얻어 온 문제 목록 필터링 */
  const FilteringQuestionList = (questionList) => {
    questionList != null &&
      questionList.length > 0 &&
      questionList.map((outerItem, outerIndex) => {
        const parsedInfo = JSON.parse(outerItem?.customSetQuestionInfo);
        const filteredInfo = parsedInfo.filter(
          (innerItem) => innerItem != null && innerItem.module_subject === (innerItem.set_subject !== 'M' ? 'E' : 'M') && parseInt(innerItem.module_num) === 1
        );

        const newExamStateQuestion = filteredInfo.map((innerItem, innerIndex) => {
          if (innerIndex === 0) {
            // 세트 정보에다가 넣기
            dispatch({
              type: 'setExamInfo',
              payload: { fieldName: 'setSubject', data: innerItem.set_subject },
            });
            dispatch({
              type: 'setExamInfo',
              payload: {
                fieldName: 'setDifficulty',
                data: innerItem.set_difficulty,
              },
            });
            setSubjectRef.current = innerItem.set_subject;
          }

          return {
            customSetSeq: innerItem.custom_set_seq,
            setUniqueCode: innerItem.set_unique_code,
            testName: innerItem.test_name,
            setSubject: innerItem.set_subject,
            setDifficulty: innerItem.set_difficulty,
            setRegUserSeq: innerItem.set_reg_user_seq,
            setRegDate: innerItem.set_reg_date,
            setModiUserSeq: innerItem.set_modi_user_seq,
            setModiDate: innerItem.set_modi_date,
            testModuleSeq: innerItem.test_module_seq,
            moduleUniqueCode: innerItem.module_unique_code,
            moduleSubject: innerItem.module_subject,
            moduleNum: parseInt(innerItem.module_num),
            moduleDifficulty: innerItem.module_difficulty,
            qmhSeq: innerItem.qmh_seq,
            questionSeq: innerItem.question_seq,
            multipleChoiceItems1: innerItem.multiple_choice_items1,
            multipleChoiceItems2: innerItem.multiple_choice_items2,
            multipleChoiceItems3: innerItem.multiple_choice_items3,
            multipleChoiceItems4: innerItem.multiple_choice_items4,
            questionSubject: innerItem.question_subject,
            testHow: innerItem.test_how,
            questionFormat: innerItem.question_format,
            questionDifficulty: innerItem.question_difficulty,
            fieldOfStudy: innerItem.field_of_study,
            researcher: innerItem.researcher,
            passage: innerItem.passage,
            question: innerItem.question,
            tryAnswer: '',
          };
        });

        dispatch({
          type: 'setExamQuestionList',
          payload: newExamStateQuestion,
        });
      });

    dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: 1 } });

    setExamMain((prev) => {
      return { ...prev, filterCompletionBool: true };
    }); // filter 완료
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    // added by psk 20240121 - 전달 파라메터 날리기
    LocalStorage.clearItem('sendParams');
    deleteRestTimeToLocalStorage();
    deleteTimeDiffToLocalStorage();

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인

    if (userInfo != null && nvl(userInfo?.userId) !== '' && nvl(stateExamInfo?.uthSeq) !== 0 && stateExamInfo['currentTest'].em1 === 'R') {
      getQuestionList();
    } else if (
      userInfo != null &&
      nvl(userInfo?.userId) !== '' &&
      nvl(stateExamInfo?.uthSeq) !== 0 &&
      (stateExamInfo['currentTest'].em1 === 'I' || stateExamInfo['currentTest'].em2 === 'I')
    ) {
      navigate('/exam/dsat/rw', {
        state: { currentNum: stateExamInfo.currentNum },
        replace: true,
      });
    } else if (
      userInfo != null &&
      nvl(userInfo?.userId) !== '' &&
      nvl(stateExamInfo?.uthSeq) !== 0 &&
      (stateExamInfo['currentTest'].mm1 === 'I' || stateExamInfo['currentTest'].mm2 === 'I')
    ) {
      navigate('/exam/dsat/math', {
        state: { currentNum: stateExamInfo.currentNum },
        replace: true,
      });
    } else {
      // navigate("/student/dashboard");
      navigate('/exam/dsat/intro', {
        replace: true,
      });
    }

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='com_popup pop_tutorial active'>
        {/*addClass 'active' 시 노출*/}
        <div className='pop_container'>
          <ExamTutorialBody />
          <ExamTutorialFooter loading={loading} setLoading={setLoading} setExamMain={setExamMain} setSubjectRef={setSubjectRef} examMain={examMain} />
        </div>
      </div>
      {examMain.progressDisplay && <Progress />}
    </>
  );
}
