import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import request from '../../utils/Request.utils.js';

import { nvl, nvlNumber } from '../../utils/Common.utils.js';

import { USER_LEVELS } from '../../utils/constants.js';
import useUserLevels from '../../hooks/useUserLevels.js';

// ID에서 사용되는 Profile image section
// Edit 개인 프로필 이미지
function EditPersonalProfileImageSection({
  accountInfo,
  personalProfileImageState,
  profileImage,
  setProfileImage,
  academyProfileImage,
  setAcademyProfileImage,
  setReloadData,
}) {
  const dispatch = useDispatch();

  // 유저 토큰 reload(change와 다름)
  const reloadUserToken = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        // console.log(response)
      }
    };

    const param = { userSeq: accountInfo.userSeq };

    request.updateUserToken(param);
  };

  const [uploadFile, setUploadFile] = useState();
  const uploadFileRef = useRef();

  const selectUploadFile = () => uploadFileRef.current.click();

  const uploadFileHandler = (e) => {
    let currentValue = e.target.value;

    setUploadFile(e.target.files[0]);
  };

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append('fileCate', 'userProfile');
    formData.append('uploadFiles', uploadFile);

    const successHandler = (response) => {
      if (response.code === 200) uploadFileInit();
    };

    request.postMultipart(`/api/member/profile/upload/userProfile/${accountInfo.userSeq}`, formData, successHandler);
  };

  const uploadFileInit = () => {
    uploadFileRef.current.value = '';

    setUploadFile(null);

    setTimeout(() => setProfileImage(`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${accountInfo.userSeq}?${Date.now()}`));

    reloadUserToken();

    setReloadData((prev) => !prev);
  };

  useEffect(() => {
    if (uploadFile != null) submitUploadFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  useEffect(() => {
    if (nvl(profileImage) !== '') {
      dispatch({
        type: 'setMenuInfo',
        payload: { fieldName: 'profileImage', data: profileImage },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  return (
    <>
      {/*이미지 수정 버튼*/}
      <input type='file' id='uploadFile' name='files' className='input' ref={uploadFileRef} style={{ display: 'none' }} onChange={uploadFileHandler} />
      <button className='btn_edit_img' onClick={selectUploadFile}>
        <i className='svg_icon icon_camera white'>&nbsp;</i>
        <div className={`com_profile_img m ${(useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy) && 'T'}`}>
          {' '}
          {/*To Do : 강사, 선생, 아카데미 일때 addClass "T"*/}
          {(nvlNumber(accountInfo.upFileSeq) > 0 && nvlNumber(accountInfo.userSeq)) || nvl(personalProfileImageState) !== '' ? ( // state 이미지가 있거나, 서버 이미지가 있을때
            nvl(personalProfileImageState) !== '' ? (
              <img className='profile-img-background' src={personalProfileImageState} alt='profile' />
            ) : (
              <img
                className='profile-img-background'
                src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${accountInfo.userSeq}?${Date.now()}`}
                alt='profile'
              />
            )
          ) : (
            <span className='name'>
              {(accountInfo?.userName ? accountInfo.userName[0] : accountInfo?.companyName ? accountInfo?.companyName[0] : '').toUpperCase() || ''}
            </span>
          )}
        </div>
      </button>
    </>
  );
}

// Edit 학원 프로필 이미지
function EditAcademyProfileImageSection({
  accountInfo,
  academyProfileImageState,
  profileImage,
  setProfileImage,
  academyProfileImage,
  setAcademyProfileImage,
  setReloadData,
}) {
  const dispatch = useDispatch();

  const [uploadFile, setUploadFile] = useState();
  const uploadFileRef = useRef();

  const selectUploadFile = () => uploadFileRef.current.click();

  const uploadFileHandler = (e) => {
    let currentValue = e.target.value;

    setUploadFile(e.target.files[0]);
  };

  const submitUploadFile = () => {
    const formData = new FormData();

    formData.append('fileCate', 'academyProfile');
    formData.append('uploadFiles', uploadFile);

    const successHandler = (response) => {
      if (response.code === 200) uploadFileInit();
    };

    request.postMultipart(`/api/member/profile/upload/academyProfile/${accountInfo.ecSeq}`, formData, successHandler);
  };

  const uploadFileInit = () => {
    uploadFileRef.current.value = '';

    setUploadFile(null);

    setTimeout(() => setAcademyProfileImage(`${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${accountInfo.ecSeq}?${Date.now()}`));

    setReloadData((prev) => !prev);
  };

  useEffect(() => {
    if (uploadFile != null) submitUploadFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile]);

  useEffect(() => {
    if (nvl(academyProfileImage) !== '') {
      dispatch({
        type: 'setMenuInfo',
        payload: {
          fieldName: 'academyProfileImage',
          data: academyProfileImage,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academyProfileImage]);

  // hook을 사용한 권한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  return (
    <>
      {/*이미지 수정 버튼*/}
      <input type='file' id='uploadFile' name='files' className='input' ref={uploadFileRef} style={{ display: 'none' }} onChange={uploadFileHandler} />
      <button className='btn_edit_img' onClick={selectUploadFile}>
        <i className='svg_icon icon_camera white'>&nbsp;</i>
        <div className={`com_profile_img m ${(useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy) && 'T'}`}>
          {' '}
          {/*To Do : 강사, 선생, 아카데미 일때 addClass "T"*/}
          {(nvlNumber(accountInfo.academyUpFileSeq) > 0 && nvlNumber(accountInfo.ecSeq)) || nvl(academyProfileImageState) !== '' ? (
            nvl(academyProfileImageState) !== '' ? (
              <img className='profile-img-background' src={academyProfileImageState} alt='profile' />
            ) : (
              <img
                className='profile-img-background'
                src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${accountInfo.ecSeq}?${Date.now()}`}
                alt='profile'
              />
            )
          ) : (
            <span className='name'>
              {(accountInfo?.userName ? accountInfo.userName[0] : accountInfo?.companyName ? accountInfo?.companyName[0] : '').toUpperCase() || ''}
            </span>
          )}
        </div>
      </button>
    </>
  );
}

function ViewProfileImageSection({ viewType, accountInfo, stateProfileImage, profileImage, setProfileImage, academyProfileImage, setAcademyProfileImage }) {
  // hook을 사용한 권한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(accountInfo?.userLevel);

  return (
    <>
      {nvl(viewType) === 'academy' ? (
        <div className={`com_profile_img m ${(useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy) && 'T'}`}>
          {' '}
          {/*To Do : 강사, 선생, 아카데미 일때 addClass "T"*/}
          {(nvlNumber(accountInfo.academyUpFileSeq) > 0 && nvlNumber(accountInfo.ecSeq) > 0) || nvl(stateProfileImage) !== '' ? ( // state 이미지가 있거나, 서버 이미지가 있을때
            nvl(stateProfileImage) !== '' ? (
              <img className='profile-img-background' src={stateProfileImage} alt='profile' />
            ) : (
              <img
                className='profile-img-background'
                src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${accountInfo.ecSeq}?${Date.now()}`}
                alt='profile'
              />
            )
          ) : (
            <span className='name'>{(accountInfo?.companyName ? accountInfo?.companyName[0] : '').toUpperCase() || ''}</span>
          )}
        </div>
      ) : (
        <div className={`com_profile_img m ${(useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy) && 'T'}`}>
          {' '}
          {/*To Do : 강사, 선생, 아카데미 일때 addClass "T"*/}
          {(nvlNumber(accountInfo.upFileSeq) > 0 && nvlNumber(accountInfo.userSeq) > 0) || nvl(stateProfileImage) !== '' ? ( // state 이미지가 있거나, 서버 이미지가 있을때
            nvl(stateProfileImage) !== '' ? (
              <img className='profile-img-background' src={stateProfileImage} alt='profile' />
            ) : (
              <img
                className='profile-img-background'
                src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${accountInfo.userSeq}?${Date.now()}`}
                alt='profile'
              />
            )
          ) : (
            <span className='name'>{(accountInfo?.userName ? accountInfo.userName[0] : '').toUpperCase() || ''}</span>
          )}
        </div>
      )}
    </>
  );
}

function PersonalImageSection({
  accountInfo,
  profileType,
  setReloadData,
  profileImage,
  setProfileImage,
  academyProfileImage,
  setAcademyProfileImage,
  personalProfileImageState,
}) {
  // 유저(개인) 프로필 이미지 section
  if (profileType === 'edit') {
    // Edit profile
    return (
      <EditPersonalProfileImageSection
        accountInfo={accountInfo}
        personalProfileImageState={personalProfileImageState}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        setReloadData={setReloadData}
      />
    );
  } else {
    // View profile
    return (
      <ViewProfileImageSection
        viewType='user'
        accountInfo={accountInfo}
        stateProfileImage={personalProfileImageState}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        academyProfileImage={academyProfileImage}
        setAcademyProfileImage={setAcademyProfileImage}
      />
    );
  }
}

function AcademyImageSection({
  accountInfo,
  profileType,
  setReloadData,
  profileImage,
  setProfileImage,
  academyProfileImage,
  setAcademyProfileImage,
  academyProfileImageState,
}) {
  // 아카데미(학원) 프로필 이미지 section

  if (profileType === 'edit') {
    // Edit profile
    return (
      <EditAcademyProfileImageSection
        accountInfo={accountInfo}
        academyProfileImageState={academyProfileImageState}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        academyProfileImage={academyProfileImage}
        setAcademyProfileImage={setAcademyProfileImage}
        setReloadData={setReloadData}
      />
    );
  } else {
    // View profile
    return (
      <ViewProfileImageSection
        viewType='academy'
        accountInfo={accountInfo}
        stateProfileImage={academyProfileImageState}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        academyProfileImage={academyProfileImage}
        setAcademyProfileImage={setAcademyProfileImage}
      />
    );
  }
}

const memberType = {
  [USER_LEVELS.STUDENT]: 'Student',
  [USER_LEVELS.TEACHER]: 'Academy Teacher', // 선생님
  [USER_LEVELS.TUTOR]: 'Private Tutor', // 강사
  [USER_LEVELS.ACADEMY]: 'Academy', // 학원 관리자
  [USER_LEVELS.PARENT]: 'Parent', // 학부모
};

function ID(props) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const stateMenuManager = useSelector((state) => state.MenuManager);

  const { accountInfo } = props;

  const userType = memberType[accountInfo.userLevel] || 'Unknown';

  const isAcademy = !accountInfo.userName; // 아카데미 프로필인지 확인 (존재 여부로 학원과 사용자 구분)

  // 프로필 이미지 코드
  const [profileImage, setProfileImage] = useState(() => {
    if (nvlNumber(accountInfo.upFileSeq) > 0) {
      return `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${accountInfo.userSeq}?${Date.now()}`;
    } else return null;
  });

  // 학원일때 - 학원 프로필 이미지 불러오기
  const [academyProfileImage, setAcademyProfileImage] = useState(() => {
    if (nvlNumber(accountInfo.academyUpFileSeq) > 0) {
      return `${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${accountInfo.ecSeq}?${Date.now()}`;
    } else return null;
  });

  // 멀티프로필 코드 - 다음 버전에 적용
  // const [idActive, setIdActive] = useState(false) //switch account
  const personalProfileImageState = stateMenuManager.profileImage; // 사용자
  const academyProfileImageState = stateMenuManager.academyProfileImage; // 학원

  const displayUserType = userType === 'Academy' && !isAcademy ? 'Academy Admin' : userType; // 아카데미에 속하면서 개인 사용자 일때 Academy Admin 외에는 userType

  return (
    <div className={`id ${props.size}`}>
      {isAcademy ? (
        <AcademyImageSection
          accountInfo={accountInfo}
          profileType={props.type}
          setReloadData={props.setReloadData}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          academyProfileImage={academyProfileImage}
          setAcademyProfileImage={setAcademyProfileImage}
          academyProfileImageState={academyProfileImageState}
        />
      ) : (
        <PersonalImageSection
          accountInfo={accountInfo}
          profileType={props.type}
          setReloadData={props.setReloadData}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          academyProfileImage={academyProfileImage}
          setAcademyProfileImage={setAcademyProfileImage}
          personalProfileImageState={personalProfileImageState}
        />
      )}
      <div className='info'>
        <p className='type'>{displayUserType}</p>
        {props.type === 'edit' ? ( // 프로필 수정
          <>
            <input
              type='text'
              className='input'
              onChange={accountInfo.userName ? props.handleUserNameChange : props.handleCompanyNameChange}
              value={accountInfo.userName ? accountInfo?.userName : accountInfo?.companyName ? accountInfo?.companyName : ''}
            />
          </>
        ) : (
          <p className='name'>
            {isAcademy
              ? !!stateMenuManager.academyName
                ? stateMenuManager.academyName
                : accountInfo.companyName
              : !!stateMenuManager.userName
                ? stateMenuManager.userName
                : accountInfo.userName}
          </p>
        )}
      </div>
    </div>
  );
}

export default ID;
