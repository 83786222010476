import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { removeTags, stringUnEscape } from 'utils/Common.utils.js';
import request from 'utils/Request.utils.js';

//contents
import Post from 'components/board/_components/_Post';
import PostSide from 'components/board/_components/_PostSide';
import Paging from 'layout/Paging';

/** TestGate News 목록 페이지 */
export default function TestgateNewsListPage() {
  const navigate = useNavigate();
  const clickLink = (boardSeq, notiType, url) => {
    const cleanUrl = stringUnEscape(removeTags(url));

    if (notiType === 'Y') window.open(encodeURI(cleanUrl), '_blank');
    else navigate(`/gateplus/news/view?post-num=${boardSeq}`);
  };

  const [gateNewsInfo, setGateNewsInfo] = useState({
    newList: [],
    sideBoard: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 7,
    pagePerBlock: 10,
  });

  const nextPageMove = (value) => {
    // console.log("value : " + value)

    setGateNewsInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const [searchKeyword, setSearchKeyword] = useState('');

  const getBoardList = () => {
    let pms = {
      searchKeyword: searchKeyword,
      limit: gateNewsInfo.numPerPage,
      offset: (gateNewsInfo.currentPage - 1) * gateNewsInfo.numPerPage,
      orderBy: 'board_seq DESC',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let boardList = response.result.boardArticleList;
        let sideBoard = response.result.sideBoard;
        let totalCount = response.result.totalCount;

        // console.log("boardList", boardList, totalCount);

        setGateNewsInfo((prev) => {
          return {
            ...prev,
            newList: boardList,
            sideBoard: sideBoard,
            totalCount: totalCount,
          };
        });
      }
    };

    request.get(
      `/api/board/notice/list?limit=${pms.limit}&offset=${pms.offset}&orderBy=${pms.orderBy}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}`,
      null,
      successHandler
    );
  };

  useEffect(() => {
    getBoardList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateNewsInfo.currentPage]);

  const boardListReload = () => {
    setGateNewsInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getBoardList();
  };

  useEffect(() => {
    boardListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  return (
    <div className='p_board com_center_wrap'>
      <section className='com_container left'>
        {gateNewsInfo.newList && gateNewsInfo.newList[0] && <Post cate='GATE⁺ NEWS' type='big' boardInfo={gateNewsInfo.newList[0]} onClick={clickLink} />}
        {gateNewsInfo.newList.length > 0 ? (
          gateNewsInfo.newList.map((item, index) => {
            if (index === 0) return null;
            return (
              <Fragment key={`new_${index}`}>
                <Post cate='GATE⁺ NEWS' type='default' boardInfo={item} onClick={clickLink} />
              </Fragment>
            );
          })
        ) : (
          <h1> No results. Please search again. </h1>
        )}
        <Paging
          totalCount={gateNewsInfo.totalCount}
          currentPage={gateNewsInfo.currentPage}
          numPerPage={gateNewsInfo.numPerPage}
          pagePerBlock={gateNewsInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        />
      </section>
      <PostSide boardType='info' boardInfo={gateNewsInfo.sideBoard} setSearchKeyword={setSearchKeyword} />
    </div>
  );
}
