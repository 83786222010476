import { useDispatch } from 'react-redux';
import request from 'utils/Request.utils';

import { nvl, nvlNumber } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getRestTimeFromLocalStorage } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';
import LocalStorage from 'utils/LocalStorage.utils.js';

/** 수학 시험 문제 영역 컴포넌트 */
export default function QuestionArea({ wrap, stateExamInfo, wrapButtonHandler, isLoading, setIsLoading, tryAnswerRef, dummyData }) {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userInfo = request.tokenDecoder();
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();
  const sendParams = LocalStorage.getItemJsonParse('sendParams');

  const [isUndoActive, setUndoActive] = useState(false);
  const [input, setInput] = useState('');

  /** 주관식 답변 Blur 핸들러 */
  const outSubjective = (e) => {
    let currentExamInfo = dummyData[state?.currentNum - 1];

    tryAnswerRef.current[state?.currentNum - 1] = e.target.value;

    currentExamInfo = { ...currentExamInfo, tryAnswer: e.target.value };

    dispatch({ type: 'editExamQuestionList', payload: { index: state?.currentNum - 1, data: currentExamInfo } });

    // -----------------------------------------------------------------------
    // 주관식 업데이트 용 따로 만듦

    let params = {
      uthSeq: stateExamInfo.uthSeq,
      bookmarkYn: dummyData[state?.currentNum - 1]?.bookMark,
      testModuleSeq: dummyData[state?.currentNum - 1]?.testModuleSeq,
      regUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
      tryAnswer: tryAnswerRef.current[state?.currentNum - 1],
    };

    let questionSeq = dummyData[state?.currentNum - 1]?.questionSeq;

    const successHandler = (response) => {
      if (response.code === 200) {
        console.log('🚨 response :', response, '🚨');
        let currentExamInfo = dummyData[state?.currentNum - 1];
        currentExamInfo = {
          ...currentExamInfo,
          tryAnswer: nvl(tryAnswerRef.current[state?.currentNum - 1]) === 'tryAnswerNull' ? '' : tryAnswerRef.current[state?.currentNum - 1],
        };

        dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: currentExamInfo } });
      }
      setIsLoading(false);
    };

    if (nvlNumber(questionSeq) > 0) {
      if (!isLoading) {
        setIsLoading(true);
        request.put(`/api/exam/subjective/${sendParams.uthSeq}/${questionSeq}`, params, successHandler).catch((error) => {
          console.error(error);
          alert('수학 시험 - 문제 영역 - 주관식 답변 Blur 핸들러 API 응답 실패');
        });
      }
    }
  };

  /** 주관식 문제 선택 핸들러 */
  const numberCheck = (e) => {
    let newValue = e.target.value;

    newValue = newValue.replace(/[^0-9-./]| /g, '');

    // Further processing for length and format
    if (newValue.length === 1 && newValue[0] !== '-') {
      newValue = newValue.replace(/[^0-9-./]/g, '');
    } else if (newValue.length > 1) {
      if (newValue[0] === '-') {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 8);
      } else {
        newValue =
          newValue[0] +
          newValue
            .substring(1)
            .replace(/[^0-9./]/g, '')
            .substring(0, 7);
      }
    }

    setInput(newValue);

    let currentExamInfo = dummyData[stateExamInfo.currentNum - 1];
    currentExamInfo = { ...currentExamInfo, tryAnswer: newValue };

    dispatch({ type: 'editExamQuestionList', payload: { index: stateExamInfo.currentNum - 1, data: currentExamInfo } });
  };

  useEffect(() => {
    let divList = document.getElementById('answerArea')?.children;

    if (divList && divList != null) {
      if (isUndoActive) {
        for (const divNode of divList) {
          if (divNode.childNodes[1]) divNode.childNodes[1].style.width = 'calc(100% - 50px)';
        }
      } else {
        for (const divNode of divList) {
          if (divNode.childNodes[1]) divNode.childNodes[1].style.width = '100%';
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUndoActive]);

  useEffect(() => {
    setInput('');
  }, [state.currentNum]);

  return (
    <article
      className={
        `wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''} ` +
        (dummyData[stateExamInfo.currentNum - 1]?.questionFormat === 'M' ? 'type_one' : '')
      }>
      <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
        &nbsp;
      </button>
      {/* TODO: 문제 풀이 영역 */}
      <div className='scroll_wrap'>
        <div className='inner'>
          <div className='top_area'>
            <div className='num'>{stateExamInfo.currentNum}</div>
          </div>
          {/* 문제 영역(문제 내용) */}
          <div id='questionArea' name='questionArea' className='text'>
            <div
              dangerouslySetInnerHTML={{
                __html: dummyData?.[stateExamInfo.currentNum - 1].question.replace(
                  '/common/commonImageView.do',
                  `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                ),
              }}
            />
          </div>
          <div id='answerArea' name='answerArea' className='answer_area'>
            {/*주관식*/}
            {dummyData[stateExamInfo?.currentNum - 1]?.questionFormat !== 'M' && (
              <div className='answer_input'>
                <div className='input_box'>
                  <input type='text' className='input' value={input} onChange={(e) => numberCheck(e)} onBlur={outSubjective} />
                </div>
                {/* <div className='preview'>
                  Answer preview : &nbsp;
                  {dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.includes('-') && dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.includes('/') ? (
                    <MathJaxViewer
                      convertString={nvl(
                        `$$-\\frac{${dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[0].replace('-', '')}}{${dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[1]}}$$`
                      )}
                    />
                  ) : dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.includes('/') ? (
                    <MathJaxViewer
                      convertString={nvl(
                        `$$\\frac{${dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[0]}}{${dummyData[stateExamInfo.currentNum - 1]?.tryAnswer.split('/')[1]}}$$`
                      )}
                    />
                  ) : (
                    <>{dummyData[stateExamInfo.currentNum - 1]?.tryAnswer}</>
                  )}
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}
