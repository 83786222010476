import styled from 'styled-components';

import ButtonBase from './_ButtonBase';

/**
 * @component
 * SVG 아이콘과 텍스트를 가진 버튼 컴포넌트
 * @example
 * ```jsx
 *  <SvgIconTextBtn
 *    svgClassName='svg_icon_클래스_이름'
 *    onClick={() => callBack()}
 *    text='버튼_텍스트'
 *  />
 * ```
 * @param {string} iconSize - 아이콘의 크기 / default: '0.75rem'
 * @param {string} svgClassName - SVG 아이콘에 적용할 클래스 이름
 * @param {function} onClick - 버튼을 눌렀을 때 실행할 함수
 * @param {string} type - 사용할 버튼스타일 (default, tableBtn 등) / default: 'default'
 * @param {string} radius - 버튼의 테두리 둥글기 (default, medium) / default: 'default'
 * @param {string} text - 버튼에 보여줄 텍스트 / default: 'TEXT'
 * @param {string|null} w - 버튼의 너비(width) / default: null(명시적 width 없음)
 * @param {string|null} h - 버튼의 높이(height) / default: null(명시적 height 없음)
 * @param {string|null} bgColor - 버튼의 배경색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} color - 버튼의 글자색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} borderColor - 버튼의 테두리 색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} hoverColor - 호버 시 버튼의 배경색 / default: null(기본 호버 효과 사용)
 * @param {boolean} dataHover - 호버 시 툴팁 표시 여부 / default: false
 * @param {object} style - 추가로 적용할 스타일 / default: {}
 * @returns {JSX.Element} SVG 아이콘과 텍스트를 가진 버튼 컴포넌트 반환
 */
function SvgIconTextBtn({
  iconSize = '0.75rem',
  svgClassName,

  onClick,
  type = 'default',
  radius = 'default',
  text = 'TEXT',
  w = null,
  h = null,
  bgColor = null,
  color = null,
  borderColor = null,
  hoverColor = null,
  dataHover = false,
  style = {},
}) {
  const baseBtnProps = {
    w: w,
    h: h,
    bgColor: bgColor,
    color: color,
    borderColor: borderColor,
    hoverColor: hoverColor,
    text: text,
    type: type,
    style: style,
    onClick: onClick,
    dataHover: dataHover,
    radius: radius,
  };
  return (
    <ButtonBase {...baseBtnProps}>
      <S.svgIcon iconSize={iconSize} className={svgClassName}>
        {' '}
      </S.svgIcon>
      <span>{text}</span>
    </ButtonBase>
  );
}

export default SvgIconTextBtn;

// --- --- --- Styled Components --- --- ---

const S = {}; // styled-components 객체(S-dot 컨벤션용)

S.svgIcon = styled.i`
  width: ${(props) => props.iconSize} !important; // _3site.scss 덮어쓰기
  height: ${(props) => props.iconSize} !important; // _3site.scss 덮어쓰기
  display: inline-block;
`;
