import styled from 'styled-components';

import gateKeyGradationIcon from 'assets/img/svg/icon_gate_key_gradation.svg';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import LocalStorage from 'utils/LocalStorage.utils';

function InsufficientKeyModal({ requiredKey, setShowModal }) {
  const userSession = LocalStorage.getItemJsonParse('userSession');
  return (
    <SimpleModalBase w='32rem' onEscape={() => setShowModal(false)}>
      <h2>Insufficient Gate Key</h2>
      <div className='modal_img_wrapper'>
        <S.ModalImageBox>
          <img src={gateKeyGradationIcon} alt='gate key image' />
        </S.ModalImageBox>
      </div>
      <div className='modal_text_container'>
        <p>
          You need <span style={{ color: '#4758FF', fontWeight: 900 }}>{requiredKey}</span> Gate key to complete the test setting.
        </p>
        <p>Please obtain more Gate Key.</p>
      </div>
      <div className='modal_btn_container'>
        <button onClick={() => setShowModal(false)}>Back</button>
        <button
          className='btn_fill'
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_MAINGATE_ROOT_URL}/sign/signin/?lastRoute=/en-US/pricing&accessToken=${userSession.accessToken}
        &refreshToken=${userSession.refreshToken}`,
              '_blank'
            )
          }>
          Gate Key
        </button>
      </div>
    </SimpleModalBase>
  );
}

export default InsufficientKeyModal;

// --- --- ---

const S = {}; // S-dot 문법용 객체

S.ModalImageBox = styled.div`
  background-color: #e6f4ff;
  border-radius: 100%;
  width: 4.4375rem;
  height: 4.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
