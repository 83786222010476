// styled-component 전역 변수 설정 용 파일

import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --point-color: #0068bd;
    --SubText-color: #7f8998;
    --sub-color2: #a5aab1;
    --main-black: #000;
    --sub-red: #f24b4b;
    --emphasis: #4b5563;
    --input-stroke: #d2dbe2;
    --table-text: #4b5563;
    --rw: #008cff;
    --rw-text: #fff;
    --math: #1fdf9f;
    --math-text: #023524;
    --Table-set-Table_fill_blue: #edf7ff;
    --Table-set-table-line: #eaeaea;
    --c-blue: #0132b8;
    --Dashboard-primary-table-text: #080808;
    --c-red: #ff4c41;
    --c-blue-bg: #e9efff;
    --c-gray-bg: #f4f4f4;
    --c-gray: #6d6d6d;
    --c-lightgray: #f1f1f1;
    --c-disabled: #f8f8f8;
    --c-border: #ebebeb;
  }
`;

export const styledCssEllipsis = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  display: block;
`;
export const styledCssMultiEllipsis = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
