/** "00:00 PM/AM" 형식의 시간을 HH:MM:SS 형식으로 변환 */
export function convertTimeHHMMSSFormat(timeString) {
  // '10:30 AM'과 같은 형식을 받아서 24시간 형식으로 변환
  const [time, modifier] = timeString.split(' ');

  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  // 시간을 "HH:mm:ss" 형식으로 변환
  return `${String(hours).padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
}
