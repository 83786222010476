import React, { useEffect, useState } from 'react';

import request from 'utils/Request.utils';

import Post from 'components/board/_components/_Post';
import PostSide from 'components/board/_components/_PostSide';

/** DSAT Info 상세 페이지 (DSAT info 와 TestGate News 라우트) */
export default function DSATInfoViewPage() {
  const [gateNewsInfo, setGateNewsInfo] = useState([]);
  const [sideBoardLst, setSideBoardList] = useState([]);

  const query = new URLSearchParams(window.location.search);
  const boardSeq = query.get('post-num');

  let onlyOne = 0;

  const getBoardView = () => {
    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let boardList = response.result.boardArticleList;

        setGateNewsInfo(boardList);
      }
    };

    request.get(`/api/board/info/view/${boardSeq}`, null, successHandler);
  };

  useEffect(() => {
    if (boardSeq > 0 && onlyOne === 0) {
      getBoardView();

      onlyOne++;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='p_board com_center_wrap'>
      <section className='com_container left'>{gateNewsInfo.length > 0 && <Post cate='Gate⁺ INFO' boardInfo={gateNewsInfo[0]} type='view' />}</section>
      <PostSide boardType='news' boardInfo={sideBoardLst} />
    </div>
  );
}
