import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';

/** 팝업 달력 컴포넌트 */
export default function PopupCalendarPeriod({ setSearchInfo, tit, size }) {
  const [viewType, setViewType] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const changeDate = (event) => {
    // event를 받아서 yyyy/mm/dd 형식으로 일자를 포맷팅해줌
    // e[0]은 사용자가 여행 일자로 선택한 시작 일자가 들어감
    // e[1]은 사용자가 여행 마치는 일자로 선택한 일자가 들어감
    const startDateFormat = moment(event[0]).format('MM.DD.YYYY ~ ');
    const endDateFormat = moment(event[1]).format('MM.DD.YYYY');

    console.log(startDateFormat, endDateFormat);

    setSearchInfo((prev) => {
      return {
        ...prev,
        searchStartDate: moment(event[0]).format('YYYY-MM-DD'),
        searchEndDate: moment(event[1]).format('YYYY-MM-DD'),
      };
    });

    // 여행 시작일자와 마치는일자의 값이 변할 때마다 값을 다시 세팅해줌
    setStartDate(startDateFormat);
    setEndDate(endDateFormat);

    setViewType(false);
  };

  const onClickConfirm = () => {
    //  props.dateType === 'hyphen' ? props.getCalendar(moment(calendarValue).format("YYYY-MM-DD")) : props.getCalendar(moment(calendarValue).format("YYYY-MM-DD"))
    calendarClose();
  };

  function calendarClose() {
    // console.log("달력 닫기");
    setViewType(false);
  }

  return (
    <div className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker search_area_input'
        onClick={() => {
          setViewType(true);
        }}
        readOnly
        placeholder={tit}
        value={startDate + endDate || ''}
        size={size}
      />
      {viewType && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button
              type='button'
              className='svg_icon btn_pop_close black'
              onClick={() => {
                calendarClose();
              }}>
              &nbsp;
            </button>
            {/* <p className="PopupCalendar_header_tit">Select date</p> */}
          </div>
          <Calendar className='calendar' onChange={changeDate} selectRange={true} formatDay={(locale, date) => moment(date).format('DD')} locale='en-EN' />
          {/* <div className='com_btn_wrap bottom'>
            <button className='confirmButton com_btn point l' type='button' onClick={ () => { onClickConfirm() } } >확인</button>
          </div> */}
        </div>
      )}
    </div>
  );
}
