import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';

/** 시간 설정 컴포넌트 */
const SetTimeLayer = ({ close, confirm, btnType, time }) => {
  const [timeSelect, setTime] = useState(false); //time select box
  const [selectTime, setSelectTime] = useState(time);

  const layerRef = useRef();

  const handleTimeSelect = (e, time) => {
    e.stopPropagation();

    setTime(false);
    setSelectTime(moment(time).format('LT'));
  };

  const generateTimeOptions = () => {
    const timeOptions = [];
    const startTime = moment().startOf('day');
    for (let i = 0; i < 24; i++) {
      const time = startTime.clone().add(i, 'hours');
      timeOptions.push(
        <p key={time.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, time)}>
          {time.format('A hh : mm')}
        </p>
      );

      const halfHour = time.clone().add(30, 'minutes');
      timeOptions.push(
        <p key={halfHour.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, halfHour)}>
          {halfHour.format('A hh : mm')}
        </p>
      );
    }
    return timeOptions;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (layerRef.current && !layerRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='com_time_layer active' ref={layerRef}>
      <div className='layer_tit'>Start Time</div>
      <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => setTime((prev) => !prev)}>
        <section className='selected_wrap'>
          <input className='selected' placeholder={selectTime} />
        </section>
        <section className='layer scroll'>{generateTimeOptions()}</section>
      </div>
      <div className='com_btn_wrap bottom'>
        {btnType === 'skip' ? (
          <button className='com_btn line point m full' onClick={close}>
            Skip
          </button>
        ) : (
          <></>
        )}
        <button className='com_btn point m full' onClick={() => confirm(selectTime)}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SetTimeLayer;
