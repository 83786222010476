import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import dayjsTZ, { getDateOnlyDayjsTZ, isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import 'react-calendar/dist/Calendar.css';
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants';
import dayjs from 'dayjs';

export default function MiniCalendar({ pathname, child }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const today = dayjsTZ();
  const [currentDate, setCurrentDate] = useState(dayjsTZ());
  const [mark, setMark] = useState([
    {
      testStartData: '2024-02-10',
      testEndDate: '2024-02-12',
      color: 'red',
    },
  ]);

  const childEcSeq = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const childInfo = response.result.childInfo;

        if (nvl(childInfo) !== '' && childInfo.length > 0) {
          const childEcSeq = childInfo[0].ecSeq;
          const childUserSeq = childInfo[0].userSeq;

          getCalendarData(childEcSeq, childUserSeq);
        }
      }
    };
    const failHandler = (error) => {
      console.error('error : ', error);
    };

    request.get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler, failHandler);
  };

  /** 앞 뒤로 넉넉히 데이터 불러오게 설정 */
  const getCalendarData = (ecSeq, userSeq = userInfo.userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result.scheduleInfo;

        setMark(
          scheduleInfo.map((item) => {
            return {
              testStartDate: isoTimeToDayjs(item.testStartDate).format('YYYY-MM-DD'),
              testEndDate: isoTimeToDayjs(item.testEndDate).format('YYYY-MM-DD'),
              classColor: item.classColor,
            };
          })
        );
      }
    };
    const searchStartDate = encodeURIComponent(currentDate.startOf('day').subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(currentDate.startOf('day').add(2, 'month').date('1').format());

    if (userInfo.userLevel === USER_LEVELS.ACADEMY || userInfo.userLevel === USER_LEVELS.TUTOR || userInfo.userLevel === USER_LEVELS.TEACHER) {
      // 학원관리자, 선생님, 튜터
      request.get(`/api/dsat/calendar/schedules?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`, null, successHandler);
    } else {
      // 학부모, 학생
      request.get(
        `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&userSeq=${userSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`,
        null,
        successHandler
      );
    }
  };

  // currentDate가 바뀔때 마다 시험 데이터 호출
  useEffect(() => {
    if (userInfo.userLevel === USER_LEVELS.PARENT) childEcSeq();
    else getCalendarData(userInfo.ecSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const markMapping = {};

  /** 인자로 받은 시험의 종료일이 오늘 이전인지 확인하고 캘린더에 보여줄 일정을 filter 해주는 함수 */
  const getDatesBetween = (startDate, endDate, color) => {
    // 시험 종료 시간이 현재 시간보다 이전이라면 표시하지 않음
    if (dayjsTZ(endDate).isBefore(today)) return;
    // 달력 표시용 객체에 데이터가 없다면 새로운 데이터 생성
    if (markMapping[startDate] === undefined) markMapping[startDate] = [];

    markMapping[startDate] = [color, ...markMapping[startDate]];
  };
  mark.forEach((item) => {
    getDatesBetween(item.testStartDate, item.testEndDate, item.classColor);
  });

  return (
    <div className='com_calendar_wrap'>
      <Calendar
        // 뷰 이동시 현재 날짜가 변경되게 구현
        onActiveStartDateChange={({ activeStartDate }) => {
          setCurrentDate(dayjs(activeStartDate));
        }}
        // 타임존에 맞게 날짜 표시 변경
        formatDay={(locale, date) => dayjsTZ(date).format('DD')}
        // 타임존에 맞게 요일 표시 변경
        formatShortWeekday={(locale, date) => dayjsTZ(date).format('ddd')}
        locale='en-EN'
        value={today.toDate()}
        className='calendar'
        tileContent={({ date, view }) => {
          const formattedDate = dayjsTZ(date).format('YYYY-MM-DD');
          // markMapping에 데이터가 있다면 해당 데이터를 표시 ( 3개 까지만 표시 )
          if (markMapping[formattedDate]) {
            return (
              <div className='icon_schedule__wrap'>
                {markMapping[formattedDate].slice(0, 3).map((color, index) => {
                  return <div key={index} className={`icon_schedule ${color}`}></div>;
                })}
              </div>
            );
          }
        }}
        onClickDay={(date) => {
          if (pathname.indexOf('dashboard') !== -1) {
            // pathname에 "main"이 포함되어 있을 때만 navigate 실행
            navigate('/com/tests/calendar', {
              state: { selectedDate: dayjsTZ(date).format('YYYY-MM-DD') },
            });
          }
        }}
      />
    </div>
  );
}
