import React, { Children } from 'react';

export default function DateCellWrapper(props) {
  const { children, value } = props;

  return React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      // backgroundColor: '#DEECF9',
      // border: "1px solid black"
    },
  });
}
