import React, { useState, useEffect, useRef } from 'react';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import 'styles/css/exam.css';

import { USER_LEVELS } from 'utils/constants.js';

const ExamEnReview = ({ examComponents, setExamComponents, currentQuestion, setCurrentQuestion, userLevel }) => {
  const userInfo = request.tokenDecoder();
  /** 취소선 노출 버튼 */
  const [isUndoActive, setUndoActive] = useState(false);
  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });
  const [selectionRate, setSelectRate] = useState({
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  });

  const examDivRef = useRef();

  const wrapButtonHandler = (arrow) => {
    if (arrow === 'left') {
      if (wrap.leftArrow)
        setWrap((prev) => {
          return { ...prev, leftArrow: false };
        });
      else {
        if (!wrap.leftArrow && wrap.rightArrow)
          setWrap((prev) => {
            return { ...prev, rightArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, leftArrow: !wrap.leftArrow };
          });
      }
    } else {
      if (wrap.rightArrow)
        setWrap((prev) => {
          return { ...prev, rightArrow: false };
        });
      else {
        if (!wrap.rightArrow && wrap.leftArrow)
          setWrap((prev) => {
            return { ...prev, leftArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, rightArrow: !wrap.rightArrow };
          });
      }
    }
  };

  const handleCloseButtonClick = () => {
    window.close();
  };

  useEffect(() => {
    if (currentQuestion.tryAnswerCntList != null) {
      let aCnt = 0;
      let bCnt = 0;
      let cCnt = 0;
      let dCnt = 0;
      let omitCnt = 0;

      currentQuestion?.tryAnswerCntList.map((item, index) => {
        // Z 는 omit 값

        if (nvl(item.tryAnswer) === 'A') aCnt += item.tryAnswerCnt;
        else if (nvl(item.tryAnswer) === 'B') bCnt += item.tryAnswerCnt;
        else if (nvl(item.tryAnswer) === 'C') cCnt += item.tryAnswerCnt;
        else if (nvl(item.tryAnswer) === 'D') dCnt += item.tryAnswerCnt;
        else if (nvl(item.tryAnswer) === 'Z') omitCnt += item.tryAnswerCnt;
        else console.log('주관식?');

        return null;
      });

      let allCnt = aCnt + bCnt + cCnt + dCnt + omitCnt;

      setSelectRate((prevState) => ({
        ...prevState,
        A: (aCnt / allCnt) * 100,
        B: (bCnt / allCnt) * 100,
        C: (cCnt / allCnt) * 100,
        D: (dCnt / allCnt) * 100,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 리뷰 북마크 */
  const bookmarkChangeHandler = () => {
    setCurrentQuestion((prev) => {
      return {
        ...prev,
        reviewBookmark: currentQuestion.reviewBookmark === 'Y' ? 'N' : 'Y',
      };
    });

    updateBookmark();
  };

  const updateBookmark = () => {
    let params = {
      reviewBookmarkYn: currentQuestion?.reviewBookmark === 'Y' ? 'N' : 'Y',
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        try {
          window.opener.getParentModule(currentQuestion?.uthSeq);
        } catch (error) {
          console.log('부모창과 연결이 끊겼어요');
        }
      }
    };

    request.put(`/api/exam/resultHistory/${currentQuestion?.uthSeq}/${currentQuestion?.questionSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('영어 시험 - Review - 북마크 API 응답 실패');
    });
  };

  /** 선생님 북마크 */
  const classOptionBookmarkHandler = () => {
    setCurrentQuestion((prev) => {
      return {
        ...prev,
        classBookmarkYn: nvl(currentQuestion.classBookmarkYn) === 'Y' ? 'N' : 'Y',
      };
    });

    updateClassBookmark();
  };

  const updateClassBookmark = () => {
    let params = {
      bookmarkYn: currentQuestion?.classBookmarkYn === 'Y' ? 'N' : 'Y',
      questionSeq: currentQuestion?.questionSeq,
      userSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        try {
          window.opener.getParentQuestion();
        } catch (error) {
          console.log('부모창과 연결이 끊겼어요');
        }
      }
    };

    request.put(`/api/dsat/class/option/bookmark/${currentQuestion.classSeq}/${currentQuestion?.questionSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      alert('영어 시험 - Review - 선생님 북마크 API 응답 실패');
    });
  };

  return (
    <div className={`com_popup pop_exam type_correct_answer active ${examComponents.directionDisplay ? 'direction_on' : ''} `}>
      {/*addClass 'active' 시 노출*/} {/*addClass 'direction_on' 시 Directions 레이어 노출*/}
      <div className='pop_container'>
        <section ref={examDivRef} className='pop_header'>
          <div className='tit_area'>
            <div className='tit'>Review</div>
            <button
              className='btn'
              onClick={() =>
                setExamComponents((prev) => {
                  return {
                    ...prev,
                    directionDisplay: !examComponents.directionDisplay,
                  };
                })
              }>
              Directions<i className='svg_icon icon_arrow black'></i>
            </button>
            {examComponents.directionDisplay ? <DirectionsPopup setExamComponents={setExamComponents} examDivRef={examDivRef} /> : null}
          </div>
        </section>
        <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
          {' '}
          {/*btn_wrap 클릭 시 addClass " change_wrap_width"*/}
          <EnglishQuestion currentQuestion={currentQuestion} wrap={wrap} wrapButtonHandler={wrapButtonHandler} />
          <article className={`wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''}`}>
            {/*btn_undo 클릭 시 addClass 'undo_active'*/}
            <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
              &nbsp;
            </button>
            <div className='scroll_wrap'>
              <div className='inner'>
                <div className='top_area'>
                  <div className='num'> {currentQuestion?.questionOrder} </div>
                  <div className='bookmark'>
                    <input type='checkbox' id='bookmark1' className='btn_bookmark' checked={currentQuestion?.bookMark === 'Y'} disabled />
                    <label style={{ cursor: 'auto' }} htmlFor='bookmark1'>
                      Mark for Review
                    </label>
                  </div>
                  {userInfo.userLevel === USER_LEVELS.STUDENT ? (
                    <div className='add_review_note'>
                      <input
                        type='checkbox'
                        id='addToReviewNote'
                        className='btn_add_review_note'
                        onChange={() => (userInfo.userLevel === USER_LEVELS.STUDENT ? bookmarkChangeHandler() : null)}
                        checked={currentQuestion?.reviewBookmark === 'Y'}
                      />
                      <label htmlFor='addToReviewNote'>{currentQuestion?.reviewBookmark !== 'Y' ? 'Add to Review Note' : 'Added to Review Note'}</label>
                    </div>
                  ) : (
                    <div className='review_bookmark'>
                      <input
                        type='checkbox'
                        id='reviewBookmark1'
                        className='btn_bookmark'
                        onChange={(e) => {
                          classOptionBookmarkHandler(e);
                        }}
                        checked={nvl(currentQuestion.classBookmarkYn) === 'Y'}
                      />
                      <label htmlFor='reviewBookmark1'>{currentQuestion.classBookmarkYn !== 'Y' ? 'Bookmark' : 'Bookmarked'}</label>
                    </div>
                  )}

                  <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={() => setUndoActive(!isUndoActive)}>
                    <i className='svg_icon icon_undo'>&nbsp;</i>
                    <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
                  </button>
                </div>
                <div className='text'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                    }}
                  />
                </div>
                <div id='answerArea' className='answer_area'>
                  {/* <div className="item"> */}
                  <div className={nvl(currentQuestion?.underLine1) !== 'Y' ? 'item' : 'item undo_selected'}>
                    <input
                      type='radio'
                      name='answer1'
                      id='answer1_1'
                      className='radio_answer'
                      value='A'
                      checked={currentQuestion?.tryAnswer === 'A'}
                      disabled
                    />
                    <label
                      className={`answer ${currentQuestion?.correctAnswer === 'A' ? 'right' : currentQuestion?.tryAnswer === 'A' ? 'wrong' : ''}`}
                      htmlFor='answer1_1'>
                      <span className='num'>A</span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion?.multipleChoiceItems1,
                        }}
                      />
                    </label>
                    <button className='num' value='A'>
                      A
                    </button>
                  </div>
                  {/*1213 정답 일경우*/}
                  <div className={`answer_per ${currentQuestion?.correctAnswer === 'A' ? '' : 'wrong'}`}>
                    {currentQuestion?.correctAnswer === 'A' && <>Correct Answer</>}
                    {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'A' && <>Your Choice</>}
                    {userLevel && <div className='per'>{Math.round(selectionRate.A)}%</div>}
                  </div>

                  <div className={nvl(currentQuestion?.underLine2) !== 'Y' ? 'item' : 'item undo_selected'}>
                    <input
                      type='radio'
                      name='answer1'
                      id='answer1_2'
                      className='radio_answer'
                      value='B'
                      checked={currentQuestion?.tryAnswer === 'B'}
                      disabled
                    />
                    <label
                      className={`answer ${currentQuestion?.correctAnswer === 'B' ? 'right' : currentQuestion?.tryAnswer === 'B' ? 'wrong' : ''}`}
                      htmlFor='answer1_2'>
                      <span className='num'>B</span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion?.multipleChoiceItems2,
                        }}
                      />
                      {/* <MathJaxViewer convertString={ nvl(currentQuestion?.multipleChoiceItems2) } /> */}
                    </label>
                    <button className='num' value='B'>
                      B
                    </button>
                  </div>
                  {/*1213 오답률*/}
                  <div className={`answer_per ${currentQuestion?.correctAnswer === 'B' ? '' : 'wrong'}`}>
                    {currentQuestion?.correctAnswer === 'B' && <>Correct Answer</>}
                    {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'B' && <>Your Choice</>}
                    {/*1213 내 답이 오답일 경우에만 노출*/}
                    {userLevel && <div className='per'>{Math.round(selectionRate.B)}%</div>}
                  </div>

                  <div className={nvl(currentQuestion?.underLine3) !== 'Y' ? 'item' : 'item undo_selected'}>
                    <input
                      type='radio'
                      name='answer1'
                      id='answer1_3'
                      className='radio_answer'
                      value='C'
                      checked={currentQuestion?.tryAnswer === 'C'}
                      disabled
                    />
                    <label
                      className={`answer ${currentQuestion?.correctAnswer === 'C' ? 'right' : currentQuestion?.tryAnswer === 'C' ? 'wrong' : ''}`}
                      htmlFor='answer1_3'>
                      <span className='num'>C</span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion?.multipleChoiceItems3,
                        }}
                      />
                    </label>
                    <button className='num' value='C'>
                      C
                    </button>
                  </div>
                  {/*1213 오답률*/}
                  <div className={`answer_per ${currentQuestion?.correctAnswer === 'C' ? '' : 'wrong'}`}>
                    {currentQuestion?.correctAnswer === 'C' && <>Correct Answer</>}
                    {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'C' && <>Your Choice</>}
                    {userLevel && <div className='per'>{Math.round(selectionRate.C)}%</div>}
                  </div>

                  <div className={nvl(currentQuestion?.underLine4) !== 'Y' ? 'item' : 'item undo_selected'}>
                    <input
                      type='radio'
                      name='answer1'
                      id='answer1_4'
                      className='radio_answer'
                      value='D'
                      checked={currentQuestion?.tryAnswer === 'D'}
                      disabled
                    />
                    <label
                      className={`answer ${currentQuestion?.correctAnswer === 'D' ? 'right' : currentQuestion?.tryAnswer === 'D' ? 'wrong' : ''}`}
                      htmlFor='answer1_4'>
                      <span className='num'>D</span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentQuestion?.multipleChoiceItems4,
                        }}
                      />
                    </label>
                    <button className='num' value='D'>
                      D
                    </button>
                  </div>
                  {/*1213 오답률*/}
                  <div className={`answer_per ${currentQuestion?.correctAnswer === 'D' ? '' : 'wrong'}`}>
                    {currentQuestion?.correctAnswer === 'D' && <>Correct Answer</>}
                    {userLevel && currentQuestion?.correctAnswer !== currentQuestion?.tryAnswer && currentQuestion?.tryAnswer === 'D' && <>Your Choice</>}
                    {userLevel && <div className='per'>{Math.round(selectionRate.D)}%</div>}
                  </div>
                  {/*1228 정답을 선택 안했을때 만 노출*/}
                  {userLevel && (nvl(currentQuestion?.tryAnswer) === '' || nvl(currentQuestion?.tryAnswer) == null) && (
                    <div className='omit'>You have omitted this question.</div>
                  )}
                </div>
              </div>
            </div>
          </article>
        </section>

        <section className='pop_footer'>
          <article className='btn_area'>
            <button className='btn btn_close' onClick={handleCloseButtonClick}>
              Close
            </button>
            {/*1213 클릭 시 팝업 닫힘*/}
          </article>
        </section>
      </div>
    </div>
  );
};

// Directions
const DirectionsPopup = ({ setExamComponents, examDivRef }) => {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, directionDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents, examDivRef]);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of inportant reading and writing skills. Each question includes one or more passages, which may include a
        table or grahp. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).
        <br />
        All questions in this wection are multiple-choice with four answer choices. Each question has a single best answer.
      </div>
      <div className='btn_field'>
        <button
          className='btn close'
          onClick={() =>
            setExamComponents((prev) => {
              return { ...prev, directionDisplay: false };
            })
          }>
          Close
        </button>
      </div>
    </article>
  );
};

const EnglishQuestion = ({ currentQuestion, wrap, setWrap, wrapButtonHandler }) => {
  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      {' '}
      {/*icon_wrap 클릭 시 addClass "big"*/}
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div className='inner'>
          <div
            className='question'
            dangerouslySetInnerHTML={{
              __html: currentQuestion.passage.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
            }}
          />
        </div>
      </div>
    </article>
  );
};

export default ExamEnReview;
