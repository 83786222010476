import moment from 'moment/moment';
import React, { useEffect, useRef } from 'react';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import {
  getRestTimeFromLocalStorage,
  getTimeDiffFromLocalStorage,
  getTimeEntryTimeLocalStorage,
  setInProgressToLocalStorage,
} from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';
import { useDispatch, useSelector } from 'react-redux';
import BtnMore from './_components/BtnMore';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';
import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';
import styled from 'styled-components';

/** 학생 대시보드 페이지의 "Upcoming Test" section 컴포넌트 */
export default function UpcomingTestSection({
  setLoading,
  userExpectTestList,
  setUserExpectTestList,
  userInProgressTestList,
  setUserInProgressTestList,
  userOnlySubjectTestList,
  dateValue,
  setAlertLayerPopup,
  getTodayDate,
  tResultSeqRef,
}) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 //////////
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const userInfo = request.tokenDecoder();
  let windowManager = SessionStorage.getItemJsonParse('windowManager');
  /** 지난 시험 시간 */
  const timeDiff = getTimeDiffFromLocalStorage();
  /** 진입 시간 */
  const entryTime = getTimeEntryTimeLocalStorage();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** exam 페이지가 열려있는지 확인하는 Ref ( 기존 자식창이 새로고침 등의 이유로 연결이 끊겼을때를 대비 ) */
  const childWindowRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////////
  /** 시험 쉬는 시간 창 닫을 때 핸들러 (시험 일정 수정 API) */
  const breakTimeClose = (uthSeq) => {
    /** 남은 시간 (실시간) */
    const restTime = getRestTimeFromLocalStorage();
    console.log('대시보드 시험 쉬는 시간 창 닫을 때 핸들러 (시험 일정 수정 API) restTime :', restTime);
    let params = {
      remainingBreakTime: restTime,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        getTodayDate(userInfo.userSeq, userInfo.ecSeq, true);

        SessionStorage.setItem('windowManager', 'popupOn', false);
      }
    };

    request
      .put(`/api/exam/userTestHistory/${uthSeq}`, params, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 쉬는 시간 창 닫을 때 핸들러 (시험 일정 수정 API) 실패', error));
  };

  /** 시험 창(영어 or 수학 시험) 닫을 경우 실행 */
  const testWindowClose = () => {
    /** 남은 시간 (실시간) */
    const restTime = getRestTimeFromLocalStorage();
    console.log('대시보드 시험 창(영어 or 수학 시험) 닫을 경우 실행 ');

    let params = {
      remainingTime: restTime,
      actionMode: 'exitQuestion',
      entryTime: entryTime - timeDiff,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        getTodayDate(userInfo.userSeq, userInfo.ecSeq, true);

        SessionStorage.setItem('windowManager', 'popupOn', false);
      }
    };

    request
      .put(`/api/exam/exitResultHistory/${tResultSeqRef?.current}`, params, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 창(영어 or 수학 시험) 닫을 경우 핸들러 (시험 일정 수정 API) 실패', error));
  };

  /** 시험 "Start" 버튼 핸들러 */
  const testStart = (uthSeq, testName, testStartDate) => {
    windowManager = SessionStorage.getItemJsonParse('windowManager');

    if (windowManager?.popupOn === true) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Test is already in progress. Please finish the exam.',
        };
      });
    } else {
      // 열려있는 시험 창이 있을경우 해당 창을 닫음
      if (childWindowRef.current !== null) childWindowRef.current.close();

      SessionStorage.setItem('windowManager', 'popupOn', true);

      LocalStorage.setItem('sendParams', 'uthSeq', uthSeq);
      LocalStorage.setItem('sendParams', 'testName', testName);
      LocalStorage.setItem('sendParams', 'testDate', testStartDate);

      // _blank
      const popupStartWindow = window.open(
        window.location.origin + '/exam/dsat/intro',
        'dsat',
        `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
      );

      // 새로운 자식창을 참조할 수 있도록 Ref에 저장
      childWindowRef.current = popupStartWindow;

      popupStartWindow.addEventListener('unload', () => {
        if (popupStartWindow.location.href.includes('breaktime')) breakTimeClose(uthSeq);
        else if (popupStartWindow.location.href.includes('rw') || popupStartWindow.location.href.includes('math')) testWindowClose();
        else if (
          popupStartWindow.location.href.includes('intro') ||
          popupStartWindow.location.href.includes('tutorial') ||
          popupStartWindow.location.href.includes('completion')
        )
          SessionStorage.setItem('windowManager', 'popupOn', false);
      });

      // 자식창(시험창)에 유효한 시험 창이라는 메세지 전달 - URL 접근 막기
      popupStartWindow.addEventListener('load', () => {
        console.log('Sending message to child window');
        popupStartWindow.postMessage({ validAccess: true }, '*');
      });

      // added by psk 20240119 - 부모창 벗어났을 때 팝업창 닫기
      window.onunload = () => {
        popupStartWindow.close();

        SessionStorage.setItem('windowManager', 'popupOn', false);
      };
    }
  };

  /** 시험 "In Progress" 버튼 핸들러 */
  const resumeExam = (uthSeq, testStatus, remainingBreakTime) => {
    windowManager = SessionStorage.getItemJsonParse('windowManager');

    if (windowManager?.popupOn === true) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Test is already in progress. Please finish the exam.',
        };
      });
    } else {
      // 열려있는 시험 창이 있을경우 해당 창을 닫음
      if (childWindowRef.current !== null) childWindowRef.current.close();

      SessionStorage.setItem('windowManager', 'popupOn', true);

      LocalStorage.setItem('sendParams', 'uthSeq', uthSeq);
      // LocalStorage.setItem('sendParams', 'remainingBreakTime', remainingBreakTime);

      LocalStorage.setItem('sendTime', 'time', moment());

      let popupInProgressWindow;

      if (testStatus === 'I') {
        popupInProgressWindow = window.open(
          window.location.origin + '/exam/dsat/inprogress',
          'dsat',
          `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
        );
      } else {
        console.log('🚨 In Progress remainingBreakTime :', remainingBreakTime, '🚨');
        setInProgressToLocalStorage({ inProgressTime: remainingBreakTime });
        popupInProgressWindow = window.open(
          window.location.origin + '/exam/dsat/breaktime',
          'dsat',
          `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
        );

        let currentExamInfo = stateExamInfo.currentTest;
        currentExamInfo = { ...currentExamInfo, mm1: 'R', mm2: 'R' };

        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'currentTest', data: { ...currentExamInfo } },
        });
      }

      // 새로운 자식창을 참조할 수 있도록 Ref에 저장
      childWindowRef.current = popupInProgressWindow;

      popupInProgressWindow.addEventListener('unload', () => {
        if (popupInProgressWindow.location.href.includes('breaktime')) breakTimeClose(uthSeq);
        else if (popupInProgressWindow.location.href.includes('rw') || popupInProgressWindow.location.href.includes('math')) testWindowClose();
        else if (
          popupInProgressWindow.location.href.includes('intro') ||
          popupInProgressWindow.location.href.includes('tutorial') ||
          popupInProgressWindow.location.href.includes('completion')
        )
          SessionStorage.setItem('windowManager', 'popupOn', false);
      });

      // 자식창(시험창)에 유효한 시험 창이라는 메세지 전달 - URL 접근 막기
      popupInProgressWindow.addEventListener('load', () => {
        console.log('Sending message to child window');
        popupInProgressWindow.postMessage({ validAccess: true }, '*');
      });

      // added by psk 20240119 - 부모창 벗어났을 때 팝업창 닫기
      window.onunload = () => {
        popupInProgressWindow.close();

        SessionStorage.setItem('windowManager', 'popupOn', false);
      };
    }
  };

  /** 시험 "수학 주관식" 문제 만 풀 수 있는 버튼 핸들러 */
  const handleTestStartOnlyMathSubjective = (uthSeq, testName, testStartDate) => {
    windowManager = SessionStorage.getItemJsonParse('windowManager');

    if (windowManager?.popupOn === true)
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Test is already in progress. Please finish the exam.',
        };
      });
    else {
      // 열려있는 시험 창이 있을경우 해당 창을 닫음
      if (childWindowRef.current !== null) childWindowRef.current.close();

      SessionStorage.setItem('windowManager', 'popupOn', true);

      LocalStorage.setItem('sendParams', 'uthSeq', uthSeq);
      LocalStorage.setItem('sendParams', 'testName', testName);
      LocalStorage.setItem('sendParams', 'testDate', testStartDate);

      /** _blank (수학 주관식 문제만 풀 수 있는 페이지) */
      const popupStartWindow = window.open(
        window.location.origin + '/exam/dsat/mathSubjective',
        'dsat',
        `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
      );

      // 새로운 자식창을 참조할 수 있도록 Ref에 저장
      childWindowRef.current = popupStartWindow;

      popupStartWindow.addEventListener('unload', () => {
        if (popupStartWindow.location.href.includes('math')) testWindowClose();
        else if (
          popupStartWindow.location.href.includes('intro') ||
          popupStartWindow.location.href.includes('tutorial') ||
          popupStartWindow.location.href.includes('completion')
        )
          SessionStorage.setItem('windowManager', 'popupOn', false);
      });

      // 자식창(시험창)에 유효한 시험 창이라는 메세지 전달 - URL 접근 막기
      popupStartWindow.addEventListener('load', () => {
        console.log('Sending message to child window');
        popupStartWindow.postMessage({ validAccess: true }, '*');
      });

      // added by psk 20240119 - 부모창 벗어났을 때 팝업창 닫기
      window.onunload = () => {
        popupStartWindow.close();

        SessionStorage.setItem('windowManager', 'popupOn', false);
      };
    }
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////////

  return (
    <article className='com_list scroll'>
      <table className='list'>
        <colgroup>
          <col width='100px' />
          <col />
          <col width='100px' />
          <col width='115px' />
        </colgroup>
        <thead className='sticky top'>
          <tr>
            <th>No.</th>
            <th>Test</th>
            <th>Date</th>
            <th>Test start</th>
          </tr>
        </thead>
        <tbody>
          {(userExpectTestList == null || userExpectTestList?.length) < 1 &&
          (userInProgressTestList == null || userInProgressTestList?.length < 1) &&
          (userOnlySubjectTestList == null || userOnlySubjectTestList?.length < 1) ? (
            <tr>
              <td colSpan='4'>You do not have any scheduled tests</td>
            </tr>
          ) : (
            <>
              {userInProgressTestList !== null &&
                userInProgressTestList?.map((item, index) => {
                  return (
                    <S.Tr
                      key={`uit-${index}`}
                      className={moment(dateValue).isBetween(moment(item.testStartDate), moment(item.testEndDate), undefined, []) ? 'active' : ''}>
                      <td>
                        <S.TableTdInnerBox>
                          {item.ecSeq === 0 && item.useAt == null && item.testStatus === 'E' && (
                            <BtnMore
                              item={item}
                              setUserExpectTestList={setUserExpectTestList}
                              setUserInProgressTestList={setUserInProgressTestList}
                              setLoading={setLoading}
                              className='btn-more'
                            />
                          )}
                          {index + 1}
                        </S.TableTdInnerBox>
                      </td>
                      <td>
                        <S.TableTdInnerBox>
                          {userInfo?.academyUpFileSeq >= 0 && item.ecSeq === 0 && (
                            <Tooltip01 text='This is a test you have set for yourself.'>
                              <ImageSVGIconStar />
                            </Tooltip01>
                          )}
                          {item.testName}
                        </S.TableTdInnerBox>
                      </td>
                      {moment(dateValue).isBetween(moment(item.testStartDate), moment(item.testEndDate), undefined, []) ? (
                        <>
                          <td className='today'>
                            {moment(item.testStartDate).isSame(moment(dateValue), 'day') ? 'TODAY' : moment(item.testStartDate).format('YYYY-MM-DD')}
                          </td>
                          <td>
                            {userInfo.userLevel === USER_LEVELS.STUDENT && (
                              <button
                                className='com_btn s red'
                                onClick={() => {
                                  resumeExam(item.uthSeq, item.testStatus, item.remainingBreakTime);
                                }}>
                                IN PROGRESS
                              </button>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td> {moment(item.testStartDate).format('YYYY-MM-DD')} </td>
                          <td></td>
                        </>
                      )}
                    </S.Tr>
                  );
                })}
              {userExpectTestList !== null &&
                userExpectTestList.map((item, index) => {
                  return (
                    <S.Tr
                      key={`uet-${index}`}
                      className={moment(dateValue).isBetween(moment(item.testStartDate), moment(item.testEndDate), 'minutes', []) ? 'active' : ''}>
                      <td>
                        <S.TableTdInnerBox>
                          {item.ecSeq === 0 && item.useAt == null && (
                            <BtnMore
                              item={item}
                              setUserExpectTestList={setUserExpectTestList}
                              setUserInProgressTestList={setUserInProgressTestList}
                              setLoading={setLoading}
                            />
                          )}
                          {index + userInProgressTestList.length + 1}{' '}
                        </S.TableTdInnerBox>
                      </td>
                      <td>
                        <S.TableTdInnerBox>
                          {userInfo?.academyUpFileSeq >= 0 && item.ecSeq === 0 && (
                            <Tooltip01 text='This is a test you have set for yourself.'>
                              <ImageSVGIconStar />
                            </Tooltip01>
                          )}
                          {item.testName}
                        </S.TableTdInnerBox>
                      </td>
                      {moment(dateValue).isBetween(moment(item.testStartDate), moment(item.testEndDate), 'minutes', []) ? (
                        <>
                          <td className='today'>
                            {moment(item.testStartDate).isSame(moment(dateValue), 'day') ? 'TODAY' : moment(item.testStartDate).format('YYYY-MM-DD')}
                          </td>
                          <td>
                            {userInfo.userLevel === USER_LEVELS.STUDENT && (
                              <button className='com_btn s black' onClick={() => testStart(item.uthSeq, item.testName, item.testStartDate)}>
                                Start
                              </button>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td> {moment(item.testStartDate).format('YYYY-MM-DD')} </td>
                          <td></td>
                        </>
                      )}
                    </S.Tr>
                  );
                })}
              {userOnlySubjectTestList
                ? userOnlySubjectTestList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td></td>
                        <td>{item.testName}</td>
                        <td className='today'>{moment().format('MMMM DD, YYYY')}</td>
                        <td>
                          {userInfo.userLevel === USER_LEVELS.STUDENT && (
                            <button className='com_btn s black' onClick={() => handleTestStartOnlyMathSubjective(userOnlySubjectTestList[0].uthSeq)}>
                              Start
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </>
          )}
        </tbody>
      </table>
    </article>
  );
}

const S = {};

/** Styled-component : <td> 태그 안에서 정렬 용 */
S.TableTdInnerBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

/** hover 용 */
S.Tr = styled.tr`
  &:hover {
    .btn-more {
      visibility: visible;
    }
  }
`;
