import React from 'react';

/** 학생 대시보드 페이지의 "Advanced Report" Section 의 "Correct answer rate by Categories(수학)" 영역 컴포넌트 */
export default function CorrectAnswerRateByCategoriesMa({ barChartData2, activeSatBox, handleClickGraphItemField, testResult }) {
  const barChartData2Avg = barChartData2[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / barChartData2[0].data.length;

  return (
    <article className={`box_graph per50 ${activeSatBox === 'math' ? 'active' : 'disable'}`} onClick={() => handleClickGraphItemField('math')}>
      {/*비활성화시 addClass disable*/}
      <div className='top_area'>
        <p className='tit_field'>Correct answer rate by Categories</p>
        <div className='type_field'>
          <p className='name'>MATH</p>
          <p className='data'>{testResult.subject !== 'E' ? `${barChartData2Avg.toFixed(2)}%` : '-'}</p>
        </div>
      </div>
      <div className='content_area'>
        {/* alg */}
        <div className='com_answer_grah'>
          <div className='item type01'>
            <p className='tit_field'>
              <i className='color'>&nbsp;</i>Alg
            </p>
            <div className='grah_field'>
              <div className='graph_wrap' style={{ width: `${barChartData2[0].data[0]}%` }}>
                <div
                  className={`rate my ${barChartData2[0].data[0] < barChartData2[0].gatePlusData[0] ? 'low' : ''} ${
                    barChartData2[0].data[0] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  <p className='num'>{barChartData2[0].data[0]}%</p>
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>Algebra :&nbsp;
                  <b>{barChartData2[0].data[0]}%</b>
                </div>
              </div>
              <div
                className='graph_wrap'
                style={{
                  width: `${
                    barChartData2[0].data[0] === barChartData2[0].gatePlusData[0]
                      ? `calc(${barChartData2[0].data[0]} + 10px)%`
                      : `${barChartData2[0].gatePlusData[0]}%`
                  }`,
                }}>
                <div
                  className={`rate average ${barChartData2[0].data[0] > barChartData2[0].gatePlusData[0] ? 'low' : ''} ${
                    barChartData2[0].gatePlusData[0] === 0 ? 'zero' : ''
                  }`}>
                  {/*수치가 더 낮은 rate에 addClass low*/}
                  <div className='bar'>&nbsp;</div>
                  {/* <p className="num">28.57%</p> */}
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                  <b>{barChartData2[0].gatePlusData[0]}%</b>
                </div>
              </div>
              <div className='bar'>&nbsp;</div>
            </div>
          </div>
          {/* am */}
          <div className='item type02'>
            <p className='tit_field'>
              <i className='color'>&nbsp;</i>AM
            </p>
            <div className='grah_field'>
              <div className='graph_wrap' style={{ width: `${barChartData2[0].data[1]}%` }}>
                <div
                  className={`rate my ${barChartData2[0].data[1] < barChartData2[0].gatePlusData[1] ? 'low' : ''} ${
                    barChartData2[0].data[1] === 0 ? 'zero' : ''
                  }`}>
                  {/*수치가 더 낮은 rate에 addClass low*/}
                  <div className='bar'>&nbsp;</div>
                  <p className='num'>{barChartData2[0].data[1]}%</p>
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>Advanced Math :&nbsp;
                  <b>{barChartData2[0].data[1]}%</b>
                </div>
              </div>
              <div
                className='graph_wrap'
                style={{
                  width: `${
                    barChartData2[0].data[1] === barChartData2[0].gatePlusData[1]
                      ? `calc(${barChartData2[0].data[1]} + 10px)%`
                      : `${barChartData2[0].gatePlusData[1]}%`
                  }`,
                }}>
                <div
                  className={`rate average ${barChartData2[0].data[1] > barChartData2[0].gatePlusData[1] ? 'low' : ''} ${
                    barChartData2[0].gatePlusData[1] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  {/* <p className="num">54%</p> */}
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                  <b>{barChartData2[0].gatePlusData[1]}%</b>
                </div>
              </div>
              <div className='bar'>&nbsp;</div>
            </div>
          </div>
          {/* psada */}
          <div className='item type03'>
            <p className='tit_field'>
              <i className='color'>&nbsp;</i>PSADA
            </p>
            <div className='grah_field'>
              <div className='graph_wrap' style={{ width: `${barChartData2[0].data[2]}%` }}>
                <div
                  className={`rate my ${barChartData2[0].data[2] < barChartData2[0].gatePlusData[2] ? 'low' : ''} ${
                    barChartData2[0].data[2] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  <p className='num'>{barChartData2[0].data[2]}%</p>
                </div>
                <div className={`layer ${barChartData2[0].data[2] > 50 ? 'psada-layer' : ''}`}>
                  <i className='color'>&nbsp;</i>Problem-Solving and Data Analysis :&nbsp;<b>{barChartData2[0].data[2]}%</b>
                </div>
              </div>
              <div
                className='graph_wrap'
                style={{
                  width: `${
                    barChartData2[0].data[2] === barChartData2[0].gatePlusData[2]
                      ? `calc(${barChartData2[0].data[2]} + 10px)%`
                      : `${barChartData2[0].gatePlusData[2]}%`
                  }`,
                }}>
                <div
                  className={`rate average ${barChartData2[0].data[2] > barChartData2[0].gatePlusData[2] ? 'low' : ''} ${
                    barChartData2[0].gatePlusData[2] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  {/* <p className="num">70%</p> */}
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                  <b>{barChartData2[0].gatePlusData[2]}%</b>
                </div>
              </div>
              <div className='bar'>&nbsp;</div>
            </div>
          </div>
          {/* gat */}
          <div className='item type04'>
            <p className='tit_field'>
              <i className='color'>&nbsp;</i>GAT
            </p>
            <div className='grah_field'>
              <div className='graph_wrap' style={{ width: `${barChartData2[0].data[3]}%` }}>
                <div
                  className={`rate my ${barChartData2[0].data[3] < barChartData2[0].gatePlusData[3] ? 'low' : ''} ${
                    barChartData2[0].data[3] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  <p className='num'>{barChartData2[0].data[3]}%</p>
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>Geometry and Trigonometry :&nbsp;<b>{barChartData2[0].data[3]}%</b>
                </div>
              </div>
              <div
                className='graph_wrap'
                style={{
                  width: `${
                    barChartData2[0].data[3] === barChartData2[0].gatePlusData[3]
                      ? `calc(${barChartData2[0].data[3]} + 10px)%`
                      : `${barChartData2[0].gatePlusData[3]}%`
                  }`,
                }}>
                {/*평균과 내 수치가 같을 경우  width = 수치+10px*/}
                <div
                  className={`rate average ${barChartData2[0].data[3] > barChartData2[0].gatePlusData[3] ? 'low' : ''} ${
                    barChartData2[0].gatePlusData[3] === 0 ? 'zero' : ''
                  }`}>
                  <div className='bar'>&nbsp;</div>
                  {/* <p className="num">25%</p> */}
                </div>
                <div className='layer'>
                  <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
                  <b>{barChartData2[0].gatePlusData[3]}%</b>
                </div>
              </div>
              <div className='bar'>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
