// Packages
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// Constants
import { GLOBAL_BREAKTIME_TIME, GLOBAL_MA_TEST_TIME } from '../_utils/constants/examTimeSetting';
// Assets
import noInternetIcon from 'assets/img/svg/no_internet_icon.svg';
// Components
import ImageModal from 'components/_common/modals/ImageModal';
import LoadingBar from 'utils/LoadingBar';
import Timer from '../_components/timer/Timer';
// Functions
import { nvl, nvlNumber } from 'utils/Common.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';
//Hooks
import useOnlineStatus from 'hooks/useOnlineStatus';
import useCheckBrowserVisibility from '../_hooks/useCheckBrowserVisibility';
import { TOTAL_QUESTION_NUMBER_MATH } from '../_utils/constants/totalQuestionNumberSetting';
import {
  deleteInProgressToLocalStorage,
  getFullTimeFromLocalStorage,
  getRestTimeFromLocalStorage,
  getTimeInProgressLocalStorage,
  setFullTimeToLocalStorage,
  setRestTimeToLocalStorage,
  deleteLocalStorageTime,
} from '../_utils/functions/timerLocalStorageFunctions';

/** 수학 시험 (모듈 1) 시간 변수 */
let MA_TEST_MODULE_1_TIME = GLOBAL_MA_TEST_TIME;

/** 쉬는 시간 렌더링 컴포넌트 */
export default function ExamBreakTimePage() {
  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지

  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////////
  /** 오프라인 모달 정보 */
  const offlineModalInfo = {
    modalImage: noInternetIcon,
    alertTitle: 'Unstable network connection',
    alertText:
      'Your network connection is low or unstable.\nTest window will be closed, but all your work will be saved.\nYou can continue your test when your network becomes\nstable.',
    buttonTextAndOnClick: [{ text: 'OK', onClick: () => window.close() }],
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** 사용자 정보 */
  const userInfo = request.tokenDecoder();
  /** 시험 정보 전역 상태 */
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  /** 온/오프라인 확인 */
  const isOnline = useOnlineStatus();
  /** 로컬 스토리지 : sendParams */
  const sendParams = LocalStorage.getItemJsonParse('sendParams');
  /** 전체 시간 */
  const fullTime = getFullTimeFromLocalStorage();
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();
  /** 재 접속 시, 받아 온 이전 시험의 남은 시간 */
  const inProgressTime = getTimeInProgressLocalStorage();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** useEffect 용 상태 */
  const [examMain, setExamMain] = useState({
    filterCompletionBool: false,
    completionBool: false,
    examMathStateBool: false,
  });
  /** 로딩 상태 : 문제 목록 및 점수 목록 API */
  const [questionLoading, setQuestionLoading] = useState(false);
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 로컬 스토리지 초기 설정 완료 여부 */
  const [isCompleteInitLocalStorage, setIsCompleteInitLocalStorage] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 제출 시간 (다음 모듈로 넘어 갈 때, 시간을 다 썼을 때, 쉬는 시간 끝났 을 때, 시험 창 재진입 할 때 설정 됨) */
  const sendTimeRef = useRef(LocalStorage.getItemJsonParse('sendTime'));
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 실행문 시작 ////////////
  useCheckBrowserVisibility();
  /////////////////// 외부 패키지 및 기타 React Hook 실행문 끝 ////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 얻어 온 문제 필터링 */
  const getQuestionListFilter = (questionList) => {
    questionList != null &&
      questionList.length > 0 &&
      questionList.map((outerItem, outerIndex) => {
        const parsedInfo = JSON.parse(outerItem?.customSetQuestionInfo);
        /** 전역 상태에 새로 추가 할 문제 */
        const filteredInfo = parsedInfo
          .filter((innerItem) => {
            return innerItem != null && innerItem.module_subject === 'M' && parseInt(innerItem.module_num) === 1;
          })
          .map((innerItem, innerIndex) => {
            return {
              customSetSeq: innerItem.custom_set_seq,
              setUniqueCode: innerItem.set_unique_code,
              testName: innerItem.test_name,
              setSubject: innerItem.set_subject,
              setDifficulty: innerItem.set_difficulty,
              setRegUserSeq: innerItem.set_reg_user_seq,
              setRegDate: innerItem.set_reg_date,
              setModiUserSeq: innerItem.set_modi_user_seq,
              setModiDate: innerItem.set_modi_date,
              testModuleSeq: innerItem.test_module_seq,
              moduleUniqueCode: innerItem.module_unique_code,
              moduleSubject: innerItem.module_subject,
              moduleNum: parseInt(innerItem.module_num),
              moduleDifficulty: innerItem.module_difficulty,
              qmhSeq: innerItem.qmh_seq,
              questionSeq: innerItem.question_seq,
              multipleChoiceItems1: innerItem.multiple_choice_items1,
              multipleChoiceItems2: innerItem.multiple_choice_items2,
              multipleChoiceItems3: innerItem.multiple_choice_items3,
              multipleChoiceItems4: innerItem.multiple_choice_items4,
              questionSubject: innerItem.question_subject,
              testHow: innerItem.test_how,
              questionFormat: innerItem.question_format,
              questionDifficulty: innerItem.question_difficulty,
              fieldOfStudy: innerItem.field_of_study,
              researcher: innerItem.researcher,
              passage: innerItem.passage,
              question: innerItem.question,
              tryAnswer: '',
              annotation: [
                {
                  annotationStart: 0,
                  annotationEnd: 0,
                  selected_words: '',
                  annotationContent: innerItem.annotation_content,
                },
              ],
            };
          });

        dispatch({
          type: 'setExamQuestionList',
          payload: filteredInfo,
        });
      });

    dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: 1 } });

    setExamMain((prev) => {
      return { ...prev, filterCompletionBool: true };
    });
  };

  /** 문제 목록 불러오기 API 요청 */
  const getQuestionList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.customSetFullInfo;

        getQuestionListFilter(questionList);
      } else if (response.code === 403) {
        // navigate("/student/dashboard");
      }
      setQuestionLoading(false);
    };

    request
      .get(
        `/api/exam/customSetFullInfo?uthSeq=${nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq}&tmSubject=M&moduleNum=1`,
        null,
        successHandler
      )
      .catch((error) => {
        console.error(error);
        alert('쉬는 시간 - 문제 목록 불러오기 API 응답 실패');
      });
  };

  /** 과목별 점수 계산 API 요청 */
  const scoreInsert = () => {
    let params = {
      // uthSeq: stateExamInfo.uthSeq,
      uthSeq: nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq,
      em2d: stateExamInfo.currentTest.em2d,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        getQuestionList();
      }
    };

    request.post('/api/exam/insertPoint', params, successHandler).catch((error) => {
      console.error(error);
      alert('쉬는 시간 - 과목별 점수 계산 API 응답 실패');
    });
  };

  /** 시험 쉬는 시간 창 열 때, 시험 일정 수정 API 요청 */
  const breakTimeOpen = (currentTime) => {
    let params = {
      testStatus: 'B',
      remainingBreakTime: currentTime,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        window.opener.parentLoadData();
        LocalStorage.setItem('sendParams', 'testStatus', 'B');
      }
    };

    request
      .put(`/api/exam/userTestHistory/${nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq}`, params, successHandler)
      .catch((error) => {
        console.error(error);
        alert('쉬는 시간 - 시험 일정 수정 API 응답 실패');
      });
  };

  /** 채점 된 것이 있는 지 확인 API 요청 */
  const getScore = () => {
    setQuestionLoading(true);
    let params = {
      // uthSeq: stateExamInfo.uthSeq,
      uthSeq: nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq,
      em2d: stateExamInfo.currentTest.em2d,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let scoreCnt = response.result.scoreCnt;

        if (scoreCnt > 0) {
          setExamMain((prev) => {
            return { ...prev, filterCompletionBool: true };
          });
          // 이미 채점함
          getQuestionList();
        } else {
          scoreInsert();
          // 방금 채점함
        }
      }
    };

    request
      .get(`/api/exam/score/exist?uthSeq=${nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq}`, params, successHandler)
      .catch((error) => {
        console.error(error);
        alert('쉬는 시간 - "채점 된 것이 있는 지 확인" API 응답 실패');
      });
  };

  /** 시험 초기 값 저장 API 요청 */
  const testInit = (uthSeq) => {
    setIsLoading(true);
    let paramsList = [];
    let params = {};

    for (let i = 0; i < TOTAL_QUESTION_NUMBER_MATH; i++) {
      params = {
        uthSeq: uthSeq,
        questionSeq: stateExamInfo.questionList[i]?.questionSeq,
        testModuleSeq: stateExamInfo.questionList[i]?.testModuleSeq,
        remainingTime: MA_TEST_MODULE_1_TIME,
        regUserSeq: userInfo?.userSeq,
      };

      const meta = {
        question_subject: stateExamInfo.questionList[i]?.questionSubject,
        question_format: stateExamInfo.questionList[i]?.questionFormat,
        question_difficulty: stateExamInfo.questionList[i]?.questionDifficulty,
      };

      params.metaData = JSON.stringify(meta);

      paramsList.push(params);
    }

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: 1 } });

        testReady();

        setExamMain((prev) => {
          return { ...prev, examInitBool: true };
        });
      }
    };

    request.post('/api/exam/resultHistoryList', paramsList, successHandler).catch((error) => {
      console.error(error);
      alert('쉬는 시간 - 시험 초기 값 저장 API 응답 실패');
    });
  };

  /** 시험 일정 수정 API 요청 */
  const testReady = () => {
    let params = {
      testStatus: 'I',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let currentExamInfo = stateExamInfo.currentTest;
        currentExamInfo = { ...currentExamInfo, mm1: 'I' };

        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });
        setIsLoading(false);

        setExamMain((prev) => {
          return { ...prev, examMathStateBool: true };
        });
      }
    };

    request
      .put(`/api/exam/userTestHistory/${nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq}`, params, successHandler)
      .catch((error) => {
        console.error(error);
        alert('쉬는 시간 - 시험 일정 수정 API 응답 실패');
      });
  };

  /** "resumeTest" 버튼 클릭 핸들러 */
  const resumeTestBtn = () => {
    if (!questionLoading) {
      testInit(nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq);

      dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: true } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });

      deleteLocalStorageTime();

      setExamMain((prev) => {
        return { ...prev, completionBool: true };
      });
    }
  };

  /** 타이머 종료 핸들러 */
  const timeOver = () => {
    console.log('쉬는 시간 타임 오버');
    if (!questionLoading) {
      testInit(nvlNumber(sendParams.uthSeq) > 0 ? sendParams.uthSeq : stateExamInfo.uthSeq);

      dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: true } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });
      deleteLocalStorageTime();

      setExamMain((prev) => {
        return { ...prev, completionBool: true };
      });
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (inProgressTime) {
      console.log('inProgress 로 재 접속한');
      // inProgress 로 재 접속한 시험인가?
      setFullTimeToLocalStorage({ fullTime: inProgressTime });
      setRestTimeToLocalStorage({ restTime: inProgressTime });
      deleteInProgressToLocalStorage();
    } else {
      // Start 로 최초 접속한 시험인가?
      if (!fullTime) {
        console.log('쉬는 시간 최초');
        breakTimeOpen(fullTime);
        setFullTimeToLocalStorage({ fullTime: GLOBAL_BREAKTIME_TIME });
        setRestTimeToLocalStorage({ restTime: GLOBAL_BREAKTIME_TIME });
      } else {
        console.log('쉬는 시간 새로고침');
        // 최초가 아닌 경우 (새로 고침), 타이머 설정
        setFullTimeToLocalStorage({ fullTime: restTime });
      }
    }

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인

    if (parseInt(sendParams.uthSeq) !== stateExamInfo.uthSeq && nvlNumber(sendParams.uthSeq) > 0)
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'uthSeq', data: sendParams.uthSeq } });

    if (
      userInfo != null &&
      nvl(userInfo?.userId) !== '' &&
      (nvlNumber(sendParams?.uthSeq) > 0 || nvlNumber(stateExamInfo?.uthSeq) > 0) &&
      stateExamInfo['currentTest'].mm1 === 'R'
    ) {
      getScore();
    } else if (
      userInfo != null &&
      nvl(userInfo?.userId) !== '' &&
      nvlNumber(stateExamInfo?.uthSeq) > 0 &&
      (stateExamInfo['currentTest'].mm1 === 'I' || stateExamInfo['currentTest'].mm2 === 'I')
    ) {
      navigate('/exam/dsat/math', {
        state: { currentNum: stateExamInfo.currentNum },
        replace: true,
      });
    } else {
      // navigate("/student/dashboard");
    }

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (examMain.filterCompletionBool && examMain.completionBool && examMain.examMathStateBool) {
      LocalStorage.clearItem('sendParams'); // breaktime 벗어낫을 때 전달 파라메터 날리기
      LocalStorage.setItem('sendTime', 'time', moment());

      window.opener.parentLoadData();
      // 시간 여기서 넣기

      navigate('/exam/dsat/math', {
        state: { currentNum: 1 },
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examMain.filterCompletionBool, examMain.completionBool, examMain.examMathStateBool]);

  useEffect(() => {
    if (restTime !== null) {
      setIsCompleteInitLocalStorage(true);
    } else {
      setIsCompleteInitLocalStorage(false);
    }
  }, [restTime]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='com_popup active pop_exam_break' onContextMenu={(e) => e.preventDefault()}>
        <div className='pop_container'>
          <section className='pop_body'>
            <article className='time_sec'>
              <div className='time_area'>
                <p className='tit'>Remaining Break Time:</p>
                {isCompleteInitLocalStorage && (
                  <div className='time'>
                    <Timer timeOver={timeOver} sendTimeRef={sendTimeRef} />
                  </div>
                )}
              </div>
              <div className='com_btn_wrap bottom'>
                <button className='com_btn yellow l oval' onClick={() => resumeTestBtn()} disabled={questionLoading}>
                  Resume Testing
                </button>
              </div>
            </article>
            <article className='info_sec'>
              <dl className='item'>
                <dt className='dt'>Test Break</dt>
                <dd className='dd'>
                  You can resume this test as soon as you’re ready to move on. On test day, you’ll wait until the clock counts down. Read below to see how
                  breaks work on test day.
                </dd>
              </dl>
              <dl className='item'>
                <dt className='dt'>Take a Break: Do Not Close Your Device</dt>
                <dd className='dd'>You may leave the room, but do not disturb students who are still testing.</dd>
                <dd className='dd'>Testing won’t resume until you return.</dd>
                <dd className='dd'>
                  <b>Follow these rules during the break:</b>
                </dd>
                <dd className='dd'>
                  <ul className='list'>
                    <li>Do not access your phone, smartwatch, textbooks, notes, or the internet.</li>
                    <li>Do not eat or drink in the test room. </li>
                    <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                  </ul>
                </dd>
              </dl>
            </article>
          </section>
          <section className='pop_breaktime_footer'>
            <p className='name'>{nvl(userInfo?.userName)}</p>
          </section>
        </div>
      </div>
      <ImageModal
        modalImage={offlineModalInfo.modalImage}
        buttonTextAndOnClick={offlineModalInfo.buttonTextAndOnClick}
        alertTitle={offlineModalInfo.alertTitle}
        alertText={offlineModalInfo.alertText}
        showModal={!isOnline}
      />
      {isLoading && <LoadingBar />}
    </>
  );
}
