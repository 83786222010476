import { useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import dayjsTZ, { convertDayjsTZ } from 'utils/functions/time/dayjs-config';

/**
 * 클래스 편집 모달에 있는 캘린더 컴포넌트
 * @description
 * 🔍 검색 키워드 - #Calendar #com/tests/scheduled
 */
function EditModalCalendar({ setAlertLayerPopup, selectedDateState, setSelectedDateState }) {
  /** 캘린더에서만 보여줄 State */
  const [onlyCalendarDate, setOnlyCalendarDate] = useState([
    selectedDateState[0] ? convertDayjsTZ(selectedDateState[0], 'local').toDate() : null,
    selectedDateState[1] ? convertDayjsTZ(selectedDateState[1].startOf('day'), 'local').toDate() : null,
  ]);
  /** 날짜 선택 */
  const handleDateChange = (selectedDate, noTZDate) => {
    const selectedDateTZ = dayjsTZ(selectedDate);
    // 만약 selectedDateState가 비어있거나(null), 둘 다 값이 있을 경우 ( 새로운 날짜 선택 )
    if (selectedDateState[0] === null || selectedDateState[1] !== null) {
      setSelectedDateState([selectedDateTZ, null]);
      setOnlyCalendarDate([noTZDate, null]);

      // 만약 selectedDateState의 첫번째 값이 있을 경우 ( 두번째 날짜 선택 )
    } else {
      const differenceInDays = selectedDate.diff(selectedDateState[0], 'days');

      // 선택된 날짜가 오늘보다 이전일 경우 ( 과거로 시험 세팅 경고 )
      if (dayjsTZ() >= selectedDateTZ) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'Scheduling exams for past dates is not allowed. <br/> Please try again. .',
          };
        });
      } else if (differenceInDays <= 7) {
        setSelectedDateState([selectedDateState[0], selectedDateTZ.endOf('day')]);
        setOnlyCalendarDate([onlyCalendarDate[0], noTZDate]);
      } else {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'The maximum setting period is 7 days.',
          };
        });

        setSelectedDateState([null, null]);
        setOnlyCalendarDate([null, null]);
      }
    }
  };

  return (
    <article className='com_calendar_wrap'>
      <S.Calendar
        className='calendar'
        value={onlyCalendarDate}
        selectRange={true}
        formatDay={(locale, date) => dayjsTZ(date).format('DD')}
        locale='en-EN'
        tileContent={({ date }) => {
          const tileDate = dayjsTZ(date);
          return (
            <div className='timezoneEl' onClick={() => handleDateChange(tileDate, date)}>
              {tileDate.format('DD')}
            </div>
          );
        }}
      />
    </article>
  );
}

export default EditModalCalendar;

// --- --- --- styled-components --- --- ---
const S = {
  Calendar: styled(Calendar)`
    // 기본 캘린더 스타일 비활성화 ( value와 format이 일치하지 않아 기존 캘린더 안보이게 )
    .react-calendar__tile abbr {
      display: none;
    }
    // 기본 캘린더 스타일 설정
    .react-calendar__tile {
      .timezoneEl {
        border-radius: 100%;
        font-size: 14px;
        font-weight: 600;
        aspect-ratio: 1 / 1;
        width: 38px;
        max-width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;

        &:hover {
          background-color: #e2f3ff;
        }
        &:focus {
          background-color: #e2f3ff;
        }
      }
      // 선택된 날짜 스타일 ( 시작하는 날짜 )
      &.react-calendar__tile--rangeStart {
        overflow: hidden;
        margin-left: 5px;

        .timezoneEl {
          background-color: #1294f2;
          color: #fff !important;
        }
      }
      // 선택된 날짜 스타일 ( 끝나는 날짜 )
      &.react-calendar__tile--rangeEnd {
        overflow: hidden;
        padding-left: 5px;

        .timezoneEl {
          background-color: #1294f2;
          color: #fff !important;
        }
      }
      // 선택된 날짜 스타일 ( 시작과 끝 사이의 날짜 )
      &.react-calendar__tile--active {
        .timezoneEl {
          color: black;
        }
      }
      // 오늘 날짜 스타일
      &.react-calendar__tile--now {
        .timezoneEl {
          background-color: #f2f1f1;
          color: #9e9e9e;
          display: flex;
          flex-direction: column;
          -moz-flex-direction: column;
          display: inline-flex;
          align-items: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          -moz-justify-content: center;
          line-height: 1;
          &:after {
            content: 'TODAY';
            font-size: 6px;
          }
        }
      }
    }
  `,
};
