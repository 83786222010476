import styled from 'styled-components';

import ButtonBase from './_ButtonBase';

/**
 * @component
 * 미니 SVG 아이콘 버튼 컴포넌트
 * @example
 * ```jsx
 *  <MiniSvgIconBtn
 *    svgClassName='svg_icon_클래스_이름'
 *    onClick={() => callBack()}
 *    text='버튼_텍스트'
 *    size="2rem"
 *  />
 * ```
 * @param {string} radius - 버튼의 테두리 둥글기 (default, medium) / default: 'default'
 * @param {string} size - 버튼의 크기 / default: '1.875rem'
 * @param {string} text - 버튼에 보여줄 텍스트
 * @param {string} svgClassName - SVG 아이콘에 적용할 클래스 이름
 * @param {function} onClick - 버튼을 눌렀을 때 실행할 함수
 * @param {string} type - 사용할 버튼스타일 (default, tableBtn 등)
 * @param {object} style - 추가로 적용할 스타일 / default: {}
 * @param {boolean} dataHover - 호버 시 툴팁 표시 여부 / default: false
 * @returns {JSX.Element} 미니 SVG 아이콘 버튼 컴포넌트 반환
 */
function MiniSvgIconBtn({ radius = 'default', size = '1.875rem', text, svgClassName, onClick, type, style = {}, dataHover = false }) {
  return (
    <ButtonBase size={size} text={text} type={type} onClick={onClick} style={style} dataHover={dataHover} radius={radius} icon>
      <i className={svgClassName}>&nbsp;</i>
    </ButtonBase>
  );
}

export default MiniSvgIconBtn;
