import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QuestionArea from './QuestionArea';
import MathQuestion from './MathQuestion';

/** 수학 시험 화면 본문 영역 컴포넌트 */
export default function ExamBody({ tryAnswerRef, isLoading, setIsLoading, dummyData }) {
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });

  const wrapButtonHandler = (arrow) => {
    if (arrow === 'left') {
      if (wrap.leftArrow)
        setWrap((prev) => {
          return { ...prev, leftArrow: false };
        });
      else {
        if (!wrap.leftArrow && wrap.rightArrow)
          setWrap((prev) => {
            return { ...prev, rightArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, leftArrow: !wrap.leftArrow };
          });
      }
    } else {
      if (wrap.rightArrow)
        setWrap((prev) => {
          return { ...prev, rightArrow: false };
        });
      else {
        if (!wrap.rightArrow && wrap.leftArrow)
          setWrap((prev) => {
            return { ...prev, leftArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, rightArrow: !wrap.rightArrow };
          });
      }
    }
  };

  return (
    <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
      {stateExamInfo.questionList[stateExamInfo.currentNum - 1]?.questionFormat !== 'M' && <MathQuestion wrap={wrap} wrapButtonHandler={wrapButtonHandler} />}

      {/* 시험 풀이 영역 */}
      <QuestionArea
        wrap={wrap}
        stateExamInfo={stateExamInfo}
        wrapButtonHandler={wrapButtonHandler}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        tryAnswerRef={tryAnswerRef}
        dummyData={dummyData}
      />
    </section>
  );
}
