import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import request from '../../../../utils/Request.utils.js';
import { USER_LEVELS } from '../../../../utils/constants.js';

// component
import AcademyInformation from '../../_AcademyInformation.jsx';

// hook
import useUserLevels from '../../../../hooks/useUserLevels.js';

export default function AcademyTutorInformation(props) {
  const { ID, setOpenEditModal, userLevel, setLinkPop, ecSeq, mcSeq, setModifiedData, setNoPassword, reloadData, setReloadData, switchAccountInfo } = props;

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userLevel);

  // Edit 타입 코드
  const [academyInfo, setAcademyInfo] = useState([]);
  // Link 타입 코드
  const [linkedAcademy, setLinkedAcademy] = useState([]);

  const changeActiveAcademy = (userSeq, mcSeq) => {
    let params = {
      activateYn: 'Y',
    };

    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        setReloadData((prev) => !prev);
      }
    };

    request.put(`/api/member/profile/company/activate/${userSeq}/${mcSeq}`, params, successHandler);
  };

  useEffect(() => {
    if (useLevels.isTutor || useLevels.isAcademy) {
      // Edit 타입 - 강사, 학원 관리자
      setNoPassword(true);

      const successHandler = (response) => {
        if (response.code !== 200) return;

        const academyData = response.result.academyProfile;

        setAcademyInfo({
          infoMode: 'academy',
          companyName: academyData.companyName,
          regDate: academyData.regDate,
          uniqueCode: academyData.uniqueCode,
          officeNumber: academyData.representativePhone.replace(/-/g, ' - '),
          ecSeq: academyData.ecSeq,
          userLevel: useLevels.isAcademy ? USER_LEVELS.ACADEMY : USER_LEVELS.TUTOR,
          upFileSeq: switchAccountInfo.upFileSeq,
          academyUpFileSeq: switchAccountInfo.upFileSeq,
        });

        setModifiedData([
          { name: 'companyName', value: academyData.companyName },
          { name: 'Registration Date', value: academyData.regDate },
          {
            name: useLevels.isAcademy ? 'Academy Code' : 'Tutor Code',
            value: academyData.uniqueCode,
          },
          { name: 'Office Number', value: academyData.representativePhone },
        ]);
      };

      request.get(`/api/member/profile/academy/${ecSeq}`, null, successHandler);
    } else if (useLevels.isStudent || useLevels.isTeacher) {
      // Link 타입 - 학생, 선생님
      const successHandler = (response) => {
        if (response.code === 200) {
          const academyData = response.result.academyList;

          setLinkedAcademy(
            academyData.map((item) => {
              return {
                activateYn: item.activateYn,
                companyName: item.companyName,
                ecSeq: item.ecSeq,
                mcSeq: item.mcSeq,
                officeNumber: item.officeNumber,
                regDate: item.regDate,
                uniqueCode: item.uniqueCode,
                upFileSeq: item.upFileSeq,
                academyUpFileSeq: item.academyUpFileSeq,
              };
            })
          );
        }
      };

      request.get(`/api/member/profile/link/${userInfo.userSeq}/academys?userLevel=${userInfo.userLevel}`, null, successHandler);
    }
    // eslint-disable-next-line
  }, [reloadData, useLevels]);

  if (useLevels.isTutor || useLevels.isAcademy) {
    // Edit 타입 - 강사, 학원 관리자
    return (
      <>
        <h2 className='tit'>Academy / Tutor information</h2>
        <p className='sub_tit'>View information about Academy or Tutor associated with your account.</p>
        <div className='btn_area'>
          <button className='com_btn m point' onClick={() => setOpenEditModal(true)}>
            EDIT
          </button>
        </div>
        <article className='information_sec'>
          <ID accountInfo={academyInfo} size='big' />
          <ul className='input_area'>
            <li className='item'>
              <div className='tit_field'>Registration Date</div>
              <div className='input_field'>
                <input type='text' defaultValue={academyInfo.regDate} readOnly />
              </div>
            </li>
            <li className='item'>
              <div className='tit_field'>{useLevels.isAcademy ? 'Academy Code' : 'Tutor code'}</div>
              <div className='input_field'>
                <input type='text' defaultValue={academyInfo.uniqueCode} readOnly />
              </div>
            </li>
            <li className='item'>
              <div className='tit_field'>Office Number</div>
              <div className='input_field'>
                <input type='text' defaultValue={academyInfo.officeNumber} readOnly />
              </div>
            </li>
          </ul>
        </article>
        {/* <div className='btn_area'>
          <Link to="/signup/user/type" target="_new" className='com_btn m point line'>ADD ANOTHER ACCOUNT<i className='svg_icon icon_go blue after'></i></Link>
        </div> */}
      </>
    );
  } else if (useLevels.isStudent || useLevels.isTeacher) {
    // Link 타입 - 학생, 선생님
    return (
      <>
        <h2 className='tit'>{useLevels.isTeacher ? 'Academy' : 'Academy / Tutor'} information</h2>
        <p className='sub_tit'>View information about {useLevels.isTeacher ? 'Academy' : 'Academy or Tutor'} associated with your account.</p>
        <div className='btn_area'>
          {linkedAcademy.length === 0 && (
            <button className='com_btn m point' onClick={() => setLinkPop(true)}>
              <i className='svg_icon icon_link white before'></i>LINK AN {useLevels.isTeacher ? 'ACADEMY' : 'ACADEMY OR TUTOR'}
            </button>
          )}
        </div>
        {linkedAcademy.length === 0 ? (
          <>
            {/* link된 학원,강사가 없는 경우 */}
            <div className='no_academy'>
              <i className='svg_icon icon_no_link gray'>&nbsp;</i>
              You are not linked with any account.
              <br />
              Link with an Academy or Tutor to see their information.
            </div>
          </>
        ) : (
          <>
            {linkedAcademy.map((item, idx) => {
              return (
                <AcademyInformation
                  userInfo={userInfo}
                  key={`${item}-${idx}`}
                  info={item}
                  userLevel={item.uniqueCode[0] === 'A' ? USER_LEVELS.ACADEMY : USER_LEVELS.TUTOR}
                  handleClickCheckbox={changeActiveAcademy}
                />
              );
            })}
          </>
        )}

        {/* { // 멀티프로필은 추후 추가 예정
          userLevel !== "001002000000000" &&
          <div className='btn_area'>
            <Link to="/signup/user/type" target="_new" className='com_btn m point line'>ADD ANOTHER ACCOUNT
              <i className='svg_icon icon_go blue after'></i>
            </Link>
          </div>
        } */}
      </>
    );
  }
}
