import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//component
import Header from 'components/user/_Header';

const JoinType = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_landing'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_landing'); //다른 페이지에선 removeClass
    };
  }, []);
  return (
    <>
      <div className='container'>
        <Header>
          <p className='txt'>The door to educational aspiration</p>
        </Header>
        <section>
          <h2 className='tit'>Which type of account do you want to sign in to?</h2>
          <div className='signtypes'>
            <Link to='/signup/academy'>
              <div className='signtype'>
                <div className='img'></div>
                <div className='signtype_text'>
                  <h3 className='tit'>Academy Admin</h3>
                  <p className='txt'>I am an academy manager</p>
                </div>
              </div>
            </Link>
            <Link to='/signup/tutor'>
              <div className='signtype'>
                <div className='img'></div>
                <div className='signtype_text'>
                  <h3 className='tit'>Tutor</h3>
                  <p className='txt'>
                    I am a private tutor
                    <br />
                    AND/OR academy teacher
                  </p>
                </div>
              </div>
            </Link>
            <Link to='/signup/student'>
              <div className='signtype'>
                <div className='img'></div>
                <div className='signtype_text'>
                  <h3 className='tit'>Student</h3>
                  <p className='txt'>I am currently studying at a school</p>
                </div>
              </div>
            </Link>
            <Link to='/signup/parent'>
              <div className='signtype last'>
                <div className='img'></div>
                <div className='signtype_text'>
                  <h3 className='tit'>Parents</h3>
                  <p className='txt'>I am a mother OR a father of a student</p>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default JoinType;
