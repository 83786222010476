import { SET_CUSTOMER, CLEAR_CUSTOMER } from './ActionType';

const stateCustomerInit = {
  userSeq: 0, // 회원 시퀀스
  userName: '', // 이름
  userNickname: '', // 닉네임
  userId: '', // 회원 아이디
  userPasswd: '', // 회원 비번
  userJumin: '', // 주민번호
  userGender: '', // 성별
  birthdayType: '', // 생일구분
  userDob: '', // 생년월일
  userLevel: '', // 고객등급 (공통코드)
  userLevelName: '', // 고객등급 명
  schoolType: '', // 학교타입 (공통코드) ---┐
  schoolTypeName: '', // 학교타입 명    ---┘ 추가
  studentSchool: '', // 학교
  schoolLocation: '', // 학교 위치 (공통코드)
  companyName: '', // 학원 이름
  companyNum: '', // 학원 번호
  studentGrade: 0, // 학년
  managerLevel: '', // 직원등급 (공통코드)
  managerLevelName: '', // 직원등급 명
  userStatus: '', // 회원 상태 (공통코드)
  userStatusName: '', // 회원 상태
  userPhone: '', // 연락처
  userMobile: '', // 휴대전화 번호
  parentsPhone: '', // 부모님 연락처
  userEmail: '', // 이메일
  loginNotAllow: '', // 로그인 불가
  lastLogin: '', // 최근 접속일
  userPostcode: '', // 우편번호
  userAddress: '', // 주소
  userAddressDetail: '', // 상세주소
  userAddressJibun: '', // 지번 주소
  userAddressDetailJibun: '', // 지번 상세주소
  admissiveChannel: '', // 유입경로 (공통코드)
  bankName: '', // 환불 계좌 은행
  bankAccountNumber: '', // 환불 계좌 번호
  bankAccountHolder: '', // 예금주
  mailYn: false,
  regUserSeq: '', // 등록자
  regUserName: '', // 등록자
  regDate: '', // 등록일시
  modiUserSeq: '', // 수정자
  modiUserName: '', // 수정자 명
  modiDate: '', // 수정일시
};

const stateCustomer = (state = stateCustomerInit, action) => {
  let { type } = action;

  switch (type) {
    case SET_CUSTOMER:
      let newState = {
        ...state,
        [action.payload.fieldName]: action.payload.data,
      };

      return newState;

    case CLEAR_CUSTOMER:
      return stateCustomerInit;

    default:
      return state;
  }
};

export default stateCustomer;
