import React from 'react';
import { Link } from 'react-router-dom';
//component
import { Swiper, SwiperSlide } from 'swiper/react';
//img
import ImgSec0101 from 'assets/img/m_visual01.png';
import ImgSec0102 from 'assets/img/m_visual02.png';
import ImgSlide01 from 'assets/img/m_slide01.png';
import ImgSlide02 from 'assets/img/m_slide02.png';
import ImgSlide03 from 'assets/img/m_slide03.png';
import ImgSlide04 from 'assets/img/m_slide04.png';
import ImgSec0301 from 'assets/img/m_visual03.png';
import ImgIntro01 from 'assets/img/m_info01.jpg';
import ImgIntro02 from 'assets/img/m_info02.jpg';
import ImgIntro03 from 'assets/img/m_info03.jpg';
import ImgKakao from 'assets/img/m_info04.png';
import ImgNews01 from 'assets/img/m_card01.jpg';
import ImgNews02 from 'assets/img/m_card02.jpg';
import ImgNews03 from 'assets/img/m_card03.jpg';
import ImgNews04 from 'assets/img/m_card04.jpg';
import ImgNews05 from 'assets/img/m_card05.jpg';
//css
import 'swiper/css';

const IntroM = () => {
  return (
    <div className='p_intro_m'>
      <section className='sec_visual'>
        <p className='txt'>
          Digital SAT 2.0
          <br />
          모바일 서비스 제공까지
          <br />
          <b>얼마 안남았어요</b>
        </p>
        <article className='img_area'>
          <img src={ImgSec0101} alt='' className='img1' />
          <p className='txt'>*예시 이미지</p>
          <img src={ImgSec0102} alt='' className='img2' />
        </article>
      </section>
      <section className='sec_pc'>
        <p className='txt'>
          기다리실 필요없이
          <br />
          PC로 바로 이용하실 수 있어요!
        </p>
        <Swiper spaceBetween={15} slidesPerView={1.1} onSlideChange={() => console.log('slide change')} onSwiper={(swiper) => console.log(swiper)} autoplay>
          <SwiperSlide>
            <img src={ImgSlide01} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ImgSlide02} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ImgSlide03} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ImgSlide04} alt='' />
          </SwiperSlide>
        </Swiper>
        <Link to='https://kr.gateplus.org/freeOffer' className='btn'>
          Digital SAT 2.0을 이용하고 싶어요
        </Link>
      </section>
      <section className='sec_info'>
        <p className='tit'>
          Digital SAT 2.0
          <br />
          주변에서는 이렇게 사용해요
        </p>
        <img src={ImgSec0301} alt='' className='img' />
        <article className='item'>
          <p className='txt1'>
            SAT를 준비하는 다른 친구들은
            <br />
            <b>College Board와 유사한</b>
            <br />
            <b>고퀄리티의 문제</b>를 풀고 있어요!
          </p>
          <img src={ImgIntro01} alt='' />
          <p className='txt2'>
            공식시험과 성적 차이를 50점 내로 줄였어요.
            <br />
            문제 출제 전문팀이 지속적으로 개발하고 있어요.
          </p>
        </article>
        <article className='item'>
          <p className='txt1'>
            <b>College Board와 유사한</b>
            <br />
            <b>성적표</b>를 받고
            <br />
            성적 관리도 받고 있어요!
          </p>
          <img src={ImgIntro02} alt='' />
          <p className='txt2'>
            실제 점수와 오차가 적고 분석도 공식 성적표와
            <br />
            유사한 성적표를 간편하게 받아보세요.
          </p>
        </article>
        <article className='item'>
          <p className='txt1'>
            이미 대형 SAT 학원에서
            <br />
            <b>3500회 이상</b>
            <br />
            <b>GATE+의 SAT 모의고사</b>를<br />
            이용하였고,
            <br />
            <br />
            <b>성적표과 시험분석 서비스를 제공받고 있어요!</b>
          </p>
          <img src={ImgIntro03} alt='' />
          <p className='txt2'>
            수업을 위한 유용한 지표를 분석해서 제공해요.
            <br />
            유형별 정오표, 문제당 소요 시간, 기간별 점수 트렌드, 클래스 평균, 클래스와 모든 GATE+ 사용자 평균
          </p>
        </article>
        <Link to='https://kr.gateplus.org/freeOffer' className='btn'>
          제가 받을 수 있는 해택이 궁금해요
        </Link>
      </section>
      <section className='sec_kako'>
        <article className='tit'>
          <p className='point'>
            Digital SAT 2.0 <b>&</b> SAT
          </p>
          소식을 빠르게 받고 싶다면?
        </article>
        <img src={ImgKakao} alt='' />
        <Link to='https://pf.kakao.com/_prxbCG' className='btn'>
          카카오톡 채널 추가하고 소식받기
        </Link>
      </section>
      <section className='sec_news'>
        <p className='tit'>SAT의 최신 소식을 알려드려요</p>
        <Link to='https://kr.gateplus.org/Info-News/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=18436619&t=board' className='item'>
          <div className='badge'>
            <i className='svg_icon icon_search white'>&nbsp;</i>
            SAT Info
          </div>
          <div className='txt_area'>
            <p className='tit_field'>2024년 SAT 일자 및 접수 기한</p>
            <p className='cate'>GATE+</p>
            <p className='more'>Read more</p>
          </div>
          <div className='img_area'>
            <img src={ImgNews01} alt='' />
          </div>
        </Link>
        <Link to='https://kr.gateplus.org/Info-News/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=18528081&t=board' className='item'>
          <div className='badge'>
            <i className='svg_icon icon_search white'>&nbsp;</i>
            SAT Info
          </div>
          <div className='txt_area'>
            <p className='tit_field'>Digital SAT 응시자 인터뷰</p>
            <p className='cate'>GATE+</p>
            <p className='more'>Read more</p>
          </div>
          <div className='img_area'>
            <img src={ImgNews02} alt='' />
          </div>
        </Link>
        <Link to='https://kr.gateplus.org/Info-News/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=18456587&t=board' className='item'>
          <div className='badge'>
            <i className='svg_icon icon_search white'>&nbsp;</i>
            SAT Info
          </div>
          <div className='txt_area'>
            <p className='tit_field'>SAT 시험장 찾기</p>
            <p className='cate'>GATE+</p>
            <p className='more'>Read more</p>
          </div>
          <div className='img_area'>
            <img src={ImgNews03} alt='' />
          </div>
        </Link>
        <Link to='https://kr.gateplus.org/Info-News/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=18456219&t=board' className='item'>
          <div className='badge'>
            <i className='svg_icon icon_search white'>&nbsp;</i>
            SAT Info
          </div>
          <div className='txt_area'>
            <p className='tit_field'>시험 전 필수 정보</p>
            <p className='cate'>GATE+</p>
            <p className='more'>Read more</p>
          </div>
          <div className='img_area'>
            <img src={ImgNews04} alt='' />
          </div>
        </Link>
        <Link to='https://kr.gateplus.org/Info-News/?q=YToxOntzOjEyOiJrZXl3b3JkX3R5cGUiO3M6MzoiYWxsIjt9&bmode=view&idx=18455611&t=board' className='item'>
          <div className='badge'>
            <i className='svg_icon icon_search white'>&nbsp;</i>
            SAT Info
          </div>
          <div className='txt_area'>
            <p className='tit_field'>
              SAT Optional SAT 시대의 종말 <br />
              (The Hill 기사 간편 요약)시험장 찾기
            </p>
            <p className='cate'>GATE+</p>
            <p className='more'>Read more</p>
          </div>
          <div className='img_area'>
            <img src={ImgNews05} alt='' />
          </div>
        </Link>
        <Link to='https://kr.gateplus.org/Info-News' className='btn'>
          모든 정보 확인하기
        </Link>
      </section>
    </div>
  );
};

export default IntroM;
