import { useEffect, useState } from 'react';

const useResponsiveFontSize = (ref, initialFontSize = 20) => {
  const [fontSize, setFontSize] = useState(initialFontSize);

  useEffect(() => {
    const adjustFontSizeOnce = () => {
      if (ref.current && ref.current.parentNode) {
        let newFontSize = initialFontSize;
        const parentWidth = ref.current.parentNode.offsetWidth;
        ref.current.style.fontSize = `${newFontSize}px`;

        if (ref.current.scrollWidth > parentWidth) {
          while (ref.current.scrollWidth > parentWidth && newFontSize > 1) {
            newFontSize--;
            ref.current.style.fontSize = `${newFontSize}px`;
          }
        }

        setFontSize(newFontSize);
      }
    };

    adjustFontSizeOnce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fontSize;
};

export default useResponsiveFontSize;
