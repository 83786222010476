import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//component
import Header from 'components/user/_Header';
import Upload from 'components/user/_Upload';
import request from 'utils/Request.utils';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

const JoinUpload = () => {
  const navigate = useNavigate();
  const stateCustomer = useSelector((state) => state.Customer);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_upload'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_upload'); //다른 페이지에선 removeClass
    };
  }, []);

  // 알랏
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0,
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        alertType: 'alert',
        visible: false,
        alertMessage: '',
        activateMode: '',
        value: 0,
      };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  const signup = () => {
    setLoading(true);
    let params = {
      userId: stateCustomer.userEmail,
      userPasswd: stateCustomer.userPasswd,
      userEmail: stateCustomer.userEmail,
      userName: stateCustomer.userName,
      userNickname: stateCustomer.userName,
      userLevel: stateCustomer.userLevel,
      managerLevel: '002002000000000',
      userMobile: stateCustomer.userMobile,
      acceptReceivingMail: stateCustomer.mailYn,
      userStatus: '005001000000000',
      regUserSeq: 1,
      requestedAcademyName: stateCustomer.companyName,
      requestedAcademyContact: stateCustomer.companyNum,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let userSeq = response.result.userSeq;
        uploadActivate(userSeq);

        request.del(`/api/common/authen/code/${params.userEmail}`);
      }
    };

    request.post('/api/common/user', params, successHandler);
  };

  const uploadActivate = (userSeq) => {
    // console.log("전송", uploadFileList)

    const formData = new FormData();

    Object.values(uploadFileList).forEach((file) => formData.append('uploadFiles', file.fileData));

    // for(const fileInfo of uploadFileList) {
    //   console.log(fileInfo.fileTextData, fileInfo.fileData)
    // }

    const successHandler = (response) => {
      // console.log(response)
      setLoading(false);
      navigate('/signup/academy/complete');
    };

    request.postMultipart(`/api/common/upload/files/academy/${userSeq}`, formData, successHandler);
  };

  const submitBtn = () => {
    if (uploadFileList.length > 0) {
      signup();
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please upload the file.',
        };
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Header>
          <p className='sub_title'>BECOME A GATE⁺</p>
          <h1>ACADEMY ADMIN</h1>
          <hr className='divider' />
        </Header>
        <Upload uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} setAlertLayerPopup={setAlertLayerPopup} />
        <section className='btns'>
          {/* <button className="btn btn_stroke btn_middle">Back</button> */}
          {/* <button className="btn btn_fill btn_middle" onClick={ uploadActivate }>Submit</button> */}
          <button className='btn btn_fill btn_middle' onClick={submitBtn}>
            Submit
          </button>
        </section>
      </div>
      {isLoading && <LoadingBar />}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
};

export default JoinUpload;
