import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
//img
import ImgOk from 'assets/img/congratulation_illust.svg';
import { useDispatch } from 'react-redux';

const SignupComplete = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_complete'); //현재페이지에서만 addClass

    dispatch({ type: 'clearCustomer' });
    dispatch({ type: 'clearMenuInfo' }); // added by psk 20240313

    return () => {
      document.body.classList.remove('gate_member', 'signup_complete'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='container'>
        <Header>
          <hr className='divider' />
        </Header>
        <div className='success_message'>
          <h1>Thank you.</h1>
          <img src={ImgOk} alt='congratulation' className='icon' />
          <p className='txt'>
            Your account has been successfully created.
            <br />
            Please login with your email address.
          </p>
        </div>
        <Link to='/signin' className='btn btn_fill btn_big'>
          Go back to Sign in
        </Link>
      </div>
    </>
  );
};

export default SignupComplete;
