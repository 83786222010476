import React, { useState, useEffect } from 'react';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils.js';

//components
import ExamMaTms from './_components/ExamMaTms';
import ExamEnTms from './_components/ExamEnTms';
import ExamEnPsk from './_components/ExamEnPsk'; // 국이 테스트용

import CalculatorPopup from 'components/_common/modals/CalculatorModal';
import ReferenceSheetPopup from 'components/_common/modals/ReferenceSheetPopup';

/** 시험 - TMS 페이지 */
const ExamMain = () => {
  const [examMain, setExamMain] = useState({
    examMaTmsDisplay: false,
    examEnTmsDisplay: false,
    examEnPskDisplay: false,
    examMaPskDisplay: false,
  });

  const [examComponents, setExamComponents] = useState({
    rspDisplay: false,
    repExpand: false,
    cpDisplay: false,
    cpExpand: false,
    cpState: {},
    directionDisplay: false,
    timeAreaDisplay: true,
    annotationDisplay: false,
    annotationEmptyDisplay: false,
    moreDisplay: false,
    helpDisplay: false,
    helpItemDisplay: false,
    exitExamDisplay: false,
    miniReviewDisplay: false,
    reviewDisplay: false,
    isFiveMinutesRemaining: false,
    isMathJaxError: false,

    mathQuestionDisplay: false,
    englishQuestionDisplay: false,
  });

  const [currentQuestion, setCurrentQuestion] = useState({
    questionSeq: 0,
    multipleChoiceItems1: '',
    multipleChoiceItems2: '',
    multipleChoiceItems3: '',
    multipleChoiceItems4: '',
    questionSubject: '',
    testHow: '',
    questionFormat: '',
    questionDifficulty: '',
    fieldOfStudy: 0,
    researcher: 0,

    tryAnswer: '', // 기입한 답

    annotation: [
      {
        // 주석 부분
        annotationStart: 0,
        annotationEnd: 0,
        annotationContent: '',
      },
    ],

    bookMark: '', // 북마크 Y,N

    entryTime: '',
    exitTime: '',

    underLine1: 'N',
    underLine2: 'N',
    underLine3: 'N',
    underLine4: 'N',

    passage: '',
    question: '',
  });

  const query = new URLSearchParams(window.location.search);
  const viewQuestion = query.get('viewQuestion');

  const getCurrentQuestion = () => {
    let pms = {
      viewQuestion: viewQuestion,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let innerItem = response.result?.question;

        setCurrentQuestion((prev) => {
          return {
            ...prev,
            questionSeq: innerItem?.questionSeq,
            multipleChoiceItems1: nvl(innerItem?.multipleChoiceItems) !== '' ? innerItem?.multipleChoiceItems.split('||')[0] : '',
            multipleChoiceItems2: nvl(innerItem?.multipleChoiceItems) !== '' ? innerItem?.multipleChoiceItems.split('||')[1] : '',
            multipleChoiceItems3: nvl(innerItem?.multipleChoiceItems) !== '' ? innerItem?.multipleChoiceItems.split('||')[2] : '',
            multipleChoiceItems4: nvl(innerItem?.multipleChoiceItems) !== '' ? innerItem?.multipleChoiceItems.split('||')[3] : '',
            questionSubject: innerItem?.subject,
            testHow: innerItem?.testHow,
            questionFormat: innerItem?.questionFormat,
            questionDifficulty: innerItem?.questionDifficulty,
            fieldOfStudy: innerItem?.fieldOfStudy,
            researcher: innerItem?.researcher,
            passage: innerItem?.passage,
            question: innerItem?.question,
          };
        });
      }
    };

    request.get(`/api/common/question?viewQuestion=${encodeURIComponent(pms.viewQuestion)}`, null, successHandler).catch((error) => {
      console.error(error);
      alert('시험 TMS - 현재 시험 얻어오기 API 응답 실패');
    });
  };

  useEffect(() => {
    getCurrentQuestion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentQuestion?.questionSubject === 'E')
      setExamMain((prev) => {
        return { ...prev, examEnTmsDisplay: true };
      });
    else if (currentQuestion?.questionSubject === 'M')
      setExamMain((prev) => {
        return { ...prev, examMaTmsDisplay: true };
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion?.questionSubject]);

  return (
    <>
      {examMain.examEnTmsDisplay && ( // 영어 미리보기
        <ExamEnTms
          setExamMain={setExamMain}
          examComponents={examComponents}
          setExamComponents={setExamComponents}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
      {examMain.examMaTmsDisplay && ( // 수학 미리보기
        <ExamMaTms
          setExamMain={setExamMain}
          examMaDisplay={examMain.examMaTmsDisplay}
          examComponents={examComponents}
          setExamComponents={setExamComponents}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
      {examMain.examEnPskDisplay && ( // 영어 국이 테스트
        <ExamEnPsk
          setExamMain={setExamMain}
          examComponents={examComponents}
          setExamComponents={setExamComponents}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
      {examComponents.cpDisplay && ( // 계산기 팝업
        <CalculatorPopup
          setExamComponents={setExamComponents}
          examComponents={examComponents}
          cpDisplay={examComponents.cpDisplay}
          cpExpand={examComponents.cpExpand}
        />
      )}
      {examComponents.rspDisplay && ( // Reference Sheet 팝업
        <ReferenceSheetPopup setExamComponents={setExamComponents} rspDisplay={examComponents.rspDisplay} repExpand={examComponents.repExpand} />
      )}
    </>
  );
};

export default ExamMain;
