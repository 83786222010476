import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LoadingBar from 'utils/LoadingBar';

const FindPasswd = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);

  const navigate = useNavigate();

  const [signupInfo, setSignupInfo] = useState({
    name: true,
    email: true,
    notFound: true,
  });

  const nameRef = useRef('');
  const emailRef = useRef('');

  const [isLoading, setLoading] = useState(false);

  const nextBtnClick = () => {
    let allPassed = true;

    if (nvl(emailRef.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, email: false };
      });
      allPassed = false;
      emailRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, email: true };
      });

    if (nvl(nameRef.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, name: false };
      });
      allPassed = false;
      nameRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, name: true };
      });

    if (allPassed) {
      findPw();
    }
  };

  const findPw = () => {
    setLoading(true);
    let pms = {
      userEmail: emailRef.current.value,
      userName: nameRef.current.value,
    };

    const successHandler = (response) => {
      console.log(response);
      if (response.code === 200) {
        let findEmail = response.result.userId;

        if (nvl(findEmail) === '') {
          setSignupInfo((prev) => {
            return { ...prev, name: false, email: false, notFound: false };
          });
        } else {
          navigate('complete', { state: findEmail });
        }
        setLoading(false);
      }
    };

    request.post('/api/common/email/passwd', pms, successHandler);
  };

  return (
    <>
      <div className='container'>
        <Header>
          <p className='desc'>The door to educational aspiration</p>
        </Header>
        <section className='forgot_email'>
          <h1 className='tit'>Forgot your password?</h1>
          <p className='txt'>
            Type in your name and email
            <br />
            to find your password.
          </p>
        </section>
        <section className='form'>
          {/* 이름 */}
          <article className={`input_wrap ${signupInfo.name ? '' : 'error'}`}>
            {/*틀릴 경우 addClass error*/}
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>NAME</span>
            </h2>
            <div className='box forgot_name'>
              <input className='input' type='text' ref={nameRef} />
            </div>
            <p className='error_message'>{signupInfo.notFound ? 'Please type in your name.' : 'Your name was not found. Please try again'}</p>
          </article>
          {/* 메일 */}
          <article className={`input_wrap ${signupInfo.email ? '' : 'error'}`}>
            {/*틀릴 경우 addClass error*/}
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>Email</span>
            </h2>
            <div className='box forgot_mail'>
              <input className='input' type='email' ref={emailRef} />
            </div>
            <p className='error_message'>{signupInfo.notFound ? 'Please type in your email.' : 'Your email was not found. Please try again'}</p>
          </article>
          <button className='btn btn_fill forgot_btn btn_big' onClick={() => nextBtnClick()}>
            Submit
          </button>
        </section>
      </div>
      {isLoading && <LoadingBar />}
    </>
  );
};

export default FindPasswd;
