import React, { useEffect, useState } from 'react';
import SimpleModalBase from './_SimpleModalBase';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import important from 'assets/img/svg/important.svg';
import { ReactComponent as Logo } from 'assets/img/svg/logo_gateplus_row_01.svg';
import { ReactComponent as XIcon } from 'assets/img/svg/x-close.svg';

// 🔍 검색 키워드 - #모달 #세팅
/** 게이트키 사용 불가 모달 */
function FreezingKeyModal({ hideModal }) {
  return (
    <SimpleModalBase onEscape={() => hideModal()} w='32.875rem' h='23.6875rem' p='0' gap='1.88rem'>
      <div className='modal_img_wrapper'>
        <img src={important} alt='세모 안에 느낌표' />
      </div>

      <FreezingKeyContainer>
        <h2>Refund in progress</h2>
        <div className='modal_text_container'>
          <p style={{ fontSize: '0.9375rem' }}>
            While refund is being processed, you cannot set additional tests.
            <br /> Please wait until your refund is completed.
          </p>
        </div>
      </FreezingKeyContainer>
      <div className='modal_btn_container' style={{ marginTop: '0.93rem' }}>
        <button className='btn_fill' onClick={() => hideModal()}>
          OK
        </button>
      </div>
    </SimpleModalBase>
  );
}

const FreezingKeyContainer = styled.div`
  h2 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.94rem; // 기존 scss 덮어쓰기
    white-space: pre-line; // \n으로 줄바꿈
  }
  p {
    fontsize: 0.9375rem;
    font-weight: 400;
    text-align: center;
    white-space: pre-line; // \n으로 줄바꿈
  }
`;

export default FreezingKeyModal;
