import React, { useEffect, useState } from 'react';
import SimpleModalBase from './_SimpleModalBase';
import Cookies from 'js-cookie';
import dayjsTZ from 'utils/functions/time/dayjs-config';
import styled from 'styled-components';
import { ReactComponent as ImageIconAnnotationAlert01 } from 'assets/img/svg/icon-annotation-alert-01.svg';
import { ReactComponent as Logo } from 'assets/img/svg/logo_gateplus_row_01.svg';
import { ReactComponent as XIcon } from 'assets/img/svg/x-close.svg';

// 🔍 검색 키워드 - #모달 #추석
/** 추석 연휴 모달 컴포넌트 */
export default function ModalChuseok() {
  const [showPopup, setShowPopup] = useState(false);
  const cookiesId = 'popupCloseDateChuseok';
  useEffect(() => {
    const today = dayjsTZ().toISOString().split('T')[0];
    const popupCloseDate = Cookies.get(`${cookiesId}`);
    if (popupCloseDate !== today) {
      setShowPopup(true);
    }
  }, []);

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  /** 오늘 그만 보기 버튼 */
  const handleStopToday = () => {
    const today = dayjsTZ().toISOString().split('T')[0];

    Cookies.set(`${cookiesId}`, today, { expires: 1 });
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <SimpleModalBase noPad={true}>
      <S.Wrap>
        <div className='modal_title_container'>
          <Logo className='logo' />
          <button onClick={handleClosePopup}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>
        <div className='contents-area'>
          <ImageIconAnnotationAlert01 />
          <h2>Happy Holidays from GATE+!</h2>
          <p>
            During the Chuseok holiday from September 14th to 18th KST, we will be on standby in case of critical errors or other issues related to our service.
            If any problems arise, please feel free to contact us at our main number, 02-6951-0904 or chat with us through Channel Talk. We will respond
            promptly to your issues if you have any.
          </p>
          <p>Thank you and happy holidays!</p>
        </div>
        <div className='button-area'>
          <button onClick={handleStopToday}>Enough for today</button>
          <button onClick={handleClosePopup} className='black'>
            Close
          </button>
        </div>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 340px;
  padding: 1.875rem;
  box-sizing: content-box;
  .modal_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eaeaea;
    .logo {
      width: 100px;
    }
  }
  .contents-area {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
    padding: 1.25rem 0;
    h2 {
      font-size: 1rem;
      font-weight: bold;
    }
    p {
      font-size: 0.875rem;
    }
    .time {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .button-area {
    display: flex;
    gap: 0.5rem;
    button {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.625rem 0;
      border: 1px solid #000000;
      font-size: 0.875rem;
      &.black {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
`;
