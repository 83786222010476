import BasicColumnChart from 'components/_common/chart/BasicColumnChart';
import React from 'react';

/** 학생 대시보드 페이지의 "Advanced Report" Section 의 "Correct answer rate by Questions" 영역 컴포넌트 */
export default function CorrectAnswerRateByQuestions({
  perSubject,
  setPerSubject,
  setPerModuleNum,
  subjectBox,
  filterValue,
  testResult,
  handleClickGraphItemField,
  perModuleNum,
  moduleBox,
  setFilterValue,
  filteredList,
  filteredList2,
  timePerQuestionData,
}) {
  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'state01 pointer';
    else if (contentDomain === '008002000000000') return 'state02 pointer';
    else if (contentDomain === '008003000000000') return 'state03 pointer';
    else if (contentDomain === '008004000000000') return 'state04 pointer';
    else if (contentDomain === '009001000000000') return 'state01 pointer';
    else if (contentDomain === '009002000000000') return 'state02 pointer';
    else if (contentDomain === '009003000000000') return 'state03 pointer';
    else if (contentDomain === '009004000000000') return 'state04 pointer';

    return '';
  };

  const perSubActive = () => {
    setPerSubject((prev) => !prev);
  };

  const perNumActive = () => {
    setPerModuleNum((prev) => !prev);
  };

  const questionCheck = (e, item) => {
    // console.log("암호화", item.seqStr, nvl(filterValue.classSeq)) // 작업중
    window.open(`/student/review?viewQuestion=${encodeURIComponent(item.uthSeqStr + 'GATEPLUS' + item.seqStr)}`, '_blank');
  };

  const selectedTimeSubject = () => {
    return filterValue.tmSubject === 'E' ? 'RW' : filterValue.tmSubject === 'M' ? 'MATH' : '-';
  };

  const selectedTimeModuleNum = () => {
    let module;
    if (filterValue.moduleNum === '1') module = 'Module 1';
    else if (filterValue.moduleNum === '2' && filterValue.tmDifficulty === 'E') module = 'Module 2 Easy';
    else if (filterValue.moduleNum === '2' && filterValue.tmDifficulty === 'H') module = 'Module 2 Hard';
    else if (filterValue.moduleNum === '2') module = 'Module 2';
    else module = '-';
    return module;
  };

  return (
    <article className='box_graph active correct_answer_sec'>
      <div className='top_area'>
        <p className='tit_field'>Correct answer (rate) by Questions</p>
        <div className={`com_select_layer ${perSubject ? 'active' : ''}`} onClick={perSubActive} ref={subjectBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            <input type='text' className='selected' placeholder={selectedTimeSubject()} size='5' />
          </section>
          <section className='layer'>
            {testResult.subject !== 'M' && (
              <p className='option' onClick={() => handleClickGraphItemField('rw')}>
                RW
              </p>
            )}
            {testResult.subject !== 'E' && (
              <p className='option' onClick={() => handleClickGraphItemField('math')}>
                MATH
              </p>
            )}
          </section>
        </div>
        <div className={`com_select_layer type_tit ${perModuleNum ? 'active' : ''}`} onClick={perNumActive} ref={moduleBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            {/* <p className="tit_field">Module</p> */}
            <input type='text' className='selected' placeholder={selectedTimeModuleNum()} size='10' />
          </section>
          <section className='layer'>
            <p
              className='option'
              onClick={() =>
                setFilterValue((prev) => {
                  return { ...prev, moduleNum: '1' };
                })
              }>
              Module 1
            </p>
            <p
              className='option'
              onClick={() =>
                setFilterValue((prev) => {
                  return { ...prev, moduleNum: '2' };
                })
              }>
              Module 2
            </p>
          </section>
        </div>
      </div>
      <div className='content_area'>
        <table className='table'>
          <thead>
            <tr>
              <th className='first'>Question No.</th>
              {filteredList.map((item, index) => (
                <th key={index} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
                  {item.questionOrder}
                  {item.reviewBookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='first'>{testResult.ecSeq === 0 ? 'GATEPLUS' : 'Class'} Average</td>
              {filteredList2.map((item, index) =>
                item.per <= 50 ? (
                  <td key={index} className='rate01'>
                    {item.per}%
                  </td>
                ) : item.per <= 75 ? (
                  <td key={index} className='rate02'>
                    {item.per}%
                  </td>
                ) : (
                  <td key={index}>{item.per}%</td>
                )
              )}
            </tr>
            <tr>
              <td>Result</td>
              {filteredList.map((item, index) => (
                <td key={index}>
                  {item.correctAnswerCnt === 1 ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        {/*Time per Question*/}
        <div className='box_graph active time_per_question'>
          <div className='top_area'>
            {' '}
            <span className='tit_field'>Time per Question</span>{' '}
          </div>
          <div className='chart_area'>
            {/* <StackedColumnsChart timePerQuestionData={timePerQuestionData}/> */}
            <BasicColumnChart timePerQuestionData={timePerQuestionData} />
          </div>
        </div>
      </div>
    </article>
  );
}
