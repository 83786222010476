import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';
import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';
import useOutsideClick from 'hooks/useOutsideClick';

// class list layer
const MultiClassList = ({ item, selectMultiClass, setSelectMultiClass, index, selectMultiClassIdx }) => {
  let classList = !!item ? JSON.parse(item) : [];

  // const multiClassLayerRef = useRef(null);
  // useOutsideClick(multiClassLayerRef, () => setSelectMultiClass(false), selectMultiClass);

  return (
    <>
      <StyledMultiClassLayer className={classList && classList.length > 1 ? 'class_name' : ''}>
        {selectMultiClass && index === selectMultiClassIdx && (
          <StyleMultiClassList className='com_list_layer active'>
            <div className='modal-title-section'>
              <p className='tit_area'>
                Class List
                <button
                  type='button'
                  className='svg_icon btn_pop_close black'
                  style={{ width: '1rem', height: '1rem' }}
                  onClick={() => setSelectMultiClass(false)}
                />
              </p>
            </div>
            <div className='list_area scroll'>
              {classList.map((item) => {
                return (
                  <p className='item' key={`class_${uuidv4()}`}>
                    <i className='svg_icon icon_report'>&nbsp;</i>
                    {item.class_name}
                  </p>
                );
              })}
            </div>
          </StyleMultiClassList>
        )}
      </StyledMultiClassLayer>
    </>
  );
};

export default MultiClassList;

const StyledMultiClassLayer = styled.div`
  display: inline-flex;
  position: relative;
  top: 70%;
  left: 0;
  margin-top: 5px;
`;

const StyleMultiClassList = styled.div`
  position: absolute;
  top: 200% !important;
  left: 0 !important;
`;
