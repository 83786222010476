import React, { useState, useCallback, useEffect } from 'react';
// import Calendar from 'react-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getRBCEventColor } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';

// components
import Toolbar from './CalendarToolbar';
import DateHeader from './CalendarDateHeader';
import DateCellWrapper from './CalendarDateCellWrapper';
import SetTimeLayer from 'components/_common/setTime'; //시간 세팅

//css
import 'react-big-calendar/lib/css/react-big-calendar.css';

moment.locale('ko-KR');
const localizer = momentLocalizer(moment);
const today = moment();

export default function CalendarTable({ currentDate, setCurrentDate, events, setEvents, classInfo, setClassInfo }) {
  const [selectedEventTitle, setSelectedEventTitle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const location = useLocation();

  const eventsForThisDay = (slotInfo) => {
    const { action, start, end, slots } = slotInfo;

    if (slots.length > 1) {
      slots.forEach((item) => {
        // console.log("기간 : " + moment(item).format("YYYY-MM-DD"))
      });
    } else {
      // console.log("하루 : " + moment(start).format("YYYY-MM-DD"))
    }

    setSelectedEventTitle(false);
    setSelectedEvent(null);
  };

  const selectDate = (event) => {
    setSelectedEventTitle(event.title);
    setSelectedEvent(event);
    // console.log("SelectDate!")

    // setReservationDateList(reservationMonthList.filter((item) => {
    //   const isCanceled = event.title.includes("취소") && item.reser_status === "C";
    //   const isChanged = event.title.includes("변경") && item.reser_status === "R" && item.modi_date !== null;
    //   const isMorning = event.title.includes("오전") && parseInt(item.reser_time.split(":")[0]) < 12;
    //   const isAfternoon = event.title.includes("오후") && parseInt(item.reser_time.split(":")[0]) >= 12;
    //   const isMatchedDate = moment(item.reser_date).isSame(moment(event.start), "day");

    //   return (isMatchedDate && (isCanceled || isChanged || isMorning || isAfternoon));
    // }));
  };

  // 개발중이던 커스텀 코드 - 개발 방향 확정시 삭제
  // const [popupInfo, setPopupInfo] = useState({ isOpen: false, props: {}, position: {} });
  // const handleShowMore = (events, date) => {
  //   setPopupInfo({
  //     isOpen: true,
  //     props: { events, date },
  //   });
  // };

  return (
    <div className='CalendarTable'>
      <Calendar
        defaultDate={location.state ? location.state.selectedDate : currentDate._d}
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        defaultView='month'
        style={{ minHeight: 974 }}
        components={{
          toolbar: (props) => <Toolbar {...props} classInfo={classInfo} setClassInfo={setClassInfo} events={events} setEvents={setEvents} />,
          month: {
            dateHeader: DateHeader,
          },
          dateCellWrapper: DateCellWrapper,
        }} // 이 부분 삭제하면 default 옵션이 나옴
        onSelectSlot={(slotInfo) => {
          // console.log(slotInfo)

          eventsForThisDay(slotInfo);
        }}
        eventPropGetter={(event) => {
          const barColorInfo =
            moment(event.end) < today._d
              ? {
                  name: 'gray',
                  backgroundColor: '#CFCFCF',
                  color: '#606060',
                  borderColor: '#BEBEBE',
                }
              : getRBCEventColor().find((item) => item.name === event.classColor);

          return {
            style: {
              ...barColorInfo,
              marginBottom: '4px',
              borderWidth: '2px',
              borderStyle: 'solid',
              outline: event === selectedEvent ? '2px solid rgb(13, 101, 255, 0.7)' : 'none',
            },
          };
        }}
        onSelectEvent={selectDate}
        onDragStart={() => {
          return;
        }}
        selectable
        popup={true}
        onNavigate={(date, _) => {
          setCurrentDate(moment(date));
        }}
        // 개발중이던 커스텀 코드 - 개발 방향 확정시 삭제
        // onShowMore={(events, date, targetElement) => handleShowMore(events, date, targetElement)}

        messages={{ showMore: (count) => `${count} More...` }}
      />
      {/* 개발중이던 커스텀 코드 - 개발 방향 확정시 삭제 */}
      {/* {popupInfo.isOpen && <CalendarPopup props={popupInfo.props} />} */}

      {/*more 클릭 시 팝업 ( 개발 후 퍼블 적용 예정 )*/}
      {/* <div className='com_set_layer type_day active'>
        <button className='svg_icon btn_pop_close'>&nbsp;</button>
        <div className='date'>
          <span className='day'>31</span>
          <span className='week'>Sunday</span>
        </div>
        <div className='test_item_list'>
          <div className='item blue'>Tag blue</div>
          <div className='item orange'>Tag orange</div>
          <div className='item red'>Tag red</div>
          <div className='item yellow'>Tag yellow</div>
          <div className='item green'>Tag green</div>
          <div className='item dark_green'>Tag dark_green</div>
          <div className='item light_blue'>Tag light_blue</div>
          <div className='item purple'>Tag purple</div>
          <div className='item gray'>Tag gray</div>
          <div className='item black'>Tag black</div>
        </div>
      </div> */}
      {/*시간 세팅*/}

      {/* <SetTimeLayer btnType='skip'/> */}
    </div>
  );
}
