// Packages
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MathJax } from 'better-react-mathjax';
// Constants
import { GLOBAL_MA_TEST_TIME } from '../_utils/constants/examTimeSetting';
// Assets
import noInternetIcon from 'assets/img/svg/no_internet_icon.svg';
import 'styles/css/exam.css';
// Components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import ModuleOver from '../_components/ModuleOver';
import HelpPopup from '../_components/popups/HelpPopup';
import ReferenceSheetPopup from './_components/popups/ReferenceSheetPopup';
import CalculatorPopup from './_components/popups/CalculatorPopup';
import ExitPopup from './_components/popups/ExitPopup';
import FiveMinutesAlert from '../_components/alerts/FiveMinutesAlert';
import MathJaxAlert from '../_components/alerts/MathJaxAlert';
import useExitQuestion from '../_hooks/useExitQuestion';
import ExamHeader from './_components/examLayout/examHeader/ExamHeader';
import ExamBody from './_components/examLayout/examBody/ExamBody';
import ExamFooter from './_components/examLayout/examFooter/ExamFooter';
// Components - Modal
import ImageModal from 'components/_common/modals/ImageModal';
import ExamLoadingModal from 'components/_common/modals/ExamLoadingModal';
// Functions
import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils.js';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';
// hook
import useOnlineStatus from 'hooks/useOnlineStatus.js';
import useCheckBrowserVisibility from '../_hooks/useCheckBrowserVisibility';
import {
  deleteFullTimeToLocalStorage,
  deleteInProgressToLocalStorage,
  deleteTimeDiffToLocalStorage,
  getFullTimeFromLocalStorage,
  getRestTimeFromLocalStorage,
  getTimeDiffFromLocalStorage,
  getTimeInProgressLocalStorage,
  setEntryTimeToLocalStorage,
  setFullTimeToLocalStorage,
  setRestTimeToLocalStorage,
  deleteLocalStorageTime,
} from '../_utils/functions/timerLocalStorageFunctions';

// #exam #math #page
/** 수학 문제 렌더링 컴포넌트 */
export default function ExamMathPage() {
  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지

  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////////
  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /** 오프라인시 창 닫기 알림 */
  const offlineModalInfo = {
    modalImage: noInternetIcon,
    alertTitle: 'Unstable network connection',
    alertText:
      'Your network connection is low or unstable.\nTest window will be closed, but all your work will be saved.\nYou can continue your test when your network becomes\nstable.',
    buttonTextAndOnClick: [{ text: 'OK', onClick: () => window.close() }],
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  /** 온/오프라인 상태 체크 */
  const isOnline = useOnlineStatus();
  const userInfo = request.tokenDecoder();
  const { fetch: fetchExitResultHistory } = useExitQuestion();
  /** 전체 남은 시험 시간 (number, 고정값) */
  const fullTime = getFullTimeFromLocalStorage();
  /** 지난 시험 시간 */
  const timeDiff = getTimeDiffFromLocalStorage();
  /** 남은 시간 (실시간) */
  const restTime = getRestTimeFromLocalStorage();
  /** 재 접속 시, 받아 온 이전 시험의 남은 시간 */
  const inProgressTime = getTimeInProgressLocalStorage();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  /** 현재 페이지 컴포넌트의 모든 로딩 상태(한 번에 하나의 로딩만 진행 되는 동기적 요청 로딩) */
  const [isLoading, setIsLoading] = useState(false);
  /** 로컬 스토리지 초기 설정 완료 여부 */
  const [isCompleteInitLocalStorage, setIsCompleteInitLocalStorage] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 제출 시간 (다음 모듈로 넘어 갈 때, 시간을 다 썼을 때, 쉬는 시간 끝났 을 때, 시험 창 재진입 할 때 설정 됨) */
  const sendTimeRef = useRef(LocalStorage.getItemJsonParse('sendTime'));
  const tryAnswerRef = useRef([]);
  /** 문제 번호 별 기억용 */
  const currentExamInfoRef = useRef([]);

  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 실행문 시작 ////////////
  useCheckBrowserVisibility();
  /////////////////// 외부 패키지 및 기타 React Hook 실행문 끝 ////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 문제 페이지 열 때(진입, 문제 하나 마다 매번 실행) */
  const openQuestion = (currentNum) => {
    console.log('문제 페이지 열 때 API 요청');
    let params = {
      uthSeq: stateExamInfo.uthSeq,
      questionSeq: stateExamInfo.questionList[currentNum - 1]?.questionSeq,
      tryAnswer: stateExamInfo.questionList[currentNum - 1]?.tryAnswer,
      bookmarkYn: stateExamInfo.questionList[currentNum - 1]?.bookMark,
      underLine1: nvl(stateExamInfo.questionList[currentNum - 1]?.underLine1),
      underLine2: nvl(stateExamInfo.questionList[currentNum - 1]?.underLine2),
      underLine3: nvl(stateExamInfo.questionList[currentNum - 1]?.underLine3),
      underLine4: nvl(stateExamInfo.questionList[currentNum - 1]?.underLine4),
      testModuleSeq: stateExamInfo.questionList[currentNum - 1]?.testModuleSeq,
      regUserSeq: userInfo?.userSeq,
      remainingTime: restTime,
    };

    const meta = {
      question_subject: stateExamInfo.questionList[currentNum - 1]?.questionSubject,
      question_format: stateExamInfo.questionList[currentNum - 1]?.questionFormat,
      question_difficulty: stateExamInfo.questionList[currentNum - 1]?.questionDifficulty,
    };

    params.metaData = JSON.stringify(meta);

    const successHandler = (response) => {
      console.log('문제 페이지 열 때 API 응답 : ', response);
      if (response.code === 200) {
        currentExamInfoRef.current = response.result.tResultSeq;

        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentNum', data: currentNum } });

        tryAnswerRef.current[currentNum] = stateExamInfo.questionList[currentNum - 1]?.tryAnswer;

        try {
          window.opener.tResultSeqRefUpdate(response.result.tResultSeq);
        } catch (e) {
          window.close();
        }
      }
      setIsLoading(false);
    };

    // 남은 시간이 -17억초가 찍힐때 문제 발생
    console.log(`\n┏━━━ 🚨🚨🚨 params.remainingTime 🚨🚨🚨 ━━━\n`, params.remainingTime, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

    if (!isLoading) {
      setIsLoading(true);
      request.post('/api/exam/resultHistory', params, successHandler).catch((error) => {
        console.error(error);
        alert('수학 시험 - 문제 페이지 열 때 API 응답 실패');
      });
    }
  };

  /** 다음 모듈 & 다음 색션 넘어 갈 때 동작 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: false } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });

      deleteLocalStorageTime();

      if (alertLayerPopup.activateMode === 'nextModule') {
        // 다음 모듈 넘어갈 때
        fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });
        // navigate('/exam/dsat/math', { state: { currentNum: 0 } }); // openQuestion 반응하게 하기 위함

        tryAnswerRef.current = [];
        currentExamInfoRef.current = null;

        // ----------------------------------------------------

        let currentExamInfo = stateExamInfo.currentTest;
        currentExamInfo = { ...currentExamInfo, mm1: 'D', mm2: 'I' };
        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });
        dispatch({ type: 'setExamComponents', payload: { fieldName: 'moduleOverDisplay', data: true } });

        // ----------------------------------------------------
      } else if (alertLayerPopup.activateMode === 'nextSection') {
        // 수학 시험 끝났을 때
        fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });

        let currentExamInfo = stateExamInfo.currentTest;
        currentExamInfo = { ...currentExamInfo, mm2: 'D' };
        dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });
        deleteFullTimeToLocalStorage();
        console.log('다음 모듈 & 다음 색션 넘어 갈 때 동작, 수학 시험 끝났을 때');

        navigate('/exam/dsat/completion', {
          replace: true,
        }); // 완료 처리는 Completion 페이지에서
      }
    }
  };

  /** 모듈 1이 끝나고, 시간 설정 */
  const resetTime = (currentTime) => {
    sendTimeRef.current.time = currentTime;
    // setFullTimeToLocalStorage({ fullTime: GLOBAL_MA_TEST_TIME });
    // setRestTimeToLocalStorage({ restTime: GLOBAL_MA_TEST_TIME });
    deleteTimeDiffToLocalStorage();
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (stateExamInfo.currentTest.mm1 === 'D' && stateExamInfo.currentTest.mm2 === 'I' && !fullTime) {
      // 최초 진입 시 (모듈 2) , 타이머 설정
      if (inProgressTime) {
        console.log('inProgress 로 재 접속한 시험인가? 수학 모듈 2');
        // inProgress 로 재 접속한 시험인가?
        setFullTimeToLocalStorage({ fullTime: inProgressTime });
        setRestTimeToLocalStorage({ restTime: inProgressTime });
      } else {
        console.log('Start 로 최초 접속한 시험인가? 수학 모듈 2');
        // Start 로 최초 접속한 시험인가?
        setFullTimeToLocalStorage({ fullTime: GLOBAL_MA_TEST_TIME });
        setRestTimeToLocalStorage({ restTime: GLOBAL_MA_TEST_TIME });
      }
    }
  }, [stateExamInfo, fullTime, inProgressTime]);

  useEffect(() => {
    if (inProgressTime) {
      console.log('inProgress 로 재 접속한 시험인가? 수학 모듈 1');
      // inProgress 로 재 접속한 시험인가?
      setFullTimeToLocalStorage({ fullTime: inProgressTime });
      setRestTimeToLocalStorage({ restTime: inProgressTime });
      deleteInProgressToLocalStorage();
    } else {
      // Start 로 최초 접속한 시험인가?
      if (!fullTime) {
        console.log('Start 로 최초 접속한 시험인가? 수학 모듈 1');
        // 최초의 진입 시 (새로고침 X, 모듈 1), 타이머 설정
        setFullTimeToLocalStorage({ fullTime: GLOBAL_MA_TEST_TIME });
        setRestTimeToLocalStorage({ restTime: GLOBAL_MA_TEST_TIME });
      } else {
        console.log('최초가 아닌 경우 (새로 고침, 수학 모듈 1, 2)');
        // 최초가 아닌 경우 (새로 고침, 모듈 1, 2), 타이머 설정
        setFullTimeToLocalStorage({ fullTime: restTime });
      }
    }

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인
    return () => {
      window.removeEventListener('message', handleMessageEvent);

      // 현재 페이지 컴포넌트 언마운트 useEffect
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 모듈 내에서 시험 문제를 이동 할 때 useEffect
    if (timeDiff) {
      setEntryTimeToLocalStorage({ entryTime: timeDiff });
    }

    return () => {
      if (nvlNumber(state?.currentNum) > 0) {
        fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.currentNum]);

  useEffect(() => {
    // 모듈을 이동 하거나, 모듈 내에서 시험 문제를 이동 할 때 useEffect
    if (nvlNumber(state?.currentNum) > 0) {
      openQuestion(state?.currentNum);
    } else {
      console.error('state?.currentNum 의 값은 0이 될 수 없습니다. ');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.currentNum, stateExamInfo.currentTest]);

  useEffect(() => {
    if (restTime !== null) {
      setIsCompleteInitLocalStorage(true);
    } else {
      setIsCompleteInitLocalStorage(false);
    }
  }, [restTime]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div
        onContextMenu={(e) => e.preventDefault()}
        className={`com_popup pop_exam active ${stateExamComponents.directionDisplay ? 'direction_on' : ''} ${stateExamComponents.cpDisplay && stateExamComponents[`${stateExamComponents.fieldName}`]?.questionFormat !== 'S' ? 'calculator_on' : ''}`}>
        <div className='pop_container'>
          {isCompleteInitLocalStorage && (
            <ExamHeader setAlertLayerPopup={setAlertLayerPopup} tryAnswerRef={tryAnswerRef} currentExamInfoRef={currentExamInfoRef} sendTimeRef={sendTimeRef} />
          )}
          <ExamBody tryAnswerRef={tryAnswerRef} isLoading={isLoading} setIsLoading={setIsLoading} />
          <ExamFooter isLoading={isLoading} setAlertLayerPopup={setAlertLayerPopup} />

          {/*완료 5분전 alert*/}
          <FiveMinutesAlert />
          {/* MathJax 랜더링 실패 alert */}
          <MathJaxAlert />
        </div>
      </div>
      {/*help 팝업*/}
      {stateExamComponents.helpDisplay && <HelpPopup />}
      {/*exit 팝업*/}
      {stateExamComponents.exitExamDisplay && <ExitPopup isLoading={isLoading} setIsLoading={setIsLoading} />}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      )}
      {stateExamComponents.moduleOverDisplay && <ModuleOver closeCustomAlert={closeCustomAlert} subject='M' resetTime={resetTime} />}
      <CalculatorPopup />
      {stateExamComponents.rspDisplay && <ReferenceSheetPopup />}
      <ImageModal
        modalImage={offlineModalInfo.modalImage}
        buttonTextAndOnClick={offlineModalInfo.buttonTextAndOnClick}
        alertTitle={offlineModalInfo.alertTitle}
        alertText={offlineModalInfo.alertText}
        showModal={!isOnline}
      />
      {isLoading && <ExamLoadingModal />}
    </>
  );
}
