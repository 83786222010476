import styled from 'styled-components';

/**
 * @component
 * 작은 버튼용 기본 버튼 베이스 컴포넌트
 * @example
 * ```jsx
 *  <ButtonBase type='type' onClick={() => callBack()}>
 *    버튼_텍스트
 *  </ButtonBase>
 * ```
 * @param {React.ReactNode} children - 버튼 내부에 표시할 content
 * @param {function} onClick - 버튼을 눌렀을 때 실행할 함수
 * @param {string} text - 버튼에 보여줄 텍스트
 * @param {string} type - 사용할 버튼스타일 (default, tableBtn 등) / default: 'default'
 * @param {string} radius - 버튼의 테두리 둥글기 (default, medium) / default: 'default'
 * @param {string|null} w - 버튼의 너비(width) / default: null(명시적 width 없음)
 * @param {string|null} h - 버튼의 높이(height) / default: null(명시적 height 없음)
 * @param {string|null} bgColor - 버튼의 배경색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} color - 버튼의 글자색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} borderColor - 버튼의 테두리 색 / default: null(preset 또는 기본값 사용)
 * @param {string|null} hoverColor - 호버 시 버튼의 배경색 / default: null(기본 호버 효과 사용)
 * @param {boolean} dataHover - 호버 시 툴팁 표시 여부 / default: false
 * @param {boolean} icon - 아이콘 버튼 여부 / default: false
 * @param {string|null} size - 아이콘 버튼일 경우 사이즈 / default: null
 * @param {object} style - 추가로 적용할 스타일 / default: {}
 * @returns {JSX.Element} 버튼 컴포넌트 반환
 */
function ButtonBase({
  children = null,
  onClick,
  type = 'default',
  radius = 'default',
  text = 'TEXT',
  w = null,
  h = null,
  bgColor = null,
  color = null,
  borderColor = null,
  hoverColor = null,
  dataHover = false,
  icon = false,
  size = null,
  style = {},
}) {
  const presetStyle = buttonPresets[type];
  const buttonRadius = buttonBorderRadius[radius];

  return (
    <S.ButtonBaseContainer
      onClick={onClick}
      data-hover={dataHover ? text : null}
      w={w}
      h={h}
      bgColor={bgColor ?? presetStyle.bgColor}
      color={color ?? presetStyle.color}
      borderColor={borderColor ?? presetStyle.borderColor}
      hoverColor={hoverColor}
      icon={icon}
      size={size}
      radius={buttonRadius}
      style={style}>
      {children ? children : text}
    </S.ButtonBaseContainer>
  );
}

export default ButtonBase;

// --- --- ---

const buttonPresets = {
  default: {
    bgColor: '#ffffff',
    color: '#000000',
    borderColor: '#EAEAEA',
  },
  tableBtn: {
    bgColor: '#F2F2F2',
    color: '#7F8998',
    borderColor: 'none',
  },
};
const buttonBorderRadius = {
  default: '0.25rem',
  medium: '0.625rem',
};

const S = {}; // styled-components 객체(S-dot 컨벤션용)

S.ButtonBaseContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  gap: 0.5rem; // SvgIconTextBtn 컴포넌트에서 아이콘과 텍스트 사이의 간격을 위한 gap

  border-radius: ${(props) => props.radius};
  padding: ${(props) => (props.icon ? '0' : '0.375rem 0.625rem')}; // 아이콘 버튼일 경우 padding 없음
  width: ${(props) => (props.icon ? props.size : props.w ?? 'auto')}; // 아이콘 버튼일 경우 size로 width, height 설정
  height: ${(props) => (props.icon ? props.size : props.h ?? 'auto')};

  border: 1px solid ${(props) => props.borderColor ?? '#000000'};
  color: ${(props) => props.color ?? '#000000'};
  background-color: ${(props) => props.bgColor ?? '#ffffff'};
  transition: all 0.1s ease-out;

  ${(props) => props.style}; // style props로 추가한 스타일(오버라이딩)

  &:hover {
    ${(props) => (props.hoverColor ? `background-color: ${props.hoverColor};` : 'filter: brightness(95%);')}
  }

  // 버튼 텍스트
  span {
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
    white-space: pre-line; // \n으로 줄바꿈
  }
  i {
    margin: 0 !important; // common.scss에 있는 svg_icon 클래스의 margin을 0으로 초기화
  }

  /// data-hover 속성에 따른 툴팁 스타일 ///
  &::before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 0.3s ease-in-out;

    position: absolute;
    z-index: 9999;
    left: 50%;
    bottom: 120%;
    transform: translateX(-50%);
    display: ${(props) => (props['data-hover'] ? 'block' : 'none')};
  }
  &:hover::before {
    content: attr(data-hover);
    opacity: 1;
    visibility: visible;
  }
`;
