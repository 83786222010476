////// "Set Tests" 전역 상태 리덕스 액션 타입 ///////

/** Set Tests : 사용자 선택 */
export const UPDATE_SELECTED_USER_TYPE = 'UPDATE_SELECTED_USER_TYPE';
/** Set Tests : 반 선택 */
export const UPDATE_SELECTED_CLASS = 'UPDATE_SELECTED_CLASS';
/** Set Tests : 학생 목록 수정 */
export const UPDATE_SELECTED_STUDENT_LIST = 'UPDATE_SELECTED_STUDENT_LIST';
/** Set Tests : 과목 선택 */
export const UPDATE_SELECTED_CONFIG = 'UPDATE_SELECTED_CONFIG';
/** Set Tests : 모듈 선택 */
export const UPDATE_SELECTED_MODULES = 'UPDATE_SELECTED_MODULES';
/** Set Tests : 날짜/시간 선택 */
export const UPDATE_SELECTED_DATE = 'UPDATE_SELECTED_DATE';
/**  Set Tests 전역 상태 초기화 */
export const CLEAR_SET_TESTS_STATE = 'CLEAR_SET_TESTS_STATE';
/**  Set Tests 에서 선택한 상품의 총 가격(Key) 전역 상태 초기화 */
export const UPDATE_TOTAL_GATE_KEY_STATE = 'UPDATE_TOTAL_GATE_KEY_STATE';
