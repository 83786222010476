import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

import '../styles/css/LoadingBar.css';
import useScrollLock from 'hooks/useScrollLock';

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.2;
  z-index: 9990;
`;

const LoadingBar = ({ type, color }) => {
  useScrollLock();
  return (
    <>
      <ModalOverlay visible='true' />
      <ReactLoading type={type} color={color} height={70} width={70} className='LoadingBar' />
    </>
  );
};

export default LoadingBar;
