/**
 * 모달, 팝업 등에서 스크롤을 막는 hook
 */

import { useEffect } from 'react';

const useScrollLock = (isActive = true) => {
  useEffect(() => {
    if (!isActive) return;

    document.body.classList.add('scroll_lock');

    return () => {
      document.body.classList.remove('scroll_lock');
    };
  }, [isActive]);
};

export default useScrollLock;
