import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
//img
import ImgOk from 'assets/img/message_icon.svg';
import { useDispatch } from 'react-redux';

const SuccessSubmit = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_complete'); //현재페이지에서만 addClass

    dispatch({ type: 'clearCustomer' });
    dispatch({ type: 'clearMenuInfo' }); // added by psk 20240313

    return () => {
      document.body.classList.remove('gate_member', 'signup_complete'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='container'>
        <Header>
          <hr className='divider' />
        </Header>
        <h1 className='tit'>Successfully submitted!</h1>
        <img src={ImgOk} alt='' className='icon' />
        <ul className='num_list'>
          <li>
            <i className='num'>1</i>In 1~2 business days, you will receive your Tutor Code to the email address you have submitted once you are verified.
          </li>
          <li>
            <i className='num'>2</i>You can login with the Tutor Code link when you receive it. Make sure to remember your Tutor Code as your students will use
            your Tutor Code to link with you.
          </li>
        </ul>
        <div className='btns'>
          <Link to='/' className='btn btn_fill btn_middle'>
            Return Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default SuccessSubmit;
