import SessionStorage from 'utils/SessionStorage.utils';

/** 부모창에서 전달되는 이벤트를 처리하는 핸들러 */
export const handleMessageEvent = (event) => {
  // react dev tool과 같은 외부 프로그램으로 인한 오류 방지
  if (event.origin !== window.location.origin) return;

  if (event.data && event.data.validAccess === true) {
    console.log('정상적인 접근입니다.');
    SessionStorage.setItem('exam', 'validAccess', 'true');
  }
};

/** 부모창에서 정상적으로 열린 시험인지 확인하는 함수 */
export const checkValidAccess = () => {
  if (SessionStorage.getItemJsonParse('exam')?.validAccess !== 'true') {
    window.location.href = '/404';
    // window.open('about:blank', '_self');
  }
};
