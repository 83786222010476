import React, { useEffect } from 'react';

import { nvl, nvlNumber } from '../utils/Common.utils';
import LocalStorage from '../utils/LocalStorage.utils';

const AuthCheck = () => {
  const storageHandler = function () {
    const userSession = LocalStorage.getItemJsonParse('userSession');

    if (nvl(userSession?.accessToken) === '') window.close();
  };

  window.addEventListener('storage', storageHandler, false);
};

export default AuthCheck;
