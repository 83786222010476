/**
 * props로 Text를 받고 지정된 문구를 보여주는 컴포넌트
 */
// <MiniSvgIconBtn text='보여줄 문구' />

import styled from 'styled-components';

export default function SMiniTextBtn({ text, bgColor = '#E8F0FF', color = '#0068BD' }) {
  return (
    <IconBtn bgColor={bgColor} color={color}>
      <Text color={color}>{text}</Text>
      <TextBallon>Account created through the platform</TextBallon>
    </IconBtn>
  );
}

const TextBallon = styled.div`
  font-size: 11px;
  display: none;
  position: absolute;
  width: 220px;
  height: 39px;
  left: -16px;
  bottom: -50px;
  justify-content: center;
  align-items: center;

  background: #dff1ff;
  color: #4692d1;
  border-radius: 4px;
  padding: 10px 12px;
  z-index: 1;

  &:after {
    border-top: 0px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #dff1ff;
    content: '';
    position: absolute;
    top: -6px;
    left: 13px;
  }
`;

const getBackgroundColor = (props) => props.bgColor;
const IconBtn = styled.div`
  background-color: ${getBackgroundColor};
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.125rem;

  &:hover ${TextBallon} {
    display: flex;
  }
`;
const getColor = (props) => props.color;
const Text = styled.span`
  font-size: 9px;
  color: ${getColor};
`;
