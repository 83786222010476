import React from 'react';
import parse from 'html-react-parser';

import usePressESC from 'hooks/usePressESC.js';

const CustomAlert = (props) => {
  usePressESC(props.onClose);

  const confirmClose = () => {
    props.returnValue('OK');
    props.onClose();
  };

  const cancelClose = () => {
    props.returnValue('');
    props.onClose();
  };

  return (
    <>
      <section className='com_popup type_alert active'>
        <div className='pop_container'>
          <button type='button' className='svg_icon btn_pop_close btnPopClose' onClick={cancelClose}></button>
          <div className='pop_body'>
            <h1 className='alert_title'>{parse(props.alertMessage)}</h1>
          </div>
          {props.alertType === 'confirm' ? (
            <div className='pop_footer com_btn_wrap buttonWrapper'>
              <button type='button' className='com_btn m point btnPopClose confirmButton' onClick={confirmClose}>
                Confirm
              </button>
              &nbsp;&nbsp;&nbsp;
              <button type='button' className='com_btn m lightgray btnPopClose cancelButton' onClick={cancelClose}>
                Cancel
              </button>
            </div>
          ) : props.alertType === 'okOnly' ? (
            <div className='pop_footer com_btn_wrap buttonWrapper'>
              <button type='button' className='com_btn m point btnPopClose confirmButton' onClick={confirmClose}>
                Confirm
              </button>
            </div>
          ) : (
            <div className='pop_footer com_btn_wrap buttonWrapper'>
              <button type='button' className='com_btn m point btnPopClose confirmButton' onClick={cancelClose}>
                Confirm
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CustomAlert;
