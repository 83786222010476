import React, { useState } from 'react';
import TestViewHeaderBtn from '../common/buttons/TestViewHeaderBtn';
import iconTestViewLogo from 'assets/img/svg/icon_test_view_gateplus_logo.svg';
import iconTestViewWithAnswer from 'assets/img/svg/icon_test_view_withAnswer.svg';
import iconTestViewWithoutAnswer from 'assets/img/svg/icon_test_view_withoutAnswer.svg';
import iconTestViewColumns01 from 'assets/img/svg/icon_test_view_columns_01.svg';
import iconTestViewColumns02 from 'assets/img/svg/icon_test_view_columns_02.svg';
import iconTestViewPrinter from 'assets/img/svg/icon_test_view_printer.svg';
import styled from 'styled-components';

/** PDF Viewer 페이지의 헤더 영역 컴포넌트 */
export default function PdfViewerHeader({
  testList,
  isSingleCol,
  setIsSingleCol,
  isStudentUserLevel,
  showAnswer,
  setShowAnswer,
  isSafari,
  isHorizontal,
  setIsHorizontal,
}) {
  const [print, setPrint] = useState(false);

  /** 프린트 버튼 동작 함수 */
  const handlePrintBtn = () => {
    if (isSafari) {
      // 사파리 브라우저에서 프린트 버튼
      const isConfirm = window.confirm(
        'The button does not work in the Safari browser. Press the cmd (⌘) + P keys on your keyboard to use the browsers own printer function.'
      );
      if (isConfirm) {
        window.alert('Press the cmd (⌘) + P keys on your keyboard to use the browsers own printer function.');
      }
    } else {
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.print();
        setPrint(false);
      }, 200);
    }
  };

  return (
    <S.wrap>
      <div className='test_view_header_inner'>
        <section className='test_view_header_logo_section'>
          <img src={iconTestViewLogo} alt='logo' />
          <p>{testList && testList[0]?.test_name}</p>
        </section>

        <section className='test_view_header_btn_section'>
          {/* {!isSingleCol && (
            <TestViewHeaderBtn btnText={'col'} onClick={() => setIsHorizontal((prev) => !prev)} btnState={isHorizontal} btnStateContent={['col', 'row']} />
          )} */}
          <TestViewHeaderBtn
            btnImage={iconTestViewColumns01}
            btnText={'1 Columns'}
            onClick={() => setIsSingleCol((prev) => !prev)}
            btnState={!isSingleCol}
            btnStateContent={[iconTestViewColumns02, '2 Columns']}
          />
          {!isStudentUserLevel && (
            <TestViewHeaderBtn
              btnImage={iconTestViewWithoutAnswer}
              btnText={'Without\nAnswer'}
              onClick={() => setShowAnswer((prev) => !prev)}
              btnState={!showAnswer}
              btnStateContent={[iconTestViewWithAnswer, 'With\nAnswer']}
            />
          )}
          <TestViewHeaderBtn btnImage={iconTestViewPrinter} btnText={'Print'} onClick={handlePrintBtn} />
        </section>
      </div>
    </S.wrap>
  );
}

const S = {};

S.wrap = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100vw;
  height: 6rem;
  background-color: black;
  @media print {
    visibility: hidden;
  }
  .test_view_header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    margin: 0 auto;
    height: 6rem;

    @media print {
      visibility: hidden;
      width: 100%;
    }

    .test_view_header_logo_section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      font-size: 1.25rem;
      font-weight: 700;
      > img {
        width: 2rem;
        height: 2rem;
      }
      > p {
        color: white;
      }
    }

    .test_view_header_btn_section {
      display: flex;
      gap: 0.5rem;
      .test_view_header_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 5rem;
        height: 4.25rem;
        background-color: #121212;
        border-radius: 10px;
        .test_view_header_btn_inner {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 0.5rem;

          > img {
            width: 1.5rem;
            height: 1.5rem;
          }
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.5rem;
            span {
              white-space: pre-wrap;
              color: white;
              font-size: 0.75rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;
