/**
 * 사용자의 ESC 키 입력을 감지하고 함수를 실행하는 훅
 */

import { useEffect } from 'react';

const usePressESC = (onEscape, isActive = true) => {
  useEffect(() => {
    if (!isActive) return;

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onEscape?.();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onEscape, isActive]);
};

export default usePressESC;
