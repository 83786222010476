import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

import IconEmpty from 'assets/img/icon_empty.png';
import useOutsideClick from 'hooks/useOutsideClick';

import ButtonBase from 'components/_common/button/_ButtonBase';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';

import Paging from 'layout/Paging';
import ClassList from 'components/com/tests/_components/_ClassList';
import DeleteModal from 'components/_common/modals/DeleteModal';
import DeleteStudentAlert from '../DeleteStudentAlert';
import DraggableProfilePopup from 'components/_common/modals/DraggableProfilePopup';

/** tests/scheduled 페이지의 "By Individual Test" 텝에 해당하는 컨텐츠 영역 컴포넌트 */
function ByIndividualTest({
  tabState,
  setAlertLayerPopup,
  setSearchInfo,
  searchInfo,
  scheduleInfo,
  setActiveTest,
  setEditTest,
  setScheduleInfo,
  activeTest,
  classEditClick,
  getSchedules,
  getTestClassMember,
  schedulesClassListReload,
}) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });
  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);
  /** 학생 삭제 confirm 팝업 */
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  /** 학생 삭제 팝업 */
  const [deleteStudent, setDeleteStudent] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const deleteStudentAlertOpen = () => {
    setDeleteStudent((prevState) => !prevState);
    setDeleteStudentAlert((prevState) => !prevState);
  };

  /** 시험 일정 삭제 API 호출 */
  const delUserTestSchedule = () => {
    setIsDeleteLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        // 체크 박스 초기화
        setSelectCheckBox((prev) => {
          return { ...prev, selectedKeys: [] };
        });
        // 성공 창
        deleteStudentAlertOpen();
        // 리로드
        schedulesListReload();
        schedulesClassListReload();

        getTestClassMember();

        setIsDeleteLoading(false);
      }
    };

    request
      .del(`/api/test/schedules?seqStr=${encodeURI(selectCheckBox.seqStr)}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "시험 일정 삭제" API 응답 에러', error));
  };

  const deletePopupOpen = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length > 0) {
      setDeleteStudent(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const scheduleTrClick = (customSetSeq, testName) => {
    // 중복 제거2
    setActiveTest((prev) => {
      return { ...prev, customSetSeq: customSetSeq, testName: testName };
    });
  };

  /** 체크 박스 변경 핸들러 */
  const handleCheckboxChange = (e, uthSeq) => {
    e.stopPropagation();

    const { selectedKeys, seqStr } = selectCheckBox;

    if (e.target.checked) {
      const updatedSelectedKeys = [...selectedKeys, uthSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** edit */
  const uthEditClick = (item) => {
    setEditTest((prev) => {
      return { ...prev, active: true, item: item, type: 'student' };
    });
  };

  /** 프로필 불러오기 API 요청 */
  const getProfileView = (e, userSeq) => {
    e.stopPropagation();

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let studentInfo = response.result.studentInfo;

        let imageUrl = null;

        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }

        studentInfo.profile = imageUrl;

        setScheduleInfo((prev) => {
          return { ...prev, studentInfoList: studentInfo };
        });
        setProfileState(true);
      }
    };

    request
      .get(`/api/test/profiles?userSeq=${userSeq}&ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "프로필 불러오기" API 응답 에러', error));
  };

  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const schedulesListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getSchedules();
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate]);

  useEffect(() => {
    // 반 별 전체 예정된 시험 조회
    if (tabState && scheduleInfo.currentPage === 1) {
    } else {
      schedulesListReload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <div className='sec_individual'>
        <div className='com_list'>
          <section className='top'>
            <article className='btn_area'>
              <MiniSvgIconBtn text='Delete' svgClassName='svg_icon icon_trash' onClick={deletePopupOpen} radius='medium' dataHover />
            </article>
            <article className='search_area'>
              <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
              <div
                className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                onClick={() =>
                  setSearchInfo((prev) => {
                    return {
                      ...prev,
                      searchFieldActive: !searchInfo.searchFieldActive,
                    };
                  })
                }
                ref={searchFiledRef}>
                {/*클래스 active 포함 시 옵션 노출*/}
                <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                <div className='layer'>
                  <p className='option' onClick={() => searchFiledClick('Name')}>
                    Name
                  </p>
                  <p className='option' onClick={() => searchFiledClick('Test')}>
                    Test
                  </p>
                </div>
              </div>
              <div className='search'>
                <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} />
                <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                  <i className='svg_icon icon_search blue before'></i>
                  SEARCH
                </button>
              </div>
            </article>
          </section>
          <table className='list'>
            <colgroup>
              <col width='56px' />
              <col width='' />
              <col width='90px' />
              <col width='110' />
              <col width='110' />
              <col width='117' />
              <col width='66' />
            </colgroup>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Test</th>
                <th>Time</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Name</th>
                <th>Profile</th>
              </tr>
            </thead>
            <tbody>
              {scheduleInfo.uthList.length > 0 ? (
                scheduleInfo.uthList.map((item, idx) => {
                  return (
                    <tr
                      className={activeTest.customSetSeq === item.customSetSeq ? 'active' : ''}
                      key={`scheduleTr_${idx}`}
                      onClick={() => scheduleTrClick(item.customSetSeq, item.testName)}>
                      <td>
                        <input
                          type='checkbox'
                          className='com_chk'
                          id={`schedule_${idx}`}
                          checked={selectCheckBox.selectedKeys.includes(item.uthSeq)}
                          onChange={(e) => handleCheckboxChange(e, item.uthSeq)}
                        />
                        <label htmlFor={`schedule_${idx}`}>&nbsp;</label>
                      </td>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>
                          <button onClick={() => uthEditClick(item)}>
                            <i className='svg_icon icon_annotate gray before'>&nbsp;</i>
                          </button>
                          {item.testName}
                        </p>
                      </td>
                      <td>{moment(item.testStartDate).format('A h : mm')}</td>
                      <td>{moment(item.testStartDate).format('MM / DD / YYYY')}</td>
                      <td>{moment(item.testEndDate).format('MM / DD / YYYY')}</td>
                      <td className='alignL'>{item.userName}</td>
                      <td>
                        <ButtonBase type='tableBtn' onClick={(e) => getProfileView(e, item.userSeq)}>
                          View
                        </ButtonBase>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='7'>
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Paging
            totalCount={scheduleInfo.totalCount}
            currentPage={scheduleInfo.currentPage}
            numPerPage={scheduleInfo.numPerPage}
            pagePerBlock={scheduleInfo.pagePerBlock}
            nextPageMove={nextPageMove}
          />
        </div>
        <ClassList
          clickEvent={classEditClick}
          testName={activeTest.testName}
          classList={activeTest.classMemberList}
          getSchedules={getSchedules}
          getTestClassMember={getTestClassMember}
          setAlertLayerPopup={setAlertLayerPopup}
        />
      </div>
      {/* 학생 상세 프로필 팝업 */}
      {profileState && <DraggableProfilePopup setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
      {/* 학생 삭제 팝업 */}
      {deleteStudent && (
        <DeleteModal
          hideModal={() => setDeleteStudent(false)}
          callback={delUserTestSchedule}
          text={'You are removing scheduled test. \n Your Gate Key will be refunded.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      {deleteStudentAlert && <DeleteStudentAlert setDeleteStudentAlert={setDeleteStudentAlert} />}
      {isDeleteLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
}

export default ByIndividualTest;
