import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
//component
import Header from 'components/user/_Header';
const FindEmail = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);
  const { state } = useLocation();
  return (
    <div className='container'>
      <Header>
        <p className='desc'>The door to educational aspiration</p>
      </Header>
      <section className='forgot_email'>
        <h1 className='forgot_title'>Forgot your email?</h1>
        <div className='your'>
          <p className='txt'>Your email is :</p>
          <p className='txt'> {state}</p>
        </div>
      </section>
      <section className='btns'>
        <Link to='/signin/find/passwd' className='btn btn_stroke btn_middle'>
          Find password
        </Link>
        <Link to='/signin' className='btn btn_fill btn_middle'>
          Go back to Sign in
        </Link>
      </section>
    </div>
  );
};

export default FindEmail;
